import React from 'react';
import PropTypes from 'prop-types';
import { Card as PrimeCard } from 'primereact/card';
import { styled } from 'styled-components';

// Styled component using styled-components
const StyledCard = styled(PrimeCard)`
  border: 1px solid #dcdcdc;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;

  .p-card-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #333333;
  }

  .p-card-subtitle {
    font-size: 1em;
    color: #666666;
  }

  .p-card-content {
    font-size: 0.9em;
    color: #444444;
    padding: 0;
  }

  .p-card-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

function Card({
  children,
  className,
  title,
  subtitle,
  footer,
}) {
  return (
    <StyledCard
      className={className}
      title={title}
      subTitle={subtitle}
      footer={footer}
    >
      {children}
    </StyledCard>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: '',
  subtitle: '',
  className: '',
  footer: null,
  children: null,
};

export default Card;
