export const SPREAD_SHEET_FIELDS = {
  firstName: 'First Name', lastName: 'Last Name', countryCode: 'Country Code', phoneNumber: 'Mobile Number', city: 'City', address: 'Address', zip: 'Zip Code', state: 'State',
};
export const CONTACT_FIELDS = ['firstName', 'lastName', 'countryCode', 'phoneNumber', 'address', 'zip', 'city', 'state'];

export const TEMPLATE_KEYS = {
  WITH_COUNTRY_CODE: 'with_country_code',
  WITHOUT_COUNTRY_CODE: 'without_country_code',
};

export const TEMPLATE_OPTIONS = [
  { label: 'With Country Code', value: TEMPLATE_KEYS.WITH_COUNTRY_CODE },
  { label: 'Without Country Code', value: TEMPLATE_KEYS.WITHOUT_COUNTRY_CODE },
];

export const IMPORT_TYPE_KEYS = {
  ADD_NEW: 'add_new',
  ADD_AND_UPDATE: 'add_and_update',
};

export const IMPORT_TYPE_OPTIONS = [
  { label: 'Add new contacts', value: IMPORT_TYPE_KEYS.ADD_NEW },
  { label: 'Add new & update existing', value: IMPORT_TYPE_KEYS.ADD_AND_UPDATE },
];

export const IMPORT_CONTACT_ACTION_KEYS = {
  REPLACE: 'replace',
  ADD_IF_EMPTY: 'add_if_empty',
  APPEND: 'append',
};

export const IMPORT_CONTACT_ACTIONS = [
  { label: 'Replace', value: IMPORT_CONTACT_ACTION_KEYS.REPLACE },
  { label: 'Add if Empty', value: IMPORT_CONTACT_ACTION_KEYS.ADD_IF_EMPTY },
  { label: 'Append', value: IMPORT_CONTACT_ACTION_KEYS.APPEND },
];
