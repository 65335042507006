import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { axiosPatch } from '../../../services/http.service';
import ModalLoader from '../../../components/Common/ModalLoader';
import { setShowReassignNumberModal } from '../../../store/phone-number/actions';
import UserSelect from '../../../components/Common/UserSelect';
// import { renderUserAvatar } from '../../../helpers/commonHelpers';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';

const validationSchema = Yup.object().shape({
  assignedUser: Yup.array().required('Please select a number to assign'),
});

// const mapToSelectOption = (user) => ({
//   value: user?.id,
//   label: renderUserAvatar(user?.name),
// });
class ReassignNumber extends Component {
  constructor(props) {
    super(props);
    const { numberInfo } = this.props;
    const selectedOptions = numberInfo?.assignedUserIds?.map((user) => user?.id);
    this.state = {
      showModalSpinner: false,
      initialValues: {
        assignedUser: selectedOptions || [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { numberInfo } = this.props;
    const selectedOptions = numberInfo?.assignedUserIds?.map((user) => user?.id);
    if (numberInfo?.assignedUserIds !== prevProps?.numberInfo?.assignedUserIds) {
      this.setState({
        initialValues: {
          assignedUser: selectedOptions,
        },
      });
    }
  }

  // to toggle the modal
  toggle = () => {
    const { onSetShowReassignNumberModal } = this.props;
    onSetShowReassignNumberModal();
  };

  // to re-assign number to another user
  handleReassignNumber = async (assignedUserIds) => {
    try {
      this.setState({ showModalSpinner: true });
      const { numberInfo, getAllNumbers } = this.props;
      const response = await axiosPatch(`numbers/${numberInfo?.id}`, {
        assignedUserIds,
      });
      if (response?.status) {
        this.setState({ showModalSpinner: false });
        toast.success(response?.message || 'Number Re-assigned successfully', {
          position: 'top-right',
        });
        getAllNumbers();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! Something went wrong', {
          position: 'top-right',
        });
        this.setState({ showModalSpinner: false });
      }
    } catch (error) {
      this.setState({ showModalSpinner: true });
      console.error('error while re-assigning number :', error);
    }
  };

  // to re-assign whatsapp number to another user
  handleReassignWhatsappNumber = async (assignedUserIds) => {
    try {
      this.setState({ showModalSpinner: true });
      const { numberInfo, getAllNumbers } = this.props;
      const response = await axiosPatch(`whatsapp-numbers/${numberInfo?.id}`, {
        assignedUserIds,
      });
      if (response?.status) {
        this.setState({ showModalSpinner: false });
        toast.success(response?.message || 'Whatsapp Number Re-assigned successfully', {
          position: 'top-right',
        });
        getAllNumbers();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! Something went wrong', {
          position: 'top-right',
        });
        this.setState({ showModalSpinner: false });
      }
    } catch (error) {
      this.setState({ showModalSpinner: true });
      console.error('error while re-assigning whatsapp number :', error);
    }
  };
  // Formik onSubmit

  handleOnSubmit = (values) => {
    const { channeltype } = this.props;

    switch (channeltype) {
      case CHANNEL_TYPES.TEXT:
        this.handleReassignNumber(values.assignedUser);
        break;
      case CHANNEL_TYPES.WHATSAPP:
        this.handleReassignWhatsappNumber(values.assignedUser);
        break;
      default:
        console.warn('Unsupported channel type:', channeltype);
        break;
    }
  };

  render() {
    const { showReassignNumberModal, className } = this.props;
    const { showModalSpinner, initialValues } = this.state;
    return (
      <Modal isOpen={showReassignNumberModal} className={className}>
        {showModalSpinner ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          Re-assign Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors, touched, setFieldValue, values,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label me-2">User</Label>
                      <UserSelect
                        className={`${errors.user && touched.user ? ' is-invalid' : ''}`}
                        selectedOptions={values.assignedUser}
                        onChange={(option) => {
                          setFieldValue('assignedUser', option);
                        }}
                        isMulti
                      />
                      <ErrorMessage
                        name="assignedUser"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ReassignNumber.propTypes = {
  className: PropTypes.any,
  onSetShowReassignNumberModal: PropTypes.func,
  numberInfo: PropTypes.object,
  getAllNumbers: PropTypes.func,
  showReassignNumberModal: PropTypes.bool,
  channeltype: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowReassignNumberModal: () => dispatch(setShowReassignNumberModal()),
});

const mapStateToProps = ({ phoneNumber }) => ({
  showReassignNumberModal: phoneNumber.showReassignNumberModal,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReassignNumber));
