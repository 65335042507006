import SHOW_OPT_FORM_MODAL from './actionTypes';

const INIT_STATE = {
  showOptFormModal: false,
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_OPT_FORM_MODAL:
      return {
        ...state,
        showOptFormModal: !state.showOptFormModal,
      };

    default:
      return state;
  }
};

export default user;
