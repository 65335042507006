import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Column from '../../../../components/Column';
import Dropdown from '../../../../components/Dropdown';
import DataTable from '../../../../components/DataTable/DataTableV1';
import Label from '../../../../components/Label';
import { IMPORT_CONTACT_ACTIONS } from '../../../../constants/importcontacts.constant';
import { tabs } from '../../../../constants/tabs';
import {
  resetImportContactsForm, setLoader, setImportContactsStepThreeData, setImportContactsStepTwoData, showToast,
} from '../../../../store/actions';
import * as contactGroupServices from '../../../../services/api/contactGroups.service';
import {
  createFilteredContacts, phoneNumberValidation, activeCountryCode, detectCountryCallingCode, countryCodeValidation,
} from '../../../../helpers/importContacts.helper';
import * as contactServices from '../../../../services/api/contacts.service';

const StyledCard = styled(Card)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0rem;
`;

const MappingTable = styled(DataTable)`
  margin-top: 1rem;
`;

const StyledDropdown = styled(Dropdown)`
  .p-dropdown {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .p-dropdown-label {
    padding: 0.5rem 0.75rem;
  }
  .p-dropdown-trigger {
    padding: 0 0.75rem;
  }
`;

const FieldIcon = styled.i`
  margin-right: 0.5rem;
  font-size: 1.25rem;
  color: var(--blue-600);
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Step3() {
  const [contactFields, setContactFields] = useState([]);
  const [contactListColumns, setContactListColumns] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    contactData,
    fieldMappings,
    segmentName,
    marketingOptIn,
    tags,
    selectAll,
  } = useSelector((state) => ({
    contactData: state.contacts.importContacts.step2.contactData,
    fieldMappings: state.contacts.importContacts.step2.fieldMappings,
    segmentName: state.contacts.importContacts.step2.segmentName,
    marketingOptIn: state.contacts.importContacts.step2.marketingOptIn,
    tags: state.contacts.importContacts.step2.tags,
    selectAll: state.contacts.importContacts.step3.selectAll,
  }));

  const selectedFieldTemplate = (option) => {
    if (option) {
      const field = contactFields?.find((f) => f?.value === option?.value);
      return (
        <div className="flex align-items-center">
          <FieldIcon className={field?.icon} />
          <span>{option.label}</span>
        </div>
      );
    }
    return <span>Select Field</span>;
  };

  const handleFieldMappingChange = useCallback((index, field, value) => {
    const fieldObj = contactFields?.find((f) => f?.value === value);

    dispatch(setImportContactsStepTwoData((prevState) => {
      const updatedMappings = cloneDeep(prevState.fieldMappings);
      updatedMappings[index][field] = value;
      updatedMappings[index].isCustomField = !!fieldObj?.isCustomField;

      return { ...prevState, fieldMappings: updatedMappings };
    }));
  }, [contactFields, dispatch]);

  // eslint-disable-next-line react/function-component-definition
  const FieldsOptionTemplate = (option) => {
    const { label, value } = option;
    const field = contactFields?.find((f) => f?.value === value);
    return (
      <div className="flex align-items-center">
        <FieldIcon className={field?.icon} />
        <span>{label}</span>
      </div>
    );
  };

  const handleGetContactFields = async () => {
    dispatch(setLoader(true));
    try {
      const response = await contactServices.getContactsFields();

      if (response?.status) {
        setContactFields(response?.data);
        setContactListColumns([
          {
            field: 'excelColumn',
            header: 'Excel Column Name',
            body: (rowData) => (
              <StyledLabel disabled={!rowData.active}>{rowData.excelColumn}</StyledLabel>
            ),
          },
          {
            field: 'contactField',
            header: 'Contact Field',
            body: (rowData, { rowIndex }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledDropdown
                  valueTemplate={selectedFieldTemplate}
                  itemTemplate={FieldsOptionTemplate}
                  value={rowData.contactField}
                  options={response?.data}
                  onChange={(e) => handleFieldMappingChange(rowIndex, 'contactField', e.value)}
                  placeholder="Select Field"
                  disabled={!rowData.active}
                />
              </div>
            ),
          },
          {
            field: 'action',
            header: 'Action',
            body: (rowData, { rowIndex }) => (
              <Dropdown
                value={rowData.action}
                options={IMPORT_CONTACT_ACTIONS}
                onChange={(e) => handleFieldMappingChange(rowIndex, 'action', e.value)}
                disabled={!rowData.active}
              />
            ),
          },
        ]);
      } else {
        dispatch(showToast({
          type: 'error',
          title: 'Contact Fields',
          message: response?.message || 'Oops! Something went wrong',
          duration: 2000,
        }));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(showToast({
        type: 'error',
        title: 'Contact Fields',
        message: error?.message || 'Oops! Something went wrong',
        duration: 2000,
      }));
      console.error('error while getting contact fields :', error);
    }
  };

  useEffect(() => {
    handleGetContactFields();
  }, []);

  const handleSelectAll = useCallback((checked) => {
    const updatedMappings = fieldMappings.map((mapping) => ({ ...mapping, active: checked }));
    dispatch(setImportContactsStepThreeData({ selectAll: checked }));
    dispatch(setImportContactsStepTwoData({ fieldMappings: updatedMappings }));
  }, [fieldMappings, dispatch]);

  const handleSave = async () => {
    const activeFields = fieldMappings.filter((mapping) => mapping?.active);

    const { isValid: isValidPhoneMapping, error: phoneMessage } = phoneNumberValidation(activeFields);

    if (!isValidPhoneMapping) {
      dispatch(showToast({
        type: 'error',
        title: 'Mapping Error',
        message: phoneMessage,
        duration: 2000,
      }));
      return;
    }

    const { isValid: isValidCountryCodeMapping, error: countryCodeMessage } = countryCodeValidation(activeFields);

    if (!isValidCountryCodeMapping) {
      dispatch(showToast({
        type: 'error',
        title: 'Mapping Error',
        message: countryCodeMessage,
        duration: 2000,
      }));
      return;
    }

    dispatch(setLoader(true));
    const isCountryCodeActive = activeCountryCode(activeFields);
    let countryCode = '';
    if (!isCountryCodeActive) {
      try {
        countryCode = await detectCountryCallingCode();
      } catch (error) {
        dispatch(showToast({
          type: 'error',
          title: 'Country Code',
          message: error?.message,
          duration: 2000,
        }));
        dispatch(setLoader(false));
        return;
      }
    }

    const filteredContacts = createFilteredContacts(contactData, activeFields, isCountryCodeActive, countryCode);

    if (isEmpty(filteredContacts)) {
      dispatch(showToast({
        type: 'error',
        title: 'No Valid Contacts',
        message: 'No valid contacts were found in the CSV file.',
        duration: 2000,
      }));
      dispatch(setLoader(false));
      return;
    }

    const fieldActions = activeFields?.map(({ excelColumn: field, action }) => ({ field, action }));

    try {
      const response = await contactGroupServices.createContactGroup({
        segmentName,
        contacts: filteredContacts,
        marketingOptIn,
        tags,
        fieldActions,
      });
      if (response?.status) {
        dispatch(showToast({
          type: 'success',
          title: 'Segment',
          message: response?.message,
          duration: 2000,
        }));
        history.push(`/communication-settings?activeTab=${tabs.CONTACT_GROUPS}`);
        dispatch(resetImportContactsForm());
      } else {
        dispatch(showToast({
          type: 'error',
          title: 'Segment',
          message: response?.message || 'Oops! Something went wrong',
          duration: 2000,
        }));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(showToast({
        type: 'error',
        title: 'Segment',
        message: error?.message || 'Oops! Something went wrong',
        duration: 2000,
      }));
      console.error('error while creating a new group :', error);
    }
  };

  const saveButtonDisabled = fieldMappings?.every((fieldMapping) => !fieldMapping?.active);

  return (
    <StyledCard>
      <FlexBetween>
        <p>Match your Excel columns to the corresponding contact fields in our system.</p>
        <Button
          type="button"
          onClick={handleSave}
          disabled={saveButtonDisabled}
          label="Save Contacts"
          icon="mdi mdi-content-save"
          iconSize="1.2rem"
          iconLeft
        />
      </FlexBetween>
      <MappingTable value={fieldMappings} showPagination={false} responsive>
        <Column
          header={(
            <Checkbox
              id="selectAll"
              onChange={handleSelectAll}
              checked={selectAll}
            />
          )}
          body={(rowData, { rowIndex }) => (
            <Checkbox
              id={String(rowIndex)}
              checked={rowData.active}
              onChange={(checked) => handleFieldMappingChange(rowIndex, 'active', checked)}
            />
          )}
        />
        {contactListColumns.map((col) => (
          <Column
            key={col.field}
            style={{ minWidth: '12rem' }}
            field={col.field}
            header={col.header}
            body={col.body}
          />
        ))}
      </MappingTable>
    </StyledCard>
  );
}

export default Step3;
