import SHOW_QUICK_SMS_MODAL from './actionTypes';

const INIT_STATE = {
  showQuickSmsModal: false,
};

const quickMessage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_QUICK_SMS_MODAL:
      return {
        ...state,
        showQuickSmsModal: !state.showQuickSmsModal,
      };

    default:
      return state;
  }
};

export default quickMessage;
