import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import WizardSteps from '../../../../components/Features/WizardSteps';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import { setActiveIndexOfBroadcastForm, setStepCompletionArray } from '../../../../store/actions';

class BulkWhatsappMessage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.setState({
      items: [
        {
          label: 'Choose Template',
          icon: 'bx bxs-detail',
          disabled: false,
          template: (item) => this.itemRenderer(item, 0),
          component: <Step1 formCompletion={(isCompleted) => this.handleFormCompletion(0, isCompleted)} />,
        },
        {
          label: 'Setup Template',
          icon: 'mdi mdi-basket-fill',
          disabled: true,
          template: (item) => this.itemRenderer(item, 1),
          component: <Step2 formCompletion={(isCompleted) => this.handleFormCompletion(1, isCompleted)} />,
        },
        {
          label: 'Choose Segment',
          icon: 'bx bx-group',
          disabled: true,
          template: (item) => this.itemRenderer(item, 2),
          component: <Step3 formCompletion={(isCompleted) => this.handleFormCompletion(2, isCompleted)} />,
        },
        {
          label: 'Broadcast',
          icon: 'mdi mdi-bullhorn',
          disabled: true,
          template: (item) => this.itemRenderer(item, 3),
          component: <Step4 />,
        },
      ],
    });
  }

  handleFormCompletion(stepIndex, isCompleted) {
    const { stepCompletionArray, onSetStepCompletionArray } = this.props;

    const completionArray = stepCompletionArray?.map((stepCompletion) => (stepCompletion?.stepIndex === stepIndex
      ? ({ ...stepCompletion, completed: isCompleted })
      : stepCompletion));

    onSetStepCompletionArray(completionArray);
  }

  itemRenderer = (item, itemIndex) => {
    const { activeIndex, onSetActiveIndexOfBroadcastForm } = this.props;
    const { icon, label } = item;
    const StepCompletedIcon = 'bx bx-check';

    return (
      <div
        className={`p-menuitem-link ${activeIndex > itemIndex ? 'p-completed' : ''} cursor-pointer`}
        tabIndex="-1"
        data-pc-section="action"
        onClick={() => onSetActiveIndexOfBroadcastForm(itemIndex)}
      >
        <span className="p-steps-number" data-pc-section="step">
          {
            activeIndex > itemIndex
              ? <i className={StepCompletedIcon} />
              : <i className={icon} />
          }
        </span>
        <span className="p-steps-title" data-pc-section="label">{label}</span>
      </div>
    );
  };

  render() {
    document.title = 'Whatsapp Campaign';
    const {
      items,
    } = this.state;

    const { stepCompletionArray, activeIndex, onSetActiveIndexOfBroadcastForm } = this.props;

    const nextButtonEnable = activeIndex !== items.length - 1 && stepCompletionArray?.reduce((acc, stepCompletion) => (stepCompletion?.stepIndex === activeIndex
      ? stepCompletion?.completed
      : acc), false);

    const previousButtonEnable = activeIndex > 0;

    return (
      <WizardSteps
        items={items}
        activeIndex={activeIndex}
        readOnly={false}
        onStepChange={(currentStep) => {
          this.setState({
            items: items.map((item, index) => (index === currentStep ? { ...item, disabled: false } : item)),
          });
          onSetActiveIndexOfBroadcastForm(currentStep);
        }}
        buttonsEnable
        previousButtonDisabled={!previousButtonEnable}
        nextButtonDisabled={!nextButtonEnable}
      >
        {items.length ? items[activeIndex].component : null}
      </WizardSteps>
    );
  }
}

BulkWhatsappMessage.propTypes = {
  activeIndex: PropTypes.number,
  stepCompletionArray: PropTypes.array,
  onSetStepCompletionArray: PropTypes.func,
  onSetActiveIndexOfBroadcastForm: PropTypes.func,
};

const mapStateToProps = ({ bulkWhatsapp }) => ({
  stepCompletionArray: bulkWhatsapp.stepCompletionArray,
  activeIndex: bulkWhatsapp.activeIndex,
});

const mapDispatchToProps = (dispatch) => ({
  onSetStepCompletionArray: (stepCompletionArray) => dispatch(setStepCompletionArray(stepCompletionArray)),
  onSetActiveIndexOfBroadcastForm: (activeIndex) => dispatch(setActiveIndexOfBroadcastForm(activeIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkWhatsappMessage));
