/* eslint-disable import/prefer-default-export */
import { axiosPatch } from '../http.service';

// Function to exchange WhatsApp token
export const whatsappExchangeToken = async (organizationId, code) => {
  try {
    const response = await axiosPatch('channels/whatsapp/exchange-token', { organizationId, code });
    return response;
  } catch (error) {
    console.error('Error exchanging WhatsApp token:', error);
    throw error;
  }
};
