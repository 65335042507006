import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import PhoneNumberSelector from '../PhoneNumberList/NumberList';
import UserSelect from '../../../components/Common/UserSelect';
import { axiosGet, axiosPost } from '../../../services/http.service';
import ModalLoader from '../../../components/Common/ModalLoader';
import { setShowNumberModal } from '../../../store/phone-number/actions';
import { STEPS_NAME } from '../../../constants/onboarding.constant';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import { handleOnboardingStepCompletion } from '../../../utils/onboarding';
import { setOnboardingData as setOnboardingDataAction } from '../../../store/actions';
import { PAGE_SIZE } from '../../../constants/channels/conversation.constant';

const initialValues = {
  areaCode: '',
  numberType: 'local',
};

const validationSchema = Yup.object().shape({
  areaCode: Yup.string().required('Please enter the area code'),
  numberType: Yup.string().required('Please select number type'),
});
class Number extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedNumberType: 'local',
      areaCode: '',
      availableNumbers: [],
      showSpinner: false,
    };
  }

  toggle = () => {
    const { onSetShowNumberModal } = this.props;
    onSetShowNumberModal();
    this.setState({ availableNumbers: [] });
  };

  // To get available numbers from twilio using area code and number type
  handleGetAvailableNumbers = async (areaCode, numberType) => {
    try {
      this.setState({ showSpinner: true, availableNumbers: [] });
      const numbers = await axiosGet(
        `numbers/available-numbers?areaCode=${areaCode}&numberType=${numberType}&pageSize=${PAGE_SIZE}`,
      );
      if (numbers?.status) {
        this.setState({
          availableNumbers: numbers?.data,
          areaCode,
          selectedNumberType: numberType,
        });
        this.setState({ showSpinner: false });
      } else {
        toast.error(numbers?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
        this.setState({ showSpinner: false });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error('error while getting available twilio numbers :', error);
    }
  };

  // To buy a twilio number
  handleNumber = async (values) => {
    try {
      this.setState({ showSpinner: true });
      const { areaCode, selectedNumberType } = this.state;
      const {
        getAllNumbers, isOnboarding, history, setOnboardingData,
      } = this.props;
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const data = {
        assignedUserId: values?.user.value,
        phoneNumbers: values?.selectedNumbers.map((num) => ({
          number: num.number,
          alias: num.alias,
        })),
        areaCode,
        numberType: selectedNumberType,
      };
      const response = await axiosPost(
        `numbers?areaCode=${areaCode}&numberType=${selectedNumberType}&pageSize=${PAGE_SIZE}`,
        data,
      );
      if (response?.status) {
        this.setState({ showSpinner: false });
        toast.success(response?.message || 'Number Purchase successfull!', {
          position: 'top-right',
        });
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.ADD_PHONE_NUMBER,
            organizationId,
            history,
            setOnboardingData,
          });
        }
        getAllNumbers();
        this.setState({ availableNumbers: [] });
        this.toggle();
      } else {
        this.setState({ showSpinner: false });
        toast.error(response?.message || 'Oops! Something went wrong!', {
          position: 'top-right',
        });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error('error while purchasing a twilio number :', error);
    }
  };

  // Formik onSubmit handler
  handleOnSubmit = (values) => {
    this.handleGetAvailableNumbers(values.areaCode, values.numberType);
  };

  render() {
    const { modal, className, activeTab } = this.props;
    const { availableNumbers, showSpinner, selectedNumberType } = this.state;
    return (
      <Modal isOpen={modal} className={className} size="lg">
        {showSpinner ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          Buy Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Area Code</Label>
                      <Field
                        name="areaCode"
                        type="text"
                        className={`form-control${
                          errors.areaCode && touched.areaCode ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="areaCode" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Number Type</Label>

                      <div className="">
                        <Field
                          name="numberType"
                          type="radio"
                          value="local"
                          id="local"
                          className="form-check-input me-1"
                        />
                        <Label className="form-check-label me-5" htmlFor="male">
                          Local
                        </Label>

                        <Field
                          name="numberType"
                          type="radio"
                          value="toll-free"
                          id="female"
                          className="form-check-input me-1"
                        />
                        <Label className="form-check-label me-5" htmlFor="female">
                          toll-free
                        </Label>
                        <ErrorMessage name="gender" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user d-flex justify-content-center"
                      >
                        Get Numbers
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {availableNumbers.length > 0 && (
            <Formik
              enableReinitialize
              initialValues={{
                user: '',
                selectedNumbers: [],
              }}
              validationSchema={Yup.object().shape({
                user: Yup.object().required('Please select a user'),
                selectedNumbers: Yup.array()
                  .min(1, 'Please select at least one phone number')
                  .required('Please select at least one phone number'),
              })}
              onSubmit={(values) => {
                this.handleNumber(values);
              }}
            >
              {({
                errors, touched, setFieldValue, values,
              }) => (
                <Form>
                  <Row className="mb-3">
                    <Label className="form-label">Available Numbers</Label>
                    <PhoneNumberSelector
                      availableNumbers={availableNumbers}
                      selectedNumberType={selectedNumberType}
                      onChange={(selectedNumbers) => {
                        setFieldValue('selectedNumbers', selectedNumbers);
                      }}
                      activeTab={activeTab}
                    />
                  </Row>
                  <ErrorMessage name="selectedNumbers" component="div" className="error" />
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">User</Label>
                        <UserSelect
                          className={`${errors.user && touched.user ? ' is-invalid' : ''}`}
                          selectedOptions={values.user}
                          onChange={(option) => {
                            setFieldValue('user', option);
                          }}
                        />
                        <ErrorMessage name="user" component="div" className="invalid-feedback" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success save-user">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

Number.propTypes = {
  onSetShowNumberModal: PropTypes.func,
  getAllNumbers: PropTypes.func,
  modal: PropTypes.bool,
  className: PropTypes.any,
  isOnboarding: PropTypes.bool,
  history: PropTypes.object,
  setOnboardingData: PropTypes.func,
  activeTab: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowNumberModal: () => dispatch(setShowNumberModal()),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

const mapStateToProps = ({ phoneNumber, onboarding }) => ({
  modal: phoneNumber.showNumberModal,
  isOnboarding: onboarding.isOnboarding,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Number));
