import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { PAGE, SIZE_PER_PAGE } from '../../constants/constants';
import { renderUserAvatar } from '../../helpers/commonHelpers';
import { fetchUsers } from '../../services/api/user.service';
import { handleError } from '../../services/errorHandler';

const GlobalStyle = createGlobalStyle`
  body .p-multiselect-panel {
    z-index: 9999 !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  // Add your styles here
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 75%;
`;

function UserSelect({
  className, role, onChange, selectedOptions, isMulti, itemTemplate,
}) {
  const [options, setOptions] = useState([]);
  const pageRef = useRef(PAGE);
  const [size] = useState(SIZE_PER_PAGE);
  const [totalPages, setTotalPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await fetchUsers({
        page: pageRef.current,
        limit: size,
        role,
      });

      if (response?.status) {
        const newOptions = response.data.results.map((user) => ({
          value: user.id,
          label: <>{renderUserAvatar(user.name)}</>,
          role: user.role,
          name: user.name,
        }));
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setTotalPages(response.data.totalPages);
        pageRef.current += 1;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [size, role]);

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    setOptions([]);
    pageRef.current = PAGE;
    setTotalPages(null);
    loadOptions();
  }, [role]);

  const handleMenuScrollToBottom = () => {
    if (!isLoading && pageRef.current <= totalPages) {
      loadOptions();
    }
  };

  const handleChange = (e) => {
    onChange(isMulti ? e.value : e.value);
  };

  let value;

  if (isMulti) {
    value = Array.isArray(selectedOptions) ? selectedOptions : [];
  } else {
    value = selectedOptions;
  }
  if (isMulti) {
    return (
      <>
        <GlobalStyle />
        <StyledMultiSelect
          className={`${className}`}
          value={value}
          onChange={handleChange}
          options={options}
          loading={isLoading}
          loadingMessage="Loading..."
          onScrollBottom={handleMenuScrollToBottom}
          filter
          showClear
          maxVisibleItems={10}
          scrollHeight={300}
          placeholder="Select users..."
          emptyMessage="No users found"
          virtualScrollerOptions={{ itemSize: 38 }}
          optionLabel="name"
          display="chip"
          itemTemplate={itemTemplate}
        />
      </>
    );
  }

  return (
    <StyledDropdown
      className={className}
      value={value}
      onChange={handleChange}
      options={options}
      loading={isLoading}
      loadingMessage="Loading..."
      onScrollBottom={handleMenuScrollToBottom}
      filter
      showClear
      maxVisibleItems={10}
      scrollHeight={300}
      placeholder="Select user..."
      emptyMessage="No users found"
      virtualScrollerOptions={{ itemSize: 38 }}
      optionLabel="name"
      itemTemplate={itemTemplate}
    />
  );
}

UserSelect.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  itemTemplate: PropTypes.func,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isMulti: PropTypes.bool,
};

UserSelect.defaultProps = {
  className: '',
  isMulti: false,
};

export default UserSelect;
