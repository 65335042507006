import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TemplateTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

const TemplateSubTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

const Container = styled.div`
`;

const StyledDiv = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  background-color: var(--blue-50);
`;

const ChildrenDiv = styled.div`
  background-color: var(--bs-white);
`;

const StyledAnchor = styled.a`
  font-size: 0.9rem;
  font-weight: 400;
`;

class TemplateParameters extends Component {
  render() {
    const {
      children,
      className,
      title,
      subTitle,
      sampleFile,
      titleClassName,
      subTitleClassName,
      childrenClassName,
      sampleFileClassName,
      ...rest
    } = this.props;

    return (
      <Container className={`mt-3 ${className}`} {...rest}>
        <TemplateTitle className={titleClassName}>{title}</TemplateTitle>
        <StyledDiv className={`my-2 border border-darkgrey rounded ${subTitleClassName}`}>
          {subTitle ? (
            <TemplateSubTitle>
              {subTitle}
              {' '}
              :
            </TemplateSubTitle>
          ) : (
            ''
          )}
          {
            sampleFile
            && (
            <StyledAnchor
              href={sampleFile}
              target="_blank"
              rel="noopener noreferrer"
              className={`ms-1 ${sampleFileClassName}`}
            >
              View Sample
            </StyledAnchor>
            )
          }
          <ChildrenDiv className={`border border-darkgrey rounded px-2 mt-2 ${childrenClassName}`}>
            {children}
          </ChildrenDiv>
        </StyledDiv>
      </Container>
    );
  }
}

TemplateParameters.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  subTitle: PropTypes.string,
  sampleFile: PropTypes.string,
  subTitleClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  sampleFileClassName: PropTypes.string,
};

TemplateParameters.defaultProps = {
  className: '',
  titleClassName: '',
  subTitle: '',
  sampleFile: '',
  subTitleClassName: '',
  childrenClassName: '',
  sampleFileClassName: '',
};

export default TemplateParameters;
