export const SUBTLE_COLORS = [
  { bg: 'bg-primary-subtle', text: 'text-primary' },
  { bg: 'bg-success-subtle', text: 'text-success' },
  { bg: 'bg-info-subtle', text: 'text-info' },
  { bg: 'bg-danger-subtle', text: 'text-danger' },
  { bg: 'bg-warning-subtle', text: 'text-warning' },
  { bg: 'bg-secondary-subtle', text: 'text-secondary' },
];

export const COLOR_PALETTE = [
  '#34c38f', '#50a5f1', '#f46a6a', '#f1b44c', '#556ee6',
  '#8d6e63', '#ff8a65', '#ba68c8', '#4db6ac', '#9575cd',
  '#ffb74d', '#64b5f6', '#81c784', '#e57373', '#ffd54f',
  '#4caf50', '#f44336', '#3f51b5', '#ffeb3b', '#795548',
  '#00bcd4', '#e91e63', '#673ab7', '#9c27b0', '#03a9f4',
];
