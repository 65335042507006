import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../../store/actions';
import InboxWhatsappMessage from './InboxMessage';

function Inbox() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.inbox.activeTab);
  const selectedNotification = useSelector((state) => state.notification.selectedNotification);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('activeTab');
    if (tab) {
      dispatch(setActiveTab(tab));
    }
  }, [dispatch]);

  useEffect(() => {
    document.title = 'inbox';
  }, []);

  return (
    <div className="">
      <Container fluid>
        <InboxWhatsappMessage
          activeTab={activeTab}
          to={selectedNotification?.to}
          from={selectedNotification?.from}
        />
      </Container>
    </div>
  );
}

export default Inbox;
