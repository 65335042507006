import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 1rem;
  margin-bottom: 0.1rem;
  ${({ $textLeft }) => ($textLeft ? 'margin-left: 0.5rem;' : 'margin-right: 0.5rem;')}
`;

const Text = styled.span`
`;

function IconText({
  className,
  icon,
  text,
  iconClassName,
  textClassName,
  textLeft,
  ...rest
}) {
  const renderContent = () => {
    try {
      if (textLeft) {
        return (
          <>
            <Text className={textClassName}>{text}</Text>
            <Icon className={`bx ${icon} ${iconClassName}`} $textLeft={textLeft} />
          </>
        );
      }
      return (
        <>
          <Icon className={`bx ${icon} ${iconClassName}`} $textLeft={textLeft} />
          <Text className={textClassName}>{text}</Text>
        </>
      );
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  return (
    <Container className={className} {...rest}>
      {renderContent()}
    </Container>
  );
}

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  textLeft: PropTypes.bool,
};

IconText.defaultProps = {
  className: '',
  iconClassName: '',
  textClassName: '',
  textLeft: false,
};

export default IconText;
