import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  UncontrolledTooltip,
  Badge,
  Row,
  Col,
  Card,
  Button,
  Container,
  CardBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import DataGrid from '../../../components/DataTable';
import DeleteModal from '../../../components/Common/DeleteModal';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';

import { axiosDel, axiosGet } from '../../../services/http.service';
import { setShowTemplateModal } from '../../../store/campaign/actions';
import { setLoader } from '../../../store/actions';
import Whatsapp from './Modal/Whatsapp';
import EditWhatsapp from './Modal/EditWhatsapp';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';

class WhatsappTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      template: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      modal: false,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      templateListColumns: [
        {
          header: 'Name',
          accessorKey: 'templateName',
        },
        {
          header: 'Base Template Name',
          accessorKey: 'template.name',
        },
        {
          header: 'Whatsapp Number',
          accessorKey: 'whatsappNumberDoc',
          Cell: ({ renderedCellValue }) => (
            <PhoneNumberWithFlag phoneNumber={renderedCellValue?.phoneNumber} />
          ),
        },
        {
          header: 'Template Content',
          accessorKey: 'templateContent',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            const slicedContent = sliceStringWithEllipsis(30, renderedCellValue);
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {renderedCellValue}
                </UncontrolledTooltip>
                <span id={tooltipId}>{slicedContent}</span>
              </>
            );
          },
        },
        {
          header: 'Language',
          accessorKey: 'template.language.code',
        },
        {
          header: 'Created At',
          accessorKey: 'createdAt',
          Cell: ({ row }) => {
            const template = row.original;
            const formattedDate = moment(template?.createdAt).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(template?.createdAt).format(
              DATE_CONSTANTS.FORMATS.HH_MM_SS,
            );
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const template = row.original;
            return (
              <div className="d-flex gap-3">
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="edit template"
                      id="edit-template"
                      onClick={() => this.handleUserClick(template)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="danger-subtle" className="text-danger">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="delete"
                      onClick={() => this.onClickDelete(template)}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetAllTemplates(page);
  }

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllTemplates(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetAllTemplates(newPageSize);
    });
  };

  toggleEditModal = () => {
    this.setState({ isEdit: false });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handleUserClicks = () => {
    this.setState({ template: '', isEdit: false });
    this.toggle();
  };

  handleUserClick = (arg) => {
    const template = arg;

    this.setState({
      template,
      isEdit: true,
    });

    // this.toggle();
  };

  /* Insert,Update Delete data */
  onClickDelete = (template) => {
    this.setState({ template, deleteModal: true });
  };

  handleGetAllTemplates = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const templates = await axiosGet(
        `whatsapp-template?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&populate=whatsappNumberDoc&sortBy=createdAt:desc`,
      );
      if (templates?.status) {
        onSetLoader(false);
        this.setState({
          templates: templates?.data?.results,
          totalSize: templates?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleGetAllTemplates :', error);
    }
  };

  handleDeleteTemplate = async () => {
    try {
      const { template, page } = this.state;
      const response = await axiosDel(`whatsapp-template/${template?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Template deleted successfully');
        this.handleGetAllTemplates(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
        this.toggleDeleteModal();
      }
    } catch (error) {
      this.toggleDeleteModal();
      console.error('error at handleDeleteTemplate :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllTemplates(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'Templates';

    const {
      isEdit,
      deleteModal,
      templates,
      template,
      modal,
      page,
      templateListColumns,
      totalSize,
      selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteTemplate}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {modal ? (
          <Whatsapp
            showModal={modal}
            toggle={this.toggle}
            template={template}
            getAllTemplates={() => {
              this.handleGetAllTemplates(page);
            }}
          />
        ) : (
          ''
        )}
        {isEdit ? (
          <EditWhatsapp
            isEdit={isEdit}
            toggle={this.toggleEditModal}
            template={template}
            getAllTemplates={() => {
              this.handleGetAllTemplates(page);
            }}
          />
        ) : (
          ''
        )}
        <div className="page-content py-0">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Whatsapp Template
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={templates}
                        columns={templateListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableEditing={false}
                        enableGlobalFilter
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

WhatsappTemplate.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = ({ campaign }) => ({
  showTemplateModal: campaign.showTemplateModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowTemplateModal: () => dispatch(setShowTemplateModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhatsappTemplate));
