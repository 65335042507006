/* eslint-disable import/prefer-default-export */
import {
  SET_STEP_ONE_DATA,
  SET_SENDER_NUMBERS,
  SET_META_WHATSAPP_TEMPLATES,
  SET_STEP_TWO_DATA,
  SET_TRANSFORMED_TEMPLATE,
  SET_STEP_THREE_DATA,
  SET_STEP_FOUR_DATA,
  SET_STEP_COMPLETION_ARRAY,
  SET_ACTIVE_INDEX_OF_BROADCAST_FORM,
  RESET_BROADCAST_FORM,
  SET_CONTACT_GROUPS,
} from './actionTypes';

export const setStepCompletionArray = (stepCompletionArray) => ({
  type: SET_STEP_COMPLETION_ARRAY,
  payload: { stepCompletionArray },
});

export const setActiveIndexOfBroadcastForm = (activeIndex) => ({
  type: SET_ACTIVE_INDEX_OF_BROADCAST_FORM,
  payload: { activeIndex },
});

export const setStepOneData = (formData) => ({
  type: SET_STEP_ONE_DATA,
  payload: formData,
});

export const setStepTwoData = (formData) => ({
  type: SET_STEP_TWO_DATA,
  payload: formData,
});

export const setStepThreeData = (formData) => ({
  type: SET_STEP_THREE_DATA,
  payload: formData,
});

export const setStepFourData = (formData) => ({
  type: SET_STEP_FOUR_DATA,
  payload: formData,
});

export const setSenderNumbers = (senderNumbers) => ({
  type: SET_SENDER_NUMBERS,
  payload: { senderNumbers },
});

export const setContactGroupsOfStep3 = (apiData) => ({
  type: SET_CONTACT_GROUPS,
  payload: apiData,
});

export const setMetaWhatsappTemplates = (templates) => ({
  type: SET_META_WHATSAPP_TEMPLATES,
  payload: { templates },
});

export const setTransformedTemplate = (template) => ({
  type: SET_TRANSFORMED_TEMPLATE,
  payload: template,
});

export const resetBroadcastForm = (template) => ({
  type: RESET_BROADCAST_FORM,
  payload: template,
});
