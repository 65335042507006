import React from 'react';
import { Col } from 'reactstrap';
import Row from '../Row';

function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <Row>
          <Col sm={6}>
            {new Date().getFullYear()}
            {' '}
            © Auto Campaign.AI
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
