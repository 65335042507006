import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListBox } from 'primereact/listbox';

const StyledListBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledListBox = styled(ListBox)`
  width: 100%;

  @media (min-width: 768px) {
    width: ${(props) => props.mdWidth || '14rem'};
  }
  .p-listbox-list {
    margin-bottom: 0;
    padding: 0;
  }
`;

function CustomListBox({
  value,
  onChange,
  options,
  optionLabel = 'label',
  itemTemplate,
  listStyle = { maxHeight: '250px' },
  className = '',
  mdWidth = '14rem',
  ...restProps
}) {
  return (
    <StyledListBoxWrapper className="card flex justify-content-center">
      <StyledListBox
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        itemTemplate={itemTemplate}
        className={`w-full ${className}`}
        listStyle={listStyle}
        mdWidth={mdWidth}
        {...restProps}
      />
    </StyledListBoxWrapper>
  );
}

CustomListBox.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionLabel: PropTypes.string,
  itemTemplate: PropTypes.func,
  listStyle: PropTypes.object,
  className: PropTypes.string,
  mdWidth: PropTypes.string,
};

export default CustomListBox;
