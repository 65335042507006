import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';

const StyledListItem = styled.li`
  /* Preserve existing styles */
`;

function ListGroupItemComponent({
  item,
  renderItem,
  index,
  onItemClick,
  className,
}) {
  const handleItemClick = () => {
    try {
      if (onItemClick) {
        onItemClick(item, index);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const renderContent = () => {
    try {
      return renderItem(item, index);
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  return (
    <StyledListItem
      onClick={handleItemClick}
      className={className}
    >
      {renderContent()}
    </StyledListItem>
  );
}

ListGroupItemComponent.propTypes = {
  item: PropTypes.object.isRequired,
  renderItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onItemClick: PropTypes.func,
  className: PropTypes.string,
};

export default ListGroupItemComponent;
