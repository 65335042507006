import { handleError } from '../errorHandler';
import { axiosGet, axiosPatch } from '../http.service';

export const fetchAlerts = async (page, limit) => {
  try {
    const response = await axiosGet('/alert', {
      params: {
        isRead: false,
        page,
        limit,
        sortBy: 'createdAt:desc',
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const getUnreadMessages = async () => {
  try {
    const response = await axiosGet('/conversations/unread-messages');
    return response;
  } catch (error) {
    handleError('Error fetching unread messages:', error);
    throw error;
  }
};

export const markAlertAsRead = async (alertId) => {
  try {
    const response = await axiosPatch(`/alert/${alertId}`, { isRead: true });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
