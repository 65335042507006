/* USERS */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

/* USERS PROFILE */
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

/* IMPORT CONTACTS */
export const SET_SELECTED_CSV_FILE = 'SET_SELECTED_CSV_FILE';
export const SET_IMPORTED_CONTACTS_DATA = 'SET_IMPORTED_CONTACTS_DATA';
export const SET_IMPORTED_CONTACTS_FIELDS = 'SET_IMPORTED_CONTACTS_FIELDS';
export const SET_MAPPED_CONTACT_FIELDS = 'SET_MAPPED_CONTACT_FIELDS';
export const SET_IMPORT_CONTACTS_STEPS_STATUS = 'SET_IMPORT_CONTACTS_STEPS_STATUS';
export const SET_ACTIVE_INDEX_IMPORT_CONTACTS = 'SET_ACTIVE_INDEX_IMPORT_CONTACTS';

/* IMPORT CONTACTS */
export const SET_IMPORT_CONTACTS_STEP_ONE_DATA = 'SET_IMPORT_CONTACTS_STEP_ONE_DATA';
export const SET_IMPORT_CONTACTS_STEP_TWO_DATA = 'SET_IMPORT_CONTACTS_STEP_TWO_DATA';
export const SET_IMPORT_CONTACTS_STEP_THREE_DATA = 'SET_IMPORT_CONTACTS_STEP_THREE_DATA';
export const RESET_IMPORT_CONTACTS_FORM = 'RESET_IMPORT_CONTACTS_FORM';

/* CONTACT GROUPS */
export const SET_SHOW_CONTACT_FORM_MODAL = 'SET_SHOW_CONTACT_FORM_MODAL';
export const SET_SHOW_GROUP_FORM_MODAL = 'SET_SHOW_GROUP_FORM_MODAL';

export const SET_FILTER_DATA = 'SET_FILTER_DATA';

// tags
export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const UPDATE_CONTACT_TAGS = 'UPDATE_CONTACT_TAGS';
