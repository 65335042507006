import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  UncontrolledTooltip,
  Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
import { axiosGet } from '../../../services/http.service';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setShowContactFormModal } from '../../../store/contacts/actions';
import { setLoader } from '../../../store/actions';
import ContactForm from './Modal/ContactForm';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import { tabs } from '../../../constants/tabs';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import DataGrid from '../../../components/DataTable';
import { deleteContactFromGroup } from '../../../services/api/contactGroups.service';

class ViewContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contact: '',
      isEdit: false,
      contactIdToDelete: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      contactListColumns: [
        {
          header: 'Name',
          accessorKey: 'firstName',
          Cell: ({ row }) => {
            const { firstName, lastName } = row?.original;
            return (
              <span>
                {firstName || '-'}
                {' '}
                {lastName}
              </span>
            );
          },
        },
        {
          header: 'Mobile Number',
          accessorKey: 'phoneNumberString',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Address',
          accessorKey: 'address',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            const slicedContent = sliceStringWithEllipsis(30, renderedCellValue);
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {renderedCellValue}
                </UncontrolledTooltip>
                <span id={tooltipId}>{slicedContent}</span>
              </>
            );
          },
        },
        {
          header: 'Zip Code',
          accessorKey: 'zip',
          Cell: ({ renderedCellValue }) => (
            <span>{renderedCellValue !== ' ' ? renderedCellValue || '-' : '-'}</span>
          ),
        },
        {
          header: 'City',
          accessorKey: 'city',
          Cell: ({ renderedCellValue }) => (
            <span>{renderedCellValue !== ' ' ? renderedCellValue || '-' : '-'}</span>
          ),
        },
        {
          header: 'State',
          accessorKey: 'state',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue || '-'}</span>,
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const contact = row?.original;
            return (
              <div className="d-flex gap-3">
                <Badge color="success-subtle" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    id="edit"
                    onClick={() => this.handleUserClick(contact)}
                  />
                </Badge>
                <Badge color="danger-subtle" className="text-danger">
                  <i
                    className="mdi mdi-trash-can-outline font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    onClick={() => this.onClickDelete(contact)}
                  />
                </Badge>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.getAllContacts(page);
  }

  toggle = () => {
    const { onSetShowContactFormModal } = this.props;
    onSetShowContactFormModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.getAllContacts(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getAllContacts(1, newPageSize);
    });
  };

  handleUserClicks = () => {
    this.setState({ contact: '', isEdit: false });
    this.toggle();
  };

  handleUserClick = (arg) => {
    const contact = arg;

    this.setState({
      contact: {
        id: contact.id,
        countryCode: contact?.countryCode,
        phoneNumber: contact?.phoneNumber,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        zip: contact?.zip,
        state: contact?.state,
        city: contact?.city,
        address: contact?.address,
      },
      isEdit: true,
    });

    this.toggle();
  };

  onClickDelete = (contact) => {
    this.setState({ contactIdToDelete: contact?.id, deleteModal: true });
  };

  /* Insert,Update Delete data */

  getAllContacts = async (page) => {
    const { size } = this.state;
    const { onSetLoader, match } = this.props;
    try {
      onSetLoader(true);
      const { groupId } = match.params;
      const groups = await axiosGet(
        `contact-groups/${groupId}?page=${page}&limit=${size}&sortBy=createdAt:desc`,
      );
      if (groups?.status) {
        onSetLoader(false);
        this.setState({
          contacts: groups?.data?.contactGroup?.contactIds,
          totalSize: groups?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting all contacts :', error);
    }
  };

  handleDeleteContact = async (id) => {
    const { match } = this.props;
    const { page } = this.state;
    try {
      const { groupId } = match.params;
      const response = await deleteContactFromGroup(id, groupId);
      if (response?.status) {
        this.getAllContacts(page);
        this.toggleDeleteModal();
      } else {
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error('error while deleting a contact :', error);
    }
  };

  render() {
    // meta title
    document.title = 'Contact Groups';

    const {
      deleteModal,
      contacts,
      contact,
      contactIdToDelete,
      isEdit,
      page,
      contactListColumns,
      totalSize,
    } = this.state;

    const { showContactFormModal } = this.props;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteContact(contactIdToDelete)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showContactFormModal && (
          <ContactForm
            isEdit={isEdit}
            contact={contact}
            getAllContacts={() => {
              this.getAllContacts(page);
            }}
          />
        )}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Contacts" breadcrumbItem="Contacts Groups" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end mb-3">
                      <Link
                        className="text-danger"
                        to={`/communication-settings?activeTab=${tabs.CONTACT_GROUPS}`}
                      >
                        <Button
                          color="secondary"
                          className="font-16 btn-block btn btn-primary me-3"
                        >
                          Back
                        </Button>
                      </Link>
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Contact
                        </Button>
                      </div>
                      <div className="text-sm-end">
                        <Link to="/import-contacts">
                          <Button color="primary" className="font-16 btn-block btn btn-primary">
                            <i className="mdi mdi-upload me-1" />
                            Upload Bulk Contacts
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={contacts}
                        columns={contactListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter={false}
                        enableEditing={false}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ViewContacts.propTypes = {
  onSetShowContactFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  showContactFormModal: PropTypes.bool,
  match: PropTypes.any,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ contacts }) => ({
  showContactFormModal: contacts.showContactFormModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewContacts));
