import { IMPORT_TYPE_KEYS } from '../../constants/importcontacts.constant';
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  SET_IMPORTED_CONTACTS_DATA,
  SET_IMPORTED_CONTACTS_FIELDS,
  SET_MAPPED_CONTACT_FIELDS,
  SET_SELECTED_CSV_FILE,
  SET_SHOW_CONTACT_FORM_MODAL,
  SET_SHOW_GROUP_FORM_MODAL,
  SET_FILTER_DATA,
  SET_IMPORT_CONTACTS_STEPS_STATUS,
  SET_ACTIVE_INDEX_IMPORT_CONTACTS,
  SET_IMPORT_CONTACTS_STEP_ONE_DATA,
  SET_IMPORT_CONTACTS_STEP_TWO_DATA,
  SET_IMPORT_CONTACTS_STEP_THREE_DATA,
  RESET_IMPORT_CONTACTS_FORM,
} from './actionTypes';

const IMPORT_CONTACTS_INIT_STATE = {
  activeIndex: 0,
  step1: {
    templateType: '',
  },
  step2: {
    selectedFile: '',
    uploadedFile: null,
    segmentName: '',
    importType: IMPORT_TYPE_KEYS.ADD_NEW,
    marketingOptIn: false,
    tags: [],
    fieldMappings: [],
    contactData: [],
  },
  step3: {
    selectAll: false,
  },
  stepsArray: [
    {
      stepIndex: 0,
      completed: false,
    },
    {
      stepIndex: 1,
      completed: false,
    },
    {
      stepIndex: 2,
      completed: false,
    },
  ],
};

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  importedContactData: [],
  importedContactFields: [],
  mappedContactFields: {},
  selectedCsvFile: [],
  showContactFormModal: false,
  showGroupFormModal: false,
  filterData: {},
  selectedOptions: [],
  options: [],
  contacts: [],
  importContacts: IMPORT_CONTACTS_INIT_STATE,
};

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => (user.id.toString() === action.payload.id.toString()
          ? { user, ...action.payload }
          : user)),
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user) => user.id.toString() !== action.payload.id.toString(),
        ),
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SET_IMPORTED_CONTACTS_DATA:
      return {
        ...state,
        importedContactData: action.payload,
      };

    case SET_IMPORTED_CONTACTS_FIELDS:
      return {
        ...state,
        importedContactFields: action.payload,
      };

    case SET_MAPPED_CONTACT_FIELDS:
      return {
        ...state,
        mappedContactFields: action.payload,
      };

    case SET_SELECTED_CSV_FILE:
      return {
        ...state,
        selectedCsvFile: action.payload,
      };

    case SET_SHOW_CONTACT_FORM_MODAL:
      return {
        ...state,
        showContactFormModal: !state.showContactFormModal,
      };

    case SET_SHOW_GROUP_FORM_MODAL:
      return {
        ...state,
        showGroupFormModal: !state.showGroupFormModal,
      };

    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
      // case FETCH_CONTACTS:
      //   return {
      //     ...state,
      //     contacts: action.payload,
      //   };
      // case UPDATE_CONTACT_TAGS:
      //   return {
      //     ...state,
      //     contacts: state.contacts.map(contact =>
      //       contact.id === action.payload.contactId
      //         ? { ...contact, tags: action.payload.tags }
      //         : contact
      //     ),
      //   };

    case SET_IMPORT_CONTACTS_STEPS_STATUS: {
      const { stepsArray } = action.payload;
      return {
        ...state,
        importContacts: {
          ...state.importContacts,
          stepsArray,
        },
      };
    }

    case SET_ACTIVE_INDEX_IMPORT_CONTACTS: {
      const { activeIndex } = action.payload;
      return {
        ...state,
        importContacts: {
          ...state.importContacts,
          activeIndex,
        },
      };
    }

    case SET_IMPORT_CONTACTS_STEP_ONE_DATA: {
      return {
        ...state,
        importContacts: {
          ...state.importContacts,
          step1: {
            ...state.importContacts.step1,
            ...action.payload,
          },
        },
      };
    }

    case SET_IMPORT_CONTACTS_STEP_TWO_DATA: {
      return {
        ...state,
        importContacts: {
          ...state.importContacts,
          step2: typeof action.payload === 'function'
            ? action.payload(state.importContacts.step2)
            : { ...state.importContacts.step2, ...action.payload },
        },
      };
    }

    case SET_IMPORT_CONTACTS_STEP_THREE_DATA: {
      return {
        ...state,
        importContacts: {
          ...state.importContacts,
          step3: {
            ...state.importContacts.step3,
            ...action.payload,
          },
        },
      };
    }

    case RESET_IMPORT_CONTACTS_FORM: {
      return {
        ...state,
        importContacts: IMPORT_CONTACTS_INIT_STATE,
      };
    }

    default:
      return state;
  }
};

export default contacts;
