import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ErrorMessage = styled.div`
  display: ${(props) => (props.showError ? 'block' : 'none')};
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: var(--bs-form-invalid-color);
`;

function CustomErrorMessage({ message, showError }) {
  return <ErrorMessage showError={showError}>{message}</ErrorMessage>;
}

CustomErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  showError: PropTypes.bool,
};

CustomErrorMessage.defaultProps = {
  showError: false,
};

export default CustomErrorMessage;
