import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Container, Row, Label,
} from 'reactstrap';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

// action

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import images
import { toast } from 'react-toastify';
import { axiosPost } from '../../services/http.service';
import ModalLoader from '../../components/Common/ModalLoader';
import profileImg from '../../assets/images/common/profile-img.png';
import {
  apiError as apiErrorAction,
  registerUser,
  registerUserFailed as registerUserFailedAction,
  setLoader,
} from '../../store/actions';
import LogoIcon from '../../assets/images/logo-icon.png';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoader: false,
    };
  }

  componentDidMount() {
    const { apiError, registerUserFailed } = this.props;
    apiError('');
    registerUserFailed('');
  }

  handleUserRegistration = async (values, formik) => {
    const { history } = this.props;
    this.setState({ modalLoader: true });
    try {
      const data = {
        name: values.username,
        phoneNumber: `${values.phoneNumber}`,
        password: values.password,
        organizationName: values.organizationName,
        email: values.email,
      };
      const response = await axiosPost('auth/register', data);
      if (response?.status) {
        toast.success(response.message || 'Registration successful!');
        formik.resetForm();
        history.push('/login');
      } else {
        toast.error(response.message || 'Oops! something went wrong!');
      }
      this.setState({ modalLoader: false });
    } catch (error) {
      this.setState({ modalLoader: false });
      console.error('error at handleUserRegistration', error);
    }
  };

  render() {
    const {
      modalLoader, email, password, username,
    } = this.state;
    return (
      <>
        {modalLoader ? <ModalLoader /> : null}
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Free Register</h5>
                          <p>Get your free Auto Campaign.AI Account.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={LogoIcon}
                              alt="auto-campaign-ai-logo-icon"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: (this.state && email) || '',
                          password: (this.state && password) || '',
                          username: (this.state && username) || '',
                          organizationName: '',
                          phoneNumber: '',
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required('Please Enter Your Email'),
                          password: Yup.string().required('Please Enter Valid Password'),
                          username: Yup.string().required('Please Enter Valid Username'),
                          organizationName: Yup.string().required('Please Enter Organization Name'),
                          phoneNumber: Yup.number().required('Please Enter Phone Number'),
                        })}
                        onSubmit={this.handleUserRegistration}
                      >
                        {({ errors, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="username" className="form-label">
                                Username
                              </Label>
                              <Field
                                name="username"
                                type="text"
                                className={`form-control${
                                  errors.username && touched.username ? ' is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="username"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                autoComplete="true"
                                type="password"
                                className={`form-control${
                                  errors.password && touched.password ? ' is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Phone Number</Label>

                              <Field
                                name="phoneNumber"
                                type="number"
                                className={`form-control${
                                  errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={`form-control${
                                  errors.email && touched.email ? ' is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="organizationName" className="form-label">
                                Organization Name
                              </Label>
                              <Field
                                name="organizationName"
                                type="text"
                                className={`form-control${
                                  errors.organizationName && touched.organizationName
                                    ? ' is-invalid'
                                    : ''
                                }`}
                              />
                              <ErrorMessage
                                name="organizationName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mt-4 d-grid">
                              <button className="btn btn-primary btn-block" type="submit">
                                Register
                              </button>
                            </div>

                            {/* <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the S{" "}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-3 text-center">
                  <p>
                    Already have an account ?
                    {' '}
                    <Link to="/login" className="fw-medium text-primary">
                      {' '}
                      Login
                    </Link>
                    {' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUserFailed: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  const { showLoader } = state.Dashboard;
  return {
    user,
    registrationError,
    loading,
    showLoader,
  };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError: apiErrorAction,
  registerUserFailed: registerUserFailedAction,
  setLoader,
})(Register);
