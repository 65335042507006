import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Card from '../Card';
import Row from '../Row';
import Col from '../Col';
import Label from '../Label';
import CustomErrorMessage from '../CustomErrorMessage';
import CsvIcon from '../../assets/images/common/csv-file-icon.jpg';

const StyledFileSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledButton = styled(Button)`
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  color: var(--bs-white);
  width: 11rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  &:hover {
    background-color: var(--bs-primary-dark, var(--bs-primary));
    border-color: var(--bs-primary-dark, var(--bs-primary));
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--bs-primary-rgb, rgba(0, 123, 255, 0.25));
  }
`;

const FilePreview = styled(Card)`
  .p-card-body {
    padding: 0.4rem;
  }
`;

const FileNameDisplay = styled.div`
  color: var(--bs-body-color);
`;

class FileSelector extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = (event) => {
    const { onSelect } = this.props;
    if (onSelect) {
      const filesArray = Array.from(event.target.files);
      onSelect(filesArray);
    }
  };

  render() {
    const {
      label,
      accept,
      multiple,
      disabled,
      className,
      id,
      onClose,
      selectedFiles,
      buttonText,
      errorMessage,
      ...rest
    } = this.props;

    const filesArray = selectedFiles || (this.fileInputRef.current?.files?.length > 0 ? Array.from(this.fileInputRef.current.files) : []);

    return (
      <StyledFileSelectorWrapper className={className}>
        <Label>{label}</Label>
        <StyledButton
          type="button"
          onClick={this.handleButtonClick}
          disabled={disabled}
          label={buttonText}
          icon="bx bx-upload"
          iconClassName="ms-2 fs-6"
        />
        <CustomErrorMessage
          message={errorMessage}
          showError={!!errorMessage}
        />
        <HiddenFileInput
          ref={this.fileInputRef}
          onChange={this.handleFileChange}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          id={id}
          {...rest}
        />
        <div className="dropzone-previews mt-3" id="file-previews">
          {filesArray.map((f, i) => (
            <FilePreview
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete mb-2"
                      // eslint-disable-next-line react/no-array-index-key
              key={`${i}-file`}
            >
              <div className="p-2 d-flex align-items-center justify-content-between">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={CsvIcon}
                    />
                  </Col>
                  <Col>
                    <FileNameDisplay
                      to="#"
                      className="text-muted font-weight-bold me-4"
                    >
                      {f.name}
                    </FileNameDisplay>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
                <i
                  className="mdi mdi-window-close cursor-pointer"
                  onClick={() => {
                    onClose(f, i);
                  }}
                />
              </div>
            </FilePreview>
          ))}
        </div>
      </StyledFileSelectorWrapper>
    );
  }
}

FileSelector.propTypes = {
  buttonText: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  selectedFiles: PropTypes.array,
  onClose: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

FileSelector.defaultProps = {
  buttonText: '',
  label: '',
  errorMessage: '',
  disabled: false,
  multiple: false,
  className: '',
  onClose: () => {},
  id: '',
  accept: '*/*',
};

export default FileSelector;
