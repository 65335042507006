/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button, UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import { axiosGet } from '../../../services/http.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { setLoader } from '../../../store/actions';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';
import { REPORTS } from '../../../constants/analytics/analytics.constant';
import DataGrid from '../../../components/DataTable';

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignReport: [],
      campaignData: {},
      campaignStatistics: {},
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      selectedRows: [],
      campaignReportColumns: [
        {
          header: 'To Number',
          accessorKey: 'to',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'From Number',
          accessorKey: 'from',
          Cell: ({ renderedCellValue }) => {
            const { campaignData } = this.state;
            if (campaignData?.usingCopilot) {
              if (renderedCellValue) {
                return <span>{`co-pilot-${renderedCellValue}`}</span>;
              }
              return <span>co-pilot</span>;
            }
            return <PhoneNumberWithFlag phoneNumber={renderedCellValue} />;
          },
        },
        {
          header: 'Message',
          accessorKey: 'whatsappMessage.templateContent' || 'message',
          Cell: ({ row }) => {
            const report = row?.original;
            const rowIndex = row?.id;
            const contentToDisplay = report?.whatsappMessage?.templateContent || report?.message || '';
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;

            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {contentToDisplay}
                </UncontrolledTooltip>

                <span id={tooltipId}>{sliceStringWithEllipsis(30, contentToDisplay)}</span>
              </>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
        },
        {
          header: 'Failure Reason',
          accessorKey: 'failureReason',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const contentToDisplay = renderedCellValue;
            const tooltipId = `FailureReasonUncontrolledTooltip_${rowIndex}`;

            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {contentToDisplay}
                </UncontrolledTooltip>

                <span id={tooltipId}>{sliceStringWithEllipsis(30, contentToDisplay) || '-'}</span>
              </>
            );
          },
        },
        {
          header: 'Cost',
          accessorKey: 'cost',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
        },
        {
          header: 'Date',
          accessorKey: 'date',
          Cell: ({ row }) => {
            const report = row?.original;
            const formattedDate = moment(report?.createdAt).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(report?.createdAt).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetCampignReportById();
  }

  handleGetCampignReportById = async (page) => {
    const { onSetLoader, match } = this.props;
    const { size } = this.state;
    try {
      onSetLoader(true);
      const { id } = match.params;
      const response = await axiosGet(`campaign/report/${id}?limit=${size}&page=${page}`);
      if (response?.status) {
        if (response?.data?.campaignData?.channel === CHANNEL_TYPES.WHATSAPP) {
          await this.handleGetWhatsappNumber(response?.data?.campaignData?.senderNumberId);
        }
        this.setState({
          campaignReport: response?.data?.campaignMessages?.messages,
          campaignData: response?.data?.campaignData,
          campaignStatistics: response?.data?.campaignStatistics,
          totalSize: response?.data?.campaignMessages?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('error at handleGetAllTemplates :', error);
      onSetLoader(false);
    }
  };

  handleGetWhatsappNumber = async (whatsappNumberId) => {
    try {
      const response = await axiosGet(`whatsapp-numbers/${whatsappNumberId}`);
      if (response?.status) {
        this.setState({ whatsappNumber: response?.data });
      }
    } catch (error) {
      console.error('error at handleGetWhatsappNumber:', error);
    }
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetCampignReportById(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetCampignReportById(1, newPageSize);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'campaign-report';

    const {
      campaignReport, campaignData, campaignStatistics, totalSize, campaignReportColumns,
    } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    const reportPage = queryParams.get('page');

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Campaign" breadcrumbItem={campaignData?.name} />
          <Row>
            {REPORTS.map((report, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col md="3" key={`_col_${key}`}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{campaignStatistics?.[report?.key] || 0}</h4>
                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={`bx ${report.iconClass} font-size-24`} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-end mb-3">
                    <Link className="text-success" to={`/report?page=${reportPage}`}>
                      <Button className="btn btn-secondary">Back</Button>
                    </Link>
                  </div>
                  <div className="mt-3">
                    <DataGrid
                      data={campaignReport}
                      columns={campaignReportColumns}
                      enableColumnResizing={false}
                      enableColumnFilterModes
                      enableEditing={false}
                      enableGlobalFilter={false}
                      onRowSelectionChange={this.handleRowSelection}
                      totalSize={totalSize}
                      onPageChange={this.handlePageClick}
                      onPageSizeChange={this.handleLimitChange}
                      initialPage={0}
                      initialPageSize={10}
                      onSearch={this.debounceSearch}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ViewReport.propTypes = {
  onSetLoader: PropTypes.func,
  match: PropTypes.any,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewReport));
