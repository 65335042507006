import { parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment-timezone';
import { DEFAULT_NAME } from '../constants/voice/call.constant';
import { TEMPLATE_COMPONENT_TYPE, WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS, WHATSAPP_TEMPLATES_PARAMETER_TYPES } from '../constants/channels/whatsApp.constant';
import Avatar from '../components/Avatar';

/* eslint-disable max-len */
export const getDataFromSessionStorage = (key, defaultValue = undefined) => (sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : defaultValue);

export const getDataFromLocalStorage = (key, defaultValue = undefined) => (localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue);

export const setDataInSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const setDataInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getFormattedPhoneNumber = (phoneNumber) => {
  try {
    let formattedPhone = phoneNumber;
    if (formattedPhone && !formattedPhone.startsWith('+')) {
      formattedPhone = `+${formattedPhone}`;
    }

    const parsedNumber = parsePhoneNumber(formattedPhone);

    if (parsedNumber) {
      return {
        parsedNumber,
      };
    }
  } catch (error) {
    console.error('Error formatting phone number:', error);
  }

  return null;
};

export const getTimezoneDisplay = (phoneNumber) => {
  try {
    let parsedNumber;
    if (phoneNumber) {
      parsedNumber = parsePhoneNumber(phoneNumber);
    }
    const countryCode = parsedNumber.country;
    const timezones = moment.tz.zonesForCountry(countryCode);
    if (timezones && timezones.length > 0) {
      const timezone = timezones[0]; // Use the first timezone for the country
      const currentTime = moment().tz(timezone).format('HH:mm');
      const offsetStr = moment().tz(timezone).format('Z');

      return (
        <span>
          {timezone}
          {' '}
          (
          {offsetStr}
          )
          {' '}
          {currentTime}
        </span>
      );
    }
  } catch (error) {
    console.error('Error getting timezone information:', error);
  }
  return null;
};

export const getInitials = (name) => {
  if (!name) return '-';
  try {
    if (typeof name !== 'string' || !name.trim()) {
      return '';
    }
    const initials = name
      .trim()
      .split(/\s+/) // Handles multiple spaces and trims each word
      .slice(0, 2)
      .map((word) => word[0].toUpperCase())
      .join('');
    return initials;
  } catch (error) {
    console.error('Error in getInitials function:', error);
    return '';
  }
};

export const sliceStringWithEllipsis = (splitDigit, str) => {
  try {
    if (typeof str !== 'string' || typeof splitDigit !== 'number' || splitDigit < 0) {
      throw new Error('Invalid input: str must be a string and splitDigit must be a non-negative number.');
    }

    if (str.length <= splitDigit) {
      return str;
    }
    return `${str.slice(0, splitDigit)}...`;
  } catch (error) {
    console.error('Error in sliceStringWithEllipsis function:', error);
    return str; // Return the original string in case of an error
  }
};

export const getContactName = (displayName) => {
  if (!displayName?.firstName) {
    return DEFAULT_NAME; // Return DEFAULT_NAME if displayName is null, undefined, or firstName is not present
  }

  const { firstName, lastName } = displayName;
  return `${firstName} ${lastName ?? ''}`.trim(); // Combine firstName and lastName, ensuring no extra spaces
};

export const customLoadingIndicator = () => (
  <div className="custom-loading-indicator">
    <Spinner size="sm" color="primary" />
  </div>
);

// Helper function to generate user avatar and name
export const renderUserAvatar = (userName, className = '', shape = 'circle') => (
  <div className="d-flex align-items-center">
    <Avatar label={getInitials(userName)} shape={shape} className={className} />
    <span className="fs-6 ms-2">{userName}</span>
  </div>
);

export const renderOptionsWithIcon = (userName, icon) => (
  <div className="d-flex align-items-center">
    <div className="text-center me-3">
      <div className="avatar-xs mx-auto">
        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
          <i className={icon} />
        </span>
      </div>
    </div>
    <span className="fs-6">{userName}</span>
  </div>
);

export const transformWhatsappTemplate = (whatsappTemplate) => {
  const newObj = {
    key: `${whatsappTemplate?.name}-${whatsappTemplate?.language}` || '',
    name: whatsappTemplate?.name || '',
    header: {},
    body: {},
    footer: {},
    buttons: [],
  };
  const {
    HEADER, BODY, FOOTER, BUTTONS,
  } = TEMPLATE_COMPONENT_TYPE;
  const { TEXT } = WHATSAPP_TEMPLATES_PARAMETER_TYPES;
  const { URL } = WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS;

  whatsappTemplate?.components?.forEach((component) => {
    if (!component.type) return;

    switch (component.type) {
      case HEADER: {
        newObj.header.type = (component?.format || TEXT)?.toLowerCase();
        newObj.header.text = component?.text || '';
        newObj.header.actualText = component?.text || '';
        newObj.header.url = component?.example?.header_handle?.[0] || '';
        break;
      }
      case BODY: {
        newObj.body.type = TEXT.toLowerCase();
        newObj.body.text = component?.text || '';
        newObj.body.actualText = component?.text || '';
        break;
      }
      case FOOTER: {
        newObj.footer.type = TEXT.toLowerCase();
        newObj.footer.text = component?.text || '';
        break;
      }
      case BUTTONS: {
        if (component?.buttons) {
          newObj.buttons = component?.buttons?.map((button) => ({
            type: (button?.type || '')?.toLowerCase(),
            url: button?.type === URL ? button?.url || '' : '',
            text: button?.text || '',
            code: button?.example?.[0] || '',
          }));
        }
        break;
      }
      // eslint-disable-next-line no-empty
      default: { }
    }
  });

  return newObj;
};

export const removeIdFromObject = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeIdFromObject(item));
  }

  if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key !== '_id') {
        newObj[key] = removeIdFromObject(obj[key]);
      }
    });
    return newObj;
  }

  return obj;
};

export const removeKeysByPattern = (object, pattern = '') => {
  if (typeof object !== 'object' || object === null) {
    throw new TypeError('The first argument must be a valid object.');
  }
  if (typeof pattern !== 'string') {
    throw new TypeError('The pattern must be a string.');
  }

  const regex = new RegExp(pattern, 'i');
  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (regex.test(key)) {
      // eslint-disable-next-line no-param-reassign
      delete object[key];
    }
  }
  return object;
};

export const sortByCreatedAt = (arrays) => arrays.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

// Formats a filter object into a URL query string.
// Converts each key-value pair, handling arrays by creating multiple key=value pairs,
// and encodes all values for safe use in a URL.
export const formatFilterParams = (filterObj) => {
  const formattedFilter = { ...filterObj };
  // Convert to query string using array methods
  const paramPairs = Object.keys(formattedFilter).reduce((acc, key) => {
    const value = formattedFilter[key];

    if (Array.isArray(value)) {
      // Handle arrays by creating multiple key=value pairs
      return [...acc, ...value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)];
    }

    if (value !== undefined && value !== null) {
      return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`];
    }

    return acc;
  }, []);

  return paramPairs.join('&');
};
