import React from 'react';
import { FileText } from 'lucide-react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { MEDIA_TYPES } from '../../../constants/file.constant';

function MediaPreview({ onRemove, mediaType, fileUrl }) {
  const removeFile = () => {
    if (onRemove) {
      onRemove();
    }
  };

  const renderPreview = () => (
    <div className="media-wrapper">
      {mediaType === MEDIA_TYPES.IMAGE && (
        <img
          width="100"
          height="100"
          src={fileUrl}
          alt="Preview"
          className="rounded max-w-full h-auto"
        />
      )}
      {mediaType === MEDIA_TYPES.AUDIO && (
        <audio width="100" height="100" controls>
          <source src={fileUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {mediaType === MEDIA_TYPES.VIDEO && (
        <video width="100" height="100" controls>
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {mediaType === MEDIA_TYPES.APPLICATION && (
        <div className="flex items-center">
          <FileText size={100} />
        </div>
      )}
      <Button
        label=""
        icon="mdi mdi-window-close font-size-14"
        iconClassName="m-0"
        className="bg-red-500 text-white rounded-circle bg-secondary border-0 p-0 media-close close-button"
        onClick={removeFile}
        aria-label="Remove file"
      />
    </div>
  );

  if (!fileUrl) return null;

  return (
    <div className="relative">
      <div className="preview-wrapper">{renderPreview()}</div>
    </div>
  );
}

MediaPreview.propTypes = {
  onRemove: PropTypes.func,
  mediaType: PropTypes.string,
  fileUrl: PropTypes.string,
};

export default MediaPreview;
