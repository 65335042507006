import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'primereact/timeline';
import { styled } from 'styled-components';

const StyledTimeline = styled(Timeline)`
  .p-timeline-event-opposite {
    text-align: right;
    padding: 0.5rem;
    display: none;
  }
`;

class CustomTimeline extends Component {
  render() {
    const {
      events, align, opposite, content, marker,
    } = this.props;

    return (
      <StyledTimeline
        value={events}
        align={align}
        opposite={opposite}
        content={content}
        marker={marker}
      />
    );
  }
}

CustomTimeline.propTypes = {
  events: PropTypes.array.isRequired,
  align: PropTypes.string, // Options: 'left', 'right', 'alternate'
  opposite: PropTypes.bool,
  content: PropTypes.func.isRequired,
  marker: PropTypes.func,
};

CustomTimeline.defaultProps = {
  align: 'alternate',
  opposite: true,
};

export default CustomTimeline;
