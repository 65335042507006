import { tabs } from '../../constants/tabs';
import {
  SHOW_ADD_NOTE_MODAL,
  SHOW_CHANGE_GROUP_MODAL,
  SHOW_NOTE_LIST_MODAL,
  SELECTED_CONTACT_ID,
  ACTIVE_TAB,
  SET_CONVERSATION_FILTER,
} from './actionTypes';

const INIT_STATE = {
  activeTab: tabs.WHATSAPP,
  showNoteListModal: false,
  showAddListModal: false,
  showChangeGroupModal: false,
  selectedContactId: null,
  filterData: {},
};

const inbox = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case SHOW_NOTE_LIST_MODAL:
      return {
        ...state,
        showNoteListModal: !state.showNoteListModal,
      };

    case SHOW_ADD_NOTE_MODAL:
      return {
        ...state,
        showAddListModal: !state.showAddListModal,
      };

    case SHOW_CHANGE_GROUP_MODAL:
      return {
        ...state,
        selectedContactId: !state.showChangeGroupModal ? state.selectedContactId : null,
        showChangeGroupModal: !state.showChangeGroupModal,
      };

    case SELECTED_CONTACT_ID:
      return {
        ...state,
        selectedContactId: action.payload,
      };

    case SET_CONVERSATION_FILTER:
      return {
        ...state,
        filterData: action.payload,
      };

    default:
      return state;
  }
};

export default inbox;
