import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps as Stepper } from 'primereact/steps';
import styled from 'styled-components';
import Button from '../../Button';

const StyledSteps = styled(Stepper)`
  padding: 0rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2.5rem;

  ol {
    padding: 0;
    margin: 0;

    li::before {
      border-top: 1px solid var(--bluegray-500);
      top: 94%;
      left: 55%;
    }

    /* Remove border for the last step */
    li:last-child::before {
      border-top: none;
    }

    .p-menuitem-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 1;
      padding: 0 1rem;
      background-color: var(--bs-body-bg);

      .p-steps-number {
        background-color: var(--bs-white);
        padding: 1.05rem 0.5rem 1.05rem 0.45rem;
        border: 2px solid var(--blue-500);
        
        i {
          font-size: 1.2rem;
          color: var(--blue-600);
        }
      }

      .p-steps-title {
        font-weight: 500;
        margin: 0;
        margin-left: 0.5rem;
      }

    }
    
    .p-completed {
      .p-steps-number {
        background-color: var(--blue-500);
        padding: 1.05rem 0.2rem 1.035rem 0.1rem;

        i {
          font-size: 1.8rem;
          color: var(--bs-white);
        }
      }
    }

    // Don't set opacity to less than 1
    .p-disabled {
      opacity: 1;

      .p-menuitem-link {
        .p-steps-number {
          border: 2px solid var(--blue-300);

          i {
            color: var(--blue-300);
          }
        }
      }
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Pushes content to the end of the container */
  align-items: center; 
`;

const StyledButton = styled(Button)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const PreviousButton = styled(Button)`
  background: transparent;
  color: var(--bs-gray-700);
  border: 1px solid var(--bs-gray-700);
  // padding: 0.1rem 0.7rem;

  &:hover, &:active {
    color: var(--bs-gray-900) !important;
    border: 1px solid var(--bs-gray-900) !important;
    background: transparent !important;
  }
`;

class WizardSteps extends Component {
  constructor(props) {
    super(props);
    const { activeIndex } = this.props;
    this.state = {
      activeIndex: activeIndex || 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { activeIndex } = this.props;
    if (activeIndex !== prevProps.activeIndex) {
      this.setState({ activeIndex });
    }
  }

  handleStepChange = (stepIndex) => {
    const { onStepChange } = this.props;
    this.setState({ activeIndex: stepIndex }, () => {
      if (onStepChange) {
        // trigger onStepChange callback when step changes
        onStepChange(stepIndex);
      }
    });
  }

  handleNextClick = () => {
    const { activeIndex } = this.state;
    const { items } = this.props;
    const newIndex = activeIndex + 1;
    this.handleStepChange(newIndex % items?.length);
  }

  handlePreviousClick = () => {
    const { activeIndex } = this.state;
    this.handleStepChange(activeIndex - 1);
  }

  render() {
    const { activeIndex } = this.state;

    const {
      items,
      readOnly,
      children,
      pt,
      ptOptions,
      unstyled,
      className,
      containerClassName,
      buttonsEnable,
      nextButtonLabel,
      nextButtonClassName,
      previousButtonLabel,
      previousButtonClassName,
      previousButtonDisabled,
      nextButtonDisabled,
    } = this.props;

    return (
      <div className={containerClassName}>
        <StyledSteps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => this.handleStepChange(e.index)}
          className={className}
          readOnly={readOnly ?? true}
          pt={pt}
          ptOptions={ptOptions}
          unstyled={unstyled}
        />
        {children}
        {
          buttonsEnable
            ? (
              <FlexContainer>
                <PreviousButton
                  label={previousButtonLabel}
                  className={`rounded me-3 ${previousButtonClassName}`}
                  onClick={this.handlePreviousClick}
                  disabled={previousButtonDisabled ?? activeIndex === 0}
                />
                {
                  (activeIndex + 1) !== items?.length
                  && (
                  <StyledButton
                    label={nextButtonLabel}
                    className={`rounded ${nextButtonClassName}`}
                    onClick={this.handleNextClick}
                    disabled={nextButtonDisabled}
                  />
                  )
                }
              </FlexContainer>
            )
            : null
        }
      </div>
    );
  }
}

WizardSteps.propTypes = {
  items: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  onStepChange: PropTypes.func,
  children: PropTypes.node,
  pt: PropTypes.object,
  ptOptions: PropTypes.object,
  unstyled: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  buttonsEnable: PropTypes.bool,
  nextButtonLabel: PropTypes.string,
  nextButtonClassName: PropTypes.string,
  previousButtonLabel: PropTypes.string,
  previousButtonClassName: PropTypes.string,
  previousButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
};

WizardSteps.defaultProps = {
  activeIndex: 0,
  readOnly: true,
  unstyled: false,
  className: '',
  containerClassName: '',
  buttonsEnable: false,
  nextButtonLabel: 'Next',
  nextButtonClassName: '',
  previousButtonLabel: 'Previous',
  previousButtonClassName: '',
  previousButtonDisabled: undefined,
  nextButtonDisabled: undefined,
};

export default WizardSteps;
