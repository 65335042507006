import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';
import Label from '../Label';

const StyledLabel = styled(Label)`
  display: block;
  flex: 5;
`;

const StyledField = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .field-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 18;
`;

const StyledIcon = styled.i`
  font-size: 1.2rem;
  margin-right: 0.3rem;
`;

const StyledSpan = styled.span`
`;

function LabelValue({
  label,
  className,
  value,
  icon,
  labelClassName,
  iconClassName,
  textClassName,
  ...rest
}) {
  const renderIcon = () => {
    if (!icon) return null;
    return <StyledIcon className={`bx ${icon} ${iconClassName}`} />;
  };

  try {
    return (
      <StyledField className={className} {...rest}>
        <StyledLabel className={labelClassName}>{label}</StyledLabel>
        <StyledDiv>
          {renderIcon()}
          <StyledSpan className={textClassName}>{value}</StyledSpan>
        </StyledDiv>
      </StyledField>
    );
  } catch (error) {
    handleError(error);
    return null;
  }
}

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
};

LabelValue.defaultProps = {
  labelClassName: '',
  fieldClassName: '',
  className: '',
  iconClassName: '',
  textClassName: '',
  value: '',
  icon: '',
};

export default LabelValue;
