import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ScrollContainer = styled.div`
  max-height: ${(props) => props.maxHeight};
  overflow-y: ${(props) => (props.showScroll ? 'scroll' : 'hidden')};

  /* Customizing the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, ${(props) => props.topBackgroundColor}, ${(props) => props.endBackgroundColor});
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, ${(props) => props.hoverTopBackgroundColor}, ${(props) => props.hoverEndBackgroundColor});
  }

  /* Customizing the scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.topBackgroundColor} #f1f1f1;
`;

class VerticleScrollBar extends Component {
  constructor(props) {
    super(props);
    this.scrollContainerRef = React.createRef();
    this.state = {
      showScroll: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateScrollVisibility();
    }, 10);
  }

  updateScrollVisibility = () => {
    const scrollContainer = this.scrollContainerRef.current;
    const { maxHeight } = this.props;

    const maxHeightPixels = window.innerHeight * (parseInt(maxHeight, 10) / 100);
    const contentHeight = scrollContainer.scrollHeight;

    this.setState({
      showScroll: contentHeight > maxHeightPixels,
    });
  };

  render() {
    const {
      children,
      maxHeight,
      topBackgroundColor,
      endBackgroundColor,
      hoverTopBackgroundColor,
      hoverEndBackgroundColor,
      ...rest
    } = this.props;

    const { showScroll } = this.state;

    return (
      <ScrollContainer
        ref={this.scrollContainerRef}
        maxHeight={maxHeight}
        topBackgroundColor={topBackgroundColor}
        endBackgroundColor={endBackgroundColor}
        hoverTopBackgroundColor={hoverTopBackgroundColor}
        hoverEndBackgroundColor={hoverEndBackgroundColor}
        showScroll={showScroll}
        {...rest}
      >
        {children}
      </ScrollContainer>
    );
  }
}

VerticleScrollBar.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  topBackgroundColor: PropTypes.string,
  endBackgroundColor: PropTypes.string,
  hoverTopBackgroundColor: PropTypes.string,
  hoverEndBackgroundColor: PropTypes.string,
};

VerticleScrollBar.defaultProps = {
  maxHeight: '68vh',
  topBackgroundColor: 'var(--bs-gray-400)',
  endBackgroundColor: 'var(--bs-gray-500)',
  hoverTopBackgroundColor: 'var(--bs-gray-500)',
  hoverEndBackgroundColor: 'var(--bs-gray-600)',
};

export default VerticleScrollBar;
