import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { COLOR_PALETTE } from '../../constants/colors.constant';
import noDataAvailable from '../../assets/images/graphics/data-not-available.svg';

class Bar extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      data,
      xAxisData,
      seriesNames,
      barWidth,
      title,
      yAxisName,
      xAxisName,
      loading,
      page,
      totalPages,
    } = this.props;
    return (
      data !== nextProps.data
      || xAxisData !== nextProps.xAxisData
      || seriesNames !== nextProps.seriesNames
      || barWidth !== nextProps.barWidth
      || title !== nextProps.title
      || yAxisName !== nextProps.yAxisName
      || xAxisName !== nextProps.xAxisName
      || loading !== nextProps.loading
      || page !== nextProps.page
      || totalPages !== nextProps.totalPages
    );
  }

  getOption = () => {
    const {
      title,
      yAxisName = 'Cost ($)',
      xAxisName = 'Campaigns',
      data,
      xAxisData,
      seriesNames,
      barWidth = '40%',
      showLine = false,
    } = this.props;

    const totalData = xAxisData?.map((_, index) => data.reduce((sum, series) => sum + series[index], 0));

    // Calculate incremental data for cost change
    const percentageChange = totalData?.map((value, index) => {
      if (index === 0) return null; // No change for the first element
      const previousValue = totalData[index - 1];
      return previousValue !== 0 ? ((value - previousValue) / previousValue) * 100 : 0;
    });

    const series = seriesNames.map((name, index) => ({
      name,
      type: 'bar',
      stack: 'total',
      barWidth,
      label: {
        show: true,
        formatter: (params) => {
          const total = totalData[params.dataIndex];
          const { value } = params;
          const percentage = total > 0 ? Math.floor((value / total) * 100) : 0;
          return `${percentage}%`;
        },
        position: 'inside',
      },
      data: data[index],
    }));

    if (showLine) {
      series.push({
        name: 'Cost Change',
        type: 'line',
        data: totalData,
        smooth: false,
        symbol: 'circle',
        symbolSize: 6,
        lineStyle: {
          width: 2,
          type: 'dashed',
        },
        label: {
          show: true,
          formatter: (params) => {
            const change = percentageChange[params.dataIndex];
            return change !== null ? `${change.toFixed(2)}%` : '';
          },
          position: 'top',
          distance: 20,
        },
      });

      series.push({
        name: 'Total Cost',
        type: 'custom',
        renderItem: (params, api) => {
          const total = totalData[params.dataIndex];
          const xValue = api.value(0);
          const yValue = api.value(1);
          const start = api.coord([xValue, yValue]);

          return {
            type: 'text',
            style: {
              text: `$${total}`,
              x: start[0],
              y: start[1] - 20,
              fill: '#333',
              fontSize: 12,

              textAlign: 'center',
            },
          };
        },
        data: totalData.map((value, index) => [index, value]),
        z: 100,
      });
    }

    return {
      grid: {
        top: 100,
      },
      toolbox: {
        top: 0,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Save As Image',
            iconStyle: {
              color: '#fff',
              backgroundColor: '#fff',
              borderColor: '#50a5f1',
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowOffsetX: 0,
              shadowOffsetY: 4,
            },
            pixelRatio: 2,
          },
        },
      },
      legend: {
        data: seriesNames.concat(showLine ? ['Cost Change'] : []),
        top: 30,
      },
      yAxis: [
        {
          type: 'value',
          name: yAxisName,
          nameLocation: 'center',
          nameTextStyle: { padding: 30, fontWeight: 'bold', fontSize: 12 },
          axisLabel: showLine
            ? {
              formatter: (value) => `$${value}`, // Add dollar sign to y-axis labels if showLine is true
            }
            : {},
        },
      ],
      xAxis: {
        type: 'category',
        name: xAxisName,
        nameLocation: 'center',
        nameTextStyle: { padding: 30, fontWeight: 'bold', fontSize: 12 },
        data: xAxisData,
        axisLabel: {
          interval: 0,
          width: 70,
          overflow: 'break',
          rich: {},
        },
      },
      color: COLOR_PALETTE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        formatter: showLine
          ? (params) => {
            const tooltipText = params
              .map((param) => {
                const { seriesName, value } = param;
                if (seriesName === 'Total Cost') {
                  return `${seriesName}: $${value[1]}`;
                }
                // For other series, keep the original format
                return `${seriesName}: $${value}`;
              })
              .join('<br/>');
            return tooltipText;
          }
          : null,
      },
      series,
      title: {
        text: title,
        top: 0,
        textStyle: {
          color: '#333',
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
    };
  };

  render() {
    const {
      loading, page, totalPages, onLoadPreviousPage, onLoadNextPage, xAxisData,
    } = this.props;
    return (
      <div className="echarts-container">
        {page > 1 && (
          <Button
            className="bg-white rounded-5 shadow-sm p-3 d-flex justify-content-center align-items-center prev-button"
            onClick={onLoadPreviousPage}
            disabled={loading || page <= 1}
          >
            <i className="mdi mdi-arrow-left text-black font-size-16" />
          </Button>
        )}
        {isEmpty(xAxisData) ? (
          <div
            className="d-flex align-items-center justify-content-center"
          >
            <img src={noDataAvailable} alt="No data available" className="d-flex align-items-center mt-5" />
          </div>
        ) : (
          <ReactEcharts
            style={{ height: '100%', width: '100%' }}
            option={this.getOption()}
            lazyUpdate
            showLoading={loading}
          />
        )}
        {page < totalPages && (
          <Button
            className="bg-white rounded-5 shadow-sm p-3 d-flex justify-content-center align-items-center next-button"
            onClick={onLoadNextPage}
            disabled={loading || page >= totalPages}
          >
            <i className="mdi mdi-arrow-right text-black font-size-16" />
          </Button>
        )}
      </div>
    );
  }
}

Bar.propTypes = {
  data: PropTypes.array,
  xAxisData: PropTypes.array,
  seriesNames: PropTypes.array,
  barWidth: PropTypes.string,
  title: PropTypes.string,
  yAxisName: PropTypes.string,
  xAxisName: PropTypes.string,
  loading: PropTypes.bool,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onLoadPreviousPage: PropTypes.func,
  onLoadNextPage: PropTypes.func,
  showLine: PropTypes.bool,
};

export default Bar;
