import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Card,
  Badge,
  UncontrolledAccordion,
  CardBody,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  addContactToGroup,
  createContactGroup,
  deleteContactFromGroup,
  fetchContactGroup,
  fetchContactGroupsByContactId,
} from '../../../services/api/contactGroups.service';
import { CREATABLE_SELECT_ACTIONS } from '../../../constants/channels/conversation.constant';
import {
  getContactByPhoneNumberString,
  updateContact,
} from '../../../services/api/contacts.service';
import { PAGE, SIZE_PER_PAGE } from '../../../constants/constants';
import ContactForm from '../../Contacts/Contact Groups/Modal/ContactForm';
import { customLoadingIndicator, getInitials } from '../../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import {
  UserContext,
  UserContext as userContext,
} from '../../../components/UserProvider/UserProvider';
import { capitalizeFirstLetter } from '../../../utils/general';
import { SUBTLE_COLORS } from '../../../constants/colors.constant';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import Skeleton from '../../../components/Skeleton';
import Avatar from '../../../components/Avatar';
import CustomButton from '../../../components/PrimeButton';
import StyledSwitch from '../../../components/Switch';
import EditableFieldWrapper from '../../../components/EditableFieldWrapper';
import StyledContainerWithLabel from '../../../components/ContainerWithLabel';
import { toggleCallWidgetVisibility } from '../../../store/actions';
import { addTag, fetchAllTags, removeTag } from '../../../services/api/tags.service';
import { showToast } from '../../../services/toastService';
import { toastType } from '../../../constants/toast';
import Select from '../../../components/Select';
import {
  ADDRESS_EXAMPLE,
  COMPANY_NAME_EXAMPLE,
  EMAIL_EXAMPLE,
  LEAD_STAGE_OPTIONS,
  NAME_EXAMPLE,
  WEBSITE_URL_EXAMPLE,
} from '../../../constants/contacts.constant';

function ProfileTab({
  activeConversation,
  onClickBlock,
  updateProfileDetails,
  handleUpdateConversation,
}) {
  const currentUser = useContext(userContext); // Context to retrieve user data.
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagPage, setTagPage] = useState(PAGE);
  const [totalTagPages, setTotalTagPages] = useState(0);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [size] = useState(SIZE_PER_PAGE);
  const [contact, setContact] = useState({});
  const [contactGroupOptions, setContactGroupOptions] = useState([]);
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [contactGroupPage, setContactGroupPage] = useState(PAGE);
  const [totalContactGroupPages, setTotalContactGroupPages] = useState(0);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const { userData } = useContext(UserContext);

  const getAllTags = async (page) => {
    const activeWorkspace = userData?.activeWorkspace?.workspaceId;
    try {
      setIsLoadingTags(true);
      const response = await fetchAllTags(activeWorkspace, page, size);
      const options = response?.results?.map((tag) => ({
        label: tag?.name,
        value: tag._id,
      }));

      // Update tagOptions by adding new options that are not already in prevOptions.
      // Filter options to exclude those already present in prevOptions,
      // then combine the existing options with the newly filtered ones.
      setTagOptions((prevOptions) => {
        const newOptions = options.filter(
          (option) => !prevOptions.some((tag) => tag?.value === option?.value),
        );
        return [...prevOptions, ...newOptions];
      });
      setTagPage((prevPage) => prevPage + 1);
      setTotalTagPages(response?.totalPages);
    } catch (error) {
      console.error('Error Fetching tags:', error);
    } finally {
      setIsLoadingTags(false);
    }
  };

  // Function to filter out selected contact group options.
  // It returns a new array containing only those options from allOptions that are not present in the selectedContactGroups array.
  const filterSelectedOptions = (allOptions) => allOptions?.filter(
    (option) => !selectedContactGroups.some((selected) => selected?.value === option?.value),
  );

  const getAllContactGroups = async (page) => {
    try {
      setIsLoadingContacts(true);
      const response = await fetchContactGroup(page, size);
      const options = response?.data?.results.map((group) => ({
        label: group?.name,
        value: group?.id,
      }));

      // Filter the options to create a new array (newOptions)
      // that excludes any options that are already selected in the selectedContactGroups array.
      // The filterSelectedOptions function takes an array of all available options (allOptions)
      // and returns only those options whose values do not match any of the values in the selectedContactGroups array
      const newOptions = filterSelectedOptions(options);

      // Append newOptions to the existing contactGroupOptions array.
      // This function updates the contactGroupOptions state by taking the previous options (prevOptions)
      // and adding the items from newOptions to the end of the array, creating an updated list of options.
      setContactGroupOptions((prevOptions) => [...prevOptions, ...newOptions]);
      setContactGroupPage((prevPage) => prevPage + 1);
      setTotalContactGroupPages(response?.data?.totalPages);
    } catch (error) {
      console.error('Error fetching contact groups:', error);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const getContactDeatilsByPhoneNumberString = async (contactNumber) => {
    try {
      const response = await getContactByPhoneNumberString(contactNumber);
      if (response?.status) {
        const selectedTag = response?.data?.tags.map((tag) => ({
          label: tag?.tagName,
          value: tag?.tagId,
        }));
        setContact(response?.data);
        setSelectedTags(selectedTag);
      }
    } catch (error) {
      console.error('Error fetching contact :', error);
    } finally {
      setIsLoadingTags(false);
    }
  };

  const getAllContactGroupsByContactId = async (contactId) => {
    try {
      const response = await fetchContactGroupsByContactId(contactId);
      if (response?.status) {
        const selectedGroups = response?.data?.results?.map((group) => ({
          label: group?.name,
          value: group?.id,
        }));
        setSelectedContactGroups(selectedGroups);
      }
    } catch (error) {
      console.error('Error fetching contact groups by contact id:', error);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const getContactByConversation = async () => {
    const contactNumber = `+${activeConversation?.to}`;
    const contactId = activeConversation?.contactId;
    if (contactId) {
      await Promise.allSettled([
        getContactDeatilsByPhoneNumberString(contactNumber),
        getAllContactGroupsByContactId(contactId),
      ]);
    }
  };

  const initializeData = async () => {
    await Promise.allSettled([getAllTags(1), getAllContactGroups(1), getContactByConversation()]);
  };

  useEffect(() => {
    if (!isEmpty(activeConversation)) {
      initializeData();
    }
  }, [activeConversation?.id]);

  const handleAddTag = async (tag, contactId) => {
    try {
      setIsLoadingTags(true);
      const tagBody = { name: tag?.label?.toLowerCase() };
      const response = await addTag(contactId, tagBody);
      const addedTag = response?.tags.find(
        (newTag) => newTag?.tagName?.toLowerCase() === tag?.label.toLowerCase(),
      );
      if (addedTag) {
        const newTag = { ...tag, value: addedTag?.tagId };
        setSelectedTags((prevTags) => [...prevTags, newTag]);
        setTagOptions((prevOptions) => {
          const tagExists = prevOptions.some((prevTag) => prevTag.value === newTag.value);
          return tagExists ? prevOptions : [...prevOptions, newTag];
        });
        return newTag;
      }
      return {};
    } catch (error) {
      return null;
    } finally {
      setIsLoadingTags(false);
    }
  };

  const handleRemoveTag = async (tagToRemove, contactId) => {
    try {
      // Removes the specified tag from the contact by ID and updates the selected tags state ,by filtering out the removed tag from the selectedTags array.
      await removeTag(contactId, tagToRemove?.value);
      setSelectedTags((prevTags) => prevTags?.filter((tag) => tag?.value !== tagToRemove?.value));
    } catch (error) {
      console.error('error at handleRemoveTag:', error);
    } finally {
      setIsLoadingTags(false);
    }
  };

  const handleChangeTags = async (newValue, actionMeta) => {
    const contactId = activeConversation?.contactId;
    let updatedValue = [...newValue];
    switch (actionMeta.action) {
      case CREATABLE_SELECT_ACTIONS.CREATE_OPTION:
      case CREATABLE_SELECT_ACTIONS.SELECT_OPTION: {
        const addedTag = await handleAddTag(actionMeta.option, contactId);
        if (addedTag) {
          updatedValue = updatedValue.map((tag) => (tag?.label.toLowerCase() === addedTag?.label?.toLowerCase() ? addedTag : tag));
        }
        break;
      }
      case CREATABLE_SELECT_ACTIONS.REMOVE_VALUE:
        setSelectedTags(newValue);
        handleRemoveTag(actionMeta.removedValue, contactId);
        updatedValue = updatedValue.filter((tag) => tag?.value !== actionMeta?.removedValue?.value);
        break;
      default:
        break;
    }

    setSelectedTags(updatedValue);
  };

  const customNoOptionsMessage = (inputValue) => (
    <span className="">{`Create new tag: ${inputValue?.inputValue}`}</span>
  );

  // Triggers loading more tags when the menu is scrolled to the bottom, as long as tags are not already loading and the current page is within the total pages available.
  const handleMenuScrollToBottom = () => {
    if (!isLoadingTags && tagPage <= totalTagPages) {
      getAllTags(tagPage);
    }
  };

  const addContactToContactGroup = async (groupData, contactId) => {
    const contactGroupId = groupData?.value;
    try {
      const contactData = {
        contactId,
      };
      const response = await addContactToGroup(contactGroupId, contactData);
      if (response.status) {
        setSelectedContactGroups((prevGroups) => [...prevGroups, groupData]);
      }
    } catch (error) {
      console.error(`Error adding contact to group: ${error}`);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const handleCreateGroup = async (inputValue) => {
    const contactId = activeConversation?.contactId;
    try {
      setIsLoadingContacts(true);
      const input = inputValue?.label?.toLowerCase();
      const groupBody = { name: input };
      const response = await createContactGroup(groupBody);

      if (response?.status) {
        showToast({
          content: response?.message || 'Group created successfully',
          type: toastType.success,
        });
        const newGroup = response.data;
        const newGroupOption = {
          label: newGroup.name,
          value: newGroup.id,
        };
        await addContactToContactGroup(newGroupOption, contactId);
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong!',
          type: toastType.error,
        });
      }
    } catch (error) {
      console.error('error at handleCreateGroup:', error);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const handleRemoveGroup = async (group) => {
    const contactId = activeConversation?.contactId;
    try {
      await deleteContactFromGroup(contactId, group.value);
      // Removes the selected contact group from `selectedContactGroups` by filtering out the group with the matching value
      setSelectedContactGroups((prevGroups) => prevGroups.filter((sg) => sg.value !== group.value));

      // Updates `contactGroupOptions` by first removing the selected group with the matching value from `prevOptions`
      // and then adds the removed group back into `contactGroupOptions` to make it available for selection again
      setContactGroupOptions((prevOptions) => {
        const updatedOptions = prevOptions.filter((option) => option.value !== group.value);
        return [...updatedOptions, group];
      });
    } catch (error) {
      console.error('Error removing group:', error);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const handleChangeContactGroups = async (newValue, actionMeta) => {
    const contactId = activeConversation?.contactId;
    switch (actionMeta.action) {
      case CREATABLE_SELECT_ACTIONS.CREATE_OPTION:
        handleCreateGroup(newValue[newValue.length - 1]);
        break;
      case CREATABLE_SELECT_ACTIONS.SELECT_OPTION:
        addContactToContactGroup(newValue[newValue.length - 1], contactId);
        break;
      case CREATABLE_SELECT_ACTIONS.REMOVE_VALUE:
        setSelectedContactGroups(newValue);
        handleRemoveGroup(actionMeta.removedValue, contactId);
        break;
      default:
        break;
    }
  };

  const handleMenuScrollToBottomGroups = () => {
    if (!isLoadingContacts && contactGroupPage <= totalContactGroupPages) {
      getAllContactGroups(contactGroupPage);
    }
  };

  const customNoOptionsMessageGroups = (inputValue) => (
    <span>{`Create new group: ${inputValue.inputValue}`}</span>
  );

  const handleCallIconClick = (phoneNumber, fromNumber) => {
    dispatch(toggleCallWidgetVisibility(phoneNumber, fromNumber));
  };

  const updateConversationContact = (updatedContact) => {
    const conversationId = activeConversation?.id;
    const name = `${updatedContact?.firstName} ${updatedContact?.lastName}`;
    setContact(updatedContact);
    updateProfileDetails(conversationId, name);
  };

  const onEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 'unset',
    }),
  };

  const handleGenericChange = async (key, value) => {
    let updateBody = { [key]: value?.value || value };
    if (key === 'name') {
      const [firstName, lastName] = value?.split(' ');
      updateBody = {
        firstName,
        lastName,
      };
    }
    const contactId = activeConversation?.contactId;
    const response = await updateContact(contactId, updateBody);
    if (response?.status) {
      setContact(response?.data);
    }
  };

  const handleCallButtonClick = (event) => {
    event.stopPropagation();
    if (!isEmpty(activeConversation)) {
      handleCallIconClick(`+${activeConversation.to}`, `+${activeConversation.from}`);
    }
  };

  const handleDndStatus = (event) => {
    event.stopPropagation();
    if (!isEmpty(activeConversation)) {
      onClickBlock();
    }
  };

  const togglePinStatus = () => {
    const isCurrentlyPinned = activeConversation?.pinedBy?.includes(currentUser?.userData?.id);
    const payload = {
      isPinned: !isCurrentlyPinned,
    };

    handleUpdateConversation(activeConversation, payload);
  };

  const name = !isEmpty(contact)
    ? `${contact?.firstName} ${contact?.lastName}`
    : activeConversation?.name;

  const email = contact?.email;
  const address = contact?.address;
  const leadStage = contact?.leadStage;
  const company = contact?.company;

  return (
    <div className="inbox-right-tab">
      {!isEmpty(contact) && activeConversation ? (
        <>
          <ContactForm
            isEdit
            contact={contact}
            editContactInbox
            onContactUpdate={updateConversationContact}
          />
          <Card className="">
            {!isEmpty(contact) ? (
              <CardBody className="pb-0 font-size-15">
                <Row className="mb-1">
                  <div className="conversation-info d-flex flex-column text-secondary">
                    <div className="d-flex align-items-center mb-2 ">
                      <Avatar
                        label={getInitials(name)}
                        shape="circle"
                        className="avatar-sm fw-bold text-light fs-5"
                      />
                      <div className="ms-2">
                        <span className="fw-bold mb-1 info-value avatar-div">
                          {' '}
                          {name && (
                            <EditableFieldWrapper
                              key={`${name}-${contact?.phoneNumber}`}
                              value={name}
                              displayValue={capitalizeFirstLetter(name)}
                              className="d-flex mb-1 fs-5"
                              iconClass=""
                              classNameInput="chat-edit-input mb-2"
                              onEdit={(newValue) => handleGenericChange('name', newValue)}
                              placeholderInput={name || NAME_EXAMPLE}
                              placeholderValue="Please enter the full name."
                              saveIcon="mdi mdi-check-bold text-success"
                              cancelIcon="mdi mdi-close-thick text-danger"
                            />
                          )}
                        </span>
                        <p className="d-flex align-items-center mb-0">
                          <PhoneNumberWithFlag phoneNumber={activeConversation?.to} />
                        </p>
                      </div>
                    </div>
                    <p className="d-flex align-items-center mb-1">
                      <span className="info-icon">
                        <i
                          className={`${
                            activeConversation?.channel === CHANNEL_TYPES.TEXT
                              ? 'mdi mdi-message-processing font-size-18 me-1'
                              : 'mdi mdi-whatsapp font-size-18 me-1'
                          }`}
                        />
                      </span>
                      {activeConversation?.from ? (
                        <span className="info-value ms-2">{activeConversation?.from}</span>
                      ) : (
                        <span className="info-title ms-2">Contact</span>
                      )}
                    </p>
                    <p className="d-flex align-items-center mb-1">
                      <span className="info-value">
                        <EditableFieldWrapper
                          key={`${email}-${contact?.phoneNumber}`}
                          value={email}
                          displayValue={email}
                          iconClass="mdi mdi-email font-size-18 me-1"
                          className="text-wrap d-flex font-size-14 mb-1"
                          onEdit={(newValue) => handleGenericChange('email', newValue)}
                          placeholderInput={email || EMAIL_EXAMPLE}
                          placeholderValue="Please enter a valid email address."
                          classNameInput="chat-edit-input mb-2"
                          saveIcon="mdi mdi-check-bold text-success"
                          cancelIcon="mdi mdi-close-thick text-danger"
                          contentClassName={`${!email ? 'placeholder-color' : ''} ms-2`}
                        />
                      </span>
                    </p>
                    <p className="d-flex align-items-center mb-1">
                      <span className="info-value">
                        <EditableFieldWrapper
                          key={`${address}-${contact?.phoneNumber}`}
                          value={address}
                          displayValue={address}
                          iconClass="mdi mdi-home-map-marker font-size-18 me-1"
                          className="text-wrap d-flex font-size-14 mb-1"
                          onEdit={(newValue) => handleGenericChange('address', newValue)}
                          placeholderInput={address || ADDRESS_EXAMPLE}
                          placeholderValue="Please enter address."
                          classNameInput="chat-edit-input mb-2"
                          saveIcon="mdi mdi-check-bold text-success"
                          cancelIcon="mdi mdi-close-thick text-danger"
                          contentClassName={`${!address ? 'placeholder-color' : ''} ms-2`}
                        />
                      </span>
                    </p>
                  </div>
                </Row>
                <hr />
                <Row className="mb-2 pb-2 d-flex align-items-center">
                  <div className="d-flex justify-content-evenly align-items-center">
                    <CustomButton
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Call"
                      icon="mdi mdi-phone text-success"
                      className="bg-success-subtle rounded-5 border-0"
                      onClick={handleCallButtonClick}
                    />
                    <CustomButton
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Block"
                      icon="mdi mdi-block-helper text-danger"
                      className="bg-danger-subtle rounded-5 border-0"
                      onClick={handleDndStatus}
                    />
                    <CustomButton
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Email"
                      icon="mdi mdi-email text-primary"
                      className="bg-primary-subtle rounded-5 border-0"
                      onClick={() => onEmailClick(contact?.email)}
                    />
                    <CustomButton
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={
                        activeConversation?.pinedBy?.includes(currentUser?.userData?.id)
                          ? 'Unpin'
                          : 'Pin'
                      }
                      icon={
                        activeConversation?.pinedBy?.includes(currentUser?.userData?.id)
                          ? 'mdi mdi-pin-off-outline text-warning'
                          : 'mdi mdi-pin-outline text-warning'
                      }
                      className="bg-warning-subtle rounded-5 border-0"
                      onClick={togglePinStatus}
                    />
                  </div>
                </Row>
              </CardBody>
            ) : (
              <Skeleton width="22rem" height="12rem" className="" />
            )}
          </Card>
          <Row>
            <Col md="12">
              <UncontrolledAccordion defaultOpen="4">
                <Row className="pb-2 d-flex align-items-center">
                  <Col className="col-12 d-flex flex-column">
                    <Card>
                      <AccordionItem className="border-0">
                        <div>
                          <AccordionHeader targetId="4">
                            <div className="d-flex align-items-center gap-2 w-100 font-size-16">
                              <span className="">
                                <i className="me-1 mdi mdi-currency-usd-circle-outline" />
                                Lead Information
                              </span>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="4" className="">
                            {leadStage && (
                              <div>
                                <label htmlFor="leadStage" className="fw-bold font-size-15 mb-1">
                                  Lead Stage
                                </label>
                                <Select
                                  options={LEAD_STAGE_OPTIONS}
                                  value={{
                                    label: LEAD_STAGE_OPTIONS.find(
                                      (option) => option.value === contact?.leadStage,
                                    )?.label,
                                    value: contact?.leadStage,
                                  }}
                                  onChange={(selectedOption) => handleGenericChange('leadStage', selectedOption)}
                                  placeholder="Please select a lead stage..."
                                  isMulti={false}
                                  isCreatable={false}
                                  styles={customStyles}
                                  className="mb-3"
                                />
                              </div>
                            )}
                            <div className="d-flex flex-column flex-wrap gap-2 mt-1">
                              <label htmlFor="marketingOptIn" className="fw-bold font-size-15 mb-0">
                                Marketing Opt-In
                              </label>
                              <StyledSwitch
                                type="checkbox"
                                checked={contact?.marketingOptIn}
                                onChange={(event) => handleGenericChange('marketingOptIn', event.value)}
                                name={contact?.marketingOptIn ? 'Yes' : 'No'}
                                id="marketingOptIn" // Use id for accessibility
                              />
                            </div>
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>
                    <Card>
                      <AccordionItem className="border-0" defaultOpen="1">
                        <div>
                          <AccordionHeader targetId="1">
                            <div className="d-flex align-items-center gap-2 w-100 font-size-16">
                              <span className="">
                                <i className="me-1 mdi mdi-tag" />
                                Tags
                              </span>
                              <Badge className="bg-primary-subtle text-primary font-size-12 d-flex align-items-center justify-content-center flex-shrink-0 rounded-5">
                                {selectedTags?.length}
                              </Badge>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="1" className="">
                            <div className="mb-3 font-size-15">
                              <CreatableSelect
                                isMulti
                                options={tagOptions}
                                value={selectedTags}
                                onChange={handleChangeTags}
                                styles={customStyles}
                                placeholder="Select or create tags..."
                                noOptionsMessage={customNoOptionsMessage}
                                isLoading={isLoadingTags}
                                isDisabled={isLoadingTags}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                components={{
                                  LoadingIndicator: customLoadingIndicator,
                                }}
                              />
                            </div>
                            <div className="d-flex flex-wrap gap-2 mt-1">
                              {selectedTags?.map((tag, index) => {
                                const colorClass = SUBTLE_COLORS[index % SUBTLE_COLORS.length];
                                return (
                                  <Badge
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index} // Use tag.value instead of index for key
                                    className={`${colorClass.bg} ${colorClass.text} d-flex align-items-center flex-shrink-0 p-2`}
                                  >
                                    {tag.label}
                                  </Badge>
                                );
                              })}
                            </div>
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>
                    <Card>
                      <AccordionItem className="border-0">
                        <div>
                          <AccordionHeader targetId="3">
                            <div className="d-flex align-items-center gap-2 w-100 font-size-16">
                              <span className="">
                                <i className="me-1 mdi mdi-account-group font-size-16" />
                                Contact Groups
                              </span>
                              <Badge className="bg-primary-subtle text-primary font-size-12 d-flex align-items-center justify-content-center flex-shrink-0 rounded-5">
                                {selectedContactGroups?.length}
                              </Badge>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <div className="mb-3 font-size-15">
                              <CreatableSelect
                                isMulti
                                options={contactGroupOptions}
                                value={selectedContactGroups}
                                onChange={handleChangeContactGroups}
                                styles={customStyles}
                                placeholder="Select or create a group..."
                                noOptionsMessage={customNoOptionsMessageGroups}
                                isLoading={isLoadingContacts}
                                isDisabled={isLoadingContacts}
                                onMenuScrollToBottom={handleMenuScrollToBottomGroups}
                                maxMenuHeight={200}
                                components={{
                                  LoadingIndicator: customLoadingIndicator,
                                }}
                              />
                            </div>
                            <div className="d-flex flex-wrap gap-2 mt-1">
                              {selectedContactGroups?.map((group, index) => (
                                <Badge
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  className="text-info bg-info-subtle d-flex align-items-center flex-shrink-0 p-2"
                                >
                                  {group.label}
                                </Badge>
                              ))}
                            </div>
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>

                    <Card>
                      <AccordionItem className="border-0">
                        <div>
                          <AccordionHeader targetId="5">
                            <div className="d-flex align-items-center gap-2 w-100 font-size-16">
                              <span className="">
                                <i className="me-1 mdi mdi-office-building-outline" />
                                Company Details
                              </span>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="5" className="font-size-15">
                            {company && (
                              <StyledContainerWithLabel
                                label="Name"
                                classNameLabel="font-size-15"
                                className="m-0 mb-1"
                              >
                                <span className="text-wrap info-value">
                                  <EditableFieldWrapper
                                    key={company?.name}
                                    value={company?.name}
                                    iconClass="mdi mdi-office-building-outline me-2 font-size-18"
                                    displayValue={`${capitalizeFirstLetter(company?.name)}`}
                                    onEdit={(newValue) => handleGenericChange('company', {
                                      name: newValue,
                                      website: company?.website,
                                    })}
                                    placeholderValue="Please provide a valid name."
                                    placeholderInput={company?.name || COMPANY_NAME_EXAMPLE}
                                    classNameInput="chat-edit-input mb-2"
                                    saveIcon="mdi mdi-check-bold text-success"
                                    cancelIcon="mdi mdi-close-thick text-danger"
                                    className="d-flex mb-2 font-size-14"
                                    contentClassName={`${
                                      !company?.name ? 'placeholder-color' : ''
                                    }`}
                                  />
                                </span>
                              </StyledContainerWithLabel>
                            )}
                            {company && (
                              <StyledContainerWithLabel
                                label="Website"
                                classNameLabel="font-size-15"
                                className="m-0"
                              >
                                <span className="info-value">
                                  <EditableFieldWrapper
                                    key={company?.website}
                                    value={company?.website}
                                    iconClass="mdi mdi-web me-2 font-size-18"
                                    displayValue={`${capitalizeFirstLetter(company?.website)}`}
                                    onEdit={(newUrl) => handleGenericChange('company', {
                                      website: newUrl,
                                      name: company?.name,
                                    })}
                                    placeholderInput={company?.website || WEBSITE_URL_EXAMPLE}
                                    placeholderValue="Please provide a valid URL."
                                    classNameInput="chat-edit-input mb-2"
                                    saveIcon="mdi mdi-check-bold text-success"
                                    cancelIcon="mdi mdi-close-thick text-danger"
                                    className="d-flex mb-2 font-size-14 "
                                    contentClassName={`${
                                      !company?.website ? 'placeholder-color' : ''
                                    }`}
                                  />
                                </span>
                              </StyledContainerWithLabel>
                            )}
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>
                  </Col>
                </Row>
              </UncontrolledAccordion>
            </Col>
          </Row>
        </>
      ) : (
        <div>
          <Skeleton width="22rem" height="2rem" className="mb-3" />
          <Skeleton width="22rem" height="12rem" className="mb-3" />
          <Skeleton width="100%" height="3rem" className="mb-3" />
          <Skeleton width="100%" height="2rem" className="mb-3" />
          <Skeleton width="100%" height="2rem" className="mb-3" />
        </div>
      )}
    </div>
  );
}

ProfileTab.propTypes = {
  activeConversation: PropTypes.object,
  onClickBlock: PropTypes.func,
  updateProfileDetails: PropTypes.func,
  handleUpdateConversation: PropTypes.func,
};

export default ProfileTab;
