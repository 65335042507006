import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import { DATE_CONSTANTS, DATE_RANGE_NAMES } from '../../../constants/datetime.constant';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import LastMessageDisplay from './LastMessageDisplay';
import { getInitials } from '../../../helpers/commonHelpers';
import { capitalizeFirstLetter } from '../../../utils/general';
import BadgedAvatar from '../../../components/BadgedAvatar';
import emptyInbox from '../../../assets/images/graphics/no-conversation.png';
import TabMenu from '../../../components/TabMenu';
import {
  MAX_UNREAD_MESSAGE_COUNT,
  TAB_ITEMS,
} from '../../../constants/channels/conversation.constant';
import whatsapp from '../../../assets/images/channels/whatsapp.png';
import text from '../../../assets/images/channels/text.png';
import ConversationInitiator from './ConversationInitiator';
import Filter from './Filter';

const ChatRightSidebar = styled.div`
  /* Add your styles here */
`;

const SearchBox = styled.div`
  /* Add your styles here */
`;

const ConversationList = styled.ul`
  /* Add your styles here */
`;

const ConversationItem = styled.li`
  /* Add your styles here */
`;

const EmptyInbox = styled.div`
  /* Add your styles here */
`;

function ConversationBox({
  conversationList,
  activeConversation,
  searchQuery,
  searchUsers,
  onYReachEnd,
  userChatOpen,
  onSetCurrentTab,
  onSetConversationList, // Sets the selected or active conversation
  onSetActiveConversation, // Sets the conversation list
}) {
  const [activeTabMenu, setActiveTabMenu] = useState(0);
  const scrollRef = useRef(null);

  // Function to handle the timestamp for the last message, which will be displayed as the last message.
  const formatLastMessageTime = (createdAt) => {
    const today = new Date();
    const messageDate = new Date(createdAt);

    if (messageDate.toDateString() === today.toDateString()) {
      return moment(createdAt).format(DATE_CONSTANTS.FORMATS.H_MM_A);
    }

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (messageDate.toDateString() === yesterday.toDateString()) {
      return DATE_RANGE_NAMES.YESTERDAY;
    }

    return moment(createdAt).format(DATE_CONSTANTS.FORMATS.MM_DD_YY);
  };

  const handleTabChange = (index) => {
    setActiveTabMenu(index);
    const tabName = TAB_ITEMS?.[index]?.label;
    onSetCurrentTab(tabName);
  };

  const getImageSourceByChannel = (channel) => {
    switch (channel) {
      case CHANNEL_TYPES.WHATSAPP:
        return whatsapp;
      case CHANNEL_TYPES.TEXT:
        return text;
      default:
        return null;
    }
  };

  const onScroll = (e) => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      onYReachEnd();
    }
  };

  const renderUnreadMessageCount = (totalUnreadMessage) => {
    if (!totalUnreadMessage) return null;

    return (
      <div className="font-size-12 d-flex justify-content-end">
        <span className="text-light rounded-circle bg-success unread-count d-flex align-items-center justify-content-center shadow-lg">
          {totalUnreadMessage <= MAX_UNREAD_MESSAGE_COUNT
            ? totalUnreadMessage
            : `+${MAX_UNREAD_MESSAGE_COUNT}`}
        </span>
      </div>
    );
  };

  return (
    <ChatRightSidebar className="chat-rightsidebar chat-right-div">
      <div className="py-4">
        <SearchBox className="search-box chat-search-box mb-3 d-flex justify-content-evenly">
          <div className="position-relative me-1">
            <InputText
              onChange={searchUsers}
              value={searchQuery}
              id="search-user"
              type="text"
              className="form-control search-input"
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
          <ConversationInitiator
            onSetConversationList={onSetConversationList}
            onSetActiveConversation={onSetActiveConversation}
          />
          {/* <div>
            <i className="mdi mdi-filter-outline font-size-24 ms-1 text-secondary" />
          </div> */}
          <Filter />
        </SearchBox>
        <TabMenu
          model={TAB_ITEMS}
          activeIndex={activeTabMenu}
          onTabChange={handleTabChange}
          displayLabel={false}
          displayIcon
        />
        {!_.isEmpty(conversationList) ? (
          <div className="chat-rightsidebar-nav mt-2">
            <div>
              <ConversationList className="list-unstyled chat-list" id="recent-list">
                <ScrollPanel className="conversation-scroll" ref={scrollRef} onScroll={onScroll}>
                  {conversationList.map((conversation, index) => (
                    <ConversationItem
                      key={`${conversation?.id}-${index + 1}`}
                      className={activeConversation?.id === conversation.id ? 'active' : ''}
                    >
                      <Link to="#" onClick={() => userChatOpen(conversation)} className="chat">
                        <div className="d-flex align-items-center">
                          <div className="text-center me-3">
                            <BadgedAvatar
                              size="large"
                              shape="circle"
                              badgeContent={(
                                <img
                                  src={getImageSourceByChannel(conversation?.channel)}
                                  height="22"
                                  alt="img"
                                />
                              )}
                              badgeClassName="font-size-18"
                              avatarContent={getInitials(conversation?.name)}
                            />
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-truncate font-size-14 mb-1">
                              {capitalizeFirstLetter(conversation?.name)}
                            </h5>
                            <p className="text-truncate mb-0 font-size-14">
                              <LastMessageDisplay
                                lastMessage={conversation?.lastMessage}
                                lastMessageType={conversation?.lastMessageType}
                              />
                            </p>
                            <p className="text-truncate mb-0 font-size-14">
                              {conversation?.latestContactGroupName}
                            </p>
                          </div>
                          <div>
                            <span className="chat-time font-size-14">
                              {formatLastMessageTime(conversation?.lastMessageCreatedAt)}
                            </span>
                            {' '}
                            {renderUnreadMessageCount(conversation?.totalUnreadMessage)}
                          </div>
                        </div>
                      </Link>
                    </ConversationItem>
                  ))}
                </ScrollPanel>
              </ConversationList>
            </div>
          </div>
        ) : (
          <EmptyInbox className="d-flex align-items-center justify-content-center h-100">
            <img src={emptyInbox} alt="empty inbox" className="w-75" />
          </EmptyInbox>
        )}
      </div>
    </ChatRightSidebar>
  );
}

ConversationBox.propTypes = {
  searchQuery: PropTypes.string,
  searchUsers: PropTypes.func,
  onYReachEnd: PropTypes.func,
  userChatOpen: PropTypes.func,
  onSetCurrentTab: PropTypes.func,
  onSetConversationList: PropTypes.func,
  onSetActiveConversation: PropTypes.func,
  conversationList: PropTypes.array,
  activeConversation: PropTypes.object,
};

export default ConversationBox;
