/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../helpers/commonHelpers';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NOT_FOUND = 404;
const SUCCESS = 200;
const INTERNAL_SERVER_ERROR = 500;
const TOO_MANY_REQUESTS = 429;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 9000, // Set a timeout for requests
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getDataFromLocalStorage('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle token refresh
    if (error.response?.status === UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = getDataFromLocalStorage('refreshToken');
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`,
          { refreshToken },
        );
        if (res.status === SUCCESS) {
          setDataInLocalStorage('accessToken', res.data.tokens.access.token);
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.data.tokens.access.token}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
      }
    }

    // Handle other errors
    switch (error.response?.status) {
      case FORBIDDEN:
        console.error('Forbidden access:', error.response.data);
        // Handle forbidden access (e.g., show an error message to the user)
        break;
      case TOO_MANY_REQUESTS:
        console.error('Rate limit exceeded:', error.response.data);
        // Handle rate limiting (e.g., implement exponential backoff)
        break;
      default:
        console.error('Request failed:', error.message);
        // Handle other errors (e.g., show a generic error message to the user)
    }

    // If token refresh failed or other error occurred, log out the user
    if (error.response?.status === UNAUTHORIZED) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login');
    }

    return Promise.reject(error);
  },
);

// Security enhancement: CSRF protection
axiosInstance.defaults.xsrfCookieName = 'csrftoken';
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken';

// Helper function for secure data transmission
export const securePost = (url, data, config = {}) => {
  const secureData = JSON.stringify(data);
  return axiosInstance.post(url, secureData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'application/json',
    },
  });
};

export default axiosInstance;
