import React from 'react';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledField = styled(Field)`
  width: 100%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.9rem;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  .invalid-feedback {
    font-size: 0.8rem !important;
    font-weight: 400;
  }
`;

function FormField({
  name,
  id = '',
  type = 'text',
  placeholder = '',
  fieldClassName = '',
  accept,
  value,
  innerRef,
  ...rest
}) {
  return (
    <StyledDiv {...rest}>
      <StyledField
        id={id}
        name={name}
        type={type}
        accept={accept}
        value={value}
        placeholder={placeholder}
        className={fieldClassName}
        ref={innerRef}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="invalid-feedback"
      />
    </StyledDiv>
  );
}

FormField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  fieldClassName: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default FormField;
