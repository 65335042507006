import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import moment from 'moment';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import RadioButton from '../../../../../components/RadioButton';
import Card from '../../../../../components/Card';
import { setBMessageStepOneData } from '../../../../../store/actions';
import {
  COPILOT,
  PHONE_NUMBER,
  SENDER_TYPES,
} from '../../../../../constants/channels/text.constant';
import SenderGroupSelect from '../../../../../components/Features/BulkSms/SenderGroupSelect';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import TemplateSelect from '../../../../../components/Features/BulkSms/TemplateSelect';
import Image from '../../../../../components/Image';
import Phone from '../../../../../assets/images/campaign/message-preview.png';
import MessagePreview from '../../../../../components/Features/BulkSms/MessagePreview';

const StyledCard = styled(Card)`
  margin-bottom: 1rem;
`;

// Style the column to act as a container
const ImageColumn = styled(Col)`
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

// Create a wrapper for the image that maintains aspect ratio
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px; /* Adjust this value based on your needs */
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Style the Image component
const StyledImage = styled(Image)`
  object-fit: contain;

  img {
    border-radius: 3rem;
  }
`;

function Step1({ formCompletion }) {
  const dispatch = useDispatch();
  const { step1 } = useSelector((state) => state.bulkMessage);
  const { selectedTemplate, selectedSenderGroup, usingCopilot } = step1;

  const [initialValues] = useState({
    senderId: selectedSenderGroup?.id,
    templateIds: selectedTemplate?.id ? [selectedTemplate?.id] : [],
    usingCopilot,
  });
  const [senderType, setSenderType] = useState(usingCopilot ? COPILOT : PHONE_NUMBER);

  const validationSchema = Yup.object().shape({
    usingCopilot: Yup.bool().default(usingCopilot),
    senderId: Yup.string().when('usingCopilot', {
      is: false,
      then: (schema) => schema.required('Sender ID is required'),
      otherwise: (schema) => schema.optional(),
    }),
    templateIds: Yup.array(Yup.string()).min(1, 'Select minimum one template').required(),
  });

  const onSenderTypeChange = (event, setFieldValue) => {
    setSenderType(event.value);
    setFieldValue('usingCopilot', event.value === COPILOT);
    dispatch(setBMessageStepOneData({ usingCopilot: event.value === COPILOT }));
  };

  const onSelectSender = (group, setFieldValue) => {
    setFieldValue('senderId', group?.id);
    dispatch(setBMessageStepOneData({ selectedSenderGroup: group }));
  };

  const onSelectTemplate = (template, setFieldValue) => {
    setFieldValue('templateIds', [template?.id]);
    dispatch(setBMessageStepOneData({ selectedTemplate: template }));
  };

  return (
    <Row className="mb-3">
      <Col sm={12} lg={6} xl={7} xxl={8}>
        <StyledCard>
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={async (values) => {
              try {
                const isValid = await validationSchema.isValid(values);
                formCompletion(isValid);
              } catch (error) {
                console.error('error: ', error);
              }
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Row>
                  <Col className="d-flex flex-wrap gap-3">
                    {SENDER_TYPES.map(({ label, value }) => (
                      <RadioButton
                        key={value}
                        inputId={value}
                        name="senderType"
                        onChange={(event) => onSenderTypeChange(event, setFieldValue)}
                        checked={senderType === value}
                        label={label}
                        value={value}
                        labelClassName="mb-0"
                        labelStyle={{ fontSize: '0.9rem' }}
                      />
                    ))}
                  </Col>
                </Row>
                {senderType === PHONE_NUMBER && (
                  <Row className="mt-3">
                    <Col>
                      <SenderGroupSelect
                        selectedValue={selectedSenderGroup}
                        onSelect={(group) => {
                          onSelectSender(group, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="senderId" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col>
                    <TemplateSelect
                      selectedValue={selectedTemplate}
                      onSelect={(template) => {
                        onSelectTemplate(template, setFieldValue);
                      }}
                    />
                    <ErrorMessage name="templateIds" component="div" className="invalid-feedback" />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </StyledCard>
      </Col>
      <ImageColumn sm={12} lg={6} xl={5} xxl={4}>
        <ImageContainer>
          <StyledImage src={Phone} alt="Preview" width="350" />
          <MessagePreview
            message={selectedTemplate?.templateContent || ''}
            time={moment().format('MMMM D, h:mm A')}
          />
        </ImageContainer>
      </ImageColumn>
    </Row>
  );
}

Step1.propTypes = {
  formCompletion: PropTypes.func.isRequired,
};

export default Step1;
