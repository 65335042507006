import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import styled from 'styled-components';
import Label from '../Label';
import { handleError } from '../../services/errorHandler';

const CheckboxWrapper = styled.div`
`;

const StyledCheckbox = styled(PrimeCheckbox)`
  .p-checkbox-box {
    width: 20px;
    height: 20px;
  }
`;

const StyledLabel = styled(Label)`
  margin-left: 10px;
  color: ${(props) => props.labelColor};
  font-size: ${(props) => props.labelFontSize};
  cursor: pointer;
  margin-bottom: 0;
`;

const DEFAULT_LABEL_COLOR = '#333333';
const DEFAULT_LABEL_FONT_SIZE = '14px';

function Checkbox({
  id,
  checked,
  label,
  disabled = false,
  labelColor = DEFAULT_LABEL_COLOR,
  labelFontSize = DEFAULT_LABEL_FONT_SIZE,
  onChange,
  ...rest
}) {
  const handleChange = (isChecked) => {
    try {
      onChange(isChecked);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <CheckboxWrapper {...rest}>
      <StyledCheckbox
        inputId={id}
        checked={checked}
        onChange={(event) => handleChange(event.checked)}
        disabled={disabled}
      />
      {label && (
        <StyledLabel
          htmlFor={id}
          labelColor={labelColor}
          labelFontSize={labelFontSize}
        >
          {label}
        </StyledLabel>
      )}
    </CheckboxWrapper>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelColor: PropTypes.string,
  labelFontSize: PropTypes.string,
};

export default Checkbox;
