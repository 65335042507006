import React, { Component } from 'react';
import { Chips as PrimeChips } from 'primereact/chips';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styled component for Chips
const StyledChips = styled(PrimeChips)`
  .p-inputtext {
    margin-bottom: 0rem;

    input {
      padding-left: 0.9rem;
      font-size: 0.9rem;
    }
  }
`;

class Chips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue || [],
    };
  }

  handleChange = (e) => {
    this.setState({ value: e.value });
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.value);
    }
  }

  render() {
    const {
      placeholder, max, disabled, readOnly, ...rest
    } = this.props;
    const { value } = this.state;

    return (
      <StyledChips
        value={value}
        onChange={this.handleChange}
        placeholder={placeholder}
        max={max}
        disabled={disabled}
        readOnly={readOnly}
        {...rest}
      />
    );
  }
}

Chips.propTypes = {
  initialValue: PropTypes.array,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

Chips.defaultProps = {
  initialValue: [],
  placeholder: 'Enter values',
  max: null,
  disabled: false,
  readOnly: false,
};

export default Chips;
