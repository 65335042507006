export const PAGE = 1;
// Default page size for tables; defines the number of records fetched and displayed per page.
export const SIZE_PER_PAGE = 10;
export const TOTAL_SIZE = 0;
export const LIMIT = 20;
export const COPILIOT = 'copilot';
export const limitRange = [10, 25, 30, 50];

export const MODE = {
  CLIENT: 'client',
  SAAS: 'saas',
};

export const optType = {
  start_with: 'start with',
  in_contain_sms: 'in contain sms',
  end_of_string: 'end of string',
};

export const UPLOAD_PERMISSION_OPTIONS = [
  { label: 'No', value: 'false' },
  { label: 'Yes', value: 'true' },
];

export const WAITING_TIMING = 500;

export const callType = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};

export const StatusTypes = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  NO_ANSWER: 'no-answer',
  BUSY: 'busy',
  CANCELED: 'canceled',
  FAILED: 'failed',
  QUEUED: 'queued',
  RINGING: 'ringing',
};

// Define badge colors for each status
export const BadgeColors = {
  [StatusTypes.COMPLETED]: 'success-subtle',
  [StatusTypes.IN_PROGRESS]: 'primary-subtle',
  [StatusTypes.NO_ANSWER]: 'danger-subtle',
  [StatusTypes.BUSY]: 'warning-subtle',
  [StatusTypes.CANCELED]: 'secondary-subtle',
  [StatusTypes.FAILED]: 'danger-subtle',
  [StatusTypes.QUEUED]: 'info-subtle',
  [StatusTypes.RINGING]: 'warning-subtle',
};

// Define text colors for each status
export const TextColors = {
  [StatusTypes.COMPLETED]: 'text-success',
  [StatusTypes.IN_PROGRESS]: 'text-primary',
  [StatusTypes.NO_ANSWER]: 'text-danger',
  [StatusTypes.BUSY]: 'text-warning',
  [StatusTypes.CANCELED]: 'text-secondary',
  [StatusTypes.FAILED]: 'text-danger',
  [StatusTypes.QUEUED]: 'text-info',
  [StatusTypes.RINGING]: 'text-warning',
};

export const ChatBoxUserStatus = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  INTERMEDIATE: 'intermediate', // If you have other statuses like 'intermediate'
};

export const getStatusClassName = (status) => {
  switch (status) {
    case ChatBoxUserStatus.ONLINE:
      return 'mdi mdi-circle text-success align-middle me-1';
    case ChatBoxUserStatus.INTERMEDIATE:
      return 'mdi mdi-circle text-warning align-middle me-1';
    case ChatBoxUserStatus.OFFLINE:
    default:
      return 'mdi mdi-circle  align-middle me-1';
  }
};

// Common date constants
export const DATE_CONSTANTS = {
  FORMATS: {
    MM_DD_YYYY: 'MM-DD-YYYY',
    MMM_D_YYYY: 'MMM D, YYYY',
    HH_MM_SS: 'HH:mm:ss',
    H_MM_A: 'h:mm A',
    MM_DD_YY: 'MM-DD-YY',
    HH_MMA: 'hh:mmA',
    DDDD: 'dddd',
    MMM_DD_YYYY: 'MMM DD YYYY',
    MMMM_DD_YYYY: 'MMMM DD YYYY',
  },
  UNITS: {
    DAY: 'day',
    DAYS: 'days',
    MONTH: 'month',
    MONTHS: 'months',
    YEAR: 'year',
    YEARS: 'years',
    WEEK: 'week',
    WEEKS: 'weeks',
  },
};

export const DEFAULT_NAME = 'Unknown';
export const MINIMUM_DELAY = 1000;

export const KEYPAD_BUTTONS = [
  { number: '1', letters: '' },
  { number: '2', letters: 'ABC' },
  { number: '3', letters: 'DEF' },
  { number: '4', letters: 'GHI' },
  { number: '5', letters: 'JKL' },
  { number: '6', letters: 'MNO' },
  { number: '7', letters: 'PQRS' },
  { number: '8', letters: 'TUV' },
  { number: '9', letters: 'WXYZ' },
  { number: '*', letters: '' },
  { number: '0', letters: '+' },
  { number: '#', letters: '' },
];

export const COLOR_PALETTE = [
  '#34c38f',
  '#50a5f1',
  '#f46a6a',
  '#f1b44c',
  '#556ee6',
  '#8d6e63',
  '#ff8a65',
  '#ba68c8',
  '#4db6ac',
  '#9575cd',
  '#ffb74d',
  '#64b5f6',
  '#81c784',
  '#e57373',
  '#ffd54f',
  '#4caf50',
  '#f44336',
  '#3f51b5',
  '#ffeb3b',
  '#795548',
  '#00bcd4',
  '#e91e63',
  '#673ab7',
  '#9c27b0',
  '#03a9f4',
];

export const DRAGGABLE_DEFAULT_POSITION = { x: 6, y: 7 };
