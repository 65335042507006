/* eslint-disable import/no-duplicates */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

// import images
import { axiosPost } from '../../services/http.service';
import LogoIcon from '../../assets/images/logo-icon.png';
import { SOMETHING_WENT_WRONG } from '../../constants/error.constant';

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifingEmail: true,
      error: '',
      tokenNotFound: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match?.params?.token) {
      this.verifyEmail(match?.params?.token);
    } else {
      this.setState({ tokenNotFound: true });
    }
  }

  verifyEmail = async (token) => {
    try {
      const response = await axiosPost(`/auth/verify-email?token=${token}`);
      if (response?.status) {
        // this.props.history.push("/login");
        this.setState({
          verifingEmail: false,
        });
      } else {
        console.error('verifyEmail API error: ', response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error('verifyEmail error: ', error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  renderContent = () => {
    const { tokenNotFound, error, verifingEmail } = this.state;
    if (tokenNotFound) {
      return (
        <>
          <h4 className="text-danger">Oops! Token not found</h4>
          <div className="mt-4">
            <a href="/retry-email-verification" className="btn btn-success w-md">
              Try Again
            </a>
          </div>
        </>
      );
    }

    if (error) {
      return (
        <>
          <h4 className="text-danger">{error}</h4>
          <div className="mt-4">
            <a href="/forgot-password" className="btn btn-success w-md">
              Try Again
            </a>
          </div>
        </>
      );
    }

    if (verifingEmail) {
      return <h5>Verifying your email...</h5>;
    }

    return (
      <>
        <h4 className="text-success">Your email has been verified</h4>
        <div className="mt-4">
          <a href="/login" className="btn btn-success w-md">
            Login
          </a>
        </div>
      </>
    );
  };

  render() {
    // meta title
    document.title = 'Email Verification | Cloudmate - Template';

    return (
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={LogoIcon}
                    alt="auto-campaign-ai-logo-icon"
                    height="50"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={LogoIcon}
                    alt="auto-campaign-ai-logo-icon"
                    height="50"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3 fw-bold fs-3">Autocampaign AI</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary" />
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        {this.renderContent()}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  Autocampaign.ai
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

EmailVerification.propTypes = {
  match: PropTypes.any,
};
