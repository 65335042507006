import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { getContactName } from '../../../helpers/commonHelpers';
import Button from '../../Button';
import Header from './Header';
import CallerInfo from './CallerInfo';

const IncomingCallContainer = styled.div`
  z-index: 99999;
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5), 0px 8px 64px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: 1px solid #434344;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IncomingCallBody = styled.div`
  background-color: #333336;
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;

const CallerInfoContainer = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 0.5rem 0;
`;

function IncomingCall({
  displayName, acceptCall, declineCall, handleMinimize,
}) {
  const contactName = getContactName(displayName);
  const contactNumber = displayName?.phoneNumberString || displayName?.From;
  return (
    <Draggable bounds="html">
      <IncomingCallContainer>
        <Header handleOnClick={handleMinimize} title="Main Sales Number" />
        <IncomingCallBody>
          <CallerInfoContainer>
            <CallerInfo contactName={contactName} contactNumber={contactNumber} />
          </CallerInfoContainer>
          <ButtonContainer>
            <StyledButton
              className="p-button-danger m-0"
              icon="mdi mdi-phone-hangup"
              label="Decline"
              onClick={declineCall}
            />
            <StyledButton
              className="p-button-success m-0"
              icon="mdi mdi-phone"
              label="Accept"
              onClick={acceptCall}
            />
          </ButtonContainer>
        </IncomingCallBody>
      </IncomingCallContainer>
    </Draggable>
  );
}

IncomingCall.propTypes = {
  declineCall: PropTypes.func.isRequired,
  acceptCall: PropTypes.func.isRequired,
  displayName: PropTypes.object,
  handleMinimize: PropTypes.func,
};

export default IncomingCall;
