// CreateWorkspaceModal.js

import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

class CreateWorkspaceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        name: '',
      },
    };
  }

  render() {
    const { isOpen, toggle, createNewWorkspace } = this.props;
    const { initialValues } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            createNewWorkspace(values);
            resetForm();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <ModalHeader toggle={toggle}>New Workspace</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Workspace Name</Label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className={`form-control ${
                      errors.name && touched.name ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="name"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Create
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

CreateWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  createNewWorkspace: PropTypes.func,
};

export default CreateWorkspaceModal;
