import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import { Mention, MentionBlot } from 'quill-mention';
import 'quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import PropTypes from 'prop-types';
import { getInitials } from '../../helpers/commonHelpers';
import Button from '../Button';
import { convertHTMLToText, decodeHTMLEntities } from '../../utils/conversation.util';

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });

function RichTextEditorWithMentions({
  handleOnSubmit,
  editMode,
  noteToEdit,
  mentionOptions,
  onSetEditMode,
  onSetNoteToEdit,
}) {
  const editorRef = useRef(null);
  const quillInstance = useRef(null);
  const [editorContent, setEditorContent] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);

  // Retrieves all mentions from the Quill editor content, returning an array of mention objects with their values and IDs.
  function getMentions() {
    if (!quillInstance.current) return [];

    const contents = quillInstance.current.getContents();
    const mentions = [];

    contents.ops.forEach((op) => {
      if (op.insert && typeof op.insert === 'object' && op.insert.mention) {
        mentions.push({
          value: op.insert.mention.value,
          id: op.insert.mention.id,
        });
      }
    });

    return mentions;
  }

  // Cleans up the Quill editor instance by removing event listeners and clearing all DOM elements within the editor.
  const cleanupEditor = () => {
    if (quillInstance.current) {
      quillInstance.current.off('text-change');
      quillInstance.current = null;
    }

    // Clean up DOM elements
    if (editorRef.current) {
      // Remove all children of the editor ref
      while (editorRef.current.firstChild) {
        editorRef.current.removeChild(editorRef.current.firstChild);
      }
    }
  };

  // Initializes the Quill editor with custom mention functionality and sets up event listeners.
  const initializeQuill = () => {
    if (!editorRef.current) return;

    // Clean up existing instance and DOM elements
    cleanupEditor();

    // Create a new editor container
    const editorContainer = document.createElement('div');
    editorRef.current.appendChild(editorContainer);

    // Initialize a new Quill editor instance
    quillInstance.current = new Quill(editorContainer, {
      theme: 'snow',
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/, // Restricts allowed mention characters
          mentionDenotationChars: ['@'], // Sets the character to trigger mention list

          // Custom renderer for mention list items
          renderItem: (data) => {
            if (data.id === 'no-options') {
              // Render 'no options' message when no mention options are available
              const noOptionsDiv = document.createElement('div');
              noOptionsDiv.style = `
                padding: 0.5rem;
                color: #666;
                font-style: italic;
                font-size: 14px;
              `;
              noOptionsDiv.innerText = data.value;
              return noOptionsDiv;
            }

            // Render user mention option with avatar and name
            const containerDiv = document.createElement('div');
            containerDiv.style = `
              display: flex;
              background-color: transparent;
              align-items: center;
              padding: 0.3rem 0rem;
            `;

            // Avatar for mention item
            const avatarDiv = document.createElement('div');
            avatarDiv.style = `
              width: 30px; 
              height: 30px; 
              border-radius: 50%; 
              background-color: var(--blue-600); 
              color: white; 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              font-size: 14px; 
              margin-right: 10px;
            `;

            const initials = getInitials(data?.value);
            avatarDiv.innerText = initials;

            // Name text for mention item
            const nameSpan = document.createElement('span');
            nameSpan.innerText = data.value;
            nameSpan.style = 'font-size: 14px; color: #333 ;    width: 11rem;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;';

            containerDiv.appendChild(avatarDiv);
            containerDiv.appendChild(nameSpan);

            return containerDiv;
          },

          // Source function to filter and render mention list based on search term
          source(searchTerm, renderList, mentionChar) {
            const values = mentionOptions;

            if (mentionChar === '@' && (!values || values.length === 0)) {
              // Render message if no mention options are available
              renderList(
                [
                  {
                    id: 'no-options',
                    value: 'No users available to mention',
                    disabled: true,
                  },
                ],
                searchTerm,
              );
              return;
            }

            const matches = values.filter((value) => value?.value.toLowerCase().includes(searchTerm.toLowerCase()));

            if (matches.length === 0) {
              // Render message if no matches found
              renderList(
                [
                  {
                    id: 'no-options',
                    value: 'No matching users found',
                    disabled: true,
                  },
                ],
                searchTerm,
              );
              return;
            }

            // Render list of matching mention options
            renderList(matches, searchTerm);
          },

          // Action when a mention item is selected
          onSelect(item, insertItem) {
            if (item.id === 'no-options') return;

            const mentionItem = {
              id: item.id,
              value: item.value,
              denotationChar: '@',
            };
            insertItem(mentionItem);
          },
        },
      },
    });

    // Listen for text changes in the editor to update mentions and editor content
    quillInstance.current.on('text-change', () => {
      const currentMentions = getMentions();
      setMentionedUsers(currentMentions); // Updates list of mentioned users
      setEditorContent(quillInstance.current.root.innerHTML); // Sets the current HTML content of the editor
    });

    // Set initial content if editing an existing note
    if (noteToEdit?.content) {
      const decodedContent = decodeHTMLEntities(noteToEdit.content); // Decodes HTML entities
      quillInstance.current.root.innerHTML = decodedContent; // Sets the decoded content as the initial editor content
      setEditorContent(decodedContent);
    }
  };

  useEffect(() => {
    initializeQuill();

    return () => {
      cleanupEditor();
    };
  }, [mentionOptions, noteToEdit]);

  const handleSave = () => {
    if (quillInstance.current) {
      const content = quillInstance.current.root.innerHTML;
      handleOnSubmit(content, mentionedUsers);
      quillInstance.current.setText('');
      setEditorContent('');
    }
  };

  const handleClear = () => {
    if (quillInstance.current) {
      quillInstance.current.setText('');
      setEditorContent('');
    }
  };

  const isContentEmpty = () => {
    const textContent = convertHTMLToText(editorContent);
    return textContent.trim().length === 0;
  };

  const isContentChanged = () => {
    if (noteToEdit && noteToEdit.content) {
      return convertHTMLToText(noteToEdit.content) !== convertHTMLToText(editorContent);
    }
    return !isContentEmpty();
  };

  const getButtonLabel = () => {
    if (editMode) {
      return isContentChanged() ? 'Save' : 'Edit';
    }
    return 'Save';
  };

  // Handles cancel action in edit mode, discarding unsaved changes and reverting to the previous state
  const handleCancel = () => {
    onSetEditMode(false);
    quillInstance.current.setText('');
    onSetNoteToEdit(null);
  };

  return (
    <div>
      <div ref={editorRef} className="note-editor" />
      <div className="my-2 d-flex justify-content-between">
        <Button
          label="Clear"
          icon="mdi mdi-format-clear"
          onClick={handleClear}
          disabled={isContentEmpty()}
          tooltipOptions={{ position: 'top' }}
          iconClassName
          severity="danger"
          iconLeft
        />
        <div className="d-flex">
          <Button
            label={getButtonLabel()}
            icon={editMode && !isContentChanged() ? 'mdi mdi-pencil' : 'mdi mdi-content-save'}
            onClick={handleSave}
            disabled={isContentEmpty() || (editMode && !isContentChanged())}
            tooltipOptions={{ position: 'top' }}
            iconLeft
            severity="primary"
          />
          {editMode ? (
            <Button
              label="Cancel"
              icon="mdi mdi-close-thick"
              onClick={handleCancel}
              disabled={!editMode}
              tooltipOptions={{ position: 'top' }}
              iconLeft
              severity="secondary"
              className="ms-1"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

RichTextEditorWithMentions.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  onSetEditMode: PropTypes.func.isRequired,
  onSetNoteToEdit: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  noteToEdit: PropTypes.shape({
    content: PropTypes.string,
  }),
  mentionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

export default RichTextEditorWithMentions;
