import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Badge, Button, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import DeleteModal from '../../components/Common/DeleteModal';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { axiosDel, axiosGet } from '../../services/http.service';
import {
  setShowAssignNumberModal,
  setShowSenderGroupModal,
} from '../../store/sender-group/actions';
import { setLoader } from '../../store/actions';
import AssignNumber from './Modal/AssignNumber';
import SenderForm from './Modal/SenderForm';
import DataGrid from '../../components/DataTable';

class SenderGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      senderGroups: [],
      senderGroup: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      senderGroupListColumns: [
        {
          header: 'User Name',
          accessorKey: 'assignedUserName',
        },
        {
          header: 'Group Name',
          accessorKey: 'name',
        },
        {
          header: 'Total Phone Numbers',
          accessorKey: 'numberIds',
          Cell: ({ renderedCellValue }) => (
            <Badge color="primary-subtle" pill className=" text-primary pt-1 fs-6">
              {renderedCellValue?.length}
            </Badge>
          ),
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const group = row?.original;
            return (
              <div className="d-flex gap-3">
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18 text-success"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="edit"
                      id="edit-gtoup"
                      onClick={() => this.handleUserClick(group)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="primary-subtle" className="text-primary">
                    <i
                      className="fas fa-hashtag font-size-18 text-primary"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="assign number"
                      id="assign-number"
                      onClick={() => this.handleAssignNumberClick(group)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="danger-subtle" className="text-danger">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="delete"
                      onClick={() => this.onClickDelete(group)}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.getAllSenderGroups(page);
  }

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.getAllSenderGroups(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.getAllSenderGroups(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getAllSenderGroups(1, newPageSize);
    });
  };

  onClickDelete = (senderGroup) => {
    this.setState({ senderGroup });
    this.setState({ deleteModal: true });
  };

  handleUserClicks = () => {
    this.setState({ senderGroup: '', isEdit: false });
    this.toggle();
  };

  handleUserClick = (arg) => {
    this.setState({
      senderGroup: arg,
      isEdit: true,
    });
    this.toggle();
  };

  handleAssignNumberClick = (arg) => {
    this.setState({
      senderGroup: arg,
    });
    this.toggleAssignNumber();
  };

  // to get all sender groups
  getAllSenderGroups = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `sender-groups?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        onSetLoader(false);
        this.setState({
          senderGroups: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting sender groups :', error);
    }
  };

  // to delete a sender group
  handleDeleteSenderGroup = async () => {
    try {
      const { senderGroup, page } = this.state;
      const response = await axiosDel(`sender-groups/${senderGroup?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Sender Group deleted successfully!', {
          position: 'top-right',
        });
        this.getAllSenderGroups(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('error while deleting sender group :', error);
    }
  };

  toggle = () => {
    const { onSetShowSenderGroupModal } = this.props;
    onSetShowSenderGroupModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  toggleAssignNumber = () => {
    const { onSetShowAssignNumberModal } = this.props;
    onSetShowAssignNumberModal();
  };

  render() {
    // meta title
    document.title = 'Sender List';

    const { showSenderFormModal, showAssignNumberModal } = this.props;

    const {
      isEdit,
      deleteModal,
      senderGroups,
      senderGroup,
      page,
      senderGroupListColumns,
      totalSize,
      selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteSenderGroup()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showSenderFormModal && (
          <SenderForm
            isEdit={isEdit}
            group={isEdit ? senderGroup : ''}
            getAllSenderGroups={() => {
              this.getAllSenderGroups(page);
            }}
          />
        )}
        {showAssignNumberModal && (
          <AssignNumber
            group={senderGroup}
            getAllSenderGroups={() => {
              this.getAllSenderGroups(page);
            }}
          />
        )}
        <div className="">
          <Container fluid>
            <h4 className="mb-2 font-size-17">Sender Groups</h4>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Sender Group
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={senderGroups}
                        columns={senderGroupListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

SenderGroup.propTypes = {
  onSetShowSenderGroupModal: PropTypes.func,
  onSetShowAssignNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  showSenderFormModal: PropTypes.bool,
  showAssignNumberModal: PropTypes.bool,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = ({ senderGroup }) => ({
  showSenderFormModal: senderGroup.showSenderFormModal,
  showAssignNumberModal: senderGroup.showAssignNumberModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowSenderGroupModal: () => dispatch(setShowSenderGroupModal()),
  onSetShowAssignNumberModal: () => dispatch(setShowAssignNumberModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SenderGroup));
