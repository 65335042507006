import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

// contacts
import contacts from './contacts/reducer';

// Dashboard
import Dashboard from './dashboard/reducer';

// toast
import ToastMsg from './toast/reducer';

// phone-number
import phoneNumber from './phone-number/reducer';

// sender-group
import senderGroup from './sender-group/reducer';

// campaign
import campaign from './campaign/reducer';

// quick-sms
import quickMessage from './quick-message/reducer';

// inbox
import inbox from './inbox/reducer';
// user
import user from './user/reducer';
// notifications
import notification from './notifications/reducer';

// blocked-numbers
import blockedNumbers from './blocked-numbers/reducer';

// contact-groups
import contactgroup from './contact-group/reducer';

// dial-pad
import dialPad from './dial-pad/reducer';

import CallWidget from './call-widget/reducer';

import onboarding from './onboarding/reducer';

import channels from './channels/reducer';

import bulkWhatsapp from './bulk-whatsapp/reducer';

import bulkMessage from './bulk-message/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  contacts,
  Dashboard,
  ToastMsg,
  phoneNumber,
  senderGroup,
  campaign,
  quickMessage,
  inbox,
  user,
  blockedNumbers,
  notification,
  contactgroup,
  dialPad,
  CallWidget,
  onboarding,
  channels,
  bulkWhatsapp,
  bulkMessage,
});

export default rootReducer;
