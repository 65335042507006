import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components';

const StyledInput = styled(InputText)`
  border-radius: 4px;
  border: 1px solid #ced4da;
`;

function Input({
  type,
  name,
  value,
  placeholder,
  onChange,
  className,
  disabled,
  invalid,
  valid,
  isChecked,
}) {
  return (
    <StyledInput
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      disabled={disabled}
      invalid={invalid}
      valid={valid}
      checked={isChecked}
    />
  );
}

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
  isChecked: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  className: '',
  placeholder: '',
  disabled: false,
  invalid: false,
  valid: false,
  isChecked: false,
};

export default Input;
