import React from 'react';
import PropTypes from 'prop-types';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import { MEDIA_ICONS } from '../../../constants/inbox/inbox.constant';
import { MEDIA_TYPES } from '../../../constants/file.constant';

function LastMessageDisplay({ lastMessage, lastMessageType }) {
  let iconClass = '';
  let text = '';

  if (lastMessageType?.startsWith('image/')) {
    iconClass = MEDIA_ICONS.IMAGE;
    text = MEDIA_TYPES.IMAGE;
  } else if (lastMessageType?.startsWith('audio/')) {
    iconClass = MEDIA_ICONS.AUDIO;
    text = MEDIA_TYPES.AUDIO;
  } else if (lastMessageType?.startsWith('video/')) {
    iconClass = MEDIA_ICONS.VIDEO;
    text = MEDIA_TYPES.VIDEO;
  } else if (lastMessageType?.startsWith('application/')) {
    iconClass = MEDIA_ICONS.APPLICATION;
    text = MEDIA_TYPES.APPLICATION;
  } else if (lastMessage?.length) {
    return sliceStringWithEllipsis(25, lastMessage);
  } else if (lastMessage) {
    return <>Photo</>;
  } else {
    return <>-</>;
  }

  return (
    <>
      <i className={iconClass} />
      {' '}
      {text}
    </>
  );
}

LastMessageDisplay.propTypes = {
  lastMessage: PropTypes.string,
  lastMessageType: PropTypes.string,
};

export default LastMessageDisplay;
