import React, { Component } from 'react';
// eslint-disable-next-line import/no-unresolved
import 'react-international-phone/style.css';
import {
  Offcanvas, OffcanvasHeader, OffcanvasBody, Nav, NavItem, NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import NoteList from '../../Inbox/Modal/NoteList';
import { tabs } from '../../../constants/tabs';
// eslint-disable-next-line import/no-named-as-default
import View from './Tabs/View';

class ViewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabs.VIEW,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      contact,
      toggleViewContact,
      isViewContactOpen,
      getAllContacts,
      updateSelectedContact,
      updateContactList,
      contactList,
    } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <Offcanvas
          direction="end"
          toggle={toggleViewContact}
          isOpen={isViewContactOpen}
          className="dialpad-container"
        >
          <OffcanvasHeader toggle={toggleViewContact}>Contact Details</OffcanvasHeader>
          <OffcanvasBody className="view-offcanvas-body">
            <div className="crypto-buy-sell-nav">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === tabs.VIEW,
                    })}
                    onClick={() => {
                      this.toggleTab(tabs.VIEW);
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === tabs.NOTES,
                    })}
                    onClick={() => {
                      this.toggleTab(tabs.NOTES);
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="">
              {activeTab === tabs.VIEW && (
                <View contact={contact} getAllContacts={getAllContacts} />
              )}
              {activeTab === tabs.NOTES && (
                <NoteList
                  selectedContact={contact}
                  contactList={contactList}
                  updateContactList={(updatedContactList) => updateContactList(updatedContactList)}
                  updateSelectedContact={(updatedContact) => updateSelectedContact(updatedContact)}
                />
              )}
            </div>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    );
  }
}

ViewContact.propTypes = {
  contact: PropTypes.object,
  contactList: PropTypes.array,
  updateSelectedContact: PropTypes.func,
  updateContactList: PropTypes.func,
  toggleViewContact: PropTypes.func,
  isViewContactOpen: PropTypes.bool,
  getAllContacts: PropTypes.func,
};

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewContact));
