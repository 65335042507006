import React from 'react';
import PropTypes from 'prop-types';
import { Image as PrimeImage } from 'primereact/image';
import styled from 'styled-components';

const StyledImage = styled(PrimeImage)`
  // Existing styles remain unchanged
`;

function Image({ children, ...props }) {
  return <StyledImage {...props}>{children}</StyledImage>;
}

Image.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: '',
};

export default Image;
