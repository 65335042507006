import React from 'react';
import PropTypes from 'prop-types';
import { BreadCrumb } from 'primereact/breadcrumb';
import styled from 'styled-components';

// Constants
const FONT_SIZE_18 = '18px';

// Styled Components
const PageTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h4`
  margin-bottom: 0;
  font-size: ${FONT_SIZE_18};
`;

const BreadcrumbWrapper = styled.div`
  .p-breadcrumb {
    background: transparent;
    border: none;
    padding: 0;
  }
`;

function Breadcrumbs({ breadcrumbItem, title }) {
  const items = [
    { label: title, url: '#' },
    { label: breadcrumbItem, url: '#' },
  ];

  const home = { icon: 'bx bx-home-alt', url: '/' };

  return (
    <div className="row">
      <div className="col-12">
        <PageTitleBox>
          <Title>{breadcrumbItem}</Title>
          <BreadcrumbWrapper>
            <BreadCrumb model={items} home={home} />
          </BreadcrumbWrapper>
        </PageTitleBox>
      </div>
    </div>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Breadcrumbs;
