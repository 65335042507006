import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PhoneInput } from 'react-international-phone';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { handleError } from '../../services/errorHandler';

const StyledPhoneInput = styled(PhoneInput)`
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #f5f5f5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  margin-bottom: 5px;
  appearance: none;
  border-color: ${(props) => (props.isError ? '#d9534f' : '')};
  outline: 0;

  &:focus {
    box-shadow: 0 0 0 0.2rem ${(props) => (props.isError ? '#f5c6cb' : '#ced4da')};
  }
`;

const Label = styled.label`
  display: block;
`;

const ErrorSpan = styled.span`
  width: 100%;
  font-size: 80%;
  color: #d9534f;
`;

function PhoneInputComponent({
  label,
  name,
  value,
  handleChangePhoneNumber,
  placeholder,
  className,
  required,
  isError,
  errorText,
  parseNumber,
  ...props
}) {
  const handleOnChange = (inputValue) => {
    try {
      if (parseNumber) {
        const parsedNumber = parsePhoneNumber(inputValue);

        if (parsedNumber && isPossiblePhoneNumber(parsedNumber.number)) {
          const { nationalNumber } = parsedNumber;
          const countryCode = parsedNumber.countryCallingCode;
          handleChangePhoneNumber(nationalNumber, countryCode);
        } else {
          handleChangePhoneNumber(inputValue, '');
        }
      } else {
        handleChangePhoneNumber(inputValue, '');
      }
    } catch (error) {
      handleError(error);
      handleChangePhoneNumber(inputValue, '');
    }
  };

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledPhoneInput
        name={name}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        required={required}
        isError={isError}
        className={className}
        {...props}
      />
      {isError && errorText && <ErrorSpan>{errorText}</ErrorSpan>}
    </>
  );
}

PhoneInputComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChangePhoneNumber: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  isError: PropTypes.bool,
  errorText: PropTypes.node,
  parseNumber: PropTypes.bool,
};

PhoneInputComponent.defaultProps = {
  label: '',
  placeholder: '',
  className: '',
  required: false,
  isError: false,
  errorText: '',
  parseNumber: false,
};

export default PhoneInputComponent;
