/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from './themes/ThemeContext';
import { initializeSocketConnection } from './services/socketService';
import {
  addNotification,
} from './store/notifications/actions';
import {
  setTwilioVoiceToken,
  toggleCallWidgetVisibility,
  setOnboardingStatus,
  setOnboardingData,
} from './store/actions';
import { authProtectedRoutes, publicRoutes } from './routes';
import './assets/scss/theme.scss';

import { getTwilioVoiceToken } from './services/channels/twiloService';
import { getUnreadMessages } from './services/api/alert.service';
import { getDataFromLocalStorage } from './helpers/commonHelpers';
import CallWidget from './components/Features/CallWidget/CallWidget';
import { UserContext } from './components/UserProvider/UserProvider';
import NonAuthLayout from './components/NonAuthLayout';
import VerticalLayout from './components/Layout';
import AppRoute from './routes/route';
import { getOnboarding } from './services/api/onboarding.service';
import { handleError } from './services/errorHandler';
import { ToastService } from './services/toastService';

function App() {
  const dispatch = useDispatch();
  const { userData, getUserinfo } = React.useContext(UserContext);
  const prevUserDataRef = useRef();

  const {
    isCallWidgetVisible,
    notifications,
    initializeSocket,
  } = useSelector((state) => ({
    isCallWidgetVisible: state.CallWidget.isCallWidgetVisible,
    notifications: state.notification.notifications,
    initializeSocket: state.notification.initializeSocket,
    layout: state.Layout,
  }));

  useEffect(() => {
    startupMiddleware();
    prevUserDataRef.current = userData;

    return () => {
      // Cleanup
    };
  }, []);

  const fetchUnreadMessages = async () => {
    try {
      const response = await getUnreadMessages();

      if (response?.status) {
        response.data.forEach((notification) => {
          dispatch(addNotification(notification));
        });
      }
    } catch (error) {
      handleError('Error fetching unread messages:', error);
    }
  };

  const startupMiddleware = useCallback(() => {
    initializeSocketConnection(getUserinfo, dispatch, notifications, fetchUnreadMessages);
    loadInitialData();
  }, [notifications]);

  const loadInitialData = () => {
    if (getDataFromLocalStorage('accessToken')) {
      Promise.allSettled([fetchUnreadMessages(), fetchTwilioVoiceToken(), fetchOnboardingData()]);
    }
  };

  useEffect(() => {
    if (initializeSocket) {
      initializeSocketConnection(getUserinfo, dispatch, notifications, fetchUnreadMessages);
    }
  }, [initializeSocket]);

  const fetchOnboardingData = async () => {
    try {
      const organizationId = userData?.organizationId;
      const response = await getOnboarding(organizationId);
      if (response?.status) {
        const { isSkipped, isCompleted } = response.data;
        dispatch(setOnboardingStatus(!(isCompleted || isSkipped)));
        dispatch(setOnboardingData(response.data));
      } else {
        handleError(response?.message || 'Cannot get onboarding data');
      }
    } catch (error) {
      handleError('Error fetching onboarding data:', error);
    }
  };

  useEffect(() => {
    if (prevUserDataRef.current !== userData) {
      fetchOnboardingData();
      prevUserDataRef.current = userData;
    }
  }, [userData]);

  const fetchTwilioVoiceToken = async () => {
    try {
      const organizationId = userData?.organizationId;
      const response = await getTwilioVoiceToken(organizationId);
      if (response.status) {
        dispatch(setTwilioVoiceToken(response?.data));
      }
    } catch (error) {
      handleError('Error fetching Twilio voice token:', error);
    }
  };

  const getLayout = () => VerticalLayout;

  const Layout = getLayout();

  return (
    <ThemeProvider>
      <ToastService />
      {userData.id && (
        <CallWidget
          className={userData.id && isCallWidgetVisible ? '' : 'd-none'}
          toggleVisibility={() => dispatch(toggleCallWidgetVisibility())}
          isCallWidgetVisible={isCallWidgetVisible}
        />
      )}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              key={`public_route_${idx}`}
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              isAuthProtected={false}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              key={`auth_route_${idx}`}
              path={route.path}
              layout={Layout}
              component={route.component}
              isAuthProtected
              exact
            />
          ))}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
