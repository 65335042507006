import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.node,
};

export default withRouter(NonAuthLayout);
