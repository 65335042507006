import {
  CALL_INITIATED, ELAPSED_TIME, START_TIMER, TWILIO_VOICE_TOKEN,
} from './actionTypes';

const INIT_STATE = {
  twilioVoiceToken: '',
  startTimer: false,
  elaspedTime: 0,
  isCallInitiated: false,
};

const dialPad = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TWILIO_VOICE_TOKEN:
      return {
        ...state,
        twilioVoiceToken: action.payload,
      };

    case START_TIMER:
      return {
        ...state,
        startTimer: action.payload,
      };

    case ELAPSED_TIME:
      return {
        ...state,
        elaspedTime: action.payload,
      };

    case CALL_INITIATED:
      return {
        ...state,
        isCallInitiated: action.payload,
      };

    default:
      return state;
  }
};

export default dialPad;
