import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Calendar } from 'primereact/calendar';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCalendar = styled(Calendar)`
  .p-inputtext {
    width: 100%;
    padding: 0.6rem;
    border-radius: 8px;
    font-size: 0.9rem;
  }

  .p-datepicker {
    padding: 0.5rem;
    border-radius: 4px;
  }

  .p-highlight {
    color: var(--bs-white);
  }
`;

const ErrorMessage = styled.span`
  color: var(--red-600);
  font-size: 0.75rem;
  margin-top: 4px;
  position: absolute;
  bottom: -0.4rem;
  left: 1rem;
`;

function DateTime({
  id,
  onDateTimeChange,
  onError,
  initialValue,
  placeholder,
  showTime,
  hourFormat,
  disabled,
  readOnly,
  minDate,
  maxDate,
  borderColor,
  fontSize,
  calendarBgColor,
  calendarBorderColor,
  highlightColor,
  className,
  hideOnDateTimeSelect,
  showButtonBar,
  error,
}) {
  const [dateTime, setDateTime] = useState(null);

  useEffect(() => {
    setDateTime(initialValue);
  }, [initialValue]);

  const handleDateTimeChange = (e) => {
    const { value } = e;

    if (minDate && value < minDate) {
      onError(`Date must be after ${minDate.toLocaleDateString()}`);
      return;
    }

    if (maxDate && value > maxDate) {
      onError(`Date must be before ${maxDate.toLocaleDateString()}`);
      return;
    }

    setDateTime(value);
    if (onDateTimeChange) {
      onDateTimeChange(value);
    }
  };

  return (
    <StyledWrapper className={className}>
      <StyledCalendar
        id={id}
        value={dateTime}
        onChange={handleDateTimeChange}
        showTime={showTime}
        hourFormat={hourFormat}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        borderColor={borderColor}
        fontSize={fontSize}
        calendarBgColor={calendarBgColor}
        calendarBorderColor={calendarBorderColor}
        highlightColor={highlightColor}
        hideOnDateTimeSelect={hideOnDateTimeSelect}
        showButtonBar={showButtonBar}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledWrapper>
  );
}

DateTime.propTypes = {
  id: PropTypes.string.isRequired,
  onDateTimeChange: PropTypes.func,
  onError: PropTypes.func,
  initialValue: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  showTime: PropTypes.bool,
  hourFormat: PropTypes.oneOf(['12', '24']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  borderColor: PropTypes.string,
  fontSize: PropTypes.string,
  calendarBgColor: PropTypes.string,
  calendarBorderColor: PropTypes.string,
  highlightColor: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  hideOnDateTimeSelect: PropTypes.bool,
  showButtonBar: PropTypes.bool,
};

DateTime.defaultProps = {
  placeholder: 'Select Date and Time',
  showTime: true,
  hourFormat: '24',
  className: '',
  disabled: false,
  readOnly: false,
  borderColor: '#ced4da',
  fontSize: '14px',
  calendarBgColor: '#ffffff',
  calendarBorderColor: '#ced4da',
  highlightColor: '#2196F3',
  error: '',
  hideOnDateTimeSelect: false,
  showButtonBar: false,
};

export default DateTime;
