import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getDataFromLocalStorage } from '../helpers/commonHelpers';

function AppRoute({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !getDataFromLocalStorage('accessToken')) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>

            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;
