export const CHANNELS_TITLE = 'Channels';

export const WHATSAPP_CONNECT_STATUS = {
  CONNECTED: 'Connected',
  DISCONNECT: 'Disconnect',
  CONFIGURE: 'Configure',
  CONNECT: 'Connect',
};

export const TWILIO_CONNECT_STATUS = {
  CONNECTED: 'Connected',
  DISCONNECT: 'Disconnect',
  CONFIGURE: 'Configure',
  CONNECT: 'Connect',
};

export const CHANNEL_CONNECT_STATUS = {
  TODO: 'todo',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

export const CHANNEL_TYPES_SETTINGS = {
  WHATSAPP: 'whatsapp',
  TWILIO: 'twilio',
};

export const CHANNEL_TYPES = {
  TEXT: 'text',
  WHATSAPP: 'whatsapp',
};

export const CONVERSATION_TYPES = {
  TEXT: 'text',
  WHATSAPP: 'whatsapp',
};
export const MEDIA_ICONS = {
  IMAGE: 'mdi mdi-image',
  VIDEO: 'mdi mdi-video',
  APPLICATION: 'mdi mdi-file-document',
  AUDIO: 'mdi mdi-microphone',
};
