import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';

const ControlsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-left: var(--spacer);
  margin-top: var(--spacer);
`;

const ControlColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--spacer);
`;

const ControlButton = styled(Button)`
  width: 3rem;
  height: 3rem;
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border: none;
  transition: var(--transition-base);

  &:hover {
    background-color: var(--primary-color);
  }

  &.active {
    background-color: var(--primary-color);
  }

  &.recording {
    background-color: var(--danger-color);
  }

  &.muted {
    background-color: var(--warning-color);
  }

  &.end-call {
    background-color: var(--danger-color);
    height: 3.5rem;
    width: 3.5rem;
  }
  
`;

const ControlLabel = styled.div`
  color: var(--light-color);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  margin-top: 0.25rem;
`;

function CallControls({
  isKeypadOpen,
  onKeypadToggle,
  onRecordToggle,
  isRecording,
  callAccepted,
  isMuted,
  onMuteToggle,
  onEndCall,
}) {
  return (
    <ControlsRow>
      <ControlColumn>
        <ControlButton
          className={isKeypadOpen ? 'active' : ''}
          onClick={onKeypadToggle}
          icon={`mdi mdi-dialpad m-0 fs-4 ${isKeypadOpen ? 'text-dark' : ''}`}
        />
        <ControlLabel>Keypad</ControlLabel>
      </ControlColumn>
      {callAccepted && (
        <ControlColumn>
          <ControlButton
            className={isRecording ? 'recording' : ''}
            onClick={onRecordToggle}
            icon={`${isRecording ? 'fas fa-stop-circle' : 'fas fa-circle'} m-0 fs-4`}
          />
          <ControlLabel>Record</ControlLabel>
        </ControlColumn>
      )}
      <ControlColumn>
        <ControlButton
          className={isMuted ? 'muted' : ''}
          onClick={onMuteToggle}
          icon={`bx bx-microphone-off m-0 fs-4 ${isMuted ? 'text-dark' : ''}`}
        />
        <ControlLabel>{isMuted ? 'Unmute' : 'Mute'}</ControlLabel>
      </ControlColumn>
      <ControlColumn>
        <ControlButton
          className="end-call"
          onClick={onEndCall}
          icon="mdi mdi-phone-hangup-outline m-0 fs-2"
        />
      </ControlColumn>
    </ControlsRow>
  );
}

CallControls.propTypes = {
  isKeypadOpen: PropTypes.bool,
  onKeypadToggle: PropTypes.func,
  onRecordToggle: PropTypes.func,
  isRecording: PropTypes.bool,
  callAccepted: PropTypes.bool,
  isMuted: PropTypes.bool,
  onMuteToggle: PropTypes.func,
  onEndCall: PropTypes.func,
};

CallControls.defaultProps = {
  isKeypadOpen: false,
  onKeypadToggle: () => {},
  onRecordToggle: () => {},
  isRecording: false,
  callAccepted: false,
  isMuted: false,
  onMuteToggle: () => {},
  onEndCall: () => {},
};

export default CallControls;
