import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import Label from '../../../../components/Label';
import Dropdown from '../../../../components/Dropdown';
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import CustomErrorMessage from '../../../../components/CustomErrorMessage';
import { TEMPLATE_OPTIONS, TEMPLATE_KEYS } from '../../../../constants/importcontacts.constant';
import { FIELDS_OBJ } from '../../../../constants/field.constant';
import { setImportContactsStepOneData } from '../../../../store/actions';

const StyledCard = styled(Card)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  &:hover {
    background-color: var(--blue-700);
    border-color: var(--blue-700);
  }

  i {
    font-size: 1rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 250px;
`;

const FieldContainer = styled.div`
  margin-bottom: 1rem;
`;

function Step1({ formCompletion }) {
  const dispatch = useDispatch();
  const templateType = useSelector((state) => state.contacts.importContacts.step1.templateType);

  const [initialValues, setInitialValues] = useState({
    templateType: templateType || TEMPLATE_KEYS.WITH_COUNTRY_CODE,
  });

  useEffect(() => {
    setInitialValues({
      templateType: templateType || TEMPLATE_KEYS.WITH_COUNTRY_CODE,
    });
  }, [templateType]);

  const downloadSampleCSV = (isWithCountryCode = false) => {
    const {
      FIRST_NAME, LAST_NAME, COUNTRY_CODE, PHONE_NUMBER, ADDRESS, ZIP_CODE, STATE, CITY,
    } = FIELDS_OBJ;
    const header = `${FIRST_NAME.KEY},${LAST_NAME.KEY},${
      isWithCountryCode ? `${COUNTRY_CODE.KEY},` : ''
    }${PHONE_NUMBER.KEY},${ADDRESS.KEY},${ZIP_CODE.KEY},${STATE.KEY},${CITY.KEY}`;
    const row1 = `John,Doe${
      isWithCountryCode ? ',1' : ''
    },5551234567,123 Main St,90210,California,Beverly Hills`;
    const row2 = `Emily,Clark${
      isWithCountryCode ? ',1' : ''
    },5559876543,789 Elm St,30301,Georgia,Atlanta`;
    const row3 = `Michael,Johnson${
      isWithCountryCode ? ',1' : ''
    },5557654321,456 Maple St,60611,Illinois,Chicago`;
    // Sample CSV content
    const csvContent = `${header}\n${row1}\n${row2}\n${row3}`;
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and create a URL for the Blob
    link.download = 'sample.csv';
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const validationSchema = Yup.object().shape({
    templateType: Yup.string().required('Please select template type'),
  });

  // Template to render items with icons, labels, and numbers
  const itemTemplate = (option) => (
    <div className="p-dropdown-item d-flex p-0">
      <div className="d-flex align-items-center me-2">
        {option.icon && <i className={`item-icon ${option.icon}`} />}
      </div>
      <div className="d-flex flex-column">
        <span className="item-label">{option?.label}</span>
      </div>
    </div>
  );

  // Template for the selected value
  const valueTemplate = (option) => {
    if (option) {
      return (
        <div className="p-dropdown-value d-flex align-items-center p-0">
          <div className="d-flex align-items-center me-3">
            {option.icon && <i className={`item-icon ${option.icon}`} />}
          </div>
          <div className="d-flex flex-column">
            <span className="item-label">{option?.label}</span>
          </div>
        </div>
      );
    }
    return <span>Select a template</span>;
  };

  return (
    <StyledCard>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={async (values) => {
          try {
            const isValid = await validationSchema.isValid(values);
            formCompletion(isValid);
            dispatch(setImportContactsStepOneData(values));
          } catch (error) {
            console.error('error: ', error);
          }
        }}
      >
        {({
          setFieldValue, errors, touched, values,
        }) => (
          <Form>
            <FormContainer>
              <FormColumn>
                <FieldContainer className="d-flex">
                  <StyledLabel htmlFor="templateType">Select Template Type:</StyledLabel>
                  <Field name="templateType">
                    {({ field }) => (
                      <Dropdown
                        id="templateType"
                        value={field.value}
                        options={TEMPLATE_OPTIONS}
                        onChange={(e) => setFieldValue('templateType', e.value)}
                        placeholder="Select a template"
                        valueTemplate={valueTemplate}
                        itemTemplate={itemTemplate}
                      />
                    )}
                  </Field>
                  <CustomErrorMessage
                    message={errors.templateType}
                    showError={errors.templateType && touched.templateType}
                  />
                </FieldContainer>
              </FormColumn>
            </FormContainer>

            {values.templateType === TEMPLATE_KEYS.WITH_COUNTRY_CODE && (
            <p>
              Include the country code in your phone numbers. For example, use 919876543210 or
              +919876543210 for Indian numbers. We&apos;ll use the country code you provide. If
              you don&apos;t include a country code, we&apos;ll assume it&apos;s for your
              account&apos;s default country.
            </p>
            )}
            {values.templateType === TEMPLATE_KEYS.WITHOUT_COUNTRY_CODE && (
            <p>
              No need to include country codes - we&apos;ll use your account&apos;s default
              country code. Use formats like 9876543210 or 09876543210. If you do include a full
              international format (like +919876543210), we&apos;ll keep it as is.
            </p>
            )}
            <StyledButton
              label="Download Template"
              icon="bx bx-download"
              onClick={() => downloadSampleCSV(values.templateType === TEMPLATE_KEYS.WITH_COUNTRY_CODE)}
              disabled={!values.templateType}
              tooltip="Download the selected template"
              tooltipOptions={{ position: 'top' }}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </StyledCard>
  );
}

Step1.propTypes = {
  formCompletion: PropTypes.func.isRequired,
};

export default Step1;
