/* eslint-disable func-names */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import UserProvider from './components/UserProvider/UserProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import store from './store';

if (process.env.REACT_APP_DEBUG !== 'true') {
  // eslint-disable-next-line no-console
  console.log = function () { };
  console.warn = function () { };
  console.error = function () { };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <AdminToUser> */}
      <UserProvider>
        <App />
      </UserProvider>
      {/* </AdminToUser> */}
    </BrowserRouter>
  </Provider>,
);
serviceWorker.unregister();
