/* eslint-disable import/prefer-default-export */
import { axiosGet, axiosPatch, axiosPost } from '../http.service';

export const createTeam = async (teamData) => {
  try {
    const response = await axiosPost('/team', teamData);
    return response;
  } catch (error) {
    console.error('Error creating team:', error);
    throw error;
  }
};

export const getTeams = async (params) => {
  const {
    page, size, q, isActive,
  } = params;
  try {
    const response = await axiosGet(
      `/team?page=${page}&limit=${size}${
        q ? `&q=${q}` : ''
      }&isActive=${isActive}&sortBy=createdAt:desc`,
    );
    return response;
  } catch (error) {
    console.error('Error getting teams:', error);
    throw error;
  }
};

export const updateTeam = async (teamId, updateData) => {
  try {
    const response = await axiosPatch(`/team/${teamId}`, updateData);
    return response;
  } catch (error) {
    console.error('Error updating team:', error);
    throw error;
  }
};
