import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'reactstrap';

const ContainerWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: 1rem 0;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

function StyledContainerWithLabel({
  children,
  label,
  className,
  classNameLabel,
  iconClass,
}) {
  return (
    <ContainerWithLabel className={className}>
      <LabelContainer>
        {iconClass && <i className={`bx ${iconClass}`} />}
        {label && <Label className={classNameLabel}>{label}</Label>}
      </LabelContainer>
      {children}
    </ContainerWithLabel>
  );
}

StyledContainerWithLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  iconClass: PropTypes.string,
};

export default StyledContainerWithLabel;
