import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardText, CardTitle, Col, Row,
} from 'reactstrap';

class DemoCard extends Component {
  render() {
    return (
      <Row className="my-1">
        <Col lg="12">
          <Card color="primary" className="border-light shadow-sm rounded-3">
            <CardBody color="light" className="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-4">
              <div className="d-flex align-items-start gap-2">
                <i className="mdi mdi-calendar-clock font-size-24 text-light" />
                <div>
                  <CardTitle className="font-weight-bold font-size-24 mb-2 text-light">
                    Schedule a Demo
                  </CardTitle>
                  <CardText className="text-light">
                    Talk to our product expert and understand how Auto-Campaign-AI can revolutionize your marketing efforts.
                  </CardText>
                </div>
              </div>
              <Button color="light" className="btn text-primary" onClick={() => {}}>
                Book Now
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DemoCard;
