/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
import React, {
  useEffect, useContext, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';
import { STEPS_NAME } from '../../../constants/onboarding.constant';
import { UserContext } from '../../UserProvider/UserProvider';
import twilioIcon from '../../../assets/images/channels/twilio.svg';
import {
  CHANNEL_CONNECT_STATUS,
  CHANNEL_TYPES,
  CHANNELS_TITLE,
  TWILIO_CONNECT_STATUS,
  WHATSAPP_CONNECT_STATUS,
} from '../../../constants/channels/channels.constant';
import { whatsappExchangeToken } from '../../../services/api/channels.service';
import { getPrivateCredentials, updateOrganization } from '../../../services/api/organization.service';
import { handleOnboardingStepCompletion } from '../../../utils/onboarding';
import {
  setLoader,
  setOnboardingData as setOnboardingDataAction,
  setShowTwilioCredentialsModal,
  setShowWhatsappCredentialsModal,
} from '../../../store/actions';
import { getChannels } from '../../../utils/channels';
import TwilioCredentials from './Modal/TwilioCredentials';
import { MODE } from '../../../constants/constants';
import WhatsappCredentials from './Modal/WhatsappCredentials';

const StyledCard = styled(Card)`
  .p-card-body {
    padding: 1.5rem;
  }
`;

const ChannelIconWrapper = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const ChannelName = styled.h4`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const ChannelStatus = styled.p`
  color: #6c757d;
  margin-bottom: 1rem;
`;

function ChannelList({ isOnboarding }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [connectingWhatsapp, setConnectingWhatsapp] = useState(false);
  const [connectingTwilio] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [toast, setToast] = useState(null);

  const fetchPrivateCredentials = useCallback(async () => {
    try {
      dispatch(setLoader(true));
      const organizationId = userData?.organizationId;
      const response = await getPrivateCredentials(organizationId);
      if (response?.status) {
        setCredentials(response.data);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.message || 'Cannot get private credentials',
        });
      }
    } catch (error) {
      console.error('Error fetching private credentials:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred',
      });
    } finally {
      dispatch(setLoader(false));
    }
  }, [dispatch, userData, toast]);

  useEffect(() => {
    document.title = CHANNELS_TITLE;
    loadFacebookSDK();
    fetchPrivateCredentials();
  }, [fetchPrivateCredentials]);

  const handleWhatsAppExchangeToken = async (code) => {
    const organizationId = userData?.organizationId;
    setConnectingWhatsapp(true);
    try {
      if (organizationId) {
        const response = await whatsappExchangeToken(organizationId, code);
        if (response?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: response?.message || 'Access Token set successfully!',
          });
          if (isOnboarding) {
            await handleOnboardingStepCompletion({
              stepName: STEPS_NAME.SETUP_CHANNELS,
              organizationId,
              history,
              setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
            });
          }
          await fetchPrivateCredentials();
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response?.message || 'Failed to set Access token',
          });
        }
      }
    } catch (error) {
      console.error('Error setting Access Token:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred',
      });
    } finally {
      setConnectingWhatsapp(false);
    }
  };

  const launchWhatsAppSignup = () => {
    window.fbq?.('trackCustom', 'WhatsAppOnboardingStart', {
      appId: process.env.REACT_APP_META_APP_ID,
      feature: 'whatsapp_embedded_signup',
    });

    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const { code } = response.authResponse;
          handleWhatsAppExchangeToken(code);
        } else {
          // NOTE: User cancelled login or did not fully authorize.
        }
      },
      {
        config_id: process.env.REACT_APP_META_APP_CONFIGURATION_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {},
        },
      },
    );
  };

  const handleOpenTwilioModal = () => {
    dispatch(setShowTwilioCredentialsModal());
  };

  const handleWhatsappConnection = (isWhatsappConnected) => {
    if (process.env.REACT_APP_MODE === MODE.CLIENT) {
      dispatch(setShowWhatsappCredentialsModal());
    } else if (isWhatsappConnected) {
      handleDisconnectWhatsapp();
    } else {
      launchWhatsAppSignup();
    }
  };

  const handleDisconnectWhatsapp = async () => {
    const updateBody = {
      channels: [
        {
          type: CHANNEL_TYPES.WHATSAPP,
          status: CHANNEL_CONNECT_STATUS.DISCONNECTED,
          config: {
            metaSystemUserAccessToken: '',
          },
        },
      ],
    };
    try {
      const organizationId = userData?.organizationId;
      const response = await updateOrganization(organizationId, updateBody);
      if (response?.status) {
        fetchPrivateCredentials();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: response?.message || 'Whatsapp disconnected successfully!',
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.message || 'Oops! Something went wrong',
        });
      }
    } catch (error) {
      console.error('Error while disconnecting Whatsapp:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred',
      });
    }
  };

  const loadFacebookSDK = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_META_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  const { twilio, whatsapp } = getChannels(credentials);
  const isWhatsAppConnected = whatsapp?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isTwilioConnected = twilio?.status === CHANNEL_CONNECT_STATUS.CONNECTED;

  const getWhatsappButtonText = () => {
    if (connectingWhatsapp) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} />;
    }
    if (isWhatsAppConnected) {
      return process.env.REACT_APP_MODE === MODE.CLIENT
        ? WHATSAPP_CONNECT_STATUS.CONFIGURE
        : WHATSAPP_CONNECT_STATUS.DISCONNECT;
    }
    return WHATSAPP_CONNECT_STATUS.CONNECT;
  };

  const getTwilioButtonText = () => {
    if (connectingTwilio) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} />;
    }
    return isTwilioConnected
      ? TWILIO_CONNECT_STATUS.CONFIGURE
      : TWILIO_CONNECT_STATUS.CONNECT;
  };

  return (
    <>
      <Toast ref={setToast} />
      {credentials.length > 0 && (
        <>
          <TwilioCredentials
            getPrivateCredentials={fetchPrivateCredentials}
            privateCreds={credentials}
          />
          <WhatsappCredentials
            getPrivateCredentials={fetchPrivateCredentials}
            privateCreds={credentials}
          />
        </>
      )}
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4">
          <StyledCard>
            <ChannelIconWrapper className="bg-danger-subtle">
              <img src={twilioIcon} alt="" height="40" />
            </ChannelIconWrapper>
            <ChannelName>Twilio</ChannelName>
            <ChannelStatus>{isTwilioConnected ? 'Connected' : 'Connect Now'}</ChannelStatus>
            <Button
              disabled={connectingTwilio || connectingWhatsapp}
              className="p-button-danger p-button-rounded"
              onClick={handleOpenTwilioModal}
              label={getTwilioButtonText()}
              icon="bx bx-link"
            />
          </StyledCard>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <StyledCard>
            <ChannelIconWrapper className="bg-success-subtle">
              <i className="bx bxl-whatsapp text-success" style={{ fontSize: '2rem' }} />
            </ChannelIconWrapper>
            <ChannelName>Whatsapp</ChannelName>
            <ChannelStatus>{isWhatsAppConnected ? 'Connected' : 'Connect Now'}</ChannelStatus>
            <Button
              disabled={connectingWhatsapp || connectingTwilio}
              className="p-button-success p-button-rounded"
              onClick={() => handleWhatsappConnection(isWhatsAppConnected)}
              label={getWhatsappButtonText()}
              icon="bx bx-link"
            />
          </StyledCard>
        </div>
      </div>
    </>
  );
}

ChannelList.propTypes = {
  isOnboarding: PropTypes.bool.isRequired,
};

export default ChannelList;
