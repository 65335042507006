import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { handleError } from '../../services/errorHandler';

const EditableFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 0;
  background-color: white;
  gap: 0.5rem;
  width: 100%;

  .action-chip {
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    z-index: 1; /* Ensure the chip is on top of other elements */
  }
  .edit-chip {
    top: -4px;
  }

  .save-cancel-chips {
    top: -30px;
  }

  .p-component {
    font-weight: 500;
  }

  .p-chip {
    font-size: 0.7rem;
  }

  .p-chip:hover {
    text-decoration: underline;
  }

  &:hover .action-chip,
  &:focus-within .action-chip {
    visibility: visible;
    opacity: 1;
  }
`;

const StyledChip = styled(Chip)`
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

function TextField({
  value,
  displayValue,
  onChange,
  isEditing,
  iconClass,
  link,
  placeholderInput,
  placeholderValue,
  className,
  classNameInput,
  classNameLink,
  contentClassName,
}) {
  if (isEditing) {
    return (
      <div className="d-flex">
        <i className={`bx ${iconClass} mt-3`} />
        <InputText
          value={value}
          onChange={onChange}
          placeholder={placeholderInput}
          className={classNameInput}
        />
      </div>
    );
  }

  const content = displayValue || value || placeholderValue;

  if (link) {
    return (
      <a href={link} className={`${classNameLink} text-field`}>
        <i className={`bx ${iconClass}`} />
        {content}
      </a>
    );
  }

  return (
    <span className={`${className} text-field`}>
      <i className={`bx ${iconClass}`} />
      <span className={contentClassName}>{content}</span>
      {' '}
      {/* Wrap content in its own span */}
    </span>
  );
}

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  iconClass: PropTypes.string.isRequired,
  contentClassName: PropTypes.string,
  link: PropTypes.string,
  placeholderInput: PropTypes.string,
  placeholderValue: PropTypes.string,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameLink: PropTypes.string,
};

function ActionChip({
  isEditing, onEdit, onSave, onCancel, saveIcon, cancelIcon,
}) {
  if (isEditing) {
    return (
      <div className="action-chip save-cancel-chips">
        <StyledChip
          onClick={onSave}
          className="text-primary me-2"
          label={saveIcon ? <i className={saveIcon} /> : 'Save'}
        />
        <StyledChip
          onClick={onCancel}
          className="text-danger"
          label={saveIcon ? <i className={cancelIcon} /> : 'Cancel'}
        />
      </div>
    );
  }

  return (
    <div className="action-chip edit-chip">
      <StyledChip onClick={onEdit} className="text-primary me-2" label="Edit" />
    </div>
  );
}

ActionChip.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saveIcon: PropTypes.string,
  cancelIcon: PropTypes.string,
};

function StyledEditableField({
  id,
  value,
  iconClass,
  onEdit,
  onDelete,
  displayValue = '',
  link = '',
  placeholderInput = '',
  placeholderValue = '',
  className = '',
  classNameInput = '',
  classNameLink = '',
  editable = true,
  saveIcon,
  cancelIcon,
  contentClassName,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleEditToggle = () => setIsEditing((prev) => !prev);

  const handleChange = (event) => setEditedValue(event.target.value);

  const handleSave = () => {
    try {
      onEdit(editedValue);
      setIsEditing(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedValue(value);
  };

  const handleDelete = () => {
    try {
      onDelete(id);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <EditableFieldWrapper>
      <TextField
        value={editedValue}
        displayValue={displayValue}
        onChange={handleChange}
        isEditing={isEditing}
        iconClass={iconClass}
        link={link}
        placeholderInput={placeholderInput}
        placeholderValue={placeholderValue}
        className={className}
        classNameInput={classNameInput}
        classNameLink={classNameLink}
        contentClassName={contentClassName}
      />
      {editable && (
        <ActionChip
          isEditing={isEditing}
          onEdit={handleEditToggle}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          saveIcon={saveIcon}
          cancelIcon={cancelIcon}
        />
      )}
    </EditableFieldWrapper>
  );
}

StyledEditableField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  displayValue: PropTypes.string,
  link: PropTypes.string,
  placeholderInput: PropTypes.string,
  placeholderValue: PropTypes.string,
  className: PropTypes.string,
  classNameLink: PropTypes.string,
  contentClassName: PropTypes.string,
  classNameInput: PropTypes.string,
  editable: PropTypes.bool,
  saveIcon: PropTypes.string,
  cancelIcon: PropTypes.string,
};

export default StyledEditableField;
