import {
  SHOW_NUMBER_MODAL, SHOW_REASSIGN_NUMBER_MODAL,
  SHOW_WHATSAPP_NUMBER_MODAL,
} from './actionTypes';

const INIT_STATE = {
  showNumberModal: false,
  showReassignNumberModal: false,
  showWhatsappNumberModal: false,
};

const phoneNumber = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_NUMBER_MODAL:
      return {
        ...state,
        showNumberModal: !state.showNumberModal,
      };

    case SHOW_REASSIGN_NUMBER_MODAL:
      return {
        ...state,
        showReassignNumberModal: !state.showReassignNumberModal,
      };

    case SHOW_WHATSAPP_NUMBER_MODAL:
      return {
        ...state,
        showWhatsappNumberModal: !state.showWhatsappNumberModal,
      };

    default:
      return state;
  }
};

export default phoneNumber;
