import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WizardSteps from '../../../../components/Features/WizardSteps';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { setActiveIndexOfBMessageForm, setStepCompletionBMessage } from '../../../../store/actions';

function BulkMessage() {
  const dispatch = useDispatch();

  const { stepCompletionArray, activeIndex } = useSelector((state) => state.bulkMessage);

  const itemRenderer = (item, itemIndex, currentStep = 0) => {
    const { icon, label } = item;
    const StepCompletedIcon = 'bx bx-check';

    return (
      <div
        className={`p-menuitem-link ${currentStep > itemIndex ? 'p-completed' : ''} cursor-pointer`}
        tabIndex="-1"
        data-pc-section="action"
        onClick={() => dispatch(setActiveIndexOfBMessageForm(itemIndex))}
      >
        <span className="p-steps-number" data-pc-section="step">
          {currentStep > itemIndex ? (
            <i className={StepCompletedIcon} />
          ) : (
            <i className={icon} />
          )}
        </span>
        <span className="p-steps-title" data-pc-section="label">{label}</span>
      </div>
    );
  };

  const [items, setItems] = useState([
    {
      label: 'Choose Template',
      icon: 'bx bxs-detail',
      disabled: false,
      template: (item) => itemRenderer(item, 0, activeIndex),
    },
    {
      label: 'Choose Segment',
      icon: 'bx bx-group',
      disabled: true,
      template: (item) => itemRenderer(item, 1, activeIndex),
    },
    {
      label: 'Broadcast',
      icon: 'mdi mdi-bullhorn',
      disabled: true,
      template: (item) => itemRenderer(item, 2, activeIndex),
    },
  ]);

  const handleFormCompletion = (stepIndex, isCompleted) => {
    const completionArray = stepCompletionArray?.map((stepCompletion) => (stepCompletion?.stepIndex === stepIndex
      ? { ...stepCompletion, completed: isCompleted }
      : stepCompletion));

    dispatch(setStepCompletionBMessage(completionArray));
  };

  const getStepComponent = (index) => {
    switch (index) {
      case 0:
        return <Step1 formCompletion={(isCompleted) => handleFormCompletion(0, isCompleted)} />;
      case 1:
        return <Step2 formCompletion={(isCompleted) => handleFormCompletion(1, isCompleted)} />;
      case 2:
        return <Step3 />;
      default:
        return null;
    }
  };

  useEffect(() => {
    document.title = 'Text Campaign';
  }, []);

  useEffect(() => {
    setItems(items.map((item, index) => {
      if (index === activeIndex) {
        // eslint-disable-next-line no-param-reassign
        item.disabled = false;
      }
      const templateNode = itemRenderer(item, index, activeIndex);
      // eslint-disable-next-line no-param-reassign
      item.template = templateNode;
      return item;
    }));
  }, [activeIndex]);

  const nextButtonEnable = activeIndex !== items.length - 1 && stepCompletionArray?.reduce(
    (acc, stepCompletion) => (
      stepCompletion?.stepIndex === activeIndex ? stepCompletion?.completed : acc
    ),
    false,
  );

  const previousButtonEnable = activeIndex > 0;

  const handleStepChange = (currentStep) => {
    dispatch(setActiveIndexOfBMessageForm(currentStep));
  };

  return (
    <WizardSteps
      containerClassName="mb-3"
      items={items}
      activeIndex={activeIndex}
      readOnly={false}
      onStepChange={handleStepChange}
      buttonsEnable
      previousButtonDisabled={!previousButtonEnable}
      nextButtonDisabled={!nextButtonEnable}
    >
      {getStepComponent(activeIndex)}
    </WizardSteps>
  );
}

export default BulkMessage;
