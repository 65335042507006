import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes, { object } from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { axiosPost } from '../../../services/http.service';
import ModalLoader from '../../../components/Common/ModalLoader';
import { setShowGroupFormModal } from '../../../store/contacts/actions';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../constants/onboarding.constant';
import { handleOnboardingStepCompletion } from '../../../utils/onboarding';
import { setOnboardingData as setOnboardingDataAction } from '../../../store/actions';

class GroupForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  createNewGroup = async (groupName) => {
    try {
      this.setState({ showModalLoader: true });
      const {
        selectedContacts, isOnboarding, history, setOnboardingData,
      } = this.props;
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const filteredContacts = selectedContacts.map(
        ({
          name,
          phoneNumber,
          phoneNumberString,
          countryCode,
          address,
          zip,
          state,
          city,
        }) => ({
          name,
          phoneNumber,
          phoneNumberString,
          countryCode,
          address,
          zip,
          state,
          city,
        }),
      );
      const response = await axiosPost('contact-groups', {
        name: groupName,
        contacts: filteredContacts,
      });
      if (response?.status) {
        toast.success(
          response?.message || 'Contact Group Created Successfully!',
          {
            position: 'top-right',
          },
        );
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.ADD_CONTACTS,
            organizationId,
            history,
            setOnboardingData,
          });
        }
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.toggle();
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.toggle();
      this.setState({ showModalLoader: false });
      console.error('error while creating new contact group :', error);
    }
  };

  toggle = () => {
    const { onSetShowGroupFormModal } = this.props;
    onSetShowGroupFormModal();
  };

  render() {
    const { showGroupFormModal, className } = this.props;
    const { showModalLoader } = this.state;

    const initialValues = {
      groupName: '',
    };

    const validationSchema = Yup.object().shape({
      groupName: Yup.string().required('Please enter group name'),
    });

    const onSubmit = (values) => {
      this.createNewGroup(values?.groupName);
    };

    return (
      <Modal isOpen={showGroupFormModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          Create a Group
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        name="groupName"
                        type="text"
                        className={
                          `form-control${
                            errors.groupName && touched.groupName
                              ? ' is-invalid'
                              : ''}`
                        }
                      />
                      <ErrorMessage
                        name="groupName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

GroupForm.propTypes = {
  className: PropTypes.any,
  onSetShowGroupFormModal: PropTypes.func,
  selectedContacts: PropTypes.arrayOf(object),
  showGroupFormModal: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  history: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

const mapStateToProps = ({ contacts, onboarding }) => ({
  showGroupFormModal: contacts.showGroupFormModal,
  isOnboarding: onboarding.isOnboarding,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GroupForm));
