import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import styled from 'styled-components';

const StyledPageLoader = styled.div`
  .page-loader {
    /* Add your existing styles here */
  }
`;

function PageLoader() {
  return (
    <StyledPageLoader>
      <div className="page-loader">
        <ProgressSpinner />
      </div>
    </StyledPageLoader>
  );
}

export default PageLoader;
