import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EditableFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${(props) => `${props.gap}rem`};
`;

const TextInput = styled.input`
  border: 1px solid #dedede;
  border-radius: 4px;
  width: 100%;
  font-size: smaller;
`;

const TextLink = styled.a`
  color: #007bff;
  text-decoration: none;
  font-size: smaller;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.span`
  color: ${(props) => props.placeholder && '#828282'};
  font-size: ${(props) => props.placeholder && 'medium'};
`;

function TextField({
  isEditing,
  value,
  onChange,
  link = false,
  displayValue,
  className,
  classNameLink,
  classNameInput,
  iconClass,
  placeholderInput,
  placeholderValue,
  gap = 1,
}) {
  const renderContent = () => {
    if (isEditing) {
      return (
        <TextInput
          className={classNameInput}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholderInput}
        />
      );
    }
    if (link && displayValue) {
      return (
        <TextLink className={classNameLink} href={value} target="_blank" rel="noopener noreferrer">
          {displayValue}
        </TextLink>
      );
    }
    return (
      <Text className={className} placeholder={!displayValue}>
        {displayValue || placeholderValue}
      </Text>
    );
  };

  return (
    <EditableFieldWrapper gap={gap}>
      {iconClass && <i className={iconClass} />}
      {renderContent()}
    </EditableFieldWrapper>
  );
}

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  link: PropTypes.bool,
  displayValue: PropTypes.string,
  className: PropTypes.string,
  classNameLink: PropTypes.string,
  classNameInput: PropTypes.string,
  iconClass: PropTypes.string,
  placeholderInput: PropTypes.string,
  placeholderValue: PropTypes.string,
  gap: PropTypes.number,
};

export default TextField;
