import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import MetisMenu from 'metismenujs';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../UserProvider/UserProvider';
import { ROLES } from '../../constants/users/role.constant';

function SidebarContent({ type }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { userData, getUserinfo } = useContext(UserContext);
  const refDiv = useRef(null);

  const scrollElement = (item) => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (refDiv.current) refDiv.current.getScrollElement().scrollTop = currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  const activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active');
          parent3.childNodes[0].classList.add('mm-active');
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-show');
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show');
              parent5.childNodes[0].classList.add('mm-active');
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  };

  const initMenu = () => {
    // eslint-disable-next-line no-new
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; i += 1) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  useEffect(() => {
    getUserinfo();
  }, [getUserinfo]);

  useEffect(() => {
    initMenu();
  }, [type, location.pathname]);

  return (
    <SimpleBar className="h-100" ref={refDiv}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{t('Menu')}</li>
          <li>
            <Link to="/">
              <i className="bx bx-home-circle" />
              <span>{t('Dashboards')}</span>
            </Link>
          </li>

          <li>
            <Link to="/inbox">
              <i className="bx bxs-inbox" />
              <span>Inbox</span>
            </Link>
          </li>

          <li>
            <Link to="/campaign" className="has-arrow">
              <i className="fas fa-bullhorn fs-5" />
              <span>Broadcast</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/analytics">
                  <i className="mdi mdi-chart-line" />
                  Analytics
                </Link>
              </li>

              <li>
                <Link to="/send-message">
                  <i className="mdi mdi-android-messages" />
                  Text
                </Link>
              </li>

              <li>
                <Link to="/send-whatsapp-message">
                  <i className="mdi mdi-whatsapp" />
                  Whatsapp
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/message-template">
              <i className="bx bx-news" />
              <span>Templates</span>
            </Link>
          </li>
          <li>
            <Link to="/report">
              <i className="bx bxs-report" />
              <span>Report</span>
            </Link>
          </li>
          <li>
            <Link to="/#" className="has-arrow">
              <i className="bx bx-dollar-circle" />
              <span>Billing</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/call-billing">
                  <i className="bx bx-phone-call" />
                  Calls
                </Link>
              </li>
              <li>
                <Link to="/message-billing">
                  <i className="bx bx-message-alt-dots" />
                  Messages
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contacts-list">
              <i className="mdi mdi-contacts" />
              <span>Contacts</span>
            </Link>
          </li>

          {/* <li>
              <Link to="/bots" className="pe-none">
                <i className="mdi mdi-robot" />
                <span>Bots</span>
              </Link>
            </li>

            <li>
              <Link to="/flow-builder" className="pe-none">
                <i className="bx bx-customize" />
                <span>Flow Builder</span>
              </Link>
            </li> */}
          <li>
            <Link to="/logs" className="has-arrow">
              <i className="bx bxs-book-content" />
              <span>Logs</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/call-logs">
                  <i className="mdi mdi-phone-log" />
                  <span>Call Logs</span>
                </Link>
              </li>
              <li>
                <Link to="/voicemail">
                  <i className="mdi mdi-voicemail" />
                  <span>Voicemail</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/communication-settings">
              <i className="bx bx-cog" />
              <span>Settings</span>
            </Link>
          </li>
          <li>
            <Link to="/alerts">
              <i className="bx bx-notification" />
              <span>Alerts</span>
            </Link>
          </li>
          {userData?.role === ROLES.ADMIN && (
            <>
              <li className="menu-title">{t('Admin Management')}</li>
              <li>
                <Link to="/user-management">
                  <i className="mdi mdi-account-group" />
                  <span>{t('User')}</span>
                </Link>
              </li>
              <li>
                <Link to="/opt-management">
                  <i className="bx bx-stop-circle" />
                  <span>{t('Opt')}</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
}

SidebarContent.propTypes = {
  type: PropTypes.string,
};

export default SidebarContent;
