import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Label from '../Label';
import TextField from '../EditableFieldWrapper/TextField';

const StyledSelectContainer = styled.div`
  .react-select__control {
    cursor: pointer;
    border-radius: 0.5rem;
    padding: 0.1rem;
    border-color: #dfe1e5;
    &:hover {
      border-color: #a3a3a3;
    }
  }

  .react-select__menu {
    z-index: 100;
    * {
      cursor: pointer;
    }
  }
`;

class Select extends Component {
  renderOptionWithIcon = (option, optionIcon) => {
    const { icon } = option;
    return <TextField displayValue={option.label} iconClass={icon || optionIcon} gap={0.5} />;
  };

  render() {
    const {
      options,
      value,
      onChange,
      placeholder,
      isMulti,
      isCreatable,
      isDisabled,
      styles,
      isLoading,
      noOptionsMessage,
      onMenuScrollToBottom,
      maxMenuHeight,
      containerStyle,
      className,
      label,
      iconClass,
      showClearAll,
      optionIcon,
      ...rest
    } = this.props;

    const SelectComponent = isCreatable ? CreatableSelect : ReactSelect;

    const mergedStyles = {
      ...styles,
      clearIndicator: (provided) => ({
        ...provided,
        display: showClearAll ? 'block' : 'none',
      }),
    };

    const getOptionLabel = (option) => (optionIcon ? this.renderOptionWithIcon(option, optionIcon) : option.label);

    return (
      <StyledSelectContainer style={containerStyle}>
        <div className="d-flex align-items-center">
          {iconClass && <i className={iconClass} />}
          {label && <Label>{label}</Label>}
        </div>
        <SelectComponent
          classNamePrefix="react-select"
          className={className}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isMulti={isMulti}
          isDisabled={isDisabled}
          styles={mergedStyles}
          isLoading={isLoading}
          noOptionsMessage={noOptionsMessage}
          onMenuScrollToBottom={onMenuScrollToBottom}
          maxMenuHeight={maxMenuHeight}
          formatOptionLabel={getOptionLabel}
          formatGroupLabel={getOptionLabel}
          {...rest}
        />
      </StyledSelectContainer>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  styles: PropTypes.object,
  isLoading: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onMenuScrollToBottom: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  iconClass: PropTypes.string,
  showClearAll: PropTypes.bool,
  optionIcon: PropTypes.string,
};

Select.defaultProps = {
  placeholder: 'Select an option',
  isMulti: false,
  isCreatable: false,
  isDisabled: false,
  styles: {},
  isLoading: false,
  noOptionsMessage: () => 'No options available',
  onMenuScrollToBottom: null,
  maxMenuHeight: 300,
  containerStyle: {},
  className: '',
  label: '',
  iconClass: '',
  showClearAll: false,
  optionIcon: '',
};

export default Select;
