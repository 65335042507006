import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create a function to show toasts
export const showToast = ({ content, type = 'default', ...options }) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  };

  switch (type) {
    case 'info':
      return toast.info(content, toastOptions);
    case 'success':
      return toast.success(content, toastOptions);
    case 'warning':
      return toast.warning(content, toastOptions);
    case 'error':
      return toast.error(content, toastOptions);
    default:
      return toast(content, toastOptions);
  }
};

// Create a function to update existing toasts
export const updateToast = ({ toastId, content, ...options }) => {
  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: content, ...options });
  }
};

// Create a function to dismiss toasts
export const dismissToast = (toastId) => {
  if (toastId) {
    toast.dismiss(toastId);
  } else {
    toast.dismiss();
  }
};

// Create the ToastService component
export function ToastService() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
}
