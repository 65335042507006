/* eslint-disable import/prefer-default-export */
// Helper function to extract the main profile name
export const getProfileNameFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    const pathParts = parsedUrl.pathname.split('/');
    return pathParts.filter(Boolean).pop(); // Get the last non-empty segment of the path
  } catch (error) {
    // In case the URL is not valid, return the entire URL
    return url;
  }
};
