import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Dropdown from '../../../../components/Dropdown';
import Card from '../../../../components/Card';
import InputSwitch from '../../../../components/InputSwitch';
import FileSelector from '../../../../components/FileSelector';
import Col from '../../../../components/Col';
import Row from '../../../../components/Row';
import HField from '../../../../components/HField';
import Chips from '../../../../components/Chips';
import CustomErrorMessage from '../../../../components/CustomErrorMessage';
import { IMPORT_CONTACT_ACTION_KEYS, IMPORT_TYPE_OPTIONS } from '../../../../constants/importcontacts.constant';
import { setImportContactsStepTwoData } from '../../../../store/actions';
import { formatBytes } from '../../../../utils/importcontacts.util';

const StyledCard = styled(Card)`
  margin-bottom: 1rem;
`;

const StyledDropdown = styled(Dropdown)`
  height: 2.5rem;
  width: 100%;

  .p-dropdown-label {
    font-size: 0.9rem;
    padding: 0.5rem 0rem 1rem 1rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 250px;
`;

const StyledChips = styled(Chips)`
  flex: 1;
  .p-chips-multiple-container {
    padding-left: 0;
    padding: 0.15rem;
    width: 100%;
  }

  .p-chips-token {
    padding: 0.2rem 0.8rem;
  }
`;

const MarketingOptInField = styled(HField)`
  margin-top: 0rem !important;

  label {
    margin-bottom: 0.5rem !important;
  }
  
  @media (min-width: 1462px) {
    margin-top: 0.3rem !important;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

function Step2({ formCompletion }) {
  const dispatch = useDispatch();
  const {
    selectedFile,
    segmentName,
    importType,
    marketingOptIn,
    tags,
    uploadedFile,
  } = useSelector((state) => state.contacts.importContacts.step2);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      selectedFile,
      segmentName,
      importType,
      marketingOptIn,
      tags,
    });
  }, [selectedFile, segmentName, importType, marketingOptIn, tags]);

  const loadCsvContent = (event) => {
    const csvContent = event.target.result;
    const rows = csvContent.split('\n'); // Split into rows
    const headers = rows[0].split(',').map((header) => header.trim()); // Extract headers
    const fieldMappings = headers?.map((header) => ({
      active: false,
      excelColumn: header,
      contactField: header,
      action: IMPORT_CONTACT_ACTION_KEYS.REPLACE,
      isCustomField: false,
    }));

    const contactData = [];
    for (let i = 1; i < rows.length; i += 1) {
      const columns = rows[i].split(',').map((column) => column.trim());

      // Create an object with properties from headers
      const rowData = { no: i }; // Adding 'id' and 'no' properties with index values
      headers.forEach((header, index) => {
        rowData[header] = columns[index];
      });
      contactData.push(rowData);
    }
    dispatch(setImportContactsStepTwoData({ fieldMappings, contactData }));
  };

  const handleAcceptedFiles = (files, setFieldValue, setFieldError) => {
    if (!files.length) return;

    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }));
    const selectedFileObj = files?.[0] || null;

    dispatch(setImportContactsStepTwoData({ uploadedFile: selectedFileObj }));

    const maxSize = 10 * 1024 * 1024; // Max file size is 10 MB
    if (selectedFileObj?.size > maxSize) {
      setFieldError('selectedFile', 'File size exceeds the 10 MB limit.');
      return;
    }

    setFieldValue('selectedFile', selectedFileObj?.name);
    const reader = new FileReader();

    reader.onload = loadCsvContent;

    reader.readAsText(selectedFileObj);
  };

  const handleOnClose = (setFieldValue) => {
    dispatch(setImportContactsStepTwoData({ uploadedFile: null, fieldMappings: [] }));
    setFieldValue('selectedFile', '');
  };

  const validationSchema = Yup.object().shape({
    selectedFile: Yup.string().required('Please select File'),
    segmentName: Yup.string().required('Segment name is required'),
    importType: Yup.string().required('Import type is required'),
    marketingOptIn: Yup.string().default(false),
    tags: Yup.array(Yup.string()).min(1, 'Please add at least 1 tag'),
  });

  return (
    <StyledCard>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={async (values) => {
          try {
            const isValid = await validationSchema.isValid(values);
            formCompletion(isValid);
            dispatch(setImportContactsStepTwoData(values));
          } catch (error) {
            console.error('error: ', error);
          }
        }}
      >
        {({
          values, errors, touched, setFieldValue, setFieldError,
        }) => (
          <Form>
            <FormContainer>
              <FormColumn style={{ gridColumn: 'span 2' }}>
                <FileSelector
                  label="File:"
                  buttonText="Choose files"
                  onSelect={(files) => {
                    handleAcceptedFiles(files, setFieldValue, setFieldError);
                  }}
                  onClose={() => handleOnClose(setFieldValue)}
                  accept=".csv"
                  selectedFiles={uploadedFile ? [uploadedFile] : []}
                  errorMessage={errors.selectedFile}
                />
              </FormColumn>
            </FormContainer>
            <Row>
              <Col sm={12} lg={12} xl={6}>
                <HField
                  label="Segment Name:"
                  placeholder="Enter Segment Name"
                  type="text"
                  fieldClassName={
                    `form-control ${
                      errors.segmentName && touched.segmentName ? 'is-invalid' : ''}`
                  }
                  name="segmentName"
                  formikField
                />
              </Col>
              <Col sm={12} lg={12} xl={6}>
                <HField
                  label="Import Type:"
                  name="importType"
                  fieldClassName={
                    `form-control ${
                      errors.importType && touched.importType ? 'is-invalid' : ''}`
                  }
                >
                  <StyledDropdown
                    id="importType"
                    value={values.importType}
                    options={IMPORT_TYPE_OPTIONS}
                    onChange={(e) => {
                      setFieldValue('importType', e.value);
                    }}
                  />
                  <CustomErrorMessage
                    message={errors.importType}
                    showError={!!errors.importType}
                  />
                </HField>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12} xl={6}>
                <HField
                  label="Tags:"
                  name="tags"
                  fieldClassName={
                    `form-control ${
                      errors.tags && touched.tags ? 'is-invalid' : ''}`
                  }
                >
                  <StyledDiv>
                    <StyledChips
                      id="tags"
                      placeholder="Enter Tags"
                      value={values.tags}
                      onChange={(e) => {
                        setFieldValue('tags', e.value);
                      }}
                      max={5}
                    />
                    <CustomErrorMessage
                      message={errors.tags}
                      showError={!!errors.tags}
                    />
                  </StyledDiv>
                </HField>
              </Col>
              <Col sm={12} lg={12} xl={6}>
                <MarketingOptInField
                  label="Marketing Opt-In:"
                  name="marketingOptIn"
                  fieldClassName={
                    `form-control ${
                      errors.marketingOptIn && touched.marketingOptIn ? 'is-invalid' : ''}`
                  }
                >
                  <InputSwitch
                    id="marketingOptIn"
                    checked={values.marketingOptIn}
                    onChange={(e) => {
                      setFieldValue('marketingOptIn', e.value);
                    }}
                  />
                </MarketingOptInField>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </StyledCard>
  );
}

Step2.propTypes = {
  formCompletion: PropTypes.func,
};

export default Step2;
