/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { COLOR_PALETTE } from '../../constants/colors.constant';
import noDataAvailable from '../../assets/images/graphics/data-not-available.svg';
import DisplayImage from '../DisplayImage';

class Pie extends Component {
  getOption = () => ({
    toolbox: {
      top: 0,
      feature: {
        saveAsImage: {
          show: true,
          title: 'Save As Image',
          iconStyle: {
            color: '#ffffff',
            backgroundColor: '#ffffff',
            borderColor: '#50a5f1',
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            shadowOffsetX: 0,
            shadowOffsetY: 4,
          },
          pixelRatio: 2,
        },
      },
    },
    title: {
      text: this.props.title,
      top: 0,
      textStyle: {
        color: '#333',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      top: 50,
      data: this.props.data.map((item) => item.name),
      textStyle: {
        color: ['#74788d'],
      },
    },
    color: COLOR_PALETTE,
    series: [
      {
        name: this.props.name,
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: this.props.data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  });

  render() {
    const { data } = this.props;

    return (
      <div>
        {isEmpty(data) ? (
          <DisplayImage src={noDataAvailable} />
        ) : (
          <ReactEcharts
            style={{ height: '350px' }}
            option={this.getOption()}
          />
        )}
      </div>
    );
  }
}

Pie.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Pie;
