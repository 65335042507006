import React, { Component } from 'react';
import {
  Container, Row, Col, Card, CardBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LIMIT } from '../../../../constants/constants';
import { axiosGet, axiosPost } from '../../../../services/http.service';
import { setLoader, setOnboardingData as setOnboardingDataAction } from '../../../../store/actions';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../../constants/onboarding.constant';
import { handleOnboardingStepCompletion } from '../../../../utils/onboarding';

class SingleMessage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      assignedNumbers: [],
    };
  }

  componentDidMount() {
    this.handleGetAllAssignedNumbers();
  }

  // to get all sender groups
  handleGetAllAssignedNumbers = async () => {
    try {
      const response = await axiosGet(`numbers?page=1&limit=${LIMIT}`);
      if (response?.status) {
        this.setState({ assignedNumbers: response?.data?.results });
      }
    } catch (error) {
      console.error('error at handleGetAllAssignedNumbers :', error);
    }
  };

  handleSendSms = async (smsData, formik) => {
    const {
      onSetLoader, isOnboarding, history, setOnboardingData,
    } = this.props;
    const { userData } = this.context;
    const organizationId = userData?.organizationId;
    try {
      onSetLoader(true);
      const response = await axiosPost('campaign/single-sms', smsData);
      if (response?.status) {
        onSetLoader(false);
        toast.success(response?.message || 'Sms sended successfully!');
        formik?.resetForm();
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.BROADCAST_MESSAGE,
            organizationId,
            history,
            setOnboardingData,
          });
        }
      } else {
        onSetLoader(false);
        toast.error(response?.message || 'Oops! something went wrong');
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleSendSms :', error);
    }
  };

  handleOnSubmit = (values, formik) => {
    const smsData = {
      message: values?.message,
      from: values?.senderNumber,
      to: `+${values?.recipentNumber}`,
    };
    this.handleSendSms(smsData, formik);
  };

  render() {
    // meta title
    document.title = 'message';

    const { assignedNumbers } = this.state;

    const initialValues = {
      recipentNumber: '',
      senderNumber: '',
      message: '',
    };

    const validationSchema = Yup.object().shape({
      recipentNumber: Yup.number().required('Please enter recipient number'),
      senderNumber: Yup.number().required('Please select a sender number'),
      message: Yup.string().required('Please provide a message to send'),
    });

    return (
      <div className="">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={this.handleOnSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Number</Label>
                              <Field
                                name="recipentNumber"
                                type="number"
                                placeholder="Enter Receiver Number"
                                className={`form-control${
                                  errors.recipentNumber && touched.recipentNumber
                                    ? ' is-invalid'
                                    : ''
                                }`}
                              />
                              <ErrorMessage
                                name="recipentNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Sender Number</Label>
                              <Field
                                as="select"
                                name="senderNumber"
                                className={`form-control-select form-select-border-bottom${
                                  errors.senderNumber && touched.senderNumber ? ' is-invalid' : ''
                                }`}
                              >
                                <option value="">Select a number</option>
                                {assignedNumbers.map((number, index) => (
                                  <option
                                    value={number?.phoneNumber}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${index}-${number?.phoneNumber}`}
                                  >
                                    {number?.phoneNumber}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="senderNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Text Box</Label>
                              <Field
                                as="textarea"
                                name="message"
                                className={`form-control-select${
                                  errors.message && touched.message ? ' is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="message"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="submit" className="btn btn-success save-user">
                                Send
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

SingleMessage.propTypes = {
  onSetLoader: PropTypes.func,
  isOnboarding: PropTypes.bool,
  history: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isOnboarding: state.onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleMessage));
