/* eslint-disable import/prefer-default-export */
import { axiosGet } from '../http.service';

export const fetchUsers = async (params) => {
  try {
    const {
      page,
      limit,
      sortBy = 'createdAt:desc',
      role,
    } = params;

    let url = `/users?page=${page}&limit=${limit}&sortBy=${sortBy}`;
    if (role) {
      url += `&role=${role}`;
    }

    const response = await axiosGet(url);
    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
