import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { WHATSAPP_VARIABLES } from '../../../constants/channels/whatsApp.constant';

class VariableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { setSelectedVariable } = this.props;
    return (
      <div className="mb-1">
        <select
          id="groupSelection"
          className="form-select width-10rem"
          onClick={(event) => setSelectedVariable(event.target.value)}
        >
          <option value="">Select a Variable</option>
          {Object.values(WHATSAPP_VARIABLES).map(
            (whatsappVariablesName) => (
              <option
                value={whatsappVariablesName}
                key={whatsappVariablesName}
              >
                {whatsappVariablesName}
              </option>
            ),
          )}
        </select>
      </div>
    );
  }
}

VariableSelect.propTypes = {
  setSelectedVariable: PropTypes.func,
};

export default VariableSelect;
