/* eslint-disable import/prefer-default-export */
import { axiosPost } from '../http.service';

// Function to exchange WhatsApp token
export const uploadSingleFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    // Upload image
    const response = await axiosPost('upload', formData);

    return response;
  } catch (error) {
    console.error('Error uploadSingleFile:', error);
    throw error;
  }
};
