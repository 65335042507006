// eslint-disable no-case-declarations
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';
import { FILE_EXTENSIONS, MEDIA_TYPES } from '../../../constants/file.constant';
import { MESSAGE_TYPES } from '../../../constants/inbox/message.constant';
import { randomString } from '../../../utils/general';

function MessageList({ conversationMessages }) {
  if (isEmpty(conversationMessages)) return null;

  return conversationMessages.map((message, index) => {
    const { createdAt } = message;
    const isFirstMessageOfDay = index === 0
      || moment(createdAt).startOf(DATE_CONSTANTS.UNITS.DAY).valueOf()
        > moment(conversationMessages[index - 1]?.createdAt)
          .startOf(DATE_CONSTANTS.UNITS.DAY)
          .valueOf();

    const renderMediaContent = () => {
      const messageMediaType = message?.mediaType?.split('/')[0] || 'image';
      const fileExtension = message?.mediaLink.split('.').pop();
      const fileName = message?.mediaLink.split('/').pop();
      let icon;
      switch (messageMediaType) {
        case MEDIA_TYPES.IMAGE:
          return (
            <a href={message?.mediaLink} target="_blank" rel="noopener noreferrer">
              <img src={message?.mediaLink} alt="sent" width={150} height={150} />
              <p className="m-0">{message.message}</p>
            </a>
          );
        case MEDIA_TYPES.VIDEO:
          return (
            <>
              <video width={150} controls>
                <source src={message?.mediaLink} type={message?.mediaType} />
                Your browser does not support the video tag.
              </video>
              <p className="m-0">{message.message}</p>
            </>
          );
        case MEDIA_TYPES.AUDIO:
          return (
            <>
              <audio width={150} controls>
                <source src={message?.mediaLink} type={message?.mediaType} />
                Your browser does not support the audio element.
              </audio>
              <p className="m-0">{message.message}</p>
            </>
          );
        case MEDIA_TYPES.APPLICATION:
          switch (fileExtension) {
            case FILE_EXTENSIONS.PDF:
              icon = <i className="mdi mdi-file-pdf-box font-size-16 me-1" />;
              break;
            case FILE_EXTENSIONS.XLSX:
              icon = <i className="mdi mdi-file-excel-box font-size-16 me-1" />;
              break;
            case FILE_EXTENSIONS.DOC:
            case FILE_EXTENSIONS.DOCX:
              icon = <i className="mdi mdi-file-word-box font-size-16 me-1" />;
              break;
            default:
              icon = <i className="mdi mdi-file-document font-size-16 me-1" />;
          }
          return (
            <>
              <a href={message?.mediaLink} target="_blank" rel="noopener noreferrer">
                {icon}
                {sliceStringWithEllipsis(15, fileName)}
              </a>
              <p className="m-0">{message.message}</p>
            </>
          );
        default:
          return <p className="m-0">Unsupported media type</p>;
      }
    };

    return (
      <div key={`${message.createdAt}`}>
        {isFirstMessageOfDay && (
          <li key={randomString(10, 3)}>
            <div className="chat-day-title">
              <span className="title border rounded-pill">
                {moment(createdAt).format(DATE_CONSTANTS.FORMATS.MMMM_DD_YYYY)}
              </span>
            </div>
          </li>
        )}

        <li className={message.type === MESSAGE_TYPES.RECEIVED ? '' : 'right'}>
          <div
            className={`conversation-list message-list-item ${
              message.type === MESSAGE_TYPES.RECEIVED ? '' : 'me-4'
            }`}
          >
            <div
              className={`conversation-name ${
                message.type === MESSAGE_TYPES.RECEIVED ? '' : 'd-flex justify-content-end'
              }`}
            />
            <div className="ctext-wrap">
              {message?.mediaLink ? renderMediaContent() : <p className="m-0">{message.message}</p>}
            </div>
            <p
              className={`chat-time mb-0 ${
                message.type === MESSAGE_TYPES.SEND
                  ? 'd-flex justify-content-end align-items-center'
                  : ''
              }`}
            >
              {moment(message.createdAt).format(DATE_CONSTANTS.FORMATS.H_MM_A)}
              {message.type === MESSAGE_TYPES.QUEUED ? (
                <i className="mdi mdi-clock ms-1 text-secondary" />
              ) : (
                <i className="mdi mdi-check ms-1 text-secondary" />
              )}
            </p>
          </div>
        </li>
      </div>
    );
  });
}

MessageList.propTypes = {
  conversationMessages: PropTypes.array,
};

export default MessageList;
