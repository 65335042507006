/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */

import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Row, Col, Button, UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';
import EmojiPicker from 'emoji-picker-react';
import { isEmpty, cloneDeep } from 'lodash';
import { fetchAllQuickMessages } from '../../../services/api/quickMessage.service';
import { MESSAGE_TYPES } from '../../../constants/inbox/message.constant';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import { showToast } from '../../../services/toastService';
import {
  sendMessageOrMedia,
  updateConversation,
  uploadMedia,
} from '../../../services/api/conversation.service';
import {
  CHAT_INPUT_TABS,
  NOTES_TAB,
  NOTES_TAB_INDEX,
  REPLY_TAB,
} from '../../../constants/channels/conversation.constant';
import MediaPreview from './MediaPreview';
import TabMenu from '../../../components/TabMenu';
import Textarea from '../../../components/Textarea';
import CustomListBox from '../../../components/ListBox';
import Avatar from '../../../components/Avatar';
import { getInitials } from '../../../helpers/commonHelpers';
import { capitalizeFirstLetter } from '../../../utils/general';
import { toastType } from '../../../constants/toast';
import { UserContext } from '../../../components/UserProvider/UserProvider';

function ChatInput({
  onSetConversationMessages,
  activeConversation,
  showContactInfo,
  setActiveConversation, // Sets the selected or active conversation.
  onSetConversationList, // sets the conversation list
  scrollToBottom,
  targetEvent,
}) {
  const userContext = useContext(UserContext);
  const [curMessage, setCurMessage] = useState('');
  const [activeTabMenu, setActiveTabMenu] = useState(0);
  const [quickMessages, setQuickMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showQuickMessages, setShowQuickMessages] = useState(false);
  const [imageAttachment, setImageAttachment] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState('');
  const emojiPickerRef = useRef(null);

  const mediaType = fileType?.split('/')[0];

  useEffect(() => {
    // Logic to close the emoji picker when clicked outside of it.
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(targetEvent.target)) {
      setShowEmojiPicker(false);
    }
  }, [targetEvent]);

  // Function to handle the add note API.
  const addNote = async () => {
    try {
      const newConversation = cloneDeep(activeConversation);
      const noteData = { content: curMessage };
      const response = await updateConversation(activeConversation?.id, { note: noteData });
      if (response?.status) {
        if (response?.data?.notes) {
          newConversation.notes = response?.data?.notes;
        }
        showToast({
          content: response?.message || 'Note saved successfully!',
          type: toastType.success,
        });
        setActiveConversation(newConversation);
        setCurMessage('');
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong!',
          type: toastType.error,
        });
      }
    } catch (error) {
      console.error('Error at addNote:', error);
    }
  };

  const handleTabChange = (index) => {
    setActiveTabMenu(index);
  };

  const handleSubmit = () => {
    const tab = CHAT_INPUT_TABS?.[activeTabMenu]?.value;
    switch (tab) {
      case REPLY_TAB:
        sendBtnOnClick();
        break;
      case NOTES_TAB:
        addNote();
        break;
      default:
        break;
    }
  };

  const getAllCannedMessages = async () => {
    try {
      const response = await fetchAllQuickMessages();
      if (!isEmpty(response?.results)) {
        setQuickMessages(response?.results);
      }
    } catch (error) {
      console.error('error at getAllCannedMessages :', error);
    }
  };

  useEffect(() => {
    getAllCannedMessages();
  }, []);

  const addEmoji = (emojiObject) => {
    setCurMessage((prev) => prev + emojiObject.emoji);
  };

  const toggleEmojiPicker = (event) => {
    event.stopPropagation();
    setShowEmojiPicker((prev) => !prev);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      const fileType = file.type;
      setImageAttachment(file);
      setCurMessage(file.name);
      setFileUrl(fileUrl);
      setFileType(fileType);
    }
  };

  const removeFile = () => {
    setImageAttachment(null);
    setCurMessage('');
    setFileUrl(null);
    setFileType(null);
  };

  // Removes a temporary message by its ID and reverts the conversation state to the last actual message
  const removeTempMessageAndRevertChanges = (tempMessageId) => {
    onSetConversationMessages((prevMessages) => {
      // Filter out the temporary message from the conversation messages array
      const filteredMessages = prevMessages.filter((msg) => msg._id !== tempMessageId);

      // Get the last actual message in the updated messages to set as the last conversation message
      const lastMessage = filteredMessages[filteredMessages.length - 1];

      // Prepare the updated conversation object with the last real message's details
      const tempUpdatedConversation = {
        ...activeConversation,
        lastMessage: lastMessage ? lastMessage.message : '',
        lastMessageType: lastMessage ? lastMessage.type : '',
      };

      // Update the active conversation with the reverted last message data
      setActiveConversation(tempUpdatedConversation);

      // Update the conversation list with the reverted data for the specific conversation
      onSetConversationList((prevConversations) => {
        const conversationIndex = prevConversations.findIndex(
          (conv) => conv?.id === activeConversation?.id,
        );

        // If conversation not found, return unchanged
        if (conversationIndex === -1) return prevConversations;

        // Create new array with updated conversation
        const newConversations = [...prevConversations];
        newConversations[conversationIndex] = tempUpdatedConversation;
        return newConversations;
      });

      // Return the filtered messages to remove the temporary message from the displayed conversation
      return filteredMessages;
    });
  };

  const handleUpload = async () => {
    // Step 1: Check if an image is selected
    if (!imageAttachment) {
      showToast({ content: 'Please select a file.', type: 'error' });
      return;
    }

    // Step 2: Create a temporary message for the media attachment
    const tempMessage = {
      _id: `temp-${Date.now()}`,
      message: 'Media',
      mediaLink: URL.createObjectURL(imageAttachment),
      mediaType: fileType,
      createdAt: new Date(),
      type: MESSAGE_TYPES.QUEUED,
    };

    // Step 3: Update the active conversation with the last message info
    const updatedConversation = {
      ...activeConversation,
      lastMessage: 'Media',
      lastMessageType: fileType,
    };

    setActiveConversation(updatedConversation);

    // Step 4: Update the conversation list with the new message status
    onSetConversationList((prevConversations) => {
      const conversationIndex = prevConversations.findIndex(
        (conv) => conv?.id === activeConversation?.id,
      );

      // If conversation not found, return unchanged
      if (conversationIndex === -1) return prevConversations;

      // Create new array with updated conversation
      const newConversations = [...prevConversations];
      newConversations[conversationIndex] = updatedConversation;
      return newConversations;
    });

    // Step 5: Add the temporary message to the selected conversation messages
    onSetConversationMessages((prevMessages) => [...prevMessages, tempMessage]);
    setImageAttachment('');

    try {
      // Step 6: Prepare the FormData for uploading the image
      const formData = new FormData();
      formData.append('file', imageAttachment);

      // Step 7: Upload the image and handle the response
      const uploadResponse = await uploadMedia(formData, () => removeTempMessageAndRevertChanges(tempMessage._id));
      const mediaLink = uploadResponse?.destination;

      // Step 8: Prepare the message payload for sending the media
      const messagePayload = {
        mediaLink,
        mediaType: fileType,
      };

      // If the channel isn't WhatsApp, send a blank message (for non-WhatsApp platforms)
      if (activeConversation.channel !== CHANNEL_TYPES.WHATSAPP) {
        messagePayload.message = ' ';
      }

      // Step 9: Send the media message and handle the response
      const sendImageResponse = await sendMessageOrMedia(
        activeConversation.channel,
        activeConversation?.id,
        messagePayload,
        () => removeTempMessageAndRevertChanges(tempMessage._id),
      );

      // Step 10: Prepare the updated conversation with the sent message
      const updatedConversation = sendImageResponse;

      // Step 11: Prepare the updated message object after the media upload
      const updatedMessage = {
        _id: sendImageResponse?.id,
        message: '',
        mediaLink: URL.createObjectURL(imageAttachment),
        mediaType: fileType,
        createdAt: new Date(),
        type: MESSAGE_TYPES.SEND,
      };
      delete updatedMessage.id;

      // Step 12: Update the conversation messages with the final sent message
      onSetConversationMessages((prevMessages) => {
        const messageIndex = prevMessages.findIndex((msg) => msg._id === tempMessage._id);

        // If message not found, return unchanged
        if (messageIndex === -1) return prevMessages;

        // Create new array with updated message
        const newMessages = [...prevMessages];
        newMessages[messageIndex] = updatedMessage;
        return newMessages;
      });

      // Step 13: Update the conversation list to reflect the latest message
      const updateConversation = (prevConversations) => {
        const conversationIndex = prevConversations.findIndex(
          (conv) => conv?.id === activeConversation?.id,
        );

        if (conversationIndex === -1) return prevConversations;

        const newConversations = [...prevConversations];
        newConversations[conversationIndex] = {
          ...newConversations[conversationIndex],
          lastMessage: updatedConversation.lastMessage,
          lastMessageType: updatedConversation.lastMessageType,
        };

        return newConversations;
      };

      // Step 14: Set the updated conversation list with the new last message details
      onSetConversationList(updateConversation);

      // Step 15: Set the active conversation state with the updated information
      setActiveConversation({
        ...updatedConversation,
        lastMessage: updatedConversation.lastMessage,
        lastMessageType: updatedConversation.lastMessageType,
      });

      // Step 16: Clear the form fields after successful upload
      setFileUrl('');
      setFileType('');
      setCurMessage('');
    } catch (error) {
      console.error('Error at handleUpload:', error);

      // Revert any temporary changes if an error occurs
      removeTempMessageAndRevertChanges(tempMessage._id);
    }
  };

  const handleSendMessage = async () => {
    // Step 1: Create a temporary message object with the current message and set it as queued
    const tempMessage = {
      _id: `temp-${Date.now()}`,
      message: curMessage,
      createdAt: new Date(),
      type: MESSAGE_TYPES.QUEUED,
    };

    // Step 2: Update the active conversation with the latest message and its type
    const updatedConversation = {
      ...activeConversation,
      lastMessage: curMessage,
      lastMessageType: '',
    };

    // Step 3: Set the updated conversation as the active conversation in the state
    setActiveConversation(updatedConversation);

    // Step 4: Update the conversation list with the new active conversation state
    const updateConversation = (prevConversations) => {
      const conversationIndex = prevConversations.findIndex(
        (conv) => conv?.id === activeConversation?.id,
      );

      if (conversationIndex === -1) return prevConversations;

      const newConversations = [...prevConversations];
      newConversations[conversationIndex] = updatedConversation;

      return newConversations;
    };

    // Step 5: Add the temporary message to the conversation's message list
    onSetConversationList(updateConversation);
    onSetConversationMessages((prevMessages) => [...prevMessages, tempMessage]);
    setCurMessage('');

    try {
      // Step 6: Create the payload for the API call to send the message
      const messagePayload = {
        message: curMessage,
        mediaType: '',
      };

      // Step 7: If the conversation is not a text channel, set a blank media link
      if (activeConversation.channel !== CHANNEL_TYPES.TEXT) {
        messagePayload.mediaLink = ' ';
      }

      // Step 8: Call the function to send the message or media
      const response = await sendMessageOrMedia(
        activeConversation.channel,
        activeConversation?.id,
        messagePayload,
        () => removeTempMessageAndRevertChanges(tempMessage._id),
      );

      // Step 9: Create the updated message with the actual sent content
      const updatedConversation = response;
      const updatedMessage = {
        message: curMessage,
        createdAt: new Date(),
        type: MESSAGE_TYPES.SEND,
      };
      delete updatedMessage.id;

      // Step 10: Update the message list with the sent message
      const updateMessages = (prevMessages) => {
        const messageIndex = prevMessages.findIndex((msg) => msg._id === tempMessage._id);

        if (messageIndex === -1) return prevMessages;

        const newMessages = [...prevMessages];
        newMessages[messageIndex] = updatedMessage;

        return newMessages;
      };

      // Step 11: Helper function to update the conversation list with the new last message and type
      const updateConversations = (prevConversations) => {
        const conversationIndex = prevConversations.findIndex(
          (conv) => conv?.id === activeConversation?.id,
        );

        if (conversationIndex === -1) return prevConversations;

        const newConversations = [...prevConversations];
        newConversations[conversationIndex] = {
          ...newConversations[conversationIndex],
          lastMessage: updatedConversation.lastMessage,
          lastMessageType: updatedConversation.lastMessageType,
        };

        return newConversations;
      };

      // Step 12: Apply both updates to the state (messages and conversations)
      onSetConversationMessages(updateMessages);
      onSetConversationList(updateConversations);

      // Step 13: Update the active conversation state with the latest last message
      setActiveConversation({
        ...updatedConversation,
        lastMessage: updatedConversation.lastMessage,
        lastMessageType: updatedConversation.lastMessageType,
      });
    } catch (error) {
      console.error('error at handleSendMessage :', error);

      // Revert any temporary changes if an error occurs
      removeTempMessageAndRevertChanges(tempMessage._id);
    }
  };

  const onKeyPress = (e) => {
    const tab = CHAT_INPUT_TABS?.[activeTabMenu]?.value;
    const { userData } = userContext;
    const { key } = e;
    const { value } = e.target;

    if (
      key === 'Enter'
      && activeConversation
      && activeConversation?.assignedUserId === userData?.id
    ) {
      if (tab === NOTES_TAB) {
        addNote();
      } else if (imageAttachment) {
        handleUpload();
        scrollToBottom();
      } else {
        setCurMessage(value);
        handleSendMessage();
        scrollToBottom();
      }
    }
  };

  const sendBtnOnClick = () => {
    if (!isEmpty(activeConversation)) {
      if (imageAttachment) {
        handleUpload();
        scrollToBottom();
      } else {
        handleSendMessage();
        scrollToBottom();
      }
    }
  };

  const renderItem = (message) => (
    <div className="d-flex align-items-center">
      <div>
        <Avatar
          label={getInitials(message?.name)}
          shape="circle"
          className="avatar-xs fw-bold text-light fs-5"
        />
      </div>
      <div className="ms-2 d-flex flex-column">
        <span className="fw-bold">{capitalizeFirstLetter(message?.name)}</span>
        <small>{message?.content}</small>
      </div>
    </div>
  );

  const onChangeCannedMessage = (e) => {
    setCurMessage(e?.value?.content);
    setShowQuickMessages(false);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Row className={`g-2 p-1 chat-input-section ${!showContactInfo ? 'right-18' : ''}`}>
        <Col xs="12" md="12" className="mt-0">
          <div>
            <TabMenu
              model={CHAT_INPUT_TABS}
              activeIndex={activeTabMenu}
              onTabChange={handleTabChange}
              className="d-flex justify-content-start mb-3"
              itemClassName="font-size-14"
              displayLabel
              displayIcon
            />
          </div>
          <div className="chat-input-wrapper mb-2">
            <MediaPreview
              mediaType={mediaType}
              fileUrl={fileUrl}
              fileName=""
              onRemove={removeFile}
            />
            <Textarea
              name="myTextarea"
              onKeyPress={onKeyPress}
              value={curMessage}
              placeholder={
                activeTabMenu === NOTES_TAB_INDEX
                  ? `Write a note for ${activeConversation?.name}`
                  : `Message ${activeConversation?.name}`
              }
              onChange={(e) => setCurMessage(e.target.value)}
              className="mt-2 form-control chat-input font-size-16"
              rows={1}
              disabled={activeConversation.length === 0}
              invalid={false}
              valid
              disableAutoResize
            />
          </div>
          <div className="d-flex gap-2 justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <ul className="chat-input-links mb-0 ms-2">
                <li className="list-inline-item me-3">
                  <label htmlFor="fileInput" className="mb-0">
                    <i
                      className="mdi mdi-link-variant font-size-24 text-secondary"
                      id="Imagetooltip"
                    />
                    <UncontrolledTooltip placement="top" target="Imagetooltip">
                      Media
                    </UncontrolledTooltip>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    className="d-none"
                    onChange={handleFileUpload}
                    disabled={activeConversation.length === 0}
                  />
                </li>
                <li className="list-inline-item me-2">
                  <label htmlFor="emoji" className="mb-0">
                    <UncontrolledTooltip placement="top" target="emoji">
                      Emoji
                    </UncontrolledTooltip>
                    <i
                      className="mdi mdi-emoticon-happy-outline font-size-24 text-secondary"
                      onClick={toggleEmojiPicker}
                      id="emoji"
                    />
                  </label>
                </li>
                <li className="list-inline-item">
                  <label htmlFor="" className="mb-0">
                    <UncontrolledTooltip placement="top" target="cannedMessages">
                      Canned Messages
                    </UncontrolledTooltip>
                    <i
                      id="cannedMessages"
                      className="ms-3 mdi mdi-chat-outline font-size-24 text-secondary"
                      onClick={() => setShowQuickMessages((prevState) => !prevState)}
                    />
                  </label>
                </li>
              </ul>
              {showQuickMessages ? (
                <CustomListBox
                  value={{}}
                  onChange={(e) => onChangeCannedMessage(e)}
                  options={quickMessages}
                  optionLabel="name"
                  itemTemplate={renderItem}
                  className="quick-message-listbox"
                />
              ) : null}
              {showEmojiPicker && (
                <div
                  className="emoji-picker position-absolute bottom-100 start-0"
                  ref={emojiPickerRef}
                >
                  <EmojiPicker onEmojiClick={addEmoji} />
                </div>
              )}
            </div>
            <div className="d-flex">
              <Button
                type="button"
                color="primary"
                onClick={handleSubmit}
                className="btn-rounded"
                disabled={
                  !curMessage
                  || (activeTabMenu !== NOTES_TAB_INDEX
                    && userContext?.userData?.id !== activeConversation?.assignedUserId)
                }
              >
                <span className="d-none d-md-inline-block me-2">
                  {activeTabMenu === NOTES_TAB_INDEX ? 'Add' : 'Send'}
                </span>
                <i
                  className={
                    activeTabMenu === NOTES_TAB_INDEX ? 'mdi mdi-plus-circle' : 'mdi mdi-send'
                  }
                />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

ChatInput.propTypes = {
  onSetConversationMessages: PropTypes.func,
  scrollToBottom: PropTypes.func,
  activeConversation: PropTypes.array,
  showContactInfo: PropTypes.bool,
  targetEvent: PropTypes.bool,
  setActiveConversation: PropTypes.func,
  onSetConversationList: PropTypes.func,
};

export default ChatInput;
