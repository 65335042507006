import React from 'react';
import { Chip as PrimeChip } from 'primereact/chip';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledChip = styled(PrimeChip)`
  // Add any custom styles here
`;

function Chip({
  label,
  icon,
  image,
  removable = false,
  onRemove,
  className,
  style,
  ...props
}) {
  return (
    <StyledChip
      label={label}
      icon={icon}
      image={image}
      removable={removable}
      onRemove={onRemove}
      className={className}
      style={style}
      {...props}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  image: PropTypes.string,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Chip;
