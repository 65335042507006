/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import {
  Container, Row, Col, Card, CardBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { axiosGet, axiosPost } from '../../../../services/http.service';
import { setLoader, setOnboardingData as setOnboardingDataAction } from '../../../../store/actions';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../../constants/onboarding.constant';
import { handleOnboardingStepCompletion } from '../../../../utils/onboarding';
import { TIME_ZONE_OPTIONS } from '../../../../constants/datetime.constant';
import { COPILIOT } from '../../../../constants/constants';
import { PHONE_NUMBERS_TYPE } from '../../../../constants/voice/phoneNumber';

class BulkMessage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: [],
      senderGroups: [],
      contactGroups: [],
      smsTemplates: [],
      selectedTimeZone: '',
    };
  }

  componentDidMount() {
    this.handleGetAllContactGroups();
    this.handleGetAllSenderGroups();
    this.handleGetAllSmsTemplates();

    const selectedTimeZone = TIME_ZONE_OPTIONS.filter((timezone) => timezone?.value === timezone?.countryCode);
    this.setState({ selectedTimeZone: selectedTimeZone[0] });
  }

  handleMulti = (selectedTemplate, setFieldValue) => {
    this.setState({ selectedTemplate });
    setFieldValue('smsTemplate', selectedTemplate);
  };

  handleTimeZone = (selectedTimeZone, setFieldValue) => {
    this.setState({ selectedTimeZone });
    setFieldValue('timezone', selectedTimeZone?.value);
  };

  handleGetAllSenderGroups = async () => {
    try {
      const response = await axiosGet('sender-groups');
      if (response?.status) {
        this.setState({ senderGroups: response?.data?.results });
      }
    } catch (error) {
      console.error('error at handleGetAllSenderGroups :', error);
    }
  };

  handleGetAllContactGroups = async () => {
    try {
      const response = await axiosGet('contact-groups/all');
      if (response?.status) {
        this.setState({ contactGroups: response?.data });
      }
    } catch (error) {
      console.error('error at handleGetAllContactGroups :', error);
    }
  };

  handleGetAllSmsTemplates = async () => {
    try {
      const response = await axiosGet('message-template/all');
      if (response?.status) {
        const formattedOptions = response?.data?.map((option) => ({
          value: option.id,
          label: option.name,
        }));
        this.setState({ smsTemplates: formattedOptions });
      }
    } catch (error) {
      console.error('error at handleGetAllSmsTemplates :', error);
    }
  };

  handleBulkMessage = async (messageData, formik) => {
    const {
      onSetLoader, isOnboarding, history, setOnboardingData,
    } = this.props;
    const { userData } = this.context;
    const organizationId = userData?.organizationId;
    try {
      onSetLoader(true);
      const response = await axiosPost('campaign/bulk-sms', messageData);
      if (response?.status) {
        onSetLoader(false);
        toast.success(response?.message || 'Campaign created successfully!');
        formik.resetForm();
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.BROADCAST_MESSAGE,
            organizationId,
            history,
            setOnboardingData,
          });
        }
        this.setState({ selectedTemplate: [], selectedTimeZone: '' });
      } else {
        onSetLoader(false);
        toast.error(response?.message || 'Oops! something went wrong');
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleBulkMessage :', error);
    }
  };

  handleOnSubmit = (values, formik) => {
    const { selectedTemplate } = this.state;
    const selectedTemplateIds = selectedTemplate?.map(
      (template) => template?.value,
    );
    const smsCampaignData = {
      name: values?.campaignTitle,
      senderIdType: values?.senderType,
      contactGroupId: values?.group,
      templateIds: selectedTemplateIds,
      dateTime: values?.dateTime,
      timezone: values?.timezone,
    };
    if (values?.senderId === COPILIOT) {
      smsCampaignData.usingCopilot = true;
    } else {
      smsCampaignData.usingCopilot = false;
      smsCampaignData.senderGroupId = values?.senderId;
    }
    this.handleBulkMessage(smsCampaignData, formik);
  };

  render() {
    // meta title
    document.title = 'message';

    const {
      senderGroups, contactGroups, smsTemplates, selectedCountryCode, selectedTemplate,
    } = this.state;

    const initialValues = {
      campaignTitle: '',
      senderType: '',
      senderId: '',
      group: '',
      smsTemplate: '',
      dateTime: '',
      timezone: '',
    };

    const validationSchema = Yup.object().shape({
      campaignTitle: Yup.string().required(
        'Please mention the title for campaign',
      ),
      senderType: Yup.string().required('Please select sender type'),
      senderId: Yup.string().required('Please select sender id'),
      group: Yup.string().required('Please select a group'),
      smsTemplate: Yup.array().required('Please select a template'),
      dateTime: Yup.string().required('Please provide schedule date and time'),
      timezone: Yup.string().required('Please select a timezone'),
    });

    return (
      <div className="">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={this.handleOnSubmit}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Campaign Title
                              </Label>
                              <Field
                                name="campaignTitle"
                                type="text"
                                placeholder="Enter the campaign title..."
                                className={
                                    `form-control${
                                      errors.campaignTitle
                                    && touched.campaignTitle
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              />
                              <ErrorMessage
                                name="campaignTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Sender Number
                              </Label>
                              <Field
                                as="select"
                                name="senderType"
                                className={
                                    `form-control-select form-select-border-bottom${
                                      errors.senderType && touched.senderType
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              >
                                <option value="">Select type</option>
                                {Object.keys(PHONE_NUMBERS_TYPE).map((type) => (
                                  <option key={type} value={type}>
                                    {PHONE_NUMBERS_TYPE[type]}
                                  </option>
                                ))}
                                {' '}
                              </Field>
                              <ErrorMessage
                                name="senderType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Sender ID</Label>
                              <Field
                                as="select"
                                name="senderId"
                                className={
                                    `form-control-select form-select-border-bottom${
                                      errors.senderId && touched.senderId
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              >
                                <option value="">Select a sender</option>
                                {senderGroups?.map((group, index) => (
                                  <option
                                    value={group?.id}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${index}-${group?.name}`}
                                  >
                                    {group?.name}
                                  </option>
                                ))}
                                <option value="copilot">co-pilot</option>
                              </Field>
                              <ErrorMessage
                                name="senderId"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Group</Label>
                              <Field
                                as="select"
                                name="group"
                                className={
                                    `form-control-select form-select-border-bottom${
                                      errors.group && touched.group
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              >
                                <option value="">Select a group</option>
                                {contactGroups?.map((group, index) => (
                                  <option
                                    value={group?.id}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${index}-${group?.name}`}
                                  >
                                    {group?.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="group"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                SMS Template
                              </Label>
                              <Select
                                value={selectedTemplate}
                                isMulti
                                onChange={(selectedValue) => this.handleMulti(
                                  selectedValue,
                                  setFieldValue,
                                )}
                                options={smsTemplates}
                                classNamePrefix="select2-selection"
                                className={
                                    `${
                                      errors.smsTemplate && touched.smsTemplate
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                                noOptionsMessage={() => 'No templates available'}
                              />
                              <ErrorMessage
                                name="smsTemplate"
                                component="div"
                                className="invalid-feedback"
                              />
                              {' '}
                            </div>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="yourDatetimeField"
                              >
                                Date & Time
                              </Label>
                              <Field
                                type="datetime-local"
                                id="yourDatetimeField"
                                name="dateTime"
                                className={
                                    `form-control-select width-21rem${
                                      errors.dateTime && touched.dateTime
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              />
                              <ErrorMessage
                                name="dateTime"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Timezone</Label>

                              <Select
                                value={selectedCountryCode}
                                onChange={(selectedValue) => {
                                  this.handleTimeZone(
                                    selectedValue,
                                    setFieldValue,
                                  );
                                }}
                                options={TIME_ZONE_OPTIONS}
                                classNamePrefix="select2-selection"
                                className={
                                    `form-control-select form-select-border-bottom select-border-bottom-none p-0${
                                      errors.timezone && touched.timezone
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              />
                              <ErrorMessage
                                name="timezone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Send Now
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

BulkMessage.propTypes = {
  onSetLoader: PropTypes.func,
  isOnboarding: PropTypes.bool,
  history: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isOnboarding: state.onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkMessage));
