import axios from 'axios';

export const callService = async (phoneNumber) => {
  try {
    const response = await axios.post('/api/call', { phoneNumber });
    return response.data;
  } catch (error) {
    throw new Error('Call service failed');
  }
};

export const clearService = async () => {
  try {
    const response = await axios.post('/api/clear');
    return response.data;
  } catch (error) {
    throw new Error('Clear service failed');
  }
};
