import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PageLoader from '../Common/PageLoader';
import { changeSidebarTheme as changeSidebarThemeAction } from '../../store/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

function Layout({
  isPreloader,
  leftSideBarTheme,
  leftSideBarType,
  children,
}) {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.Dashboard.showLoader);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    const handleResize = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (leftSideBarTheme) {
      dispatch(changeSidebarThemeAction(leftSideBarTheme));
    }
  }, [isPreloader, leftSideBarTheme, dispatch]);

  const toggleMenuCallback = () => {
    const { body } = document;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  };

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        {showLoader && <PageLoader />}
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </>
  );
}

Layout.propTypes = {
  isPreloader: PropTypes.bool,
  leftSideBarTheme: PropTypes.string,
  leftSideBarType: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
