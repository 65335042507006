import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`;

const TabItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;

  ${({ active }) => active
    && `
    border-bottom: 2px solid #007bff;
    color: #007bff;
  `}

  &:hover {
    background-color: #f8f9fa;
  }

  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
`;

function TabMenu({
  model,
  activeIndex,
  onTabChange,
  displayLabel,
  displayIcon,
  className,
  itemClassName,
}) {
  return (
    <TabMenuContainer className={className}>
      {model.map((item, index) => (
        <TabItem
          className={itemClassName}
          key={`${item?.label}-${index + 1}`}
          active={index === activeIndex}
          onClick={() => onTabChange(index)}
        >
          {displayIcon && item.icon ? (
            <IconWrapper>
              <i className={item.icon} />
            </IconWrapper>
          ) : (
            ''
          )}
          {displayLabel ? item.label : ''}
        </TabItem>
      ))}
    </TabMenuContainer>
  );
}

TabMenu.propTypes = {
  model: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ).isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  activeIndex: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  displayLabel: PropTypes.bool.isRequired,
  displayIcon: PropTypes.bool.isRequired,
};

export default TabMenu;
