import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { styled } from 'styled-components';

const StyledBadgedAvatar = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledAvatar = styled(Avatar)`
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text);
  font-size: 1rem;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -4px;
  left: -11px;
  background-color: transparent;
`;

function BadgedAvatar({
  icon = 'pi pi-user',
  image,
  size = 'large',
  shape = 'circle',
  badgeContent,
  badgeClassName = '',
  avatarClassName = '',
  className = '',
  avatarContent,
  backgroundColor = 'var(--badge-primary)',
}) {
  return (
    <StyledBadgedAvatar className={className}>
      <StyledAvatar
        icon={icon}
        image={image}
        size={size}
        shape={shape}
        style={{ backgroundColor }}
        className={`p-overlay-badge ${avatarClassName}`}
      >
        {avatarContent}
      </StyledAvatar>
      {badgeContent && <StyledBadge value={badgeContent} className={badgeClassName} />}
    </StyledBadgedAvatar>
  );
}

BadgedAvatar.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large', 'xlarge']),
  shape: PropTypes.oneOf(['square', 'circle']),
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeClassName: PropTypes.string,
  avatarClassName: PropTypes.string,
  className: PropTypes.string,
  avatarContent: PropTypes.node,
  backgroundColor: PropTypes.string,
};

export default BadgedAvatar;
