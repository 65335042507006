/* eslint-disable import/prefer-default-export */

import {
  axiosGet, axiosPost, axiosDel, axiosPatch,
} from '../http.service';

// Function to update onboarding data
export const updateContact = async (contactId, updateData) => {
  try {
    const response = await axiosPatch(`contacts/${contactId}`, updateData);
    return response;
  } catch (error) {
    console.error('Error updating onboarding data:', error);
    throw error;
  }
};

export const getContactsFields = async () => {
  try {
    const response = await axiosGet('contacts/field-options');
    return response;
  } catch (error) {
    console.error('Error in getContactsFields:', error);
    throw error;
  }
};

export const getContacts = async (page, limit, searchQuery = '') => {
  try {
    const response = await axiosGet(
      `contacts?page=${page}&limit=${limit}${searchQuery ? `&q=${searchQuery}` : ''
      }&sortBy=createdAt:desc`,
    );
    return response;
  } catch (error) {
    console.error('Error in getContacts:', error);
    throw error;
  }
};

export const filterContacts = async (page, limit, filterData) => {
  try {
    const response = await axiosPost(`contacts/filter?page=${page}&limit=${limit}`, filterData);
    return response;
  } catch (error) {
    console.error('Error in filterContacts:', error);
    throw error;
  }
};

export const deleteContact = async (id) => {
  try {
    const response = await axiosDel(`contacts/${id}`);
    return response;
  } catch (error) {
    console.error('Error in deleteContact:', error);
    throw error;
  }
};

export const deleteNote = async (contactId, noteId) => {
  try {
    const response = await axiosDel(`/contacts/${contactId}/notes/${noteId}`);
    return response;
  } catch (error) {
    console.error('Error in deleteContactNote:', error);
    throw error;
  }
};

export const updateDndStatus = async (data) => {
  try {
    const response = await axiosPatch('contacts/update-dnd-status', data);
    return response;
  } catch (error) {
    console.error('Error in blockUnblockContact:', error);
    throw error;
  }
};

export const createContact = async (contactData) => {
  try {
    const response = await axiosPost('contact', contactData);
    return response;
  } catch (error) {
    console.error('Error in createContact:', error);
    throw error;
  }
};

export const getContactById = async (id) => {
  try {
    const response = await axiosGet(`contacts/${id}`);
    return response;
  } catch (error) {
    console.error('Error in getContactById:', error);
    throw error;
  }
};

export const getContactByPhoneNumberString = async (contactNumber) => {
  try {
    const response = await axiosGet(`/contacts/${contactNumber}`);
    if (response?.status) {
      return response;
    }
    throw new Error(response?.message || 'Failed to get contact!');
  } catch (error) {
    console.error('Error in getContactByPhoneNumberString:', error);
    throw error;
  }
};
