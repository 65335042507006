const PHONE_NUMBER_REGEX = /\+/g;

const parseNotificationData = (data) => ({
  to: data.to?.replace(PHONE_NUMBER_REGEX, ''),
  from: data.from?.replace(PHONE_NUMBER_REGEX, ''),
  message: data.message,
  channel: data.channel,
  userId: data.userId,
  timestamp: data.timestamp,
  mediaType: data.conversationObj?.lastMessageType,
  name: data.conversationObj?.name,
});
module.exports = parseNotificationData;
