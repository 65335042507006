import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCountryFlag from 'react-country-flag';
import { getFormattedPhoneNumber } from '../../helpers/commonHelpers';
import { handleError } from '../../services/errorHandler';

const PhoneNumberContainer = styled.div`
  display: flex;
  align-items: center;
  .flag-icon {
    margin-right: 6px;
  }
  .phone-number {
    font-size: 14px;
    line-height: 1.5;
  }
`;

const DEFAULT_COUNTRY_CODE = 'US';

function PhoneNumberWithFlag({ phoneNumber, className }) {
  const renderPhoneNumber = () => {
    try {
      const { parsedNumber } = getFormattedPhoneNumber(phoneNumber);

      if (!parsedNumber) {
        return <span>{phoneNumber}</span>;
      }

      const formattedNumber = parsedNumber.formatInternational();
      const countryCode = parsedNumber.country || DEFAULT_COUNTRY_CODE;

      return (
        <PhoneNumberContainer>
          <ReactCountryFlag className="flag-icon" countryCode={countryCode} />
          <span className={className}>{formattedNumber}</span>
        </PhoneNumberContainer>
      );
    } catch (error) {
      handleError(error);
      return <span>{phoneNumber}</span>;
    }
  };

  return renderPhoneNumber();
}

PhoneNumberWithFlag.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PhoneNumberWithFlag;
