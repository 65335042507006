import React from 'react';
import PropTypes from 'prop-types';
import { Column as PrimeColumn } from 'primereact/column';
import styled from 'styled-components';

const StyledColumn = styled(PrimeColumn)`
  && {
    .p-column-title,
    .p-column-header-content {
      font-size: 9px;
    }
    
    td {
      font-size: 9px;
    }
  }
`;

function Column({
  field,
  header,
  sortable = false,
  body = null,
  className = '',
  style = {},
  filter,
  filterField,
  filterApply,
  filterElement,
  filterClear,
  filterPlaceholder,
  selectionMode,
}) {
  return (
    <StyledColumn
      field={field}
      header={header}
      sortable={sortable}
      body={body}
      className={className}
      style={style}
      filter={filter}
      filterField={filterField}
      filterElement={filterElement}
      filterApply={filterApply}
      filterClear={filterClear}
      filterPlaceholder={filterPlaceholder}
      selectionMode={selectionMode}
      align="center"
    />
  );
}

Column.propTypes = {
  field: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  sortable: PropTypes.bool,
  body: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  filter: PropTypes.object,
  filterField: PropTypes.string,
  filterElement: PropTypes.object,
  filterClear: PropTypes.object,
  filterApply: PropTypes.object,
  filterPlaceholder: PropTypes.string,
  selectionMode: PropTypes.string,
};

export default Column;
