import {
  ADD_NOTIFICATION,
  INITIALIZE_SOCKET,
  LATEST_NOTIFICATION,
  SELECTED_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_ALERT,
} from './actionTypes';

const initialState = {
  notifications: [],
  latestNotification: {},
  selectedNotification: '',
  initializeSocket: false,
  alerts: [],
  totalResults: 0,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const {
        to,
        from,
        message,
        timestamp,
        mediaType,
        name,
      } = action.payload;

      let notificationFound = false;
      const updatedNotifications = state.notifications.map((notificationObj) => {
        if (notificationObj?.to === to && notificationObj?.from === from) {
          notificationFound = true;
          return {
            ...notificationObj,
            totalCount: notificationObj.totalCount + 1,
            message,
            timestamp,
            mediaType,
            name,
          };
        }
        return notificationObj;
      })
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()); // This is make latest message notification to top

      if (!notificationFound) {
        return {
          ...state,
          notifications: [...state.notifications, { totalCount: 1, ...action.payload }],
        };
      }
      return {
        ...state,
        notifications: updatedNotifications,
      };
    }
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: [...action.payload],
      };
    case LATEST_NOTIFICATION:
      return {
        ...state,
        latestNotification: action.payload,
      };
    case SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: action.payload,
      };
    case INITIALIZE_SOCKET:
      return {
        ...state,
        initializeSocket: !state.initializeSocket,
      };
    case UPDATE_ALERT:
      return {
        ...state,
        alerts: state.alerts.map((alert) => (alert.id === action.payload.id ? { ...alert, ...action.payload } : alert)),
        totalResults: state.alerts.reduce((count, alert) => (!alert.isRead ? count + 1 : count), 0),
      };
    default:
      return state;
  }
};

export default notification;
