import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button';
import Card from '../Card';
import Image from '../Image';
import Checkbox from '../Checkbox';
import { FILE_TYPES } from '../../constants/file.constant';
import { WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS, WHATSAPP_TEMPLATES_PARAMETER_TYPES } from '../../constants/channels/whatsApp.constant';

const TemplateContainer = styled.div`
  max-width: 300px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledCard = styled(Card)`
  position: relative;
  z-index: 1;
  border-radius: 0.5rem;
  box-shadow: ${(props) => (props?.checked ? '0.1rem 0.1rem 0.4rem var(--bs-gray-600)' : '')};

  .p-card-body {
    padding: 0.6rem;
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  color: var(--blue-500);
  border: none;
  border-top: 1px solid var(--bs-border-color);
  border-radius: 0;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  
  &:hover, &:active {
    color: var(--blue-700) !important;
    background-color: transparent !important;
    border-top: 1px solid var(--bs-border-color) !important;
  }

  i {
    font-size: 1rem;
  }

  span {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Body = styled.div`
  margin-bottom: 16px;
`;

const Footer = styled.div`
  font-size: 0.9em;
  color: #666;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin-bottom: 0.4rem;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 8rem;
  object-fit: contain;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 8rem;
  object-fit: contain;
`;

const DocumentPreview = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 0.4rem;
`;

const PDFIcon = styled.i`
  font-size: 1.6rem;
  margin-right: 10px;
  color: #ff0000;
`;

const DocumentName = styled.span`
  font-size: 1rem;
  color: #333;
`;

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  z-index: 2;
  left: -1.4rem;
  top: 0rem;
`;

function WhatsAppTemplate({
  templateViewObj,
  checked,
  onTemplateSelect,
  checkboxId,
  onlyPreview,
  ...rest
}) {
  const renderHeader = (header) => {
    const { IMAGE, VIDEO, DOCUMENT } = FILE_TYPES;

    switch (header.type) {
      case IMAGE:
        return (
          <ImageContainer>
            <StyledImage src={header.url} alt="Header Image" preview />
          </ImageContainer>
        );
      case VIDEO:
        return (
          <StyledVideo controls src={header.url}>
            Your browser does not support the video tag.
          </StyledVideo>
        );
      case DOCUMENT:
        return (
          <DocumentPreview>
            <PDFIcon className="mdi mdi-file-pdf" />
            <DocumentName>
              {header.filename || 'Document.pdf'}
            </DocumentName>
          </DocumentPreview>
        );
      default:
        return header?.text ? <Header>{header?.text}</Header> : null;
    }
  };

  const renderButtons = (buttons) => {
    const { QUICK_REPLY, URL, COPY_CODE } = WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS;

    let buttonLimitExists = false;

    return buttons?.map((button, index) => {
      if (buttonLimitExists) return '';

      if (buttons?.length > 3 && index > 1) {
        buttonLimitExists = true;
        return (
          <StyledButton
            key={button?.text}
            label="See all options"
            icon="mdi mdi-format-list-bulleted"
            iconLeft
          />
        );
      }

      switch (button.type) {
        case URL.toLowerCase(): {
          return (
            <StyledButton
              key={button?.text}
              label={button?.text}
              icon="bx bx-link-external"
              onClick={() => window.open(button.url, '_blank')}
              iconLeft
            />
          );
        }
        case COPY_CODE.toLowerCase(): {
          return (
            <StyledButton
              key={button?.text}
              label={button?.text}
              icon="mdi mdi-content-copy"
              onClick={() => navigator.clipboard.writeText(button.code)}
              iconLeft
            />
          );
        }
        case QUICK_REPLY.toLowerCase(): {
          return (
            <StyledButton
              key={button?.text}
              label={button?.text}
              icon="bx bxs-share"
              iconLeft
            />
          );
        }
        default:
          return null;
      }
    });
  };

  const {
    header, body, footer, buttons,
  } = templateViewObj;

  return (
    <TemplateContainer {...rest}>
      <StyledCard checked={checked}>
        {!onlyPreview && (
          <StyledCheckbox
            id={checkboxId}
            checked={checked}
            onChange={(check) => onTemplateSelect(check)}
          />
        )}
        {header && renderHeader(header)}
        {body && <Body>{body.text}</Body>}
        {footer && <Footer>{footer.text}</Footer>}
        <ButtonContainer>{renderButtons(buttons)}</ButtonContainer>
      </StyledCard>
    </TemplateContainer>
  );
}

WhatsAppTemplate.propTypes = {
  templateViewObj: PropTypes.shape({
    header: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(WHATSAPP_TEMPLATES_PARAMETER_TYPES).map((val) => val.toLowerCase())),
      url: PropTypes.string,
      text: PropTypes.string,
    }),
    body: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
    footer: PropTypes.shape({
      text: PropTypes.string,
    }),
    buttons: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(Object.values(WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS).map((type) => type.toLowerCase())).isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      code: PropTypes.string,
    })),
  }).isRequired,
  onTemplateSelect: PropTypes.func,
  checked: PropTypes.bool,
  checkboxId: PropTypes.string,
  onlyPreview: PropTypes.bool,
};

WhatsAppTemplate.defaultProps = {
  checked: false,
};

export default WhatsAppTemplate;
