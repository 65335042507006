/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Button,
  Progress,
} from 'reactstrap';
import { connect } from 'react-redux';
import { UserContext } from '../../UserProvider/UserProvider';
import { STEP_DETAILS, STEPS_NAME } from '../../../constants/onboarding.constant';
import { CHANNEL_CONNECT_STATUS } from '../../../constants/channels/channels.constant';
import { setOnboardingData as setOnboardingDataAction, setOnboardingStatus as setOnboardingStatusAction } from '../../../store/actions';
import { updateOnboarding } from '../../../services/api/onboarding.service';
import { getPrivateCredentials } from '../../../services/api/organization.service';
import { getCurrentDay } from '../../../utils/date';
import DemoCard from './DemoCard';
import { getChannels } from '../../../utils/channels';

class Onboarding extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      openStep: '0',
      completed: false,
      isWhatsAppConnected: false,
      isTwilioConnected: false,
    };
  }

  componentDidMount() {
    this.fetchOnboardingData();
    this.fetchPrivateCredentials();
  }

  componentDidUpdate(prevProps) {
    const { onboardingData } = this.props;
    if (prevProps.onboardingData !== onboardingData) {
      this.updateOnboardingState();
    }
  }

  fetchPrivateCredentials = async () => {
    try {
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const response = await getPrivateCredentials(organizationId);
      if (response?.status) {
        const { twilio, whatsapp } = getChannels(response?.data);
        const isWhatsAppConnected = twilio?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
        const isTwilioConnected = whatsapp?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
        this.setState({ isWhatsAppConnected, isTwilioConnected });
      } else {
        toast.error(response?.message || 'Cannot get private credentials');
      }
    } catch (error) {
      console.error('Error fetching private credentials:', error);
    }
  };

  fetchOnboardingData = async () => {
    const { onboardingData } = this.props;
    if (onboardingData) {
      this.updateOnboardingState();
    }
  };

  updateOnboardingState = () => {
    const { onboardingData, setOnboardingStatus } = this.props;
    const { isCompleted } = onboardingData;
    if (isCompleted) {
      this.setState({ completed: true });
      setOnboardingStatus(false);
    }
    this.setState({ steps: onboardingData.steps }, this.setDefaultOpenStep);
  };

  setDefaultOpenStep = () => {
    const { steps } = this.state;
    const firstIncompleteStepIndex = steps.findIndex((step) => !step.completed);
    this.setState({ openStep: firstIncompleteStepIndex !== -1 ? String(firstIncompleteStepIndex) : '-1' });
  };

  updateOnboardingData = async (updateData) => {
    try {
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const response = await updateOnboarding(organizationId, updateData);
      return response;
    } catch (error) {
      console.error('Error updating onboarding data:', error);
      return null;
    }
  };

  toggleAccordion = (index) => {
    const { openStep } = this.state;
    this.setState({ openStep: openStep === index ? '-1' : index });
  };

  calculateProgress = () => {
    const { steps } = this.state;
    const completedSteps = steps.filter((step) => step.completed).length;
    return (completedSteps / steps.length) * 100;
  };

  handleSkip = async () => {
    const { setOnboardingStatus } = this.props;
    const { setOnboardingData } = this.props;
    const response = await this.updateOnboardingData({ isSkipped: true });
    if (response?.status) {
      setOnboardingData(response?.data);
      setOnboardingStatus(false);
      toast.info('Onboarding Skipped!');
    }
  };

  render() {
    const {
      steps, openStep, completed, isWhatsAppConnected, isTwilioConnected,
    } = this.state;
    const { userData } = this.context;

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex flex-column align-items-center">
            <Col lg="9">
              <h3 className="fw-bold">
                Hi
                {' '}
                {userData?.name}
                ,
                {' '}
                <span className="text-primary">
                  Happy
                  {' '}
                  {getCurrentDay()}
                </span>
              </h3>
              {completed ? (
                <div className="congrats-section text-center my-5">
                  <i className="bx bxs-trophy fs-1 text-warning mb-3" />
                  <h4 className="text-success">Congratulations!</h4>
                  <p className="text-muted">
                    You&apos;ve successfully completed the onboarding process. You&apos;re all set to start using Auto Campaign AI to its full potential!
                  </p>
                  <Link to="/" className="btn btn-primary">
                    Go to Dashboard
                  </Link>
                </div>
              ) : (
                <>
                  <p className="text-muted">Welcome aboard! Let&apos;s get you set up with everything you need to start using Auto Campaign AI effectively.</p>
                  <DemoCard />
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>
                      Get going in
                      {' '}
                      {steps.length}
                      {' '}
                      steps
                    </h5>
                    <span className="text-muted font-size-12 fw-semibold">
                      {steps.filter((step) => step.completed).length}
                      {' '}
                      out of
                      {' '}
                      {steps.length}
                    </span>
                  </div>
                  <Progress color="success" value={this.calculateProgress()} className="mb-4" style={{ height: '8px' }} />
                  <Accordion open={openStep} toggle={this.toggleAccordion} className="custom-accordion">
                    {steps.map((step, index) => {
                      const details = STEP_DETAILS[step.name];
                      const isPreviousStepCompleted = index === 0 || steps[index - 1].completed;

                      let redirectUrl = details?.redirectUrl;
                      let buttonClass = 'btn-primary';

                      switch (true) {
                        case !isPreviousStepCompleted:
                          buttonClass = 'btn-primary disabled';
                          break;
                        case step.completed:
                          redirectUrl = '#';
                          buttonClass = 'btn-success disabled';
                          break;
                        case step.name === STEPS_NAME.BROADCAST_MESSAGE && isTwilioConnected:
                          redirectUrl = '/send-message';
                          break;
                        case step.name === STEPS_NAME.BROADCAST_MESSAGE && isWhatsAppConnected:
                          redirectUrl = '/send-whatsapp-message';
                          break;
                        default:
                          buttonClass = 'btn-primary';
                      }

                      return (
                        <AccordionItem
                          key={index}
                          className="border border-light-subtle"
                        >
                          <AccordionHeader targetId={String(index)}>
                            <i
                              className={`bx bxs-check-circle fs-4 me-2 ${
                                step.completed ? 'text-success' : 'text-secondary opacity-75'
                              }`}
                            />
                            <span className="lh-sm">
                              {step.name}
                            </span>
                          </AccordionHeader>
                          <AccordionBody accordionId={String(index)} className="px-4">
                            <p>{details.description}</p>
                            <Link
                              to={redirectUrl}
                              className={`btn ${buttonClass}`}
                            >
                              {step.completed ? 'Completed' : details.buttonLabel}
                            </Link>
                          </AccordionBody>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                  <div className="d-flex justify-content-end my-3">
                    <Button className="btn btn-secondary" onClick={this.handleSkip}>
                      Skip
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Onboarding.propTypes = {
  setOnboardingStatus: PropTypes.func.isRequired,
  onboardingData: PropTypes.object,
  setOnboardingData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isOnboarding: state.onboarding.isOnboarding,
  onboardingData: state.onboarding.onboardingData,
});

const mapDispatchToProps = (dispatch) => ({
  setOnboardingStatus: (value) => dispatch(setOnboardingStatusAction(value)),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Onboarding));
