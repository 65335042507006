let toastRef;

export const setToastRef = (ref) => {
  toastRef = ref;
};

export const handleError = (error) => {
  console.error('An error occurred:', error);
  if (toastRef) {
    toastRef.show({
      severity: 'error',
      summary: 'Error',
      detail: error?.response?.data?.message || 'Something went wrong',
      life: 3000,
    });
  }
};

export const SOMETHING_WENT_WRONG = 'Something went wrong';
