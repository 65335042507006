import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label,
} from 'reactstrap';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import UserSelect from '../../../components/Common/UserSelect';
import { renderUserAvatar } from '../../../helpers/commonHelpers';
import { ROLES } from '../../../constants/users/role.constant';

class AddTeam extends Component {
  constructor(props) {
    super(props);

    this.validationSchema = Yup.object({
      name: Yup.string().required('Team name is required'),
      teamManagers: Yup.array()
        .test(
          'all-managers',
          'One or more selected users are not managers. Please ensure all team managers have the manager role or update their roles before assigning them as team managers.',
          (value) => value.every((user) => user.role === ROLES.MANAGER),
        )
        .min(1, 'Select at least one team manager'),
      teamMembers: Yup.array()
        .test('all-staff', 'Please select one or more users having staff role', (value) => value.every((user) => user.role === ROLES.STAFF))
        .min(1, 'Select at least one team member'),
    });
  }

  handleSubmit = async (values, { setSubmitting }) => {
    const { currentTeam, toggle, handleTeamData } = this.props;

    // Prepare the data to send to parent component
    const data = {
      name: values.name,
      description: values.description,
      managerIds: values.teamManagers.map((option) => option.value),
      memberIds: values.teamMembers.map((option) => option.value),
    };

    // Separate payloads for create and edit operations
    const requestPayload = currentTeam
      ? { data, _id: currentTeam._id } // Include id for update
      : data; // No id for create

    try {
      // Pass data to parent component
      await handleTeamData(requestPayload);
    } catch (error) {
      console.error('Error submitting team:', error);
    } finally {
      setSubmitting(false);
      toggle();
    }
  };

  render() {
    const {
      isOpen, toggle, isEditing, currentTeam,
    } = this.props;
    const mapToSelectOption = (user) => ({
      value: user.id,
      label: (renderUserAvatar(user.name)
      ),
      role: user.role,
    });

    const initialValues = isEditing
      ? {
        name: currentTeam?.name || '',
        description: currentTeam?.description || '',
        teamManagers: currentTeam?.managerDetails?.map(mapToSelectOption) || [],
        teamMembers: currentTeam?.memberDetails?.map(mapToSelectOption) || [],
      }
      : {
        name: '',
        description: '',
        teamManagers: [],
        teamMembers: [],
      };

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            setFieldValue, isSubmitting, values, errors, touched, setFieldTouched,
          }) => (
            <Form>
              <ModalHeader toggle={toggle}>{isEditing ? 'Edit Team' : 'Add Team'}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="name">Team Name</Label>
                  <Field
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Enter team name"
                  />
                  <ErrorMessage name="name" component="div" className="text-danger" />
                </FormGroup>

                <FormGroup>
                  <Label for="description">Description</Label>
                  <Field
                    as="textarea"
                    name="description"
                    id="description"
                    className="form-control"
                    placeholder="Enter description"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="teamManagers">Team Managers</Label>
                  <UserSelect
                    onChange={(selectedOptions) => {
                      setFieldValue('teamManagers', selectedOptions);
                      setFieldTouched('teamManagers', true, false);
                    }}
                    selectedOptions={values.teamManagers}
                    className={`${
                      errors.teamManagers && touched.teamManagers ? ' is-invalid' : ''
                    }`}
                    isMulti
                  />
                  <ErrorMessage name="teamManagers" component="div" className="invalid-feedback" />
                </FormGroup>

                <FormGroup>
                  <Label for="teamMembers">Team Members</Label>
                  <UserSelect
                    onChange={(selectedOptions) => {
                      setFieldValue('teamMembers', selectedOptions);
                      setFieldTouched('teamMembers', true, false);
                    }}
                    selectedOptions={values.teamMembers}
                    className={`${
                      errors.teamMembers && touched.teamMembers ? ' is-invalid' : ''
                    }`}
                    isMulti
                  />
                  <ErrorMessage name="teamMembers" component="div" className="invalid-feedback d-block" />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {isEditing ? 'Update Team' : 'Add Team'}
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddTeam.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  isEditing: PropTypes.bool,
  handleTeamData: PropTypes.func,
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    managerIds: PropTypes.arrayOf(PropTypes.object),
    memberIds: PropTypes.arrayOf(PropTypes.object),
    memberDetails: PropTypes.arrayOf(PropTypes.object),
    managerDetails: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default AddTeam;
