import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: absolute;
  top: 6rem;
  width: 350px;
  margin: 1.25rem auto;
  margin-right: 2.5rem;
  border-radius: 0.7rem;
  padding: 0.7rem;
  padding-top: 0.3rem;
  max-height: ${(props) => props.$maxHeight};
  overflow-x: hidden;
  overflow-y: ${(props) => (props.$isScrollable ? 'scroll' : 'hidden')};

  /* Customizing the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, var(--bs-gray-400), var(--bs-gray-500));
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, var(--bs-gray-500), var(--bs-gray-600));
  }

  /* Customizing the scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--bs-gray-400) #f1f1f1;
`;

const Header = styled.div`
  text-align: center;
  color: var(--bluegray-400);
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
  margin-left: ${(props) => (props.$isScrollable ? '2.8rem' : '2.05rem')};
`;

const MessageBubble = styled.div`
  background-color: var(--bg-message-green);
  color: var(--bs-white);
  border-radius: 0.8rem;
  padding: 0.7rem 0.95rem;
  max-width: ${(props) => (props.$isScrollable ? '72.7%' : '70%')};;
  font-size: 1rem;
  line-height: 1.4;
  margin-left: 2rem;
  white-space: pre-wrap;
  display: inline-block;
  width: auto;
`;

function MessagePreview({ message, time, maxHeight = '293px' }) {
  const containerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      // Check if content height exceeds maxHeight
      // eslint-disable-next-line radix
      setIsScrollable(containerRef.current.scrollHeight > parseInt(maxHeight));
    }
  }, [message, maxHeight]);

  return (
    <Container ref={containerRef} $maxHeight={maxHeight} $isScrollable={isScrollable}>
      <Header $isScrollable={isScrollable}>
        {time}
      </Header>
      {
        message
        && (
        <MessageBubble $isScrollable={isScrollable}>
          {message}
        </MessageBubble>
        )
      }
    </Container>
  );
}

MessagePreview.propTypes = {
  maxHeight: PropTypes.string,
  message: PropTypes.string,
  time: PropTypes.string.isRequired,
};

export default MessagePreview;
