import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Input } from 'reactstrap';
import ListGroupItemComponent from '../../../components/ListGroupItem';
import ListGroupComponent from '../../../components/ListGroup/index';
import Badge from '../../../components/Badge/index';
import SimpleBar from '../../../components/SimpleBar/index';
import Button from '../../../components/Button';
import { tabs } from '../../../constants/tabs';
import Input from '../../../components/Input';
import { SUBTLE_COLORS } from '../../../constants/colors.constant';

class PhoneNumberSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNumbers: [],
      selectedNumber: {},
      alias: {},
      editingAlias: null,
    };
  }

  handleSelectionChange = (phoneNumber, checked) => {
    const { activeTab } = this.props;

    if (activeTab === tabs.WHATSAPP) {
      this.setState({
        selectedNumber: {
          number: phoneNumber.display_phone_number,
          id: phoneNumber.id,
          verifiedName: phoneNumber.verified_name,
        },
      }, this.updateParent);
    } else {
      this.setState((prevState) => {
        const selectedNumbers = checked
          ? [...prevState.selectedNumbers, phoneNumber]
          : prevState.selectedNumbers.filter(
            (number) => number.phoneNumber !== phoneNumber.phoneNumber,
          );
        return { selectedNumbers };
      }, this.updateParent);
    }
  };

  handleSetAlias = (phoneNumber) => {
    this.setState({ editingAlias: phoneNumber.display_phone_number || phoneNumber.phoneNumber });
  };

  handleAliasChange = (e, phoneNumber) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      alias: {
        ...prevState.alias,
        [phoneNumber.display_phone_number || phoneNumber.phoneNumber]: value,
      },
    }), this.updateParent);
  };

  handleSaveAlias = () => {
    this.setState({ editingAlias: null }, this.updateParent);
  };

  handleCancelAlias = () => {
    this.setState({ editingAlias: null });
  };

  updateParent = () => {
    const { selectedNumbers, selectedNumber, alias } = this.state;
    const { onChange, activeTab } = this.props;

    if (onChange) {
      if (activeTab === tabs.WHATSAPP) {
        onChange({
          ...selectedNumber,
          alias: alias[selectedNumber.number] || '',
        });
      } else {
        const selectedNumbersWithAlias = selectedNumbers.map((number) => ({
          number: number.phoneNumber,
          alias: alias[number.phoneNumber] || '',
        }));
        onChange(selectedNumbersWithAlias);
      }
    }
  };

  renderCapabilities = (capabilities) => {
    const capabilityColors = {
      SMS: SUBTLE_COLORS[0],
      MMS: SUBTLE_COLORS[1],
      voice: SUBTLE_COLORS[4],
    };

    return (
      <>
        {capabilities?.SMS && (
          <Badge
            className={`me-1 ${capabilityColors.SMS.bg} ${capabilityColors.SMS.text}`}
            pill
          >
            SMS
          </Badge>
        )}
        {capabilities?.MMS && (
          <Badge
            className={`me-1 ${capabilityColors.MMS.bg} ${capabilityColors.MMS.text}`}
            pill
          >
            MMS
          </Badge>
        )}
        {capabilities?.voice && (
          <Badge
            className={`me-1 ${capabilityColors.voice.bg} ${capabilityColors.voice.text}`}
            pill
          >
            Voice
          </Badge>
        )}
      </>
    );
  };

  renderItem = (phoneNumber) => {
    const {
      alias, editingAlias, selectedNumbers, selectedNumber,
    } = this.state;
    const { selectedNumberType, activeTab } = this.props;
    const isChecked = activeTab === tabs.WHATSAPP
      ? selectedNumber?.number === phoneNumber.display_phone_number
      : selectedNumbers.some((number) => number.phoneNumber === phoneNumber.phoneNumber);
    const aliasName = alias[phoneNumber.display_phone_number || phoneNumber.phoneNumber];
    const phoneNumberDisplay = phoneNumber.display_phone_number || phoneNumber.friendlyName;

    return (
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div>
          <Input
            type={activeTab === tabs.WHATSAPP ? 'radio' : 'checkbox'}
            checked={isChecked}
            onChange={(e) => this.handleSelectionChange(phoneNumber, e.target.checked)}
            className="me-2"
            name={activeTab === tabs.WHATSAPP ? 'selectedNumber' : 'selectedNumbers'}
          />
        </div>
        <div className="d-flex align-items-end">
          <i className="bx bx-phone me-2 align-content-end text-muted" />
          <div className="d-flex flex-column">
            {aliasName && (
              <div className="font-size-14">{aliasName}</div>
            )}
            <div className="text-muted small">{phoneNumberDisplay}</div>
          </div>
        </div>

        <div>
          {activeTab !== tabs.WHATSAPP && this.renderCapabilities(phoneNumber.capabilities)}
        </div>
        <div>
          <Badge color="info" className="ms-2" pill>{selectedNumberType}</Badge>
        </div>
        <div>
          {editingAlias === (phoneNumber.display_phone_number || phoneNumber.phoneNumber) ? (
            <>
              <input
                type="text"
                value={aliasName || ''}
                onChange={(e) => this.handleAliasChange(e, phoneNumber)}
                placeholder="Set Alias"
                className="form-control me-2"
              />
              <div className="text-end">
                <i
                  className="mdi mdi-check text-success font-size-20 me-2"
                  onClick={this.handleSaveAlias}
                />
                <i
                  className="mdi mdi-close text-danger font-size-20"
                  onClick={this.handleCancelAlias}
                />
              </div>
            </>
          ) : (
            <Button
              color="link"
              onClick={() => this.handleSetAlias(phoneNumber)}
              label="Set Alias"
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { availableNumbers } = this.props;
    return (
      <div>
        <div className="mb-3">
          <SimpleBar autoHide={false}>
            <ListGroupComponent>
              {availableNumbers?.map((number, index) => (
                <ListGroupItemComponent
                  key={number.display_phone_number || number.phoneNumber}
                  item={number}
                  index={index}
                  renderItem={this.renderItem}
                />
              ))}
            </ListGroupComponent>
          </SimpleBar>
        </div>
      </div>
    );
  }
}

PhoneNumberSelector.propTypes = {
  availableNumbers: PropTypes.array.isRequired,
  selectedNumberType: PropTypes.string,
  onChange: PropTypes.func,
  activeTab: PropTypes.string.isRequired,
};

export default PhoneNumberSelector;
