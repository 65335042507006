// actions.js
import {
  ADD_NOTIFICATION,
  INITIALIZE_SOCKET,
  LATEST_NOTIFICATION,
  SELECTED_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_ALERT,
} from './actionTypes';

export const addNotification = (data) => ({
  type: ADD_NOTIFICATION,
  payload: data,
});

export const setLatestConversation = (data) => ({
  type: LATEST_NOTIFICATION,
  payload: data,
});

export const setSelectedNotification = (data) => ({
  type: SELECTED_NOTIFICATION,
  payload: data,
});

export const updateNotification = (data) => ({
  type: UPDATE_NOTIFICATION,
  payload: data,
});

export const toggleInitializeNewSocket = () => ({
  type: INITIALIZE_SOCKET,
});

export const updateAlert = (data) => ({
  type: UPDATE_ALERT,
  payload: data,
});
