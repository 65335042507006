import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Skeleton as PrimeSkeleton } from 'primereact/skeleton';
import { styled } from 'styled-components';

const StyledSkeleton = styled(PrimeSkeleton)`
  &.p-skeleton {
    background-color: #e9ecef;
  }

  &.p-skeleton-circle {
    border-radius: 50%;
  }

  &.p-skeleton-none {
    animation: none;
  }
`;

class Skeleton extends Component {
  render() {
    const {
      shape,
      size,
      width,
      height,
      borderRadius,
      animation,
      className,
      style,
    } = this.props;

    return (
      <StyledSkeleton
        shape={shape}
        size={size}
        width={width}
        height={height}
        borderRadius={borderRadius}
        animation={animation}
        className={className}
        style={style}
      />
    );
  }
}

Skeleton.propTypes = {
  shape: PropTypes.oneOf(['rectangle', 'circle']),
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  animation: PropTypes.oneOf(['wave', 'none']),
  className: PropTypes.string,
  style: PropTypes.object,
};

Skeleton.defaultProps = {
  shape: 'rectangle',
  size: null,
  width: '100%',
  height: '1rem',
  borderRadius: null,
  animation: 'wave',
  className: '',
  style: {},
};

export default Skeleton;
