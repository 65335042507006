import { isArray, isNumber, isString } from 'lodash';
// NOTE: `BMESSAGE`: Broadcast message
import {
  SET_BMESSAGE_STEP_ONE_DATA,
  SET_BMESSAGE_STEP_TWO_DATA,
  SET_BMESSAGE_STEP_THREE_DATA,
  SET_STEP_COMPLETION_BMESSAGE,
  SET_ACTIVE_INDEX_OF_BMESSAGE_FORM,
  RESET_BMESSAGE_FORM,
  SET_BMESSAGE_CONTACT_GROUPS,
} from './actionTypes';
import { PAGE, SIZE_PER_PAGE } from '../../constants/constants';

const INIT_STATE = {
  activeIndex: 0,
  step1: {
    selectedTemplate: {},
    selectedSenderGroup: {},
    usingCopilot: false,
  },
  step2: {
    selectedContactGroup: {},
    contactGroups: [],
    totalSize: 0,
    page: PAGE,
    size: SIZE_PER_PAGE,
    searchValue: '',
  },
  step3: {
    broadcastData: {},
  },
  stepCompletionArray: [
    {
      stepIndex: 0,
      completed: false,
    },
    {
      stepIndex: 1,
      completed: false,
    },
    {
      stepIndex: 2,
      completed: false,
    },
  ],
};

const bulkMessage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_INDEX_OF_BMESSAGE_FORM: {
      const { activeIndex } = action.payload;

      return {
        ...state,
        activeIndex,
      };
    }

    case SET_STEP_COMPLETION_BMESSAGE: {
      const { stepCompletionArray } = action.payload;

      return {
        ...state,
        stepCompletionArray,
      };
    }

    case SET_BMESSAGE_STEP_ONE_DATA: {
      return {
        ...state,
        step1: {
          ...state.step1,
          ...action.payload,
        },
      };
    }

    case SET_BMESSAGE_STEP_TWO_DATA: {
      const { selectedContactGroup } = action.payload;

      return {
        ...state,
        step2: {
          ...state.step2,
          selectedContactGroup,
        },
      };
    }

    case SET_BMESSAGE_CONTACT_GROUPS: {
      const {
        contactGroups,
        totalSize,
        page,
        size,
        searchValue,
      } = action.payload;

      const newData = {};

      if (isArray(contactGroups)) {
        newData.contactGroups = contactGroups;
      }

      if (isNumber(totalSize)) {
        newData.totalSize = totalSize;
      }

      if (isNumber(page)) {
        newData.page = page;
      }

      if (isNumber(size)) {
        newData.size = size;
      }

      if (isString(searchValue)) {
        newData.searchValue = searchValue;
      }

      return {
        ...state,
        step2: {
          ...state.step2,
          ...newData,
        },
      };
    }

    case SET_BMESSAGE_STEP_THREE_DATA: {
      return {
        ...state,
        step3: {
          ...state.step3,
          broadcastData: action.payload,
        },
      };
    }

    case RESET_BMESSAGE_FORM: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default bulkMessage;
