import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Field from '../Field';
import Label from '../Label';

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: 0;
`;

const StyledContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > label {
    flex: 5;
  }

  & > div {
    display: flex;
    flex: 18;
  }

  .field-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .required {
    color: red;
    margin-left: 0.2rem;
  }
`;

const StyledField = styled(Field)`
  input {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
`;

const StyledDiv = styled.div`
`;

function HField({
  label,
  name,
  required,
  labelClassName,
  fieldClassName,
  formikField,
  placeholder,
  children,
  ...rest
}) {
  const renderField = () => {
    if (formikField) {
      return (
        <StyledField
          fieldClassName={fieldClassName}
          id={name}
          name={name}
          placeholder={placeholder}
        />
      );
    }
    return children;
  };

  return (
    <StyledContainer {...rest}>
      <StyledLabel className={labelClassName} htmlFor={name}>
        {label}
        {required && <span className="required">*</span>}
      </StyledLabel>
      <StyledDiv>
        {renderField()}
      </StyledDiv>
    </StyledContainer>
  );
}

HField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formikField: PropTypes.bool,
  children: PropTypes.node,
};

HField.defaultProps = {
  required: false,
  labelClassName: '',
  fieldClassName: '',
  placeholder: '',
  formikField: false,
  children: null,
};

export default HField;
