import React from 'react';
import PropTypes from 'prop-types';
import { ListBox } from 'primereact/listbox';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';

const StyledListBox = styled(ListBox)`
  /* Preserve existing styles */
`;

function ListGroupComponent({ children, flush }) {
  try {
    return (
      <StyledListBox flush={flush}>
        {children}
      </StyledListBox>
    );
  } catch (error) {
    handleError(error);
    return null;
  }
}

ListGroupComponent.propTypes = {
  children: PropTypes.node.isRequired,
  flush: PropTypes.bool,
};

export default ListGroupComponent;
