import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { PAGE } from '../../../../constants/constants';

import { axiosGet } from '../../../../services/http.service';
import { customLoadingIndicator, renderUserAvatar } from '../../../../helpers/commonHelpers';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      page: PAGE,
      totalPages: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadOptions();
  }

  componentDidUpdate(prevProps) {
    const { channel, fetchAllCampaigns } = this.props;
    if (prevProps.channel !== channel || prevProps.fetchAllCampaigns !== fetchAllCampaigns) {
      this.resetAndLoadOptions();
    }
  }

  resetAndLoadOptions = () => {
    this.setState(
      {
        options: [],
        page: PAGE,
        totalPages: null,
      },
      () => this.loadOptions(),
    );
  };

  loadOptions = async () => {
    const { page } = this.state;
    const { channel, fetchAllCampaigns } = this.props;
    this.setState({ isLoading: true });

    try {
      let url = `campaign?page=${page}&limit=10&sortBy=createdAt:desc`;
      if (!fetchAllCampaigns && channel) {
        url += `&channel=${channel}`;
      }

      const response = await axiosGet(url);

      if (response?.status) {
        const newOptions = response.data.results.map((campaign) => ({
          value: campaign.id,
          label: (
            renderUserAvatar(campaign.name)
          ),
        }));
        this.setState((prevState) => ({
          options: [...prevState.options, ...newOptions],
          totalPages: response.data.totalPages,
          page: prevState.page + 1,
          isLoading: false,
        }));
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      this.setState({ isLoading: false });
    }
  };

  handleMenuScrollToBottom = () => {
    const { page, totalPages, isLoading } = this.state;
    if (!isLoading && page <= totalPages) {
      this.loadOptions();
    }
  };

  render() {
    const { options, isLoading } = this.state;
    const { selectedOptions, onChange, className } = this.props;

    return (
      <Select
        className={className}
        value={selectedOptions}
        onChange={onChange}
        options={options}
        isLoading={isLoading}
        loadingMessage="Loading..."
        onMenuScrollToBottom={this.handleMenuScrollToBottom}
        isMulti
        pageSize={10}
        placeholder="Select campaigns..."
        components={{ LoadingIndicator: customLoadingIndicator }}
        noOptionsMessage={() => 'No campaigns available'}
      />
    );
  }
}

MultiSelect.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.string,
  fetchAllCampaigns: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
};

export default MultiSelect;
