import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as PrimeReactCalendar } from 'primereact/calendar';
import styled from 'styled-components';

const StyledCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacer);
`;

const StyledCalendar = styled(PrimeReactCalendar)`
  &.p-calendar {
    width: 100%;
  }

  &.p-calendar .p-inputtext {
    width: 100%;
    background-color: var(--bg-light);
    border-color: var(--border-color);
    border-radius: var(--border-radius);
    font-family: var(--font-family-sans-serif);
    font-size: var(--font-size-base);
    transition: var(--transition-base);

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:hover {
      border-color: var(--primary-color);
    }
  }

  &.p-calendar .p-datepicker {
    font-family: var(--font-family-sans-serif);
  }

  &.p-calendar .p-datepicker-header {
    background-color: var(--bg-primary);
  }

  &.p-calendar .p-datepicker-current-day {
    background-color: var(--primary-color);
  }
`;

const ErrorMessage = styled.span`
  color: var(--danger-color);
  font-size: 0.75rem;
  margin-top: 4px;
`;

function Calendar({
  value,
  onChange,
  placeholder,
  inline,
  dateFormat,
  showIcon,
  showTime,
  timeOnly,
  showSeconds,
  hourFormat,
  minDate,
  maxDate,
  disabled,
  yearRange,
  readOnlyInput,
  numberOfMonths,
  selectionMode,
  locale,
  monthNavigator,
  yearNavigator,
  showButtonBar,
  error,
  className,
  style,
}) {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  const calendarClassName = `
    ${className || ''}
    ${inline ? 'p-calendar-inline' : ''}
  `.trim();

  return (
    <StyledCalendarWrapper>
      <StyledCalendar
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        inline={inline}
        dateFormat={dateFormat}
        showIcon={showIcon}
        showTime={showTime}
        timeOnly={timeOnly}
        showSeconds={showSeconds}
        hourFormat={hourFormat}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        yearRange={yearRange}
        readOnlyInput={readOnlyInput}
        numberOfMonths={numberOfMonths}
        selectionMode={selectionMode}
        locale={locale}
        monthNavigator={monthNavigator}
        yearNavigator={yearNavigator}
        showButtonBar={showButtonBar}
        className={calendarClassName}
        style={style}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledCalendarWrapper>
  );
}

Calendar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  dateFormat: PropTypes.string,
  showIcon: PropTypes.bool,
  showTime: PropTypes.bool,
  timeOnly: PropTypes.bool,
  showSeconds: PropTypes.bool,
  hourFormat: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  yearRange: PropTypes.string,
  readOnlyInput: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  selectionMode: PropTypes.string,
  locale: PropTypes.object,
  monthNavigator: PropTypes.bool,
  yearNavigator: PropTypes.bool,
  showButtonBar: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

Calendar.defaultProps = {
  placeholder: '',
  inline: false,
  dateFormat: 'mm/dd/yy',
  showIcon: false,
  showTime: false,
  timeOnly: false,
  showSeconds: false,
  hourFormat: '24',
  minDate: null,
  maxDate: null,
  disabled: false,
  yearRange: '1900:2100',
  readOnlyInput: false,
  numberOfMonths: 1,
  selectionMode: 'single',
  locale: null,
  monthNavigator: false,
  yearNavigator: false,
  showButtonBar: false,
  value: undefined,
  error: undefined,
  className: '',
  style: undefined,
};

export default Calendar;
