/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Card, CardBody, Col, Container, Label, Row,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { axiosPost } from '../../services/http.service';
import LogoIcon from '../../assets/images/logo-icon.png';

// action
import {
  userForgetPassword,
  userForgetPasswordSuccess,
  userForgetPasswordError,
} from '../../store/actions';

// import images
import profile from '../../assets/images/common/profile-img.png';
import { SOMETHING_WENT_WRONG } from '../../constants/error.constant';

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { forgetError, forgetSuccessMsg } = this.props;
    const { email } = this.state;
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Forgot your password? </h5>
                          <p>No worries! Enter your email address below, and we&#39;ll send you a link to reset your password.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={LogoIcon}
                              alt="auto-campaign-ai-logo-icon"
                              className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {forgetError && forgetError ? (
                        <Alert color="danger" style={{ marginTop: '13px' }}>
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: '13px' }}>
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: (this.state && email) || '',
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            'Please Enter Your Email',
                          ),
                        })}
                        onSubmit={async (values) => {
                          try {
                            const response = await axiosPost(
                              '/auth/forgot-password',
                              { email: values?.email },
                            );
                            if (response?.status) {
                              toast.success(response?.message || 'Success! reset password email sent. Check your inbox.');
                            } else {
                              console.error(
                                'forgotPassword API error: ',
                                response?.message,
                              );
                              toast.error(response?.message || SOMETHING_WENT_WRONG);
                            }
                          } catch (error) {
                            toast.error(error?.message || SOMETHING_WENT_WRONG);
                            console.error('forgotPassword error: ', error);
                          }
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  `form-control${
                                    errors.email && touched.email
                                      ? ' is-invalid'
                                      : ''}`
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to
                    {' '}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>
                    {' '}
                  </p>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, {
    userForgetPassword,
    userForgetPasswordSuccess,
    userForgetPasswordError,
  })(ForgetPasswordPage),
);
