/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser, updateNotification, toggleCallWidgetVisibility } from '../../store/actions';

class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount() {
    // emit the event
    const { isCallWidgetVisible } = this.props;
    this.props.updateNotification([]);
    this.props.logoutUser(this.props.history);
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push('/login');
    if (isCallWidgetVisible) {
      this.props.toggleCallWidgetVisibility();
    }
  }

  render() {
    return null;
  }
}

Logout.propTypes = {
  history: PropTypes.any,
  logoutUser: PropTypes.func,
  updateNotification: PropTypes.func,
  isCallWidgetVisible: PropTypes.bool,
  toggleCallWidgetVisibility: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isCallWidgetVisible: state.CallWidget.isCallWidgetVisible,
});

export default withRouter(connect(mapStateToProps, { logoutUser, updateNotification, toggleCallWidgetVisibility })(Logout));
