/* eslint-disable import/prefer-default-export */
import { CHANNEL_TYPES } from '../constants/channels/channels.constant';

export const getChannelClassName = (status) => {
  switch (status) {
    case CHANNEL_TYPES.WHATSAPP:
      return 'mdi mdi-whatsapp text-success align-middle me-1 font-size-18';
    case CHANNEL_TYPES.TEXT:
      return 'mdi mdi-message-text-outline text-primary align-middle me-1 font-size-18';
    default:
      return '';
  }
};

export const decodeHTMLEntities = (text) => {
  const entities = {
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&#39;': "'",
  };
  return text.replace(/&lt;|&gt;|&amp;|&quot;|&#39;/g, (match) => entities[match]);
};

export const convertHTMLToText = (html) => {
  // Decode HTML entities
  let text = decodeHTMLEntities(html);

  // Remove all HTML tags except for mention spans
  text = text.replace(/<(?!span class="mention")[^>]+>/g, '');

  // Extract mention text and replace mention spans
  text = text.replace(/<span class="mention"[^>]*data-value="([^"]*)"[^>]*>.*?<\/span>/g, '@$1');

  // Remove any remaining HTML tags
  text = text.replace(/<[^>]+>/g, '');

  // Trim whitespace and remove extra spaces
  text = text.trim().replace(/\s+/g, ' ');

  return text;
};
