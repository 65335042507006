import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { KEYPAD_BUTTONS } from '../../../constants/voice/call.constant';

const StyledButton = styled(Button)`
  &.keypad-button {
    .number {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .letters {
      font-size: 0.8rem;
      font-weight: 200;
      margin-top: -0.5rem;
    }
  }
`;

function Keypad({ handleNumberClick }) {
  const onButtonClick = (number) => {
    try {
      handleNumberClick(number);
    } catch (error) {
      console.error('Error handling number click:', error);
    }
  };

  return (
    <>
      {KEYPAD_BUTTONS.map((button) => (
        <StyledButton
          key={button.number}
          onClick={() => onButtonClick(button.number)}
          className="keypad-button p-button-outlined d-flex flex-column justify-content-center align-items-center"
        >
          <div className="number">{button.number}</div>
          <div className="letters">{button.letters}</div>
        </StyledButton>
      ))}
    </>
  );
}

Keypad.propTypes = {
  handleNumberClick: PropTypes.func.isRequired,
};

export default Keypad;
