import { CLOSE_TOAST, SHOW_TOAST } from './actionTypes';

export const showToast = (data) => ({
  type: SHOW_TOAST,
  payload: data,
});

export const closeToast = () => ({
  type: CLOSE_TOAST,
});
