import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  &.p-menu {
    width: auto;
    padding: 0;
    .p-menuitem-link {
      padding: 0.5rem 1rem;
    }
    .p-menu-list {
      margin-bottom: 0;
    }
  }
`;

const TransparentButton = styled(Button)`
  background-color: ${({ label }) => (label ? 'initial' : 'transparent')};
  border: ${({ label }) => (label ? 'initial' : 'none')};
  box-shadow: ${({ label }) => (label ? 'initial' : 'none')};
  padding: ${({ label }) => (label ? '0.5rem' : '0')};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ label }) => (label ? 'initial' : 'transparent')};
    box-shadow: ${({ label }) => (label ? 'initial' : 'none')};
  }

  .pi {
    font-size: ${({ label }) => (label ? '1rem' : '1.5rem')}; // Larger icon if no label
  }
`;

function DropdownMenu({
  model,
  className,
  popup,
  menuRef,
  ariaControls,
  isButtonRequired,
  buttonIcon,
  buttonOnClick,
  popupAlignment,
  ...rest
}) {
  const menuReference = menuRef || useRef(null);
  return (
    <div>
      {isButtonRequired && (
        <TransparentButton
          icon={buttonIcon}
          onClick={(event) => {
            event.stopPropagation();
            if (buttonOnClick) buttonOnClick(event);
            menuReference.current.toggle(event);
          }}
          aria-haspopup
        />
      )}
      <StyledMenu
        model={model}
        className={className}
        popup={popup}
        ref={menuReference}
        aria-controls={ariaControls}
        popupAlignment={popupAlignment}
        {...rest}
      />
    </div>
  );
}

DropdownMenu.propTypes = {
  model: PropTypes.array.isRequired,
  className: PropTypes.string,
  ariaControls: PropTypes.string,
  popupAlignment: PropTypes.string,
  popup: PropTypes.bool,
  menuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isButtonRequired: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

DropdownMenu.defaultProps = {
  className: '',
  popup: false,
  isButtonRequired: false,
  buttonIcon: 'pi pi-bars',
  buttonOnClick: null,
};

export default DropdownMenu;
