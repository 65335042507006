import io from 'socket.io-client';

const socketService = (() => {
  let socket = null;

  return {
    init: (id) => {
      if (id) {
        socket = io(`${process.env.REACT_APP_CLIENT_SOCKET_API_ENDPOINT}`, {
          auth: {
            userId: id,
          },
          transports: ['websocket', 'polling'],
        });
      } else {
        socket = io(process.env.REACT_APP_CLIENT_SOCKET_API_ENDPOINT);
      }
    },
    on: (eventName, callback) => {
      socket?.off(eventName)?.on(eventName, callback);
    },
    close: () => {
      socket?.disconnect();
    },
    emit: (str, payload) => {
      socket.emit(str, payload);
    },
    leave: (eventName) => {
      socket.leave(eventName);
    },
    removeAllListeners: () => {
      socket.removeAllListeners();
    },
    getSocket: () => socket,

    destroy: () => {
      socket?.destroy();
    },
  };
})();

// const userData = getDataFromLocalStorage("userData")
// const id = userData?.id;

// socketService.init(id);

socketService.on('connect_failed', () => {
  // eslint-disable-next-line no-alert
  alert('Connection failed');
});

socketService.on('reconnecting', () => {
  // eslint-disable-next-line no-alert
  alert('reconnecting');
});

socketService.on('reconnect_failed', () => {
  // eslint-disable-next-line no-alert
  alert('reconnect_failed failed');
});

socketService.on('error', () => {
  // eslint-disable-next-line no-alert
  alert('Connection failed error');
});

export default socketService;
