import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components';

const StyledButton = styled.button`
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .pi {
    margin-left: 0.5rem;
  }
`;

function CustomOverlay({
  buttonContent, children, buttonIcon, className, onClick, isOpen,
}) {
  const op = useRef(null);

  useEffect(() => {
    if (op.current) {
      if (!isOpen && op.current.isVisible()) {
        op.current.hide();
      }
    }
  }, [isOpen]);

  // Handles the click event to toggle the overlay; calls onClick if the overlay is not visible.
  const handleOnClick = (event) => {
    op.current.toggle(event); // Toggle the overlay visibility

    // If overlay is not visible after toggle, trigger onClick callback if defined
    // Basically used when need to close the overlay dynamically
    if (!op?.current?.isVisible() && onClick) {
      onClick();
    }
  };

  return (
    <div className="custom-overlay">
      <StyledButton onClick={handleOnClick} className={className}>
        {buttonIcon && <i className={buttonIcon} />}
        {buttonContent}
      </StyledButton>
      <OverlayPanel ref={op}>{children}</OverlayPanel>
    </div>
  );
}

CustomOverlay.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttonIcon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CustomOverlay;
