import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { axiosPatch, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import { setShowTemplateModal } from '../../../../store/campaign/actions';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../../constants/onboarding.constant';
import { handleOnboardingStepCompletion } from '../../../../utils/onboarding';
import { setOnboardingData as setOnboardingDataAction } from '../../../../store/actions';
import { SMS_CHARACTER_COUNT } from '../../../../constants/inbox/message.constant';
import { TEMPLATE_LANGUAGES, TAG } from '../../../../constants/channels/whatsApp.constant';
import { CHANNEL_TYPES } from '../../../../constants/channels/channels.constant';

class Sms extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowTemplateModal } = this.props;
    onSetShowTemplateModal();
  };

  handleAddTemplate = async (templateData) => {
    try {
      this.setState({ showModalLoader: true });
      const {
        getAllTemplates, isOnboarding, history, setOnboardingData,
      } = this.props;
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const response = await axiosPost('message-template', templateData);
      if (response?.status) {
        toast.success(response?.message || 'Template created successfully!');
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.CREATE_TEMPLATE,
            organizationId,
            history,
            setOnboardingData,
          });
        }
        getAllTemplates();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleAddTemplate :', error);
    }
  };

  handleUpdateTemplate = async (templateData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllTemplates, template } = this.props;
      const response = await axiosPatch(
        `message-template/${template?.id}`,
        templateData,
      );
      if (response?.status) {
        toast.success(response?.message || 'Template updated successfully!');
        getAllTemplates();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleUpdateTemplate :', error);
    }
  };

  handleOnSubmit = (values) => {
    const { template, isEdit } = this.props;
    const templateData = {
      name: values?.name,
      templateFor: CHANNEL_TYPES.TEXT,
      language: values?.language,
      templateContent: values?.templateContent,
    };

    if (isEdit) {
      const changedFields = {};

      // Compare templateData with previous template data to identify changes
      Object.keys(templateData).forEach((key) => {
        if (templateData[key] !== template[key]) {
          changedFields[key] = templateData[key];
        }
      });
      this.handleUpdateTemplate(changedFields);
    } else {
      this.handleAddTemplate(templateData);
    }
  };

  render() {
    const {
      isEdit, showTemplateModal, template, className,
    } = this.props;
    const { showModalLoader } = this.state;

    const initialValues = {
      name: template?.name || '',
      templateContent: template.templateContent || '',
      language: template.language || '',
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required('Please enter template name'),
      templateContent: Yup.string().required('Please enter template message'),
      language: Yup.string().required(
        'Please select language for the template',
      ),
    });
    return (
      <Modal isOpen={showTemplateModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          {isEdit ? `Edit Template : ${template?.name}` : 'Add Template'}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors, touched, values, setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Template Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          `form-control${
                            errors.name && touched.name ? ' is-invalid' : ''}`
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Language</Label>
                      <Field
                        as="select"
                        name="language"
                        className={
                          `form-control-select${
                            errors.language && touched.language
                              ? ' is-invalid'
                              : ''}`
                        }
                      >
                        <option value="">Select a language</option>
                        {Object.keys(TEMPLATE_LANGUAGES).map((type) => (
                          <option key={type} value={TEMPLATE_LANGUAGES[type]}>
                            {TEMPLATE_LANGUAGES[type]}
                          </option>
                        ))}
                        {' '}
                      </Field>
                      <ErrorMessage
                        name="language"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3 d-flex mt-4">
                      <Label className="form-label">Tag</Label>
                      <div className="ms-2">
                        {Object.keys(TAG).map((type) => (
                          <span
                            key={type}
                            className="me-2 rounded-pill bg-secondary text-white px-3 py-2 cursor-pointer"
                            onClick={() => {
                              setFieldValue(
                                'templateContent',
                                `${values?.templateContent}{${TAG[type]}}`,
                              );
                            }}
                          >
                            {TAG[type]}
                          </span>
                        ))}
                        {' '}
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Template Content</Label>
                      <Field
                        as="textarea"
                        name="templateContent"
                        maxLength={SMS_CHARACTER_COUNT}
                        className={
                          `form-control-select${
                            errors.templateContent && touched.templateContent
                              ? ' is-invalid'
                              : ''}`
                        }
                      />
                      <ErrorMessage
                        name="templateContent"
                        component="div"
                        className="invalid-feedback"
                      />
                      <p className="mt-2 rounded-pill bg-danger text-white p-2 width-10rem text-center">
                        <span className="">Characters : </span>
                        {values?.templateContent?.length}
                        /
                        {SMS_CHARACTER_COUNT}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {isEdit ? 'Update Template' : 'Save'}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

Sms.propTypes = {
  className: PropTypes.any,
  onSetShowTemplateModal: PropTypes.func,
  getAllTemplates: PropTypes.func,
  template: PropTypes.object,
  isEdit: PropTypes.bool,
  showTemplateModal: PropTypes.bool,
  history: PropTypes.object,
  isOnboarding: PropTypes.bool,
  setOnboardingData: PropTypes.func,
};

const mapStateToProps = ({ campaign, onboarding }) => ({
  showTemplateModal: campaign.showTemplateModal,
  isOnboarding: onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowTemplateModal: () => dispatch(setShowTemplateModal()),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sms));
