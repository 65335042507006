import {
  SHOW_ADD_NOTE_MODAL,
  SHOW_CHANGE_GROUP_MODAL,
  SHOW_NOTE_LIST_MODAL,
  SELECTED_CONTACT_ID,
  ACTIVE_TAB,
  SET_CONVERSATION_FILTER,
} from './actionTypes';

export const setActiveTab = (activeTab) => ({
  type: ACTIVE_TAB,
  payload: activeTab,
});

export const setShowNoteListModal = () => ({
  type: SHOW_NOTE_LIST_MODAL,
});

export const setShowAddNoteModal = () => ({
  type: SHOW_ADD_NOTE_MODAL,
});

export const setShowChangeGroupModal = () => ({
  type: SHOW_CHANGE_GROUP_MODAL,
});
export const setSelectedContactId = (contactId) => ({
  type: SELECTED_CONTACT_ID,
  payload: contactId,
});
export const setConversationFilter = (filterData) => ({
  type: SET_CONVERSATION_FILTER,
  payload: filterData,
});
