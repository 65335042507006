/* eslint-disable import/prefer-default-export */
import { handleError } from '../errorHandler';
import { PAGE, SIZE_PER_PAGE } from '../../constants/constants';
import {
  axiosDel, axiosGet, axiosPatch, axiosPost,
} from '../http.service';
import { toastType } from '../../constants/toast';
import { showToast } from '../toastService';

export const getAllContactGroupsInPages = async (page = PAGE, size = SIZE_PER_PAGE, searchQuery = '') => {
  try {
    const response = await axiosGet(
      `contact-groups?page=${page}&limit=${size}${searchQuery ? `&q=${searchQuery}` : ''
      }&sortBy=createdAt:desc&projectBy=contactIds:hide`,
    );
    return response;
  } catch (error) {
    console.error('error while getting all contact groups :', error);
    throw error;
  }
};

export const getContactCount = async (contactGroupId) => {
  try {
    const response = await axiosGet(
      `contact-groups/contacts-count/${contactGroupId}`,
    );
    return response;
  } catch (error) {
    console.error('error while getting all contact groups :', error);
    throw error;
  }
};

export const createContactGroup = async ({
  segmentName, contacts, marketingOptIn, tags, fieldActions,
}) => {
  try {
    const response = await axiosPost('contact-groups', {
      name: segmentName,
      contacts,
      marketingOptIn,
      tags,
      fieldActions,
    });
    return response;
  } catch (error) {
    console.error('error while creating contact group :', error);
    throw error;
  }
};

export const deleteContactFromGroup = async (contactId, groupValue) => {
  try {
    const response = await axiosDel(`contact-groups/${groupValue}/contacts/${contactId}`);
    if (response?.status) {
      showToast({ content: 'Contact removed successfully!' || response?.message, type: toastType.success });
      return response;
    }
    showToast({ content: 'Failed to remove contact!' || response?.message, type: toastType.error });
    throw new Error(response?.message || 'Failed to remove contact!');
  } catch (error) {
    handleError('Error removing contact:', error);
    throw error;
  }
};

export const fetchContactGroup = async (page, size) => {
  try {
    const response = await axiosGet(`contact-groups?page=${page}&limit=${size}&sortBy=createdAt:desc`);
    if (response?.status) {
      return response;
    }
    throw new Error(response?.message || 'Failed to fetch contact group!');
  } catch (error) {
    handleError('Error fetching contact group:', error);
    throw error;
  }
};

export const fetchContactGroupsByContactId = async (contactId) => {
  try {
    const response = await axiosGet(`contact-groups/contacts/${contactId}`);
    if (response?.status) {
      return response;
    }
    throw new Error(response?.message || 'Failed to fetch contact group!');
  } catch (error) {
    handleError('Error fetching contact group by ContactId:', error);
    throw error;
  }
};

// Service to add a existing contact to contact group
export const addContactToGroup = async (contactGroupId, data) => {
  try {
    const response = await axiosPatch(`/contact-groups/${contactGroupId}/contacts/existing`, data);
    if (response?.status) {
      showToast({ content: 'Contact added to group!' || response?.message, type: toastType.success });
      return response;
    }
    showToast({ content: 'Failed to add contact to group!' || response?.message, type: toastType.error });
    throw new Error(response?.message || 'Failed to add contact to group!');
  } catch (error) {
    handleError('Error while adding contact to group:', error);
    throw error;
  }
};

// Service to add one or bulk contacts in contact groups
export const addContactsToGroup = async (contactGroupId, data) => {
  try {
    const response = await axiosPost(`/contact-groups/${contactGroupId}/contacts`, data);
    if (response?.status) {
      showToast({ content: 'Contacts added to group!' || response?.message, type: toastType.success });
      return response;
    }
    showToast({ content: 'Failed to add contacts to group!' || response?.message, type: toastType.error });
    throw new Error(response?.message || 'Failed to add contacts to group!');
  } catch (error) {
    handleError('Error while adding contacts to group:', error);
    throw error;
  }
};
