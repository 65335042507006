/* eslint-disable import/prefer-default-export */
import { toastType } from '../../constants/toast';
import { showToast } from '../toastService';
import { handleError } from '../errorHandler';
import { axiosDel, axiosGet, axiosPatch } from '../http.service';

export const fetchAllTags = async (activeWorkspaceId, tagPage, size) => {
  try {
    const response = await axiosGet(`/workspace/${activeWorkspaceId}/tags?page=${tagPage}&limit=${size}`);
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to fetch tags');
  } catch (error) {
    handleError('Error fetching tags:', error);
    throw error;
  }
};

export const addTag = async (contactId, body) => {
  try {
    const response = await axiosPatch(`/contacts/${contactId}/tags`, body);
    if (response?.status) {
      showToast({ content: 'Tag added successfully!' || response?.message, type: toastType.success });
      return response.data;
    }
    showToast({ content: 'Failed to add tag!' || response?.message, type: toastType.error });
    throw new Error(response?.message || 'Failed to add tag');
  } catch (error) {
    handleError('Error adding tag:', error);
    throw error;
  }
};

export const removeTag = async (contactId, tagValue) => {
  try {
    const response = await axiosDel(`/contacts/${contactId}/tags/${tagValue}`);
    if (response?.status) {
      showToast({ content: 'Tag removed successfully!' || response?.message, type: toastType.success });
      return response.data;
    }
    showToast({ content: 'Failed to remove tag!' || response?.message, type: toastType.error });
    throw new Error(response?.message || 'Failed to remove tag');
  } catch (error) {
    handleError('Error removing tag:', error);
    throw error;
  }
};
