import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import noDataAvailable from '../../assets/images/graphics/data-not-available.svg';

class LineChart extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      data, xAxisData, seriesNames, title, yAxisName, xAxisName, loading, page, totalPages,
    } = this.props;
    return (
      data !== nextProps.data
      || xAxisData !== nextProps.xAxisData
      || seriesNames !== nextProps.seriesNames
      || title !== nextProps.title
      || yAxisName !== nextProps.yAxisName
      || xAxisName !== nextProps.xAxisName
      || loading !== nextProps.loading
      || page !== nextProps.page
      || totalPages !== nextProps.totalPages
    );
  }

  getOption = () => {
    const {
      title,
      yAxisName = 'Value',
      xAxisName = 'Time',
      data,
      xAxisData,
      seriesNames,
      tooltipFormatter,
    } = this.props;

    const series = seriesNames.map((name, index) => ({
      name,
      type: 'line',
      stack: 'cost',
      smooth: true,
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        width: 2,
      },
      label: {
        show: true,
        position: (params) => {
          // Check if the values are equal or close
          const otherSeriesValue = data[1 - index][params.dataIndex];
          const difference = Math.abs(params.value - otherSeriesValue);
          const threshold = 0.1; // Adjust this value as needed

          if (difference < threshold) {
            // If values are close, offset the labels vertically
            return index === 0 ? [0, -30] : [0, 30];
          }
          // Otherwise, keep the original positioning
          return index === 0 ? 'top' : 'bottom';
        },
        distance: 50,
        formatter: (params) => (params.value !== 0 ? `$${params.value.toFixed(2)}` : ''),
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: [4, 8],
        borderRadius: 4,
        color: '#333',
        fontSize: 12,
        fontWeight: 'bold',
      },
      data: data[index],
    }));

    return {
      grid: {
        top: 100,
        bottom: 60,
        left: 60,
        right: 60,
      },
      toolbox: {
        top: 0,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Save As Image',
            iconStyle: {
              color: '#fff',
              backgroundColor: '#fff',
              borderColor: '#50a5f1',
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowOffsetX: 0,
              shadowOffsetY: 4,
            },
            pixelRatio: 2,
          },
        },
      },
      legend: { data: seriesNames, top: 30 },
      yAxis: [
        {
          type: 'value',
          name: yAxisName,
          nameLocation: 'center',
          nameTextStyle: { padding: 30, fontWeight: 'bold', fontSize: 12 },
          axisLabel: {
            formatter: (value) => `$${value.toFixed(2)}`, // Format y-axis with dollar sign
          },
        },
      ],
      xAxis: {
        type: 'category',
        name: xAxisName,
        nameLocation: 'center',
        nameTextStyle: { padding: 30, fontWeight: 'bold', fontSize: 12 },
        data: xAxisData,
        axisLabel: {
          interval: 0,
          width: 70,
          overflow: 'break',
          rich: {},
        },
      },
      color: ['#34c38f', '#50a5f1', '#f46a6a', '#f1b44c'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        formatter:
          tooltipFormatter
          || ((params) => {
            const date = params[0].name;
            const details = params
              .map((item) => `${item.marker} ${item.seriesName}: $${item.value.toFixed(2)}`)
              .join('<br/>');
            return `Date: ${date}<br/>${details}`;
          }),
      },
      series,
      title: {
        text: title,
        top: 0,
        textStyle: {
          color: '#333',
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
    };
  };

  render() {
    const {
      loading, page, totalPages, onLoadPreviousPage, onLoadNextPage, xAxisData,
    } = this.props;

    return (
      <div className="echarts-container">
        {page > 1 && (
          <Button
            className="bg-white rounded-5 shadow-sm p-3 d-flex justify-content-center align-items-center"
            onClick={onLoadPreviousPage}
            disabled={loading || page <= 1}
          >
            <i className="mdi mdi-arrow-left text-black font-size-16" />
          </Button>
        )}
        {isEmpty(xAxisData) ? (
          <div
            className="d-flex align-items-center justify-content-center"
          >
            <img src={noDataAvailable} alt="No data available" className="d-flex align-items-center mt-5" />
          </div>
        ) : (
          <ReactEcharts
            style={{ height: '100%', width: '100%' }}
            option={this.getOption()}
            lazyUpdate
            showLoading={loading}
          />
        )}
        {page < totalPages && (
          <Button
            className="bg-white rounded-5 shadow-sm p-3 d-flex justify-content-center align-items-center"
            onClick={onLoadNextPage}
            disabled={loading || page >= totalPages}
          >
            <i className="mdi mdi-arrow-right text-black font-size-16" />
          </Button>
        )}
      </div>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.array,
  xAxisData: PropTypes.array,
  seriesNames: PropTypes.array,
  title: PropTypes.string,
  yAxisName: PropTypes.string,
  xAxisName: PropTypes.string,
  loading: PropTypes.bool,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onLoadPreviousPage: PropTypes.func,
  onLoadNextPage: PropTypes.func,
  tooltipFormatter: PropTypes.object,
};

export default LineChart;
