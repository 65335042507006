/* eslint-disable import/prefer-default-export */
export const ACCEPTED_FILE_TYPES = {
  AUDIO: 'audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg',
  DOCUMENT:
      'text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  IMAGE: 'image/jpeg, image/png',
  VIDEO: 'video/mp4, video/3gp',
};

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  APPLICATION: 'application',
  AUDIO: 'audio',
};

export const FILE_EXTENSIONS = {
  PDF: 'pdf',
  XLSX: 'xlsx',
  DOC: 'doc',
  DOCX: 'docx',
};

export const FILE_SIZE_ALLOWED = {
  AUDIO: 16,
  VIDEO: 16,
  DOCUMENT: 100,
  IMAGE: 5,
};
