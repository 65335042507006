import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as PrimeAvatar } from 'primereact/avatar';
import { styled } from 'styled-components';

const StyledAvatar = styled(PrimeAvatar)`
  &.p-avatar {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }

  &.p-avatar-circle {
    border-radius: 50%;
  }

  &.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
`;

function Avatar({
  label,
  icon,
  image,
  size,
  shape,
  className,
  style,
  template,
  imageAlt,
  onImageError,
}) {
  return (
    <StyledAvatar
      label={label}
      icon={icon}
      image={image}
      size={size}
      shape={shape}
      className={className}
      style={style}
      template={template}
      imageAlt={imageAlt}
      onImageError={onImageError}
    />
  );
}

Avatar.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large', 'xlarge']),
  shape: PropTypes.oneOf(['square', 'circle']),
  className: PropTypes.string,
  style: PropTypes.object,
  template: PropTypes.func,
  imageAlt: PropTypes.string,
  onImageError: PropTypes.func,
};

Avatar.defaultProps = {
  size: 'normal',
  shape: 'square',
  className: '',
  style: {},
  imageAlt: 'avatar',
};

export default Avatar;
