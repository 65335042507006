import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { setActiveIndexImportContacts, setImportContactsStepsStatus } from '../../../store/actions';
import WizardSteps from '../../../components/Features/WizardSteps';

function ImportContacts() {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const stepsArray = useSelector((state) => state.contacts.importContacts.stepsArray);
  const activeIndex = useSelector((state) => state.contacts.importContacts.activeIndex);

  const handleFormCompletion = (stepIndex, isCompleted) => {
    const completionArray = stepsArray?.map((stepCompletion) => (stepCompletion?.stepIndex === stepIndex
      ? ({ ...stepCompletion, completed: isCompleted })
      : stepCompletion));

    dispatch(setImportContactsStepsStatus(completionArray));
  };

  const itemRenderer = (item, itemIndex) => {
    const { icon, label } = item;
    const StepCompletedIcon = 'bx bx-check';

    return (
      <a href="#" className={`p-menuitem-link ${activeIndex > itemIndex ? 'p-completed' : ''}`} tabIndex="-1" data-pc-section="action">
        <span className="p-steps-number" data-pc-section="step">
          {
            activeIndex > itemIndex
              ? <i className={StepCompletedIcon} />
              : <i className={icon} />
          }
        </span>
        <span className="p-steps-title" data-pc-section="label">{label}</span>
      </a>
    );
  };

  useEffect(() => {
    setItems([
      {
        label: 'Download Template',
        icon: 'bx bxs-download',
        disabled: false,
        template: (item) => itemRenderer(item, 0),
        component: <Step1 formCompletion={(isCompleted) => handleFormCompletion(0, isCompleted)} />,
      },
      {
        label: 'Upload CSV',
        icon: 'mdi mdi-cloud-upload-outline',
        disabled: true,
        template: (item) => itemRenderer(item, 1),
        component: <Step2 formCompletion={(isCompleted) => handleFormCompletion(1, isCompleted)} />,
      },
      {
        label: 'Map Fields',
        icon: 'mdi mdi-sitemap',
        disabled: true,
        template: (item) => itemRenderer(item, 2),
        component: <Step3 />,
      },
    ]);
  }, []);

  const getButtonStates = () => {
    const isNextButtonEnable = activeIndex !== items.length - 1
      && stepsArray?.reduce((acc, stepCompletion) => (stepCompletion?.stepIndex === activeIndex ? stepCompletion?.completed : acc), false);

    const isPreviousButtonEnable = activeIndex > 0;

    return {
      isNextButtonEnable,
      isPreviousButtonEnable,
    };
  };

  const { isNextButtonEnable, isPreviousButtonEnable } = getButtonStates();

  return (
    <div className="page-content">
      <WizardSteps
        items={items}
        activeIndex={activeIndex}
        readOnly={false}
        onStepChange={(currentStep) => {
          dispatch(setActiveIndexImportContacts(currentStep));
        }}
        buttonsEnable
        previousButtonDisabled={!isPreviousButtonEnable}
        nextButtonDisabled={!isNextButtonEnable}
      >
        {items.length ? items[activeIndex]?.component : null}
      </WizardSteps>
    </div>
  );
}

export default ImportContacts;
