import { isArray, isNumber, isString } from 'lodash';
import { transformWhatsappTemplate } from '../../helpers/commonHelpers';
import {
  SET_STEP_ONE_DATA,
  SET_SENDER_NUMBERS,
  SET_META_WHATSAPP_TEMPLATES,
  SET_STEP_TWO_DATA,
  SET_TRANSFORMED_TEMPLATE,
  SET_STEP_THREE_DATA,
  SET_STEP_FOUR_DATA,
  SET_STEP_COMPLETION_ARRAY,
  SET_ACTIVE_INDEX_OF_BROADCAST_FORM,
  RESET_BROADCAST_FORM,
  SET_CONTACT_GROUPS,
} from './actionTypes';
import { PAGE, SIZE_PER_PAGE } from '../../constants/constants';

const INIT_STATE = {
  activeIndex: 0,
  step1: {
    senderNumberId: '',
    selectedWhatsappNumberData: {},
    templateKey: '',
    senderNumbers: [],
    whatsappTemplates: [],
    transformedWhatsappTemplates: [],
    selectedWhatsappTemplate: {},
    selectedTransformTemplate: {},
  },
  step2: {
    templateValues: {},
    storableTemplateData: {},
  },
  step3: {
    selectedContactGroup: {},
    contactGroups: [],
    totalSize: 0,
    page: PAGE,
    size: SIZE_PER_PAGE,
    searchValue: '',
  },
  step4: {
    broadcastData: {},
  },
  stepCompletionArray: [
    {
      stepIndex: 0,
      completed: false,
    },
    {
      stepIndex: 1,
      completed: false,
    },
    {
      stepIndex: 2,
      completed: false,
    },
    {
      stepIndex: 3,
      completed: false,
    },
  ],
};

const bulkWhatsapp = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_INDEX_OF_BROADCAST_FORM: {
      const { activeIndex } = action.payload;

      return {
        ...state,
        activeIndex,
      };
    }

    case SET_STEP_COMPLETION_ARRAY: {
      const { stepCompletionArray } = action.payload;

      return {
        ...state,
        stepCompletionArray,
      };
    }

    case SET_STEP_ONE_DATA: {
      const { senderId, templateKey, selectedWhatsappNumberData } = action.payload;

      const transformedWhatsappTemplates = state.step1.transformedWhatsappTemplates.map((templateObj) => (templateKey === templateObj?.key
        ? ({ ...templateObj, checked: true })
        : ({ ...templateObj, checked: false })));

      let selectedWhatsappTemplate = state.step1.selectedWhatsappTemplate || {};
      let selectedTransformTemplate = state.step1.selectedTransformTemplate || {};

      if (templateKey && templateKey !== state.step1.templateKey) {
        const [name, language] = templateKey?.split('-');

        selectedWhatsappTemplate = state.step1.whatsappTemplates.reduce((acc, template) => (template?.name === name && template.language === language
          ? template
          : acc), {});

        selectedTransformTemplate = transformWhatsappTemplate(selectedWhatsappTemplate);
      }

      return {
        ...state,
        step1: {
          ...state.step1,
          selectedWhatsappNumberData,
          senderNumberId: senderId,
          templateKey,
          transformedWhatsappTemplates,
          selectedWhatsappTemplate,
          selectedTransformTemplate,
        },
        step2: {
          ...state.step2,
          // When different template select then empty old template variable values
          templateValues: templateKey !== state.step1.templateKey
            ? {}
            : state.step2.templateValues,
        },
      };
    }

    case SET_SENDER_NUMBERS: {
      const { senderNumbers } = action.payload;
      return {
        ...state,
        step1: {
          ...state.step1,
          senderNumbers,
        },
      };
    }

    case SET_CONTACT_GROUPS: {
      const {
        contactGroups,
        totalSize,
        page,
        size,
        searchValue,
      } = action.payload;

      const newData = {};

      if (isArray(contactGroups)) {
        newData.contactGroups = contactGroups;
      }

      if (isNumber(totalSize)) {
        newData.totalSize = totalSize;
      }

      if (isNumber(page)) {
        newData.page = page;
      }

      if (isNumber(size)) {
        newData.size = size;
      }

      if (isString(searchValue)) {
        newData.searchValue = searchValue;
      }

      return {
        ...state,
        step3: {
          ...state.step3,
          ...newData,
        },
      };
    }

    case SET_META_WHATSAPP_TEMPLATES: {
      const { templates } = action.payload;
      const transformedWhatsappTemplates = templates?.map((template) => transformWhatsappTemplate(template));

      return {
        ...state,
        step1: {
          ...state.step1,
          whatsappTemplates: templates,
          transformedWhatsappTemplates,
        },
      };
    }

    case SET_STEP_TWO_DATA: {
      return {
        ...state,
        step2: {
          ...state.step2,
          templateValues: action.payload,
        },
      };
    }

    case SET_STEP_THREE_DATA: {
      const { selectedContactGroup } = action.payload;

      return {
        ...state,
        step3: {
          ...state.step3,
          selectedContactGroup,
        },
      };
    }

    case SET_STEP_FOUR_DATA: {
      return {
        ...state,
        step4: {
          ...state.step4,
          broadcastData: action.payload,
        },
      };
    }

    case SET_TRANSFORMED_TEMPLATE: {
      const { transformedTemplate, storableTemplateData } = action.payload;

      return {
        ...state,
        step1: {
          ...state.step1,
          selectedTransformTemplate: transformedTemplate,
        },
        step2: {
          ...state.step2,
          storableTemplateData,
        },
      };
    }

    case RESET_BROADCAST_FORM: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default bulkWhatsapp;
