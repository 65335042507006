import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';

const HeaderContainer = styled.div`
  &.call-widget-header {
  }
`;

const HeaderContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  border: none;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Title = styled.span`
  font-weight: 200;
  color: lightgray;
`;

const StyledButton = styled(Button)`
  &.p-button-text {
    margin: 0;
    padding: 0;
  }
`;

function Header({ handleDrag = () => {}, handleOnClick, title = '' }) {
  return (
    <HeaderContainer className="call-widget-header">
      <HeaderContent>
        <LeftSection>
          <StyledButton
            className="p-button-text"
            icon="mdi mdi-drag"
            iconClassName="font-size-22 text-light m-0"
            onClick={handleDrag}
          />
          {title && <Title>{title}</Title>}
        </LeftSection>
        <StyledButton
          className="p-button-text"
          icon="mdi mdi-window-minimize"
          iconClassName="font-size-22 text-light m-0"
          onClick={handleOnClick}
        />
      </HeaderContent>
    </HeaderContainer>
  );
}

Header.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  handleDrag: PropTypes.func.isRequired,
};

export default Header;
