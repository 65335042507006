import React, { Component } from 'react';
import {
  Container,
} from 'reactstrap';
import BulkWhatsappMessage from './BulkWhatsappMessage';

class WhatsappMessage extends Component {
  render() {
    document.title = 'Whatsapp Campaign';

    return (
      <div className="page-content">
        <Container fluid>
          <BulkWhatsappMessage />
        </Container>
      </div>
    );
  }
}

export default WhatsappMessage;
