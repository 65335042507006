import { v4 as uuidv5 } from 'uuid';

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const bytesToMegabytes = (bytes) => {
  const MB = 1024 * 1024;
  return Math.ceil(bytes / MB);
};

export const randomString = (
  length,
  dashLength = 0,
  chars = '0123456789abcdefghijklmnopqrstuvwxyz',
) => {
  let result = '';
  for (let i = 0; i < length; i += 1) {
    if (dashLength && i !== 0 && i % dashLength === 0) {
      result += '-';
    } else {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
  }
  return result;
};
export const uuidv4 = () => uuidv5();
