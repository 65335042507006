import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'primereact/card';
import PhoneInputComponent from '../../PhoneInput';
import Keypad from './Keypad';
import { clearService } from '../../../services/keypad.service';
import Button from '../../Button';

const StyledCard = styled(Card)`
  .p-card-body {
    padding: 0 1rem;
  }
`;

const NumberPad = styled.div`
  /* Add your number pad styles here */
`;

const StyledButton = styled(Button)`
  &.p-button {
  }
`;

function KeypadContainer({
  phoneNumber,
  handlePhoneNumberChange,
  handleNumberClick,
  handleCallClick,
}) {
  const handleDailCall = async () => {
    try {
      handleCallClick();
    } catch (error) {
      // Handle error
      console.error('Call failed:', error);
    }
  };

  const handleClearClick = async () => {
    try {
      await clearService();
      handlePhoneNumberChange('');
    } catch (error) {
      // Handle error
      console.error('Clear failed:', error);
    }
  };

  return (
    <div className="keypad-container">
      <StyledCard className="keypad-container-body">
        <div className="mb-2">
          <PhoneInputComponent
            name="phoneNumber"
            value={phoneNumber}
            handleChangePhoneNumber={handlePhoneNumberChange}
            placeholder="Enter your phone number"
            required
            defaultCountry="US"
            disableDialCodePrefill
            inputStyle={{ width: '250px', maxHeight: '100%' }}
          />
        </div>
        <NumberPad className="number-pad">
          <Keypad handleNumberClick={handleNumberClick} />
          <StyledButton
            className="p-button-success keypad-button call-button"
            onClick={handleDailCall}
            icon="bx bx-phone"
            iconClassName="fs-4"
            label="Call"
          />
          <StyledButton
            className="keypad-button clear-button"
            onClick={handleClearClick}
            icon="bx bx-left-arrow-alt"
            iconClassName="fs-4"
          />
        </NumberPad>
      </StyledCard>
    </div>
  );
}

KeypadContainer.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  handleNumberClick: PropTypes.func.isRequired,
  handleCallClick: PropTypes.func.isRequired,
};

export default KeypadContainer;
