import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Badge,
} from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import DataGrid from '../../../components/DataTable';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
// Import Breadcrumb
import { axiosDel, axiosGet } from '../../../services/http.service';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setLoader } from '../../../store/actions';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      campaign: {},
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      searchQuery: '',
      selectedRows: [],
      reportListColumns: [
        {
          header: 'No',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const rowIndex = row?.id;
            return <span>{parseInt(rowIndex, 10) + 1}</span>;
          },
        },
        {
          header: 'Title',
          accessorKey: 'name',
        },
        {
          header: 'Date',
          accessorKey: 'createdAt',
          Cell: ({ renderedCellValue }) => {
            const formattedDate = moment(renderedCellValue).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          Cell: ({ renderedCellValue }) => this.statusFormatter(renderedCellValue),
        },
        {
          header: 'Type',
          accessorKey: 'channel',
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const report = row?.original;
            return (
              <div className="d-flex gap-3">
                <Link
                  // eslint-disable-next-line react/destructuring-assignment
                  to={`/view-report/${report?.id}?page=${this.state.page}`}
                >
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-eye-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="view"
                      id="view"
                    />
                  </Badge>
                </Link>
                <div>
                  <Badge color="danger-subtle" className="text-danger">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="delete"
                      onClick={() => this.onClickDelete(report)}
                    />
                  </Badge>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Accessing the value of the 'id' parameter
    const contactGroupPage = queryParams.get('page');
    if (contactGroupPage) {
      this.setState({ page: contactGroupPage }, () => {
        this.handleGetAllcampaign(contactGroupPage);
      });
    } else {
      this.handleGetAllcampaign(page);
    }
  }

  statusFormatter = (cellContent) => {
    let color;
    let textColor;
    switch (cellContent) {
      case 'completed':
        color = 'success-subtle';
        textColor = ' fs-6 text-success';
        break;
      case 'pending':
        color = 'warning-subtle';
        textColor = 'fs-6 text-warning';
        break;
      case 'error':
        color = 'danger-subtle';
        textColor = 'fs-6 text-danger';
        break;
      default:
        color = 'secondary-subtle';
        textColor = 'fs-6 text-secondary';
    }

    return (
      <Badge color={color} pill className={textColor}>
        {cellContent}
      </Badge>
    );
  };

  onClickDelete = (campaign) => {
    this.setState({ campaign, deleteModal: true });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllcampaign(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetAllcampaign(1, newPageSize);
    });
  };

  handleGetAllcampaign = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `campaign?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        this.setState({
          campaigns: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleGetAllcampaign :', error);
    }
  };

  handleDeleteCampainById = async () => {
    try {
      const { campaign, page } = this.state;
      const response = await axiosDel(`campaign/${campaign?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Campaign deleted successfully!', {
          position: 'top-right',
        });
        this.handleGetAllcampaign(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('error while deleting sender group :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllcampaign(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'campaign-report';

    const {
      campaigns, deleteModal, reportListColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Campaign" breadcrumbItem="Report" />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => this.handleDeleteCampainById()}
            onCloseClick={() => this.setState({ deleteModal: false })}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mt-3">
                    <DataGrid
                      data={campaigns}
                      columns={reportListColumns}
                      enableColumnResizing={false}
                      enableColumnFilterModes
                      enableGlobalFilter
                      enableEditing={false}
                      selectedRows={selectedRows}
                      onRowSelectionChange={this.handleRowSelection}
                      totalSize={totalSize}
                      onPageChange={this.handlePageClick}
                      onPageSizeChange={this.handleLimitChange}
                      initialPage={0}
                      initialPageSize={10}
                      onSearch={this.debounceSearch}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Report.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));
