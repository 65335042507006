import {
  ErrorMessage,
  Field, Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import ModalLoader from '../../../components/Common/ModalLoader';
import { axiosPatch, axiosPost } from '../../../services/http.service';
import setShowQuickSmsModal from '../../../store/quick-message/actions';

class messageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowQuickSmsModal } = this.props;
    onSetShowQuickSmsModal();
  };

  handleAddQuickMessage = async (quickMessageData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllQuickMessages } = this.props;
      const response = await axiosPost('quick-message', quickMessageData);
      if (response?.status) {
        toast.success(
          response?.message || 'Quick message created successfully!',
        );
        getAllQuickMessages();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleAddQuickMessage :', error);
    }
  };

  handleEditQuickMessage = async (quickMessageData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllQuickMessages, quickMessage } = this.props;
      const response = await axiosPatch(
        `quick-message/${quickMessage?.id}`,
        quickMessageData,
      );
      if (response?.status) {
        toast.success(
          response?.message || 'Quick message updated successfully!',
        );
        getAllQuickMessages();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleEditQuickMessage :', error);
    }
  };

  handleOnSubmit = (values) => {
    try {
      const { isEdit, quickMessage } = this.props;
      const isNameChanged = quickMessage?.name !== values?.title;
      const isContentChanged = quickMessage?.content !== values?.content;
      const quickMessageData = {};

      if (isNameChanged) {
        quickMessageData.name = values?.title;
      }
      if (isContentChanged) {
        quickMessageData.content = values?.content;
      }

      if (isEdit) {
        this.handleEditQuickMessage(quickMessageData);
      } else {
        this.handleAddQuickMessage(quickMessageData);
      }
    } catch (error) {
      console.error('QuickMessage OnSubmit error :', error.message);
    }
  };

  render() {
    const {
      isEdit, showQuickSmsModal, quickMessage, className,
    } = this.props;
    const { showModalLoader } = this.state;

    const initialValues = {
      title: quickMessage?.name || '',
      content: quickMessage?.content || '',
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string().required('Please enter title for quick message'),
      content: Yup.string().required(
        'Please enter the content for quick message',
      ),
    });

    return (
      <Modal isOpen={showQuickSmsModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          {isEdit ? 'Edit Group' : 'Add Group'}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Quick Title</Label>
                      <Field
                        name="title"
                        type="text"
                        className={
                          `form-control${
                            errors.title && touched.title ? ' is-invalid' : ''}`
                        }
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Quick Content</Label>
                      <Field
                        name="content"
                        type="text"
                        className={
                          `form-control${
                            errors.content && touched.content
                              ? ' is-invalid'
                              : ''}`
                        }
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

messageForm.propTypes = {
  className: PropTypes.any,
  onSetShowQuickSmsModal: PropTypes.func,
  getAllQuickMessages: PropTypes.func,
  quickMessage: PropTypes.object,
  isEdit: PropTypes.bool,
  showQuickSmsModal: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowQuickSmsModal: () => dispatch(setShowQuickSmsModal()),
});

const mapStateToProps = ({ quickMessage }) => ({
  showQuickSmsModal: quickMessage.showQuickSmsModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(messageForm));
