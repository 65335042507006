export const PHONE_NUMBER = 'PHONE_NUMBER';
export const COPILOT = 'COPILOT';

export const COPILOT_OPTION = {
  label: 'Copilot',
  value: COPILOT,
};

export const PHONE_NUMBER_OPTION = {
  label: 'Phone Number',
  value: PHONE_NUMBER,
};

export const SENDER_TYPES = [
  PHONE_NUMBER_OPTION,
  COPILOT_OPTION,
];

export const NUMBER_TYPES = {
  LOCAL: 'local',
  TOLL_FREE: 'toll-free',
  NA: 'N/A',
};
