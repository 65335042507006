import React, { Component } from 'react';
import { Container } from 'reactstrap';
import ChannelList from '../../components/Features/Channels/ChannelList';

class Channels extends Component {
  render() {
    return (
      <div className="">
        <Container fluid>
          <h4 className="mb-2 font-size-17">Channels</h4>
          <ChannelList />
        </Container>
      </div>
    );
  }
}

export default Channels;
