import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { styled } from 'styled-components';

const StyledSimpleBar = styled(SimpleBarReact)`
    max-height: 300px;
`;

class SimpleBar extends Component {
  render() {
    const {
      children,
      className,
      autoHide,
      forceVisible,
      scrollbarMinSize,
      scrollbarMaxSize,
      ...rest
    } = this.props;
    return (
      <StyledSimpleBar
        className={className}
        autoHide={autoHide}
        forceVisible={forceVisible}
        scrollbarMinSize={scrollbarMinSize}
        scrollbarMaxSize={scrollbarMaxSize}
        {...rest}
      >
        {children}
      </StyledSimpleBar>
    );
  }
}

SimpleBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  autoHide: PropTypes.bool,
  forceVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  scrollbarMinSize: PropTypes.number,
  scrollbarMaxSize: PropTypes.number,
};

SimpleBar.defaultProps = {
  className: '',
  scrollbarMinSize: 25,
  scrollbarMaxSize: 0,
};

export default SimpleBar;
