import React from 'react';
import PropTypes from 'prop-types';
import { Badge as PrimeBadge } from 'primereact/badge';
import { styled } from 'styled-components';

const StyledBadge = styled(PrimeBadge)`
  /* Add custom styles here if needed */
  padding: 0.25em 0.5em;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: ${(props) => (props.pill ? '1rem' : '0.2rem')};
`;

function Badge({
  color = 'primary', pill = false, className = '', children, ...rest
}) {
  return (
    <StyledBadge
      severity={color}
      className={`${className} ${pill ? 'p-badge-pill' : ''}`}
      {...rest}
    >
      {children}
    </StyledBadge>
  );
}

Badge.propTypes = {
  color: PropTypes.string,
  pill: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Badge;
