import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';

import Dropdown from '../Dropdown';

import { handleError } from '../../services/errorHandler';
import { capitalizeFirstLetter } from '../../utils/general';
import { ROLES } from '../../constants/users/role.constant';

const StyledDropdown = styled(Dropdown)`
  .p-dropdown {
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .p-dropdown-label {
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
  }

  .p-dropdown-trigger {
    background: transparent;
    border: none;
    padding: 0 0.75rem;
  }

  .p-dropdown-panel {
    margin-top: 5px;
  }

  .p-dropdown-items {
    padding: 2px 2px !important;
    margin-bottom: 0p !important;
  }

  .p-dropdown-item {
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
  }
`;

const AvatarSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 14px;
  margin-right: 0.75rem;
`;

const WorkspaceNameSpan = styled.span`
  margin-right: 0.5rem;
`;

const NoWorkspacesMessage = styled.div`
  text-align: center;
  padding: 0.75rem 1rem;
`;

const NewWorkspaceButton = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
`;
function WorkspaceDropdown({
  selectedWorkspace,
  workspaceOptions,
  selectWorkspace,
  userData,
  toggleCreateWorkspaceModal,
}) {
  const renderWorkspaceItem = (workspace) => (
    <>
      <AvatarSpan>
        <i className="bx bx-briefcase" />
      </AvatarSpan>
      {capitalizeFirstLetter(workspace.name)}
    </>
  );

  const handleWorkspaceSelect = (workspace) => {
    try {
      selectWorkspace(workspace);
    } catch (error) {
      handleError(error);
    }
  };

  const renderDropdownValue = () => (
    <>
      <AvatarSpan>
        <i className="bx bx-briefcase" />
      </AvatarSpan>
      <WorkspaceNameSpan>
        {capitalizeFirstLetter(selectedWorkspace?.name)}
      </WorkspaceNameSpan>
    </>
  );

  return (
    <StyledDropdown
      value={selectedWorkspace}
      options={workspaceOptions}
      onChange={(e) => handleWorkspaceSelect(e.value)}
      optionLabel="name"
      valueTemplate={renderDropdownValue}
      itemTemplate={renderWorkspaceItem}
      placeholder="Select a workspace"
      className="w-full ms-5 md:w-14rem"
    >
      {workspaceOptions.length === 0 && (
        <NoWorkspacesMessage>No other workspaces</NoWorkspacesMessage>
      )}
      {userData?.role === ROLES.ADMIN && (
        <NewWorkspaceButton
          label="New Workspace"
          icon="bx bx-folder-plus"
          onClick={toggleCreateWorkspaceModal}
        />
      )}
    </StyledDropdown>
  );
}

WorkspaceDropdown.propTypes = {
  selectedWorkspace: PropTypes.object,
  workspaceOptions: PropTypes.array.isRequired,
  selectWorkspace: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  toggleCreateWorkspaceModal: PropTypes.func.isRequired,
};

export default WorkspaceDropdown;
