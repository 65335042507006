import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Select from 'react-select';
import { axiosPatch, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import PhoneInputComponent from '../../../../components/PhoneInput';
import { renderOptionsWithIcon } from '../../../../helpers/commonHelpers';
import { USER_ROLES_OPTIONS } from '../../../../constants/users/user.constant';
import { STATUS_OPTIONS } from '../../../../constants/status.constant';

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
      showPassword: false,
    };
  }

  addUser = async (userData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle } = this.props;
      const response = await axiosPost('users', userData);
      if (response?.status) {
        toast.success(response?.message || 'user added successfully!');
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while adding user :', error);
    }
  };

  editUser = async (userData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle, user } = this.props;
      const response = await axiosPatch(`users/${user?.id}`, userData);
      if (response?.status) {
        toast.success(response?.message || 'user updated successfully!');
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while editing user :', error);
    }
  };

  handleOnSubmit = (values) => {
    try {
      const { isEdit, user } = this.props;
      const assignedWorkspaces = values.assignedWorkspaces.map((workspace) => ({
        name: workspace.name,
        workspaceId: workspace.value,
      }));
      const data = {
        email: values.email,
        password: values.password,
        name: values.name,
        phoneNumber: `${values.phoneNumber}`,
        role: values.role,
        status: values.status,
        assignedWorkspaces,
        activeWorkspace: assignedWorkspaces[0],
      };
      if (!isEdit) {
        this.addUser(data);
      } else if (isEdit) {
        const editedFields = Object.keys(values).reduce((acc, key) => {
          if (values[key] !== user[key] && values[key]) {
            acc[key] = `${values[key]}`;
          }
          return acc;
        }, {});
        if (!_.isEmpty(editedFields)) {
          const editedData = {
            ...editedFields,
            assignedWorkspaces: data.assignedWorkspaces,
          };
          this.editUser(editedData);
        } else {
          this.toggle();
        }
      }
    } catch (error) {
      console.error('error at handleOnSubmit :', error);
    }
  };

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      isEdit, showUserModal, toggle, user, workspaceList, className,
    } = this.props;
    const { showModalLoader, showPassword } = this.state;
    const workspaceOptions = workspaceList.map((workspace) => ({
      label: (
        renderOptionsWithIcon(workspace?.name, 'mdi mdi-briefcase-account-outline font-size-20')
      ),
      value: workspace.id,
      name: workspace.name,
    }));
    const initalOptions = user && user?.workspaces?.map((workspace) => ({
      label: (
        renderOptionsWithIcon(workspace?.name, 'mdi mdi-briefcase-account-outline font-size-20')
      ),
      value: workspace.workspaceId,
      name: workspace?.name,
    }));

    const initialValues = {
      name: (user && user.name) || '',
      email: (user && user.email) || '',
      phoneNumber: (user && user.phoneNumber) || '',
      role: (user && user.role) || '',
      assignedWorkspaces:
      initalOptions
        || [], // Add this line
      ...(!isEdit
        ? {
          status: (user && user.status) || 'active',
          password: '',
        }
        : {}),
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string().required('Please enter user name'),
      email: Yup.string().required("Please enter user's email"),
      phoneNumber: Yup.number().required("Please enter user's phone number"),
      role: Yup.string().required('Please select a role'),
      assignedWorkspaces: Yup.array()
        .min(1, 'Select at least one workspace')
        .required("Please select user's workspace"),
      ...(!isEdit && {
        status: Yup.string().required("Please enter user's status"),
        password: Yup.string().required('Enter user password'),
      }),
    });

    return (
      <Modal isOpen={showUserModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={toggle} tag="h4">
          {isEdit ? 'Edit User Details' : 'Add User'}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors, touched, setFieldValue, values,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Field
                        name="name"
                        type="text"
                        className={`form-control${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Field
                        name="email"
                        type="email"
                        className={`form-control${
                          errors.email && touched.email ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Phone Number</Label>
                      <PhoneInputComponent
                        name="phoneNumber"
                        value={values.phoneNumber} // Replace with the value from Formik’s values object
                        handleChangePhoneNumber={(value) => setFieldValue('phoneNumber', value)} // Update Formik’s field value
                        placeholder="Enter your phone number"
                        required
                        isError={errors.phoneNumber && touched.phoneNumber} // Conditionally apply error state
                        errorText={errors.phoneNumber}
                        inputStyle={{ width: '100%', maxHeight: '100%' }} // Adjust styles via props or directly in styled component
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Role</Label>
                      <Field
                        as="select"
                        name="role"
                        className={`form-control-select${
                          errors.role && touched.role ? ' is-invalid' : ''
                        }`}
                      >
                        <option value="">Select a role</option>
                        {USER_ROLES_OPTIONS.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="role" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Workspaces</Label>
                      <Select
                        name="assignedWorkspaces"
                        options={workspaceOptions} // Ensure you pass the options prop to the UserForm component
                        isMulti
                        className={
                          touched.assignedWorkspaces && errors.assignedWorkspaces
                            ? ' is-invalid'
                            : ''
                        }
                        classNamePrefix="select"
                        onChange={(option) => setFieldValue('assignedWorkspaces', option)}
                        value={values.assignedWorkspaces}
                        noOptionsMessage={() => 'No workspaces available'}
                      />
                      <ErrorMessage
                        name="assignedWorkspaces"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    { isEdit ? (
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <Field
                          as="select"
                          name="status"
                          className={`form-control-select${
                            errors.status && touched.status ? ' is-invalid' : ''
                          }`}
                        >
                          {STATUS_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="status" component="div" className="invalid-feedback" />
                      </div>
                    ) : (
                      ''
                    )}
                    {!isEdit ? (
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Field
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            className={
                                  `form-control${errors.password && touched.password
                                    ? ' is-invalid'
                                    : ''}`
                                }
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                            onClick={() => this.handleStates(
                              'showPassword',
                              !showPassword,
                            )}
                          >
                            {showPassword ? (
                              <i className="mdi mdi-eye-outline" />
                            ) : (
                              <i className="mdi mdi-eye-off-outline" />
                            )}
                          </button>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

UserForm.propTypes = {
  getAllUsers: PropTypes.func,
  user: PropTypes.object,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  showUserModal: PropTypes.bool,
  className: PropTypes.any,
  workspaceList: PropTypes.arrayOf(PropTypes.object),
};

export default UserForm;
