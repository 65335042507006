import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';

const IconContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: -1rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid lightgray;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function MenuIcon({ toggleMenuCallback }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen((prevState) => !prevState);
    toggleMenuCallback();
  };

  return (
    <IconContainer>
      <Button
        aria-label="menu"
        type="button"
        onClick={handleToggle}
        className="p-button-text p-0 m-0 font-size-16"
        id="vertical-menu-btn"
        icon={isMenuOpen ? 'mdi mdi-arrow-right' : 'mdi mdi-arrow-left'} // Toggle icon
        iconClassName="p-0 m-0"
      />
    </IconContainer>
  );
}

MenuIcon.propTypes = {
  toggleMenuCallback: PropTypes.func.isRequired,
};

export default MenuIcon;
