/* eslint-disable import/prefer-default-export */

export const SET_STEP_COMPLETION_ARRAY = 'SET_STEP_COMPLETION_ARRAY';
export const SET_ACTIVE_INDEX_OF_BROADCAST_FORM = 'SET_ACTIVE_INDEX_OF_BROADCAST_FORM';
export const RESET_BROADCAST_FORM = 'RESET_BROADCAST_FORM';

/* Step 1 */
export const SET_STEP_ONE_DATA = 'SET_STEP_ONE_DATA';
export const SET_SENDER_NUMBERS = 'SET_SENDER_NUMBERS';
export const SET_META_WHATSAPP_TEMPLATES = 'SET_META_WHATSAPP_TEMPLATES';
export const SET_TRANSFORMED_TEMPLATE = 'SET_TRANSFORMED_TEMPLATE';

/* Step 2 */
export const SET_STEP_TWO_DATA = 'SET_STEP_TWO_DATA';
export const SET_CONTACT_GROUPS = 'SET_CONTACT_GROUPS';

/* Step 3 */
export const SET_STEP_THREE_DATA = 'SET_STEP_THREE_DATA';

/* Step 3 */
export const SET_STEP_FOUR_DATA = 'SET_STEP_FOUR_DATA';
