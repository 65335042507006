import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ModalLoader() {
  return (
    <LoaderWrapper>
      <ProgressSpinner
        style={{ width: '50px', height: '50px' }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </LoaderWrapper>
  );
}

export default ModalLoader;
