import { ROLES } from './role.constant';

/* eslint-disable import/prefer-default-export */
export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Others', value: 'others' },
];

export const USER_ROLES_OPTIONS = [
  { value: ROLES.MANAGER, label: 'Manager' },
  { value: ROLES.STAFF, label: 'Staff' },
  { value: ROLES.ADMIN, label: 'Admin' },
];
