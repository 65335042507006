import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Badge, Button, Card, CardBody, Nav, NavItem, NavLink, TabContent,
} from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import DeleteModal from '../../components/Common/DeleteModal';
import AddTeam from './Modals/AddTeams';
import { tabs } from '../../constants/tabs';
import { createTeam, getTeams, updateTeam } from '../../services/api/teams.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { setLoader } from '../../store/actions';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { ROLES } from '../../constants/users/role.constant';
import DataGrid from '../../components/DataTable';

class Teams extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      currentTeam: null,
      activeTab: tabs.ACTIVE,
      teams: [],
      isModalOpen: false,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      columns: [
        { accessorKey: 'name', header: 'Team Name' },
        {
          accessorKey: 'managerDetails',
          header: 'Team Managers',
          Cell: ({ renderedCellValue }) => (
            <span>{renderedCellValue?.map((manager) => manager.name).join(', ')}</span>
          ),
        },
        {
          accessorKey: 'memberIds',
          header: 'Users',
          Cell: ({ renderedCellValue }) => (
            <Badge color="primary" pill>
              {Array.isArray(renderedCellValue) ? renderedCellValue?.length : 0}
            </Badge>
          ),
        },
        {
          accessorKey: 'actions',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            // Access userData from context
            const originalRow = row?.original;
            const { userData } = this.context;

            return (
              <>
                {originalRow.isActive && (
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18 text-success"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      onClick={() => this.handleEditTeam(originalRow)}
                    />
                  </Badge>
                )}
                {' '}
                {userData.role === ROLES.ADMIN && (
                  <Badge
                    color={originalRow.isActive ? 'danger-subtle' : 'primary-subtle'}
                    className={originalRow.isActive ? 'text-danger' : 'text-primary'}
                    onClick={() => this.handleDeactivateTeam(originalRow)}
                  >
                    <i
                      className={
                        originalRow.isActive
                          ? 'mdi mdi-block-helper font-size-18 text-danger'
                          : 'mdi mdi-check-circle-outline font-size-18 text-primary'
                      }
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={originalRow.isActive ? 'Deactivate' : 'Activate'}
                      onClick={() => this.handleDeactivateTeam(originalRow)}
                    />
                  </Badge>
                )}
              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetTeams();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab } = this.state;
    if (prevState.activeTab !== activeTab) {
      this.setState(
        {
          page: PAGE,
          totalSize: TOTAL_SIZE,
          teams: [],
        },
        () => {
          this.handleGetTeams();
        },
      );
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  toggleModal = () => {
    const { isEditing } = this.state;
    this.setState((prevState) => {
      if (prevState.isModalOpen && isEditing) {
        // Reset the form values when closing the edit modal
        return {
          isModalOpen: false,
          isEditing: false,
          currentTeam: null,
        };
      }
      return {
        isModalOpen: !prevState.isModalOpen,
        isEditing: false,
        currentTeam: null,
      };
    });
  };

  handleTeamData = async (teamData) => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      let response;
      if (teamData._id) {
        // Update existing team
        response = await updateTeam(teamData._id, teamData.data);
      } else {
        // Create new team
        response = await createTeam(teamData);
      }

      if (response?.status) {
        toast.success(response?.message || 'Team created successfully');
        await this.handleGetTeams();
      } else {
        toast.error(response?.message || 'Failed to process team.');
      }
    } catch (error) {
      console.error('Error during team operation:', error);
    } finally {
      onSetLoader(false);
    }
  };

  handleEditTeam = (team) => {
    this.setState({
      isEditing: true,
      currentTeam: team,
      isModalOpen: true,
    });
  };

  handleTeamStatus = async (team) => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const updateTeamStatus = { isActive: !team.isActive };
      const response = await updateTeam(team._id, updateTeamStatus);
      if (response?.status) {
        toast.success(`Team ${response.data.isActive ? 'activated' : 'deactivated'} successfully`);
        await this.handleGetTeams();
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error('Error during team operation:', error);
    } finally {
      onSetLoader(false);
    }
  };

  handleGetTeams = async () => {
    const { onSetLoader } = this.props;
    const {
      size, page, searchQuery, activeTab,
    } = this.state;
    try {
      onSetLoader(true);
      const response = await getTeams({
        page,
        size,
        q: searchQuery,
        isActive: activeTab === tabs.ACTIVE,
      });
      if (response?.status) {
        this.setState({
          teams: response.data.results,
          totalSize: response?.data?.totalPages,
        });
      }
    } catch (error) {
      console.error('Error getting the teams:', error);
    } finally {
      onSetLoader(false);
    }
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetTeams(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetTeams(1, newPageSize);
    });
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetTeams(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  handleDeactivateTeam = (row) => {
    this.setState({ deleteModal: true, currentTeam: row });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  render() {
    const {
      activeTab,
      teams,
      columns,
      isModalOpen,
      isEditing,
      currentTeam,
      totalSize,
      deleteModal,
      selectedRows,
    } = this.state;
    const { userData } = this.context;
    return (
      <>
        <DeleteModal
          show={deleteModal}
          buttonText={currentTeam?.isActive ? 'Yes, Deactivate it!' : 'Yes, Activate it!'}
          onDeleteClick={() => this.handleTeamStatus(currentTeam)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <AddTeam
          isOpen={isModalOpen}
          toggle={this.toggleModal}
          handleTeamData={this.handleTeamData}
          isEditing={isEditing}
          currentTeam={currentTeam}
        />
        <div className="teams-container">
          <div className="d-flex justify-content-between">
            <h4 className="mb-2 font-size-17">Teams</h4>
            {userData.role === ROLES.ADMIN && (
              <Button color="primary" onClick={this.toggleModal} outline>
                <i className="mdi mdi-account-multiple-plus me-1" />
                Add Team
              </Button>
            )}
          </div>
          <Nav tabs className="nav-tabs-custom" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === tabs.ACTIVE })}
                onClick={() => this.toggle(tabs.ACTIVE)}
              >
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === tabs.INACTIVE })}
                onClick={() => this.toggle(tabs.INACTIVE)}
              >
                Inactive
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <div className="mt-3">
                  <DataGrid
                    data={teams}
                    columns={columns}
                    enableColumnResizing={false}
                    enableColumnFilterModes
                    enableGlobalFilter
                    enableEditing={false}
                    selectedRows={selectedRows}
                    onRowSelectionChange={this.handleRowSelection}
                    totalSize={totalSize}
                    onPageChange={this.handlePageClick}
                    onPageSizeChange={this.handleLimitChange}
                    initialPage={0}
                    initialPageSize={10}
                    onSearch={this.debounceSearch}
                  />
                </div>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

Teams.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};
const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});
export default connect(null, mapDispatchToProps)(Teams);
