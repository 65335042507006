import { SHOW_NUMBER_MODAL, SHOW_REASSIGN_NUMBER_MODAL, SHOW_WHATSAPP_NUMBER_MODAL } from './actionTypes';

export const setShowNumberModal = () => ({
  type: SHOW_NUMBER_MODAL,
});

export const setShowReassignNumberModal = () => ({
  type: SHOW_REASSIGN_NUMBER_MODAL,
});

export const setShowWhatsappNumberModal = () => ({
  type: SHOW_WHATSAPP_NUMBER_MODAL,
});
