// reducer.js

import { TOGGLE_CALL_WIDGET_VISIBILITY } from './actionTypes';

const initialState = {
  isCallWidgetVisible: false,
  globalPhoneNumber: '',
  fromNumber: '',
  // Other state properties
};

const CallWidget = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CALL_WIDGET_VISIBILITY:
      return {
        ...state,
        isCallWidgetVisible: !state.isCallWidgetVisible,
        globalPhoneNumber: action.payload.globalPhoneNumber || '',
        fromNumber: action.payload.fromNumber || '',
      };
    // Other cases
    default:
      return state;
  }
};

export default CallWidget;
