import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputSwitch } from 'primereact/inputswitch';
import styled from 'styled-components';
import Label from '../Label';

// Define styled components
const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-weight: bold;
  font-size: large;
  margin-left: 0.5rem;
`;

const InputSwitchContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledInputSwitch = styled(InputSwitch)`
  .p-inputswitch-slider {
    height: 1.5rem;
    margin-top: 0.1rem;
  }
`;

class StyledSwitch extends Component {
  render() {
    const {
      checked, onChange, label, name, disabled, ...rest
    } = this.props;

    return (
      <SwitchContainer>
        {label && <Label className="mb-2">{label}</Label>}
        <InputSwitchContainer>
          <StyledInputSwitch checked={checked} onChange={onChange} disabled={disabled} {...rest} />
          {name && <Name>{name}</Name>}
        </InputSwitchContainer>
      </SwitchContainer>
    );
  }
}

StyledSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

StyledSwitch.defaultProps = {
  label: '',
  disabled: false,
  name: '',
};

export default StyledSwitch;
