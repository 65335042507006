import {
  SET_ONBOARDING_DATA,
  SET_ONBOARDING_STATUS,
} from './actionTypes';

const initialState = {
  onboardingData: null,
  isOnboarding: false,
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONBOARDING_STATUS:
      return {
        ...state,
        isOnboarding: action.payload,
      };
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        onboardingData: action.payload,
      };

    default:
      return state;
  }
};

export default onboarding;
