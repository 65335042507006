import axios from 'axios';
import { FIELDS_OBJ } from '../constants/field.constant';

export const createFilteredContacts = (contacts, activeFields, isCountryCodeActive, countryCode) => {
  const filteredContacts = contacts.map((contact) => {
    const newContact = {
      customFieldsData: {},
    };

    let phoneNumberFound = false;
    let countryCodeFound = false;

    activeFields.forEach((field) => {
      const { excelColumn, contactField, isCustomField } = field;

      if (contact[excelColumn]) {
        if (isCustomField) {
          newContact.customFieldsData[contactField] = contact[excelColumn];
        } else {
          newContact[contactField] = contact[excelColumn];
        }

        if (contactField === FIELDS_OBJ.PHONE_NUMBER.KEY) {
          phoneNumberFound = true;
        }
        if (contactField === FIELDS_OBJ.COUNTRY_CODE.KEY) {
          countryCodeFound = true;
        }
      }
    });

    if (!isCountryCodeActive && !countryCodeFound) {
      newContact[FIELDS_OBJ.COUNTRY_CODE.KEY] = countryCode;
      countryCodeFound = true;
    }

    if (!phoneNumberFound) return null;
    if (!countryCodeFound) return null;

    return newContact;
  }).filter((contact) => contact);

  return filteredContacts;
};

export const phoneNumberValidation = (activeFields) => {
  const isPhoneNumberActive = activeFields?.some((activeField) => activeField?.excelColumn === FIELDS_OBJ.PHONE_NUMBER.KEY);

  if (!isPhoneNumberActive) {
    return { isValid: false, error: 'Phone number must be selected.' };
  }

  const isValidPhoneNumberMap = activeFields?.some((activeField) => activeField?.excelColumn === FIELDS_OBJ.PHONE_NUMBER.KEY && activeField?.contactField === FIELDS_OBJ.PHONE_NUMBER.KEY);

  if (!isValidPhoneNumberMap) {
    return { isValid: false, error: 'Phone number field must be mapped correctly.' };
  }
  return { isValid: true, error: '' };
};

export const activeCountryCode = (activeFields) => {
  const isCountryCodeActive = activeFields?.some((activeField) => activeField?.excelColumn === FIELDS_OBJ.COUNTRY_CODE.KEY);
  return !!isCountryCodeActive;
};

export const countryCodeValidation = (activeFields) => {
  const isCountryCodeActive = activeCountryCode(activeFields);

  if (!isCountryCodeActive) {
    return { isValid: true, error: '' };
  }

  const isValidCountryCodeMap = activeFields?.some((activeField) => activeField?.excelColumn === FIELDS_OBJ.COUNTRY_CODE.KEY && activeField?.contactField === FIELDS_OBJ.COUNTRY_CODE.KEY);

  if (!isValidCountryCodeMap) {
    return { isValid: false, error: 'Country code field must be mapped correctly.' };
  }
  return { isValid: true, error: '' };
};

export const detectCountryCallingCode = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');

    if (response.data?.country_calling_code) {
      return response.data?.country_calling_code;
    }
    throw new Error('Country not found; please add the country code to your CSV file and reupload.');
  } catch (err) {
    throw new Error('Unable to fetch location; disable ad blockers or privacy extensions and try again.');
  }
};
