import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getInitials } from '../../../helpers/commonHelpers';

const CallerAvatar = styled.div`
  background-color: #6c63ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  margin-right: 10px;
`;

const CallerName = styled.div`
  font-size: 1rem;
  color: white;
`;

const CallerNumber = styled.span`
  font-size: 0.7rem;
  font-weight: 200;
  color: lightgray;
`;

const StatusText = styled.span`
  font-size: 0.7rem;
  font-weight: 200;
  color: lightgray;
`;

const CallerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

function CallerInfo({ contactName, contactNumber, statusText }) {
  return (
    <>
      <CallerAvatar>{getInitials(contactName) || 'U'}</CallerAvatar>
      <CallerDetails>
        <CallerName>{contactName || 'Unknown'}</CallerName>
        <CallerNumber>{contactNumber || '+91 9999999999'}</CallerNumber>
        {statusText && <StatusText>{statusText}</StatusText>}
      </CallerDetails>
    </>
  );
}

CallerInfo.propTypes = {
  contactName: PropTypes.string,
  contactNumber: PropTypes.string,
  statusText: PropTypes.string,
};

export default CallerInfo;
