export const STEPS_NAME = {
  SETUP_CHANNELS: 'Setup Channels',
  ADD_PHONE_NUMBER: 'Add Phone Number',
  ADD_CONTACTS: 'Add Contacts',
  CREATE_TEMPLATE: 'Create Template',
  BROADCAST_MESSAGE: 'Broadcast Message',
};

export const STEP_DETAILS = {
  [STEPS_NAME.SETUP_CHANNELS]: {
    description: 'Seamlessly connect your account with WhatsApp or Twilio to enable powerful messaging capabilities.',
    buttonLabel: 'Get Connected',
    redirectUrl: '/channels',
  },
  [STEPS_NAME.ADD_PHONE_NUMBER]: {
    description: 'Add your WhatsApp or Twilio phone number to unlock messaging and start reaching your audience.',
    buttonLabel: 'Add Number',
    redirectUrl: '/phone-number',
  },
  [STEPS_NAME.ADD_CONTACTS]: {
    description: 'Build your network by adding contacts to your group and prepare for impactful campaigns.',
    buttonLabel: 'Add Contacts',
    redirectUrl: '/contacts-list',
  },
  [STEPS_NAME.CREATE_TEMPLATE]: {
    description: 'Craft custom message templates for WhatsApp or SMS that resonate with your audience.',
    buttonLabel: 'Create Template',
    redirectUrl: '/message-template',
  },
  [STEPS_NAME.BROADCAST_MESSAGE]: {
    description: 'Send your message to the world. Broadcast to your contacts and make your campaign a success.',
    buttonLabel: 'Start Broadcasting',
    redirectUrl: '/send-whatsapp-message',
  },
};
