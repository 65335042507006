// eslint-disable-next-line import/no-unresolved
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import SimpleBar from 'simplebar-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'simplebar-react/dist/simplebar.min.css';
import moment from 'moment';
import {
  DATE_CONSTANTS, DATE_RANGE_NAMES, DATE_RANGE_OPTIONS, DEFAULT_DATE_RANGE,
} from '../../constants/datetime.constant';

const dateCalculations = {
  Today: () => {
    const today = moment().startOf(DATE_CONSTANTS.UNITS.DAY).toDate();
    return [today, moment().endOf(DATE_CONSTANTS.UNITS.DAY).toDate()];
  },
  Yesterday: () => {
    const yesterdayStart = moment().subtract(1, DATE_CONSTANTS.UNITS.DAYS).startOf(DATE_CONSTANTS.UNITS.DAY).toDate();
    const yesterdayEnd = moment().subtract(1, DATE_CONSTANTS.UNITS.DAYS).endOf(DATE_CONSTANTS.UNITS.DAY).toDate();
    return [yesterdayStart, yesterdayEnd];
  },
  'Last 7 Days': () => [
    moment().subtract(7, DATE_CONSTANTS.UNITS.DAYS).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
    moment().subtract(1, DATE_CONSTANTS.UNITS.DAYS).endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
  ],
  'Last 7 Days + Today': () => [
    moment().subtract(7, DATE_CONSTANTS.UNITS.DAYS).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
    moment().endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
  ],
  'Last 30 Days': () => [
    moment().subtract(30, DATE_CONSTANTS.UNITS.DAYS).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
    moment().endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
  ],
  'This Month': () => [
    moment().startOf(DATE_CONSTANTS.UNITS.MONTH).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
    moment().endOf(DATE_CONSTANTS.UNITS.MONTH).endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
  ],
  'This Year': () => [
    moment().startOf(DATE_CONSTANTS.UNITS.YEAR).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
    moment().endOf(DATE_CONSTANTS.UNITS.YEAR).endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
  ],
  'Last Month': () => [
    moment().subtract(1, DATE_CONSTANTS.UNITS.MONTHS).startOf(DATE_CONSTANTS.UNITS.MONTH).startOf(DATE_CONSTANTS.UNITS.DAY)
      .toDate(),
    moment().subtract(1, DATE_CONSTANTS.UNITS.MONTHS).endOf(DATE_CONSTANTS.UNITS.MONTH).endOf(DATE_CONSTANTS.UNITS.DAY)
      .toDate(),
  ],
  'Last Year': () => [
    moment().subtract(1, DATE_CONSTANTS.UNITS.YEARS).startOf(DATE_CONSTANTS.UNITS.YEAR).startOf(DATE_CONSTANTS.UNITS.DAY)
      .toDate(),
    moment().subtract(1, DATE_CONSTANTS.UNITS.YEARS).endOf(DATE_CONSTANTS.UNITS.YEAR).endOf(DATE_CONSTANTS.UNITS.DAY)
      .toDate(),
  ],
};

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showCustomRange: false,
      selectedOption: DEFAULT_DATE_RANGE,
      customStartDate: null,
      customEndDate: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.setState({
        customStartDate: startDate,
        customEndDate: endDate,
        selectedOption: DATE_RANGE_NAMES.CUSTOM_RANGE,
        showCustomRange: false,
      });
    }
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleOptionClick = (optionValue) => {
    // Handle Custom Range separately
    const { onDateChange } = this.props;
    if (optionValue === DATE_RANGE_NAMES.CUSTOM_RANGE) {
      this.setState({
        selectedOption: optionValue,
        showCustomRange: true,
        customStartDate: null,
        customEndDate: null,
      });
      return;
    }

    // Retrieve the date calculation function based on the option value
    const calculateDates = dateCalculations[optionValue];
    if (calculateDates) {
      const [startDate, endDate] = calculateDates();

      this.setState(
        {
          selectedOption: optionValue,
          showCustomRange: false,
        },
        () => {
          onDateChange([startDate, endDate]);
          this.setState({ isOpen: false });
        },
      );
    }
  };

  handleCustomRange = (dates) => {
    const [startDate, endDate] = dates;
    const { onDateChange } = this.props;
    if (startDate && endDate) {
      this.setState({
        selectedOption: DATE_RANGE_NAMES.CUSTOM_RANGE,
        showCustomRange: false,
      });
      onDateChange([startDate, endDate]);
    } else {
      this.setState({
        customStartDate: startDate,
        customEndDate: endDate,
      });
    }
  };

  render() {
    const {
      isOpen, showCustomRange, selectedOption, customStartDate, customEndDate,
    } = this.state;
    const { startDate, endDate } = this.props;

    return (
      <div className="date-range-selector">
        <Dropdown isOpen={isOpen} toggle={this.toggleDropdown} direction="down">
          <DropdownToggle caret color="primary">
            {moment(startDate).format(DATE_CONSTANTS.FORMATS.MMM_D_YYYY)}
            {' '}
            -
            {moment(endDate).format(DATE_CONSTANTS.FORMATS.MMM_D_YYYY)}
          </DropdownToggle>
          <DropdownMenu className="date-dropdown-menu">
            <SimpleBar className="date-simplebar">
              {DATE_RANGE_OPTIONS.map(({ label, value }) => (
                <DropdownItem
                  key={value}
                  active={selectedOption === value}
                  onClick={() => this.handleOptionClick(value)}
                >
                  {label}
                </DropdownItem>
              ))}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
        {showCustomRange && (
          <div className="custom-range-picker">
            <DatePicker
              selected={customStartDate}
              onChange={this.handleCustomRange}
              startDate={customStartDate}
              endDate={customEndDate}
              selectsRange
              inline
            />
          </div>
        )}
      </div>
    );
  }
}

DateRangeSelector.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func,
};
export default DateRangeSelector;
