/* eslint-disable import/prefer-default-export */
export const CONVERSATIONS_LIMIT = 20;
export const CONVERSATIONS_MESSAGES_LIMIT = 20;
export const PAGE_SIZE = 20;
export const SCROLL_TO_BOTTOM_DELAY = 100;
export const MIN_SCROLL_HEIGHT = 300;
export const LAZY_LOAD_DELAY = 250;
export const MAX_UNREAD_MESSAGE_COUNT = 9;

export const DEFAULT_ASSIGNEE_OPTION = { label: 'Unassign Conversation', value: 'unnassign', unassigned: true };
export const UNASSIGN_VALUE = 'unnassign';

export const SOURCE_TYPES = {
  CONVERSATION: 'conversation',
  CONTACT: 'contact',
};

export const UPDATE_CONVERSATION_KEYS = {
  MENTIONED_USER_ID: 'mentioned',
  STATUS: 'status',
  ASSIGNED_USER_ID: 'assignedUserId',
};

export const FILTER_KEYS = {
  READ: 'read',
  UNREAD: 'unread',
};

export const DND_TRIGGERS = {
  BLOCKED: 'blocked',
};

export const STATUS_TYPE = {
  RESOLVED: 'resolved',
  OPEN: 'open',
};

export const ALL_STATUS_TYPE = {
  RESOLVED: 'resolved',
  OPEN: 'open',
  READ: 'read',
  UNREAD: 'unread',
};

export const ASSIGNED_USER_TYPE = {
  USER: 'user',
  TEAM: 'team',
  BOT: 'bot',
};

export const CONVERSATION_TYPE = {
  WHATSAPP: 'whatsapp',
  TEXT: 'text',
};

export const CREATABLE_SELECT_ACTIONS = {
  CREATE_OPTION: 'create-option',
  SELECT_OPTION: 'select-option',
  REMOVE_VALUE: 'remove-value',
};

export const TAB_ITEMS = [
  { label: 'all', icon: 'mdi mdi-format-list-bulleted font-size-18' },
  { label: 'mine', icon: 'mdi mdi-account font-size-18' },
  { label: 'mention', icon: 'mdi mdi-at font-size-18' },
  { label: 'resolved', icon: 'mdi mdi-check-circle font-size-18' },
  { label: 'bot', icon: 'bx bx-bot font-size-18' },
];

export const CHAT_INPUT_TABS = [
  { label: 'Reply', icon: 'mdi mdi-reply font-size-18', value: 'reply' },
  { label: 'Notes', icon: 'mdi mdi-notebook-outline font-size-18', value: 'notes' },
];
export const NOTES_TAB_INDEX = 1;

export const INBOX_INFO_TABS = [
  { label: 'Profile', icon: '', value: 'profile' },
  { label: 'Notes', icon: '', value: 'notes' },
  { label: 'Timeline', icon: '', value: 'timeline' },
];

export const NOTES_TAB = 'notes';
export const REPLY_TAB = 'reply';
export const TIMELINE_TAB = 'timeline';

export const ARCHIEVED_TAB = 'archieved';
export const MENTION_TAB = 'mention';
export const MINE_TAB = 'mine';
export const RESOLVED_TAB = 'resolved';
export const ALL_TAB = 'all';

export const DUMMY_TIMELINE_DATA = [
  {
    date: '15 Oct, 2024 at 2:30PM',
    icon: 'mdi mdi-account-plus',
    color: '#3B82F6',
    content: "Contact 'John Doe' created by Emily Johnson",
  },
  {
    date: '15 Oct, 2024 at 2:35PM',
    icon: 'mdi mdi-tag-plus',
    color: '#22C55E',
    content: "Tag 'New Lead' added to contact 'John Doe' by Emily Johnson",
  },
  {
    date: '15 Oct, 2024 at 3:15PM',
    icon: 'mdi mdi-chat-processing',
    color: '#F59E0B',
    content: "Conversation initiated with 'John Doe' by Emily Johnson",
  },
  {
    date: '16 Oct, 2024 at 10:00AM',
    icon: 'mdi mdi-account-switch',
    color: '#8B5CF6',
    content: "Contact 'John Doe' assigned to Mark Wilson by Emily Johnson",
  },
  {
    date: '16 Oct, 2024 at 11:30AM',
    icon: 'mdi mdi-note-text',
    color: '#EC4899',
    content: "Note added to contact 'John Doe' by Mark Wilson",
  },
  {
    date: '17 Oct, 2024 at 9:45AM',
    icon: 'mdi mdi-phone-in-talk',
    color: '#10B981',
    content: "Outgoing call initiated to 'John Doe' by Mark Wilson",
  },
  {
    date: '17 Oct, 2024 at 2:00PM',
    icon: 'mdi mdi-flag-variant',
    color: '#F97316',
    content: "Lead stage changed to 'Qualified' for 'John Doe' by Mark Wilson",
  },
  {
    date: '18 Oct, 2024 at 11:00AM',
    icon: 'mdi mdi-email-check',
    color: '#6366F1',
    content: "Marketing opt-in added for 'John Doe' by Sarah Lee",
  },
  {
    date: '19 Oct, 2024 at 3:30PM',
    icon: 'mdi mdi-check-circle',
    color: '#059669',
    content: "Contact 'John Doe' marked as resolved by Mark Wilson",
  },
  {
    date: '20 Oct, 2024 at 10:15AM',
    icon: 'mdi mdi-refresh',
    color: '#DC2626',
    content: "Contact 'John Doe' re-opened by Emily Johnson",
  },
];
