import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Button, Card, CardBody, CardText, Label,
} from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { SOURCE_TYPES } from '../../../constants/channels/conversation.constant';
import emptyContacts from '../../../assets/images/contacts/emptyContact.png';
import DeleteModal from '../../../components/Common/DeleteModal';
import { DATE_CONSTANTS, DATE_RANGE_NAMES } from '../../../constants/datetime.constant';
import { getInitials, sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import RichTextEditorWithMentions from '../../../components/EditorWithMention';
import { convertHTMLToText } from '../../../utils/conversation.util';
import { capitalizeFirstLetter } from '../../../utils/general';
import { showToast } from '../../../services/toastService';
import { toastType } from '../../../constants/toast';
import { fetchUsersByAssignedNumber } from '../../../services/api/conversation.service';
import { deleteNote, updateContact } from '../../../services/api/contacts.service';

function NoteList({
  // contact related props
  selectedContact,
  contactList,
  updateSelectedContact,
  updateContactList,

  // conversation related props
  activeConversation,
  onSetActiveConversation, // Sets the selected or active conversation
  onSetConversationList,
}) {
  const [notes, setNotes] = useState([]);
  const [expandedNotes, setExpandedNotes] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [noteState, setNoteState] = useState({});
  const [assignedUsers, setAssignedUsers] = useState([]);

  const getNotes = () => {
    let updatedNotes = [];
    if (activeConversation && activeConversation.notes) {
      updatedNotes = activeConversation.notes;
    } else if (selectedContact && selectedContact.notes) {
      updatedNotes = selectedContact.notes;
    }
    setNotes(updatedNotes);
  };

  // Function to retrieve the assigned users for the conversation sender's number, providing options for the mention list.
  const getAssignedUsers = async () => {
    const users = await fetchUsersByAssignedNumber(
      `+${activeConversation?.from}`,
      activeConversation?.channel,
    );
    const mentionOptions = users?.data?.map((user) => ({
      id: user?.id,
      value: user?.name,
    }));
    setAssignedUsers(mentionOptions);
  };

  useEffect(() => {
    getNotes();
  }, [activeConversation?.id, selectedContact?.id]);

  useEffect(() => {
    if (!isEmpty(activeConversation)) {
      getAssignedUsers();
    }
  }, [activeConversation?.assignedUserId]);

  const onClickDelete = (note) => {
    setDeleteModal(true);
    setNoteState(note);
  };

  const handleEditClick = (note) => {
    setEditMode(true);
    setNoteToEdit(note);
  };

  const resetNoteEditingState = () => {
    setEditMode(false);
    setNoteToEdit(null);
  };

  const formatDate = (date) => {
    const noteDate = moment(date);
    const now = moment();

    if (now.isSame(noteDate, DATE_CONSTANTS.UNITS.DAY)) {
      return `${DATE_RANGE_NAMES.TODAY}, ${noteDate.format(DATE_CONSTANTS.FORMATS.HH_MMA)}`;
    }
    if (now.subtract(1, DATE_CONSTANTS.UNITS.DAYS).isSame(noteDate, DATE_CONSTANTS.UNITS.DAY)) {
      return `${DATE_RANGE_NAMES.YESTERDAY}, ${noteDate.format(DATE_CONSTANTS.FORMATS.HH_MMA)}`;
    }
    if (now.isSame(noteDate, DATE_CONSTANTS.UNITS.WEEK)) {
      return noteDate.format(`${DATE_CONSTANTS.FORMATS.DDDD}, ${DATE_CONSTANTS.FORMATS.HH_MMA}`);
    }
    return noteDate.format(
      `${DATE_CONSTANTS.FORMATS.MMM_DD_YYYY}, ${DATE_CONSTANTS.FORMATS.HH_MMA}`,
    );
  };

  const toggleNote = (index) => {
    setExpandedNotes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderNoteContent = (note, index) => {
    const isExpanded = expandedNotes[index];
    const noteContent = convertHTMLToText(note?.content);
    const truncatedMessage = sliceStringWithEllipsis(30, noteContent);

    return (
      <>
        <CardText id={`note-${index}`} className="mb-0">
          {isExpanded ? noteContent : truncatedMessage}
        </CardText>
        {noteContent?.length > 30 && (
          <Button className="ps-0" color="link" onClick={() => toggleNote(index)}>
            {isExpanded ? 'View Less' : 'View More'}
          </Button>
        )}
      </>
    );
  };

  // Updates conversation in the conversation list
  const updateConversationInList = (updatedConversation) => {
    onSetConversationList((prevConversations) => {
      const index = prevConversations.findIndex((conv) => conv?.id === activeConversation?.id);

      // Return updated list if conversation found, otherwise return unchanged list
      if (index !== -1) {
        const updatedConversations = [...prevConversations];
        updatedConversations[index] = updatedConversation;
        return updatedConversations;
      }
      return prevConversations;
    });
  };

  // Updates states related to conversation
  const handleConversationUpdates = (responseData) => {
    // Clone current conversation to avoid direct state mutation
    const updatedActiveConversation = cloneDeep(activeConversation);

    // Filter notes specific to active conversation
    const conversationNotes = responseData?.notes?.filter(
      (note) => note?.sourceId === activeConversation?.id,
    );

    // Update notes state and conversation object
    setNotes(conversationNotes);
    updatedActiveConversation.notes = conversationNotes;
    onSetActiveConversation(updatedActiveConversation);

    // Update conversation in the list
    updateConversationInList(updatedActiveConversation);
  };

  // Updates states related to contact
  const handleContactUpdates = (responseData) => {
    setNotes(responseData?.notes);
    updateSelectedContact(responseData);

    // Update contact in contact list
    const index = contactList.findIndex((contact) => contact?.id === selectedContact?.id);

    if (index !== -1) {
      const updatedContacts = [...contactList];
      updatedContacts[index] = responseData;
      updateContactList(updatedContacts);
    } else {
      updateContactList(contactList);
    }
  };

  // Updates application states based on API response for conversations and contacts
  const updateStates = (response) => {
    const { data: responseData } = response || {};

    // Handle active conversation updates
    if (!isEmpty(activeConversation)) {
      handleConversationUpdates(responseData);
      // Handle selected contact updates
    } else if (!isEmpty(selectedContact)) {
      handleContactUpdates(responseData);
    }
  };

  const handleCreateOrUpdateNote = async (noteData) => {
    try {
      const contactId = selectedContact?.id || activeConversation?.contactId;
      // Send a request to update the contact with the new or updated note
      const response = await updateContact(contactId, {
        note: noteData,
      });

      // Check if the response is successful
      if (response?.status) {
        // If in edit mode, reset editing state for the note
        if (editMode) {
          resetNoteEditingState(); // Reset states related to note update
        }

        // Update any relevant states with the response data
        updateStates(response);

        // Show a success toast notification to inform the user that the note was added or updated successfully
        showToast({
          content: editMode
            ? 'Note updated successfully!'
            : 'Note saved successfully!' || response?.message,
          type: toastType.success,
        });
      } else {
        // Show an error toast notification if response indicates failure
        showToast({
          content: 'Oops! Something went wrong!' || response?.message,
          type: toastType.error,
        });
      }
    } catch (error) {
      console.error('Error at handleCreateOrUpdateNote:', error);
    }
  };

  const handleDeleteNote = async (noteData) => {
    try {
      const contactId = selectedContact?.id || activeConversation?.contactId;
      // Send a request to delete the specified note for the selected contact or conversation
      const response = await deleteNote(contactId, noteData._id);

      // Check if the delete operation was successful
      if (response?.status) {
        // Update relevant states with the response data
        updateStates(response);

        // Update the notes list in state after deletion
        setNotes(response?.data?.notes);

        // Show a success toast notification indicating the note was deleted
        showToast({
          content: response?.message || 'Note deleted successfully!',
          type: toastType.success,
        });
      } else {
        // Show an error toast notification if the deletion was unsuccessful
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      console.error('Error at handleDeleteNote:', error);
    } finally {
      // Close the delete confirmation modal
      setDeleteModal(false);
    }
  };

  const handleOnSubmit = async (data, mentionedUsers) => {
    // Extract mentioned user IDs from the mentionedUsers array
    const mentionedUserIds = mentionedUsers?.map((user) => user?.id) || [];

    // Prepare the note data object with content and mentioned user IDs
    const noteData = {
      content: data,
      mentionedUsers: mentionedUserIds,
      // It indicates that the note has been added from conversation or contact
      source: isEmpty(selectedContact) ? SOURCE_TYPES.CONVERSATION : SOURCE_TYPES.CONTACT,
    };

    // If editing an existing note, include the note ID in noteData
    if (noteToEdit) {
      noteData.noteId = noteToEdit._id;
    }

    // If there is no selected contact, set the sourceId as the active conversation ID
    // Required if the source is not 'contact'
    if (isEmpty(selectedContact)) {
      noteData.sourceId = activeConversation?.id;
    }

    try {
      // Call function to create or update the note with the prepared note data
      await handleCreateOrUpdateNote(noteData);
    } catch (error) {
      // Log any errors that occur during the note submission
      console.error('Error creating or updating note:', error);
    }
  };

  return (
    <div className="">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteNote(noteState)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <RichTextEditorWithMentions
        handleOnSubmit={handleOnSubmit}
        editMode={editMode}
        onSetEditMode={(bool) => setEditMode(bool)}
        onSetNoteToEdit={(data) => setNoteToEdit(data)}
        noteToEdit={noteToEdit}
        mentionOptions={assignedUsers}
      />
      <div>
        <Label className="fw-medium">Note List</Label>
        <PerfectScrollbar className="mt-1 note-list-scroll">
          <div className="">
            {notes?.length > 0 ? (
              notes?.map((note, index) => (
                <Card key={`${note?.id}-${index + 1}`} className="mb-2 note-card">
                  <CardBody className="font-size-14 selected-note">
                    <div className=" mb-2 d-flex justify-content-between text-muted">
                      <div className="d-flex align-items-center mb-1">
                        <div className="rounded-circle avatar-xs bg-primary d-flex align-items-center justify-content-center me-1">
                          <span className="fw-semibold text-light">
                            {getInitials(note?.createdBy?.name)}
                          </span>
                        </div>
                        <span>{capitalizeFirstLetter(note?.createdBy?.name)}</span>
                      </div>
                      <div className="d-flex flex-row note-actions justify-content-end">
                        <div
                          className="me-2 bg-transparent border-none"
                          onClick={() => handleEditClick(note)}
                        >
                          <i className="mdi mdi-pencil font-size-18 text-success" />
                        </div>
                        <div
                          className="d-flex bg-transparent border-none"
                          onClick={() => onClickDelete(note)}
                        >
                          <i className="mdi mdi-trash-can-outline font-size-18 text-danger" />
                        </div>
                      </div>
                    </div>
                    {renderNoteContent(note, index)}
                    <div className="d-flex flex-row note-actions justify-content-end">
                      <div className="d-flex align-items-center gap-2">
                        <i className="mdi mdi-calendar-clock mt-1" />
                        <small>{formatDate(note?.createdAt)}</small>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))
            ) : (
              <div>
                <img src={emptyContacts} width="100%" alt="No contacts" />
              </div>
            )}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

NoteList.propTypes = {
  // contact related proptypes
  selectedContact: PropTypes.object,
  contactList: PropTypes.array,
  updateSelectedContact: PropTypes.func,
  updateContactList: PropTypes.func,
  // conversation realated proptypes
  activeConversation: PropTypes.object,
  onSetActiveConversation: PropTypes.func,
  onSetConversationList: PropTypes.func,
};

export default NoteList;
