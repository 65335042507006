import React from 'react';
import PropTypes from 'prop-types';
import { Col as ReactStrapCol } from 'reactstrap';
import styled from 'styled-components';

const StyledColumn = styled(ReactStrapCol)`
  // Add any custom styles here
`;

function Col({ children, className = '', ...props }) {
  return (
    <StyledColumn className={className} {...props}>
      {children}
    </StyledColumn>
  );
}

Col.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Col;
