/* eslint-disable import/prefer-default-export */
import {
  SET_ONBOARDING_DATA,
  SET_ONBOARDING_STATUS,
} from './actionTypes';

export const setOnboardingStatus = (status) => ({
  type: SET_ONBOARDING_STATUS,
  payload: status,
});

export const setOnboardingData = (data) => ({
  type: SET_ONBOARDING_DATA,
  payload: data,
});
