import { axiosGet } from '../http.service';
import { handleError } from '../errorHandler';

export const getTwilioVoiceToken = async (organizationId) => {
  try {
    if (!organizationId) {
      throw new Error('Organization ID is required');
    }
    const response = await axiosGet(`organization/${organizationId}/voice-access-token`);
    if (response.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to fetch Twilio voice token');
  } catch (error) {
    handleError('Error fetching Twilio voice token:', error);
    throw error;
  }
};

export default {
  getTwilioVoiceToken,
};
