import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
import styled from 'styled-components';
import Button from '../Button';
import { handleError } from '../../services/errorHandler';

const StyledDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 1rem;
    padding: 0rem 2rem 1rem 2rem;
  }
  .p-dialog-header {
    padding: 0.5rem;
  }
`;

const IconWrapper = styled.div`
  font-size: 6em;
  color: orange;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;

function DeleteModal({
  show, onCloseClick, onDeleteClick, buttonText = 'Yes, delete it!', message = "You won't be able to revert this!",
}) {
  const handleDeleteClick = () => {
    try {
      onDeleteClick();
    } catch (error) {
      handleError.handleError(error);
    }
  };

  const renderFooter = () => (
    <div className="d-flex justify-content-center text-center">
      <Button
        type="button"
        onClick={handleDeleteClick}
        label={buttonText || 'Yes, delete it!'}
        icon="bx bx-check"
        iconSize="1.5rem"
        iconLeft
        className="p-button-success me-2"
      />
      <Button
        type="button"
        onClick={onCloseClick}
        label="Cancel"
        icon="bx bx-x"
        iconSize="1.5rem"
        iconLeft
        className="p-button-danger"
      />
    </div>
  );

  return (
    <StyledDialog
      visible={show}
      onHide={onCloseClick}
      footer={renderFooter()}
      closeOnEscape
      dismissableMask
    >
      <ContentWrapper>
        <IconWrapper>
          <i className="bx bx-error-circle" />
        </IconWrapper>
        <h2>Are you sure?</h2>
        <h4>{message}</h4>
      </ContentWrapper>
    </StyledDialog>
  );
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  message: PropTypes.string,
};

export default DeleteModal;
