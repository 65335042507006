import { axiosGet, axiosPatch } from '../http.service';

// Function to get private credentials
export const getPrivateCredentials = async (organizationId) => {
  try {
    const response = await axiosGet(`/organization/${organizationId}/private-cred`);
    return response;
  } catch (error) {
    console.error('Error fetching private credentials:', error);
    throw error;
  }
};

export const updateOrganization = async (organizationId, updateData) => {
  try {
    const response = await axiosPatch(`/organization/${organizationId}`, updateData);
    return response;
  } catch (error) {
    console.error('Error updating organization:', error);
    throw error;
  }
};
