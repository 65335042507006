/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import { updateOnboarding } from '../services/api/onboarding.service';

export const handleOnboardingStepCompletion = async ({
  stepName,
  organizationId,
  history,
  setOnboardingData,
}) => {
  const updateData = {
    steps: [
      {
        name: stepName,
        completed: true,
      },
    ],
  };
  try {
    const response = await updateOnboarding(organizationId, updateData);
    if (response?.status) {
      setOnboardingData(response?.data);
      history.push('/');
    } else {
      toast.error(response?.message || 'Failed to update onboarding step.');
    }
  } catch (error) {
    console.error('Error updating onboarding step:', error);
  }
};
