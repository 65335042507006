import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'primereact/utils';
import styled from 'styled-components';

const StyledLabel = styled.label`
`;

const Label = React.forwardRef((props, ref) => {
  const { children, className, ...otherProps } = props;

  return (
    <StyledLabel
      ref={ref}
      {...otherProps}
      className={classNames('p-label', className)}
    >
      {children}
    </StyledLabel>
  );
});

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Label;
