/* eslint-disable import/prefer-default-export */
import { handleError } from '../errorHandler';
import { axiosGet } from '../http.service';

export const fetchAssignedWhatsappNumbers = async () => {
  try {
    const response = await axiosGet('/whatsapp-numbers/assigned');
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to phone numbers');
  } catch (error) {
    handleError('Error fetching phone numbers:', error);
    throw error;
  }
};
