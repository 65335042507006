import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import 'react-drawer/lib/react-drawer.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AlertNotificationDropdown from '../TopbarDropdown/AlertNotificationDropdown';
import CreateWorkspaceModal from '../../pages/AdminManagement/Workspace/Modal/CreateWorkspaceModal';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../../helpers/commonHelpers';
import { axiosGet, axiosPatch, axiosPost } from '../../services/http.service';
import LogoIcon from '../../assets/images/logo-icon.png';
import {
  toggleCallWidgetVisibility as toggleCallWidgetVisibilityAction,
  toggleInitializeNewSocket as toggleInitializeNewSocketAction,
  updateNotification as updateNotificationAction,
} from '../../store/actions';
import { UserContext } from '../UserProvider/UserProvider';
import ProfileMenu from '../TopbarDropdown/ProfileMenu';
import NotificationDropdown from '../TopbarDropdown/NotificationDropdown';
import WorkspaceDropdown from '../TopbarDropdown/WorkspaceDropdown';
import Button from '../Button';
import MenuIcon from './MenuIcon';

function Header({ toggleMenuCallback, onSetLoader }) {
  const dispatch = useDispatch();
  const { userData, getUserinfo } = useContext(UserContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] = useState(false);

  useEffect(() => {
    if (userData) {
      const assignedWorkspaces = userData?.assignedWorkspaces;
      const activeWorkspace = userData?.activeWorkspace;
      setWorkspaces(assignedWorkspaces);
      setSelectedWorkspace(activeWorkspace);
    } else {
      getUserinfo().then((user) => {
        if (user) {
          const { assignedWorkspaces, activeWorkspace } = user;
          setWorkspaces(assignedWorkspaces);
          setSelectedWorkspace(activeWorkspace);
        }
      });
    }
  }, [userData, getUserinfo]);

  const handleGetUnreadMessages = async () => {
    try {
      const response = await axiosGet('/conversations/unread-messages');
      if (response?.status) {
        dispatch(updateNotificationAction(response?.data));
      }
    } catch (error) {
      console.error('error at handleNotificationClick', error);
    }
  };

  const handleBackToAdmin = async () => {
    setDataInLocalStorage('accessToken', getDataFromLocalStorage('adminAccessToken'));
    setDataInLocalStorage('refreshToken', getDataFromLocalStorage('adminRefreshToken'));
    localStorage.removeItem('adminAccessToken');
    localStorage.removeItem('adminRefreshToken');
    await getUserinfo();
    dispatch(toggleInitializeNewSocketAction());
    handleGetUnreadMessages();
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleActiveWorkspace = async (workspace) => {
    const requestBody = {
      activeWorkspace: {
        name: workspace.name,
        workspaceId: workspace.workspaceId,
      },
    };
    try {
      const response = await axiosPatch(`/users/${userData?.id}`, requestBody);
      if (response?.status) {
        window.location.reload();
      } else {
        onSetLoader(false);
        toast.error(response?.message || 'Oops ! something went wrong');
      }
    } catch (error) {
      // Handle error
    }
  };

  const selectWorkspace = async (workspace) => {
    await handleActiveWorkspace(workspace);
    setSelectedWorkspace(workspace);
    setDropdownOpen(false);
  };

  const toggleCreateWorkspaceModal = () => {
    setShowCreateWorkspaceModal((prevState) => !prevState);
  };

  const createNewWorkspace = async (value) => {
    const requestBody = {
      name: value.name,
    };

    try {
      const response = await axiosPost('/workspace', requestBody);
      if (response?.status) {
        toast.success(response?.message || 'Workspace created successfully!');
        getUserinfo();
        setWorkspaces(userData.assignedWorkspaces || []);
        window.location.reload();
        toggleCreateWorkspaceModal();
      } else {
        toast.error(response?.message || 'Failed to create workspace');
      }
    } catch (error) {
      onSetLoader(false);
      toast.error('Error creating workspace');
    }
  };

  const workspaceOptions = workspaces?.filter(
    (workspace) => workspace.workspaceId !== selectedWorkspace.workspaceId,
  );

  return (
    <>
      <CreateWorkspaceModal
        isOpen={showCreateWorkspaceModal}
        toggle={toggleCreateWorkspaceModal}
        createNewWorkspace={createNewWorkspace}
      />
      <header id="page-topbar">
        <div className="navbar-header position-relative">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={LogoIcon} alt="" height="35" />
                </span>
              </Link>
            </div>
            <MenuIcon toggleMenuCallback={toggleMenuCallback} />
            <WorkspaceDropdown
              dropdownOpen={dropdownOpen}
              toggleDropdown={toggleDropdown}
              selectedWorkspace={selectedWorkspace}
              workspaceOptions={workspaceOptions}
              selectWorkspace={selectWorkspace}
              userData={userData}
              toggleCreateWorkspaceModal={toggleCreateWorkspaceModal}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            {getDataFromLocalStorage('adminAccessToken') ? (
              <div className="d-flex align-items-center">
                <Button
                  type="button"
                  onClick={handleBackToAdmin}
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                  id="vertical-menu-btn"
                  icon="mdi"
                  iconClassName="mdi mdi-account-arrow-left-outline font-size-16 me-1"
                  label="Admin"
                />
              </div>
            ) : (
              ''
            )}
            <NotificationDropdown />
            <AlertNotificationDropdown />
            <div className="dropdown d-inline-block">
              <Button
                aria-label="phone"
                type="button"
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
                onClick={() => dispatch(toggleCallWidgetVisibilityAction())}
                icon="bx"
                iconClassName="bxs-phone"
              />
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
}

Header.propTypes = {
  toggleMenuCallback: PropTypes.func.isRequired,
  onSetLoader: PropTypes.func,
};

export default Header;
