import React, { Component } from 'react';
import {
  Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';

// Import Breadcrumb
import { tabs } from '../../../constants/tabs';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import SingleMessage from './Form/SingleMessage';
import BulkMessage from './Form/BulkMessage';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabs.BULK,
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleMenu() {
    this.setState((prevState) => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    // meta title
    document.title = 'message';
    const { activeTab } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Campaign" breadcrumbItem="Message" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="crypto-buy-sell-nav">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === tabs.BULK,
                          })}
                          onClick={() => {
                            this.toggleTab(tabs.BULK);
                          }}
                        >
                          Bulk
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === tabs.SINGLE,
                          })}
                          onClick={() => {
                            this.toggleTab(tabs.SINGLE);
                          }}
                        >
                          Single
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="">
                    {activeTab === tabs.BULK ? <BulkMessage /> : ''}
                    {activeTab === tabs.SINGLE ? <SingleMessage /> : ''}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Message;
