import {
  CALL_INITIATED, ELAPSED_TIME, START_TIMER, TWILIO_VOICE_TOKEN,
} from './actionTypes';

export const setTwilioVoiceToken = (token) => ({
  type: TWILIO_VOICE_TOKEN,
  payload: token,
});

export const setStartTimer = (bool) => ({
  type: START_TIMER,
  payload: bool,
});

export const setElaspedTime = (value) => ({
  type: ELAPSED_TIME,
  payload: value,
});

export const setIsCallInitiated = (bool) => ({
  type: CALL_INITIATED,
  payload: bool,
});
