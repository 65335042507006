/* eslint-disable import/prefer-default-export */

// NOTE: `BMESSAGE`: Broadcast message
export const SET_STEP_COMPLETION_BMESSAGE = 'SET_STEP_COMPLETION_BMESSAGE';
export const SET_ACTIVE_INDEX_OF_BMESSAGE_FORM = 'SET_ACTIVE_INDEX_OF_BMESSAGE_FORM';
export const RESET_BMESSAGE_FORM = 'RESET_BMESSAGE_FORM';

/* Step 1 */
export const SET_BMESSAGE_STEP_ONE_DATA = 'SET_BMESSAGE_STEP_ONE_DATA';

/* Step 2 */
export const SET_BMESSAGE_STEP_TWO_DATA = 'SET_BMESSAGE_STEP_TWO_DATA';
export const SET_BMESSAGE_CONTACT_GROUPS = 'SET_BMESSAGE_CONTACT_GROUPS';

/* Step 3 */
export const SET_BMESSAGE_STEP_THREE_DATA = 'SET_BMESSAGE_STEP_THREE_DATA';
