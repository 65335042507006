/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */

import React, { Component } from 'react';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { axiosPatch, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import VariableSelect from '../VariableSelect';
import { removeIdFromObject } from '../../../../helpers/commonHelpers';
import {
  TEMPLATE_COMPONENT_TYPE, WHATSAPP_TEMPLATES_BUTTON_TYPES, WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS, WHATSAPP_TEMPLATES_PARAMETER_TYPES,
} from '../../../../constants/channels/whatsApp.constant';
import { bytesToMegabytes, capitalizeFirstLetter } from '../../../../utils/general';
import { FILE_SIZE_ALLOWED, FILE_TYPES } from '../../../../constants/file.constant';

class EditWhatsapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
      initialValues: {},
      validationSchema: '',
      uploadedFile: '',
      prevUploadedFile: '',
      selectedVariable: {},
    };
  }

  componentDidMount() {
    const { template } = this.props;

    this.setState(
      {
        initialValues: {
          templateName: template?.templateName || '',
        },
        validationSchema: {
          templateName: Yup.string().required('Please enter template name'),
        },
      },
      () => {
        this.initializeValues();
      },
    );
  }

  initializeValues = () => {
    const { template } = this.props;

    const { initialValues, validationSchema } = this.state;

    template?.template?.components.forEach((component) => {
      if (component.type !== 'button') {
        component.parameters.forEach((parameter, index) => {
          if (parameter.type === 'text') {
            const fieldName = `${component.type}-${parameter?.type}-${index}`;
            initialValues[fieldName] = parameter[parameter?.type] || '';
            validationSchema[fieldName] = Yup.string().required('Required');
          } else if (parameter.type === 'image') {
            this.setState({
              prevUploadedFile: parameter?.image?.link,
            });
            const fieldName = `${component.type}-${parameter?.type}-uploadedFile`;
            initialValues[fieldName] = '';
            validationSchema[fieldName] = Yup.string();
          }
        });
      } else {
        component.parameters.forEach((parameter) => {
          const fieldName = `${component.type}-${component?.sub_type}-${parameter?.type}-${component?.index}`;
          initialValues[fieldName] = parameter[parameter?.type] || parameter?.text || '';
          validationSchema[fieldName] = Yup.string().required('Required');
        });
      }
    });

    this.setState({
      initialValues,
      validationSchema,
    });
  };

  handleUpdateTemplate = async (templateData) => {
    try {
      const { toggle } = this.props;
      this.setState({ showModalLoader: true });
      const { getAllTemplates, template } = this.props;
      const response = await axiosPatch(
        `whatsapp-template/${template?.id}`,
        templateData,
      );
      if (response?.status) {
        toast.success(response?.message || 'Template updated successfully!');
        getAllTemplates();
        toggle();
      } else {
        toast.error('Oops! something went wrong' || response?.message);
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleUpdateTemplate :', error);
    }
  };

  handleFileUpload = async (event, setFieldValue, fieldName, fileTypeParam) => {
    const file = event.target.files?.[0];
    const fileSize = event.currentTarget.files?.[0]?.size;
    const sizeInMb = bytesToMegabytes(fileSize);
    const sizeAllowed = FILE_SIZE_ALLOWED?.[fileTypeParam?.toUpperCase()];

    if (sizeInMb < sizeAllowed) {
      setFieldValue(fieldName, event.target.value);
      this.setState({
        uploadedFile: file,
      });
    } else {
      toast.error(
        `${capitalizeFirstLetter(
          fileTypeParam,
        )} uploaded must be under ${sizeAllowed} MB in size`,
      );
    }
  };

  handleFileAddition = async () => {
    try {
      const { uploadedFile } = this.state;
      // Create formData object and append imageAttachment
      const formData = new FormData();
      formData.append('file', uploadedFile);

      // Upload image
      this.setState({ showModalLoader: true });
      const uploadResponse = await axiosPost('upload', formData);

      this.setState({ showModalLoader: false });
      return uploadResponse;
    } catch (error) {
      console.error('error at handleFileAddition:'.error);
      return null;
    }
  };

  formatTemplate = async (templateData, templateViewObj) => {
    const components = [];
    const { uploadedFile, prevUploadedFile } = this.state;

    // Iterate over the keys of the template data object
    for (const key in templateData) {
      const [type, sub_type, index] = key.split('-'); // Split the key into type and index

      if (templateData.hasOwnProperty(key)) {
        if (
          (type === TEMPLATE_COMPONENT_TYPE.HEADER.toLowerCase()
            && sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.LOCATION)
          || type === TEMPLATE_COMPONENT_TYPE.BODY.toLowerCase()
        ) {
          // Determine the type of component
          let uploadedFilelink = '';
          if (
            sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase()
            && uploadedFile
          ) {
            // eslint-disable-next-line no-await-in-loop
            const response = await this.handleFileAddition();
            uploadedFilelink = response?.data?.destination; // Assuming handleFileAddition sets the uploaded file link
          }

          const componentType = type.toLowerCase();

          let existingComponentIndex = -1;
          // Check if a component of the same type already exists
          for (let i = 0; i < components.length; i += 1) {
            if (components[i] && components[i].type === componentType) {
              existingComponentIndex = i;
              break;
            }
          }

          const parameter = {};
          if (!templateViewObj[type.toLowerCase()]) {
            templateViewObj[type.toLowerCase()] = {
              type: WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase(),
              text: '',
              url: '',
              actualText: '',
            };
          }

          if (sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase()) {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = {
              link: uploadedFilelink || prevUploadedFile,
            };

            templateViewObj[type.toLowerCase()].type = sub_type.toLowerCase();
            templateViewObj[type.toLowerCase()].url = uploadedFilelink;
          } else {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = templateData[key];

            // If index is 1, it means `header` of `body` come first time, hence replace `text` with `actualText`
            if (index === '0') {
              templateViewObj[type.toLowerCase()].text = templateViewObj[type.toLowerCase()].actualText;
            }

            const text = templateViewObj[type.toLowerCase()].text || '';
            templateViewObj[type.toLowerCase()].text = text.replace(`{{${parseInt(index, 10) + 1}}}`, templateData[key]);
          }

          if (existingComponentIndex !== -1) {
            // If a component of the same type already exists, push the parameters to it
            components[existingComponentIndex].parameters.push(parameter);
          } else {
            // If no component of the same type exists, create a new one
            components.push({
              type: componentType,
              parameters: [parameter],
            });
          }
        } else if (type === TEMPLATE_COMPONENT_TYPE.BUTTON.toLowerCase()) {
          const { COPY_CODE, URL } = WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS;

          // Handle button type
          // eslint-disable-next-line no-unused-vars, no-shadow
          const [type, sub_type, parameter_type, index] = key.split('-'); // Split the key into type and index
          const button = {
            type: type.toLowerCase(),
            sub_type: sub_type.toLowerCase(),
            index,
            parameters: [],
          };
          const buttons = templateViewObj?.buttons || [];

          if (sub_type === 'copy_code') {
            button.parameters.push({
              type: 'coupon_code',
              coupon_code: templateData[key],
            });
          } else {
            button.parameters.push({
              type: sub_type.toLowerCase(),
              text: templateData[key],
            });
          }
          templateViewObj.buttons = buttons?.map((buttonObj) => {
            switch (buttonObj?.type) {
              case COPY_CODE.toLowerCase(): {
                return { ...buttonObj, code: templateData[key] };
              }
              case URL.toLowerCase(): {
                return { ...buttonObj, url: buttonObj?.url?.replace(`{{${parseInt(index, 10) + 1}}}`, templateData[key]) };
              }
              default: {
                return buttonObj;
              }
            }
          });
          components.push(button);
        }
      }
    }

    return { components };
  };

  handleSetFieldValue = (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    this.setState((prevState) => ({
      selectedVariable: {
        ...prevState.selectedVariable,
        [fieldName]: value,
      },
    }));
  };

  handleSetSelectedVariable = (variable, fieldName, setFieldValue) => {
    if (variable) {
      this.setState(
        (prevState) => ({
          selectedVariable: {
            ...prevState.selectedVariable,
            [fieldName]: `${
              prevState.selectedVariable[fieldName]
                ? prevState.selectedVariable[fieldName]
                : ''
            }{{${variable}}}`,
          },
        }),
        () => {
          const { selectedVariable } = this.state;
          if (fieldName) {
            setFieldValue(fieldName, selectedVariable[fieldName]);
          }
        },
      );
    }
  };

  renderComponents = (components, errors, touched, setFieldValue, values) => components
    ?.map((component, index) => {
      const formattedType = component.type.charAt(0).toUpperCase()
          + component.type.slice(1).toLowerCase();
      const { uploadedFile } = this.state;
      if (component.type !== 'button') {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="mb-2" key={`${component.type}-${index}`}>
            <Label className="form-label">
              {`${capitalizeFirstLetter(
                component.type,
              )} Parameters`}
            </Label>
            <div className="border border-darkgrey rounded p-3">
              {component?.parameters?.map((parameter, parameterIndex) => {
                if (parameter.type === 'text') {
                  const fieldName = `${component.type}-${parameter.type}-${parameterIndex}`;
                  return (
                    <div key={fieldName} className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label className="form-label">
                          {`{{${
                            parameterIndex + 1
                          }}}`}
                        </Label>
                        <VariableSelect
                          setSelectedVariable={(value) => this.handleSetSelectedVariable(
                            value,
                            fieldName,
                            setFieldValue,
                          )}
                        />
                      </div>
                      <Field
                        name={fieldName}
                        type="text"
                        value={values?.[fieldName]}
                        onChange={(event) => this.handleSetFieldValue(
                          fieldName,
                          event.target.value,
                          setFieldValue,
                        )}
                        className={
                            `form-control${
                              errors[fieldName] && touched[fieldName]
                                ? ' is-invalid'
                                : ''}`
                          }
                      />
                      <ErrorMessage
                        name={fieldName}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  );
                }
                const extension = parameter[parameter.type].link.match(/\.([^.]+)$/)[1];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={parameterIndex} className="mb-3">
                    <Label className="fw-bold">{`Upload ${parameter.type}`}</Label>
                    <Field
                      type="file"
                      id="file"
                      accept={
                            parameter.type === FILE_TYPES.DOCUMENT
                              ? 'application/pdf'
                              : parameter.type === FILE_TYPES.IMAGE
                                ? ['.jpeg', '.png']
                                : parameter.type === FILE_TYPES.VIDEO
                                  ? ['.mp4']
                                  : null // Add a default case or handle appropriately
                          }
                      name={`${component?.type}-${parameter?.type}-uploadedFile`}
                      className={
                            `form-control${
                              errors[
                                `${component?.type}-${parameter?.type}-uploadedFile`
                              ]
                            && touched[
                              `${component?.type}-${parameter?.type}-uploadedFile`
                            ]
                                ? ' is-invalid'
                                : ''}`
                          }
                      onChange={(event) => this.handleFileUpload(
                        event,
                        setFieldValue,
                        `${component?.type}-${parameter?.type}-uploadedFile`,
                        parameter.type,
                      )}
                      value={
                            values[
                              `${component?.type}-${parameter?.type}-uploadedFile`
                            ]
                          }
                    />
                    <ErrorMessage
                      name={`${component?.type}-${parameter?.type}-uploadedFile`}
                      component="div"
                      className="text-danger"
                    />
                    <div className="mt-2">
                      {
                            parameter.type === 'image'
                            && !uploadedFile ? (
                              <img
                                width="auto"
                                height="100px"
                                src={parameter[parameter.type].link}
                                alt="Image_Preview"
                              />
                              ) : parameter.type === 'video'
                              && !uploadedFile ? (
                              // Video content
                                <video width="100%" controls>
                                  <source
                                    src={parameter[parameter.type].link}
                                    type={`video/${extension}`}
                                    width="50%"
                                    height="100px"
                                  />
                                </video>
                                ) : parameter.type === 'document'
                              && !uploadedFile ? (
                                <a
                                  href={parameter[parameter.type].link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Document
                                </a>
                                  ) : null // Fallback in case of other types or undefined type
                          }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
      return component?.parameters?.map((parameter) => {
        const fieldName = `${component.type}-${component?.sub_type}-${parameter?.type}-${component?.index}`;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Label className="form-label">
              {`${formattedType} : ${
                WHATSAPP_TEMPLATES_BUTTON_TYPES[component?.sub_type.toUpperCase()]
              }`}
            </Label>
            <div key={fieldName} className="mb-3">
              <Field
                name={fieldName}
                type="text"
                className={
                      `form-control${
                        errors[fieldName] && touched[fieldName]
                          ? ' is-invalid'
                          : ''}`
                    }
              />
              <ErrorMessage
                name={fieldName}
                component="div"
                className="invalid-feedback"
              />
            </div>
          </React.Fragment>
        );
      });
    })
    .flat();

  handleOnSubmit = async (values) => {
    try {
      const { template } = this.props;
      const { templateViewObj } = template || {};

      const formetedData = await this.formatTemplate(values, templateViewObj);

      const templateData = {};
      templateData.templateName = values?.templateName;
      templateData.template = {};
      templateData.template.name = template?.template?.name;
      templateData.template.language = {};
      templateData.template.language = template?.template?.language;
      templateData.template.components = formetedData?.components;
      templateData.templateViewObj = Object.keys(templateViewObj).reduce((acc, key) => {
        if (Object.values(templateViewObj[key]).length) {
          return { ...acc, [key]: templateViewObj[key] };
        }
        return acc;
      }, {});
      templateData.templateViewObj = removeIdFromObject(templateData.templateViewObj);

      if (templateData) {
        this.handleUpdateTemplate(templateData);
      }
    } catch (error) {
      console.error('error at handleOnSubmit::', error);
    }
  };

  render() {
    const { isEdit, toggle, template } = this.props;
    const { showModalLoader, initialValues, validationSchema } = this.state;

    // const initialValues = {
    //   templateName: template?.templateName || "",
    // };

    // const validationSchema = ;
    return (
      <Modal isOpen={isEdit} className="whatsapp-template">
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={toggle} tag="h4">
          Edit Template
        </ModalHeader>
        <ModalBody
          className={`pb-0
            ${
              template?.template?.components?.length
                ? 'whatsapp-template-body'
                : ''
            }
          `}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors, touched, values, setFieldValue, dirty,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div
                      className="alert alert-warning d-flex align-items-center"
                      role="alert"
                    >
                      <i className="mdi mdi-alert font-size-24 me-3" />
                      <div>
                        Please keep in mind that your template should closely
                        align with the example or sample text/document provided.
                        Deviating significantly may result in rejection by the
                        meta.
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Template Name</Label>
                      <Field
                        name="templateName"
                        type="text"
                        className={
                          `form-control${
                            errors.templateName && touched.templateName
                              ? ' is-invalid'
                              : ''}`
                        }
                      />
                      <ErrorMessage
                        name="templateName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {this.renderComponents(
                      template?.template?.components,
                      errors,
                      touched,
                      setFieldValue,
                      values,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user my-3"
                        disabled={!dirty}
                      >
                        Update Template
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

EditWhatsapp.propTypes = {
  template: PropTypes.object,
  getAllTemplates: PropTypes.func,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default EditWhatsapp;
