import React from 'react';
import { InputSwitch as PrimeInputSwitch } from 'primereact/inputswitch';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Label from '../Label';

const StyledInputSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledLabel = styled(Label)`
  margin-right: 0.5rem;
  margin-bottom: 0rem;
  font-size: 1rem;
  color: var(--bs-body-color);
`;

const StyledInputSwitch = styled(PrimeInputSwitch)`
  .p-inputswitch-slider:before {
    background-color: var(--bs-white);
  }

  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: var(--bs-primary-dark, var(--bs-primary));
  }

  &.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 0.2rem var(--bs-primary-rgb, rgba(0, 123, 255, 0.25));
  }
`;

function InputSwitch({
  label,
  checked,
  onChange,
  disabled = false,
  className = '',
  id,
  ...rest
}) {
  return (
    <StyledInputSwitchWrapper className={className}>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledInputSwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={id}
        {...rest}
      />
    </StyledInputSwitchWrapper>
  );
}

InputSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

InputSwitch.defaultProps = {
  disabled: false,
  className: '',
};

export default InputSwitch;
