import React, { Component } from 'react';

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

// actions
import { axiosPost } from '../../services/http.service';
import { showToast } from '../../store/actions';

// import images
import profile from '../../assets/images/common/profile-img.png';
import LogoIcon from '../../assets/images/logo-icon.png';
import { SOMETHING_WENT_WRONG } from '../../constants/error.constant';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenNotFound: false,
      showPassword: false,
      showConfirmPassword: false,
      error: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match?.params?.token) {
      this.setState({ tokenNotFound: true });
    }
  }

  handleResetPassword = async (values) => {
    const { match, showToast: showToastProp, history } = this.props;
    try {
      const response = await axiosPost(
        `/auth/reset-password?token=${match?.params?.token}`,
        {
          password: values?.password,
        },
      );
      if (response?.status) {
        showToastProp({
          type: 'success',
          message: response?.message || 'Reset Password successfully',
          title: 'Reset Password',
          duration: 3000,
        });
        history.push('/login');
      } else {
        console.error('ResetPassword API error: ', response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error('ResetPassword error: ', error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      tokenNotFound, showConfirmPassword, showPassword, error, password, confirmPassword,
    } = this.state;

    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={LogoIcon}
                              alt="auto-campaign-ai-logo-icon"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={LogoIcon}
                              alt="auto-campaign-ai-logo-icon"
                              height="50"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {tokenNotFound ? (
                        <>
                          <Alert color="danger">
                            Reset password token not found
                          </Alert>
                          <div className="mt-4 d-flex justify-content-end">
                            <a href="/login" className="">
                              Back to login
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {error && error ? (
                            <Alert color="danger">{error}</Alert>
                          ) : null}
                          <Formik
                            enableReinitialize
                            initialValues={{
                              password: this.state && password,
                              confirmPassword:
                                this.state && confirmPassword,
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string().required(
                                'Password is required',
                              ),
                              confirmPassword: Yup.string()
                                .oneOf(
                                  [Yup.ref('password'), null],
                                  'Passwords must match',
                                )
                                .required('Confirm Password is required'),
                            })}
                            onSubmit={this.handleResetPassword}
                          >
                            {({ errors, touched }) => (
                              <Form className="form-horizontal">
                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      name="password"
                                      type={showPassword ? 'text' : 'password'}
                                      autoComplete="true"
                                      className={
                                        `form-control${errors.password && touched.password
                                          ? ' is-invalid'
                                          : ''}`
                                      }
                                    />
                                    <button
                                      className="btn btn-light "
                                      type="button"
                                      id="password-addon"
                                      onClick={() => this.handleStates(
                                        'showPassword',
                                        !showPassword,
                                      )}
                                    >
                                      {showPassword ? (
                                        <i className="mdi mdi-eye-outline" />
                                      ) : (
                                        <i className="mdi mdi-eye-off-outline" />
                                      )}
                                    </button>
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label
                                    for="confirmPassword"
                                    className="form-label"
                                  >
                                    Confirm Password
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      name="confirmPassword"
                                      type={
                                        showConfirmPassword
                                          ? 'text'
                                          : 'password'
                                      }
                                      autoComplete="true"
                                      className={
                                        `form-control${errors.confirmPassword
                                          && touched.confirmPassword
                                          ? ' is-invalid'
                                          : ''}`
                                      }
                                    />
                                    <button
                                      className="btn btn-light "
                                      type="button"
                                      id="password-addon"
                                      onClick={() => this.handleStates(
                                        'showConfirmPassword',
                                        !showConfirmPassword,
                                      )}
                                    >
                                      {showConfirmPassword ? (
                                        <i className="mdi mdi-eye-outline" />
                                      ) : (
                                        <i className="mdi mdi-eye-off-outline" />
                                      )}
                                    </button>
                                    <ErrorMessage
                                      name="confirmPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Reset Password
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  showToast: PropTypes.func,
};

const mapStateToProps = () => { };

export default withRouter(
  connect(mapStateToProps, { showToast })(ResetPassword),
);
