/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-promise-executor-return */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  DEFAULT_ASSIGNEE_OPTION,
  MIN_SCROLL_HEIGHT,
  SCROLL_TO_BOTTOM_DELAY,
  STATUS_TYPE,
} from '../../../constants/channels/conversation.constant';
import Avatar from '../../../components/Avatar';
import { getInitials } from '../../../helpers/commonHelpers';
import { getChannelClassName } from '../../../utils/conversation.util';
import MessageList from './MessageList';
import Button from '../../../components/Button';
import ChatInput from './ChatInput';
import DropdownMenu from '../../../components/DropdownMenu';
import { MINIMUM_DELAY } from '../../../constants/constants';
import { setLoader } from '../../../store/actions';
import Dropdown from '../../../components/Dropdown';
import {
  fetchUsersByAssignedNumber,
  getConversationMessages,
} from '../../../services/api/conversation.service';
import DeleteModal from '../../../components/Common/DeleteModal';

function ChatConversation({
  activeConversation,
  showContactInfo,
  onToggleContactInfo,
  onSetActiveConversation, // Sets the selected or active conversation.
  onSetConversationList, // to set the conversationList
  targetEvent,
  handleUpdateConversation,
}) {
  const [conversationMessages, setConversationMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [assignedUserOptions, setAssignedUserOptions] = useState([]);
  const messageBoxRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(activeConversation)) {
      getConversationMessagesByConversationId(activeConversation, 1);
      getAssignedUsers();
    }
    // The scroll-to-bottom function is delayed because it was implemented before the messageBoxRef (scrollbar reference) was initialized.
    const timeoutId = setTimeout(() => {
      scrollToBottom();
    }, SCROLL_TO_BOTTOM_DELAY);
    return () => clearTimeout(timeoutId);
  }, [activeConversation?.id]);

  useEffect(() => {
    setSelectedOwner(activeConversation?.assignedUserId);
  }, [activeConversation?.assignedUserId]);

  // Function to retrieve the assigned users for the conversation sender's number, providing options for the assignee list.
  const getAssignedUsers = async () => {
    const users = await fetchUsersByAssignedNumber(
      `+${activeConversation?.from}`,
      activeConversation?.channel,
    );
    const mentionOptions = users?.data?.map((user) => ({
      label: user?.name,
      value: user?.id,
    }));
    setAssignedUserOptions([DEFAULT_ASSIGNEE_OPTION, ...mentionOptions]);
  };

  // Function to sort messages by createdAt timestamp.
  const handleNewMessages = (messages) => messages?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));

  // Function to retrieve messages for the selected conversation.
  const getConversationMessagesByConversationId = async (conversation, page) => {
    if (isLoading || page > totalPages) return;

    setIsLoading(true);
    dispatch(setLoader(true));

    try {
      const response = await getConversationMessages(conversation?.id, page);

      if (response?.status) {
        const newMessages = response?.data?.results;

        // Get current scroll height before adding new messages
        const scrollContainer = messageBoxRef.current?._container;
        const previousScrollHeight = scrollContainer?.scrollHeight || 0;
        const previousScrollTop = scrollContainer?.scrollTop || 0;

        setConversationMessages((prevMessages) => {
          // Prepend new messages to existing ones
          if (page === 1) {
            // Sort messages if needed
            return handleNewMessages(newMessages);
          }
          const updatedMessages = [...newMessages, ...prevMessages];
          // Sort messages if needed
          return handleNewMessages(updatedMessages);
        });

        setPage(page + 1);
        setTotalPages(response?.data?.totalPages);

        // If it's the initial load, scroll to the bottom
        if (page !== 1) {
          // Adjust scroll position after new messages are rendered
          setTimeout(() => {
            if (scrollContainer) {
              const newScrollHeight = scrollContainer.scrollHeight;
              const newScrollTop = newScrollHeight - previousScrollHeight + previousScrollTop;
              scrollContainer.scrollTop = newScrollTop;
            }
          }, 0);
        }

        await new Promise((resolve) => setTimeout(resolve, MINIMUM_DELAY));
      }
    } catch (error) {
      console.error('Error fetching conversation messages:', error);
    } finally {
      setIsLoading(false);
      dispatch(setLoader(false));
    }
  };

  // Function to handle onScroll events.
  const onScroll = ({ target }) => {
    // Check if scrolled to top
    if (target.scrollTop === 0 && !isLoading) {
      getConversationMessagesByConversationId(activeConversation, page);
    }
    if (messageBoxRef.current) {
      const container = messageBoxRef.current._container;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const isUserNearBottom = scrollHeight - clientHeight - scrollTop > MIN_SCROLL_HEIGHT;
        setShowScrollButton(isUserNearBottom);
      }
    }
  };

  // Function to handle actions when scrolled to the bottom.
  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      const ps = messageBoxRef.current._ps;
      if (ps) {
        ps.update(); // Update perfect scrollbar
        const scrollHeight = ps.contentHeight;
        ps.element.scrollTop = scrollHeight;
        // Force another update to ensure the scrollbar position is correct
        ps.update();
      }
    }
  };

  // Menu items for the settings button.
  const MENU_ITEMS = [
    {
      label: showContactInfo ? 'Hide Profile' : 'View Profile',
      command: (event) => {
        event.originalEvent.stopPropagation();
        onToggleContactInfo();
      },
    },
    {
      label: 'Clear',
      command: () => {
        /* Add clear chat logic */
      },
    },
  ];

  // Template to render items with icons and labels
  const itemTemplate = (option) => (
    <div className="p-dropdown-item d-flex p-0">
      <div className="d-flex align-items-center me-2">
        <Avatar
          label={getInitials(option?.label)}
          shape="circle"
          className="font-size-14"
          // style is required
          style={option?.unassigned ? { backgroundColor: 'var(--danger-color)' } : {}}
        />
      </div>
      <div className="d-flex flex-column ">
        <span className="item-label font-size-14 ">{option?.label}</span>
      </div>
    </div>
  );

  // Template for the selected value
  const valueTemplate = (option) => {
    if (option) {
      return (
        <div className="p-dropdown-value d-flex align-items-center p-0">
          <div className="d-flex align-items-center me-3">
            <Avatar
              label={getInitials(option?.label)}
              shape="circle"
              className="font-size-14"
              // style is required
              style={option?.unassigned ? { backgroundColor: 'var(--danger-color)' } : {}}
            />
          </div>
          <div className="d-flex flex-column">
            <span className="item-label font-size-14 ">{option?.label}</span>
          </div>
        </div>
      );
    }
    return <span>Select Owner...</span>;
  };

  const handleOnDeleteClick = () => {
    setShowConfirmationModal(false);
    const statusObj = {
      status:
        activeConversation?.status === STATUS_TYPE.OPEN ? STATUS_TYPE.RESOLVED : STATUS_TYPE.OPEN,
    };
    handleUpdateConversation(activeConversation, statusObj);
  };

  const handleOnCloseClick = () => {
    setShowConfirmationModal(false);
  };

  const handleConversationAssigneeChange = (event) => {
    setSelectedOwner(event.value);
    const assigneeObj = { assignedUserId: event.value };
    handleUpdateConversation(activeConversation, assigneeObj);
  };

  const onChangeConversationStatus = (event) => {
    event.stopPropagation();
    setShowConfirmationModal(true);
  };

  return (
    <>
      <DeleteModal
        buttonText={`Yes! ${
          activeConversation?.status === STATUS_TYPE.OPEN ? 'resolve' : 're-open'
        } the conversation`}
        message="You can re-open it again!"
        show={showConfirmationModal}
        onDeleteClick={() => handleOnDeleteClick()}
        onCloseClick={() => handleOnCloseClick()}
      />
      <div className="w-100 user-chatbox">
        <Card className="user-chatbox-card">
          <div className="p-4 border-bottom" onClick={onToggleContactInfo}>
            <Row>
              <Col md="12" xs="12" lg="4">
                <div className="d-flex text-center me-4 align-items-center">
                  <div className="avatar-sm">
                    <span className="avatar-title rounded-circle bg-soft primary text font-size-16">
                      {getInitials(activeConversation?.name)}
                    </span>
                  </div>
                  <div>
                    <h5 className="ms-2 font-size-15 mb-1">{activeConversation?.name}</h5>
                    <p className="ms-2 text-muted mb-0 text-start d-flex align-items-center">
                      <i className={getChannelClassName(activeConversation.channel)} />
                      {activeConversation?.to}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="12" xs="12" lg="8" className="d-flex justify-content-end align-items-center">
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item d-none d-sm-inline-block">
                    <DropdownMenu
                      model={MENU_ITEMS}
                      isButtonRequired
                      popup
                      buttonIcon="bx bx-cog font-size-24 text-secondary mt-1"
                      buttonClassName=""
                      ariaControls="Settings"
                      popupAlignment="right"
                    />
                  </li>
                </ul>
                <div>
                  <Button
                    label={activeConversation?.status === STATUS_TYPE.OPEN ? 'Resolve' : 'Re-open'}
                    icon="mdi mdi-tooltip-check-outline"
                    className="me-2 btn btn-light border border-secondary rounded-pill py-1 px-3"
                    iconClassName="font-size-18"
                    iconLeft
                    onClick={onChangeConversationStatus}
                  />
                </div>
                <div>
                  <Dropdown
                    value={selectedOwner}
                    options={assignedUserOptions}
                    onChange={handleConversationAssigneeChange}
                    onClick={(event) => event.stopPropagation()}
                    optionLabel="label"
                    optionNumber="value"
                    placeholder="Select ..."
                    className="width-15rem"
                    dropdownIcon=""
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="h-100">
            <div className="chat-conversation p-3 main-chatbox h-100">
              <ul className="list-unstyled mb-0">
                <PerfectScrollbar
                  className="chat-convo-scroll"
                  ref={messageBoxRef}
                  onScroll={onScroll}
                  options={{
                    suppressScrollX: true, // Disable horizontal scrolling
                    minScrollbarLength: 50, // Minimum scrollbar length
                  }}
                >
                  <MessageList conversationMessages={conversationMessages} />
                </PerfectScrollbar>
              </ul>
              {showScrollButton && (
                <Button
                  label="Latest message"
                  icon="fas fa-angle-down"
                  className="scroll-button bg-success bg-gradient bg-opacity-70"
                  onClick={scrollToBottom}
                  iconLeft
                />
              )}
              <ChatInput
                targetEvent={targetEvent}
                onSetConversationMessages={(conversationMessages) => setConversationMessages(conversationMessages)}
                activeConversation={activeConversation}
                showContactInfo={showContactInfo}
                setActiveConversation={onSetActiveConversation}
                onSetConversationList={onSetConversationList}
                scrollToBottom={scrollToBottom}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

ChatConversation.propTypes = {
  activeConversation: PropTypes.shape({
    _id: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    channel: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
    status: PropTypes.string,
    assignedUserId: PropTypes.string,
  }),
  showContactInfo: PropTypes.bool,
  targetEvent: PropTypes.bool,
  onToggleContactInfo: PropTypes.func,
  onSetActiveConversation: PropTypes.func,
  onSetConversationList: PropTypes.func,
  handleUpdateConversation: PropTypes.func,
};

export default ChatConversation;
