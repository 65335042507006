export const LEAD_STAGE_OPTIONS = [
  { value: 'NEW', label: 'New Lead' },
  { value: 'CONTACTED', label: 'Contacted' },
  { value: 'RESPONDED', label: 'Responded' },
  { value: 'QUALIFIED', label: 'Qualified' },
  { value: 'CONVERTED', label: 'Converted' },
  { value: 'FOLLOW_UP', label: 'Follow-Up' },
  { value: 'UNQUALIFIED', label: 'Unqualified' },
  { value: 'LOST', label: 'Lost' },
];

export const SOCIAL_PROFILES = {
  LINKEDIN: 'linkedIn',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
};

export const ADDRESS_ICONS = {
  address: 'mdi mdi-home-map-marker',
  city: 'mdi mdi-city',
  state: 'mdi mdi-map',
  zip: 'bx bxs-map-pin',
};

export const OPT_IN_OPTION = [
  { label: 'Opt In', value: 'opt-in', icon: 'mdi mdi-block-helper' },
];
export const OPT_OUT_OPTION = [
  { label: 'Opt Out', value: 'opt-out', icon: 'mdi mdi-block-helper' },
];

export const NAME_EXAMPLE = 'e.g. John Doe';
export const EMAIL_EXAMPLE = 'e.g. example@gmail.com';
export const ADDRESS_EXAMPLE = 'e.g. 123 Main St, City, State, ZIP';
export const COMPANY_NAME_EXAMPLE = 'e.g. Global Solutions Ltd.';
export const WEBSITE_URL_EXAMPLE = 'e.g. https://companydomain.com';
