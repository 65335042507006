import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import { UserContext } from '../UserProvider/UserProvider';
import LogoIcon from '../../assets/images/logo-icon.png';

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

function ProfileMenu({ success }) {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { getUserinfo, userData } = useContext(UserContext);
  const menuRef = useRef(null);

  useEffect(() => {
    getUserinfo();
  }, [getUserinfo]);

  useEffect(() => {
    if (success) {
      getUserinfo();
    }
  }, [success, getUserinfo]);

  const toggleMenu = (event) => {
    menuRef.current.toggle(event);
  };

  const menuItems = [
    {
      label: t('Profile'),
      icon: 'bx bx-user',
      command: () => navigate.push('/profile'),
    },
    {
      label: t('Change Password'),
      icon: 'bx bx-key',
      command: () => navigate.push('/change-password'),
    },
    {
      label: t('Logout'),
      icon: 'bx bx-power-off',
      className: 'text-danger',
      command: () => navigate.push('/logout'),
    },
  ];

  return (
    <div className="d-inline-block">
      <Button className="p-button-text" onClick={toggleMenu}>
        <ProfileImage src={LogoIcon} alt="Header Avatar" />
        <span className="d-none d-xl-inline-block ms-1">{userData?.name}</span>
        <i className="bx bx-chevron-down d-none d-xl-inline-block ml-1" />
      </Button>
      <Menu model={menuItems} popup ref={menuRef} id="popup_menu" className="setting" />
    </div>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  success: state.Profile.success,
});

export default connect(mapStateToProps)(ProfileMenu);
