import CHANGE_SIDEBAR_THEME from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const changeSidebarTheme = (theme) => {
  if (document.body) document.body.setAttribute('data-sidebar', theme);

  return {
    type: CHANGE_SIDEBAR_THEME,
    payload: theme,
  };
};
