import React from 'react';
import PropTypes from 'prop-types';
import { Button as PrimeButton } from 'primereact/button';
import styled from 'styled-components';

const StyledButton = styled(PrimeButton)`
  padding: 0.3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.outline ? '' : props.theme[`${props.color}Color`])};
  border-color: ${(props) => props.theme[`${props.color}Color`]};
  color: ${(props) => (props.outline ? props.theme[`${props.color}Color`] : props.theme.textColor)};
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  border-radius: var(--border-radius);
  transition: var(--transition-base);

  &:hover {
    background-color: ${(props) => (props.outline ? props.theme[`${props.color}Color`] : '')};
    color: ${(props) => (props.outline ? props.theme.textColor : props.theme[`${props.color}Color`])};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const StyledIcon = styled.i`
  margin-right: ${(props) => (props.iconLeft ? '0.5rem' : '0')};
  margin-left: ${(props) => (props.iconLeft ? '0rem' : '0.5rem')};

  ${({ iconSize }) => (iconSize ? `font-size: ${iconSize}` : '')};
`;

const StyledSpan = styled.span``;

function Button({
  label,
  icon = '',
  className = '',
  disabled = false,
  onClick = undefined,
  type = 'button',
  color = 'primary',
  iconClassName = '',
  outline = false,
  iconLeft = false,
  iconSize = '',
  ...rest
}) {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <StyledButton
      color={color}
      type={type}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      outline={outline}
      {...rest}
    >
      {iconLeft && icon ? (
        <StyledIcon
          className={`${icon} ${iconClassName}`}
          iconLeft={iconLeft}
          iconSize={iconSize}
        />
      ) : (
        ''
      )}
      {label ? <StyledSpan>{label}</StyledSpan> : ''}
      {!iconLeft && icon ? (
        <StyledIcon
          className={`${icon} ${iconClassName}`}
          iconLeft={iconLeft}
          iconSize={iconSize}
        />
      ) : (
        ''
      )}
    </StyledButton>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconSize: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]),
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  outline: PropTypes.bool,
};

export default Button;
