/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import {
  Container, Nav, NavItem, NavLink, Row, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import DisplayImage from '../../../components/DisplayImage';
import { tabs } from '../../../constants/tabs';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import BarChart from '../../../components/Charts/BarChart';
import { axiosGet } from '../../../services/http.service';
import MultiSelect from './Dropdown/MultiSelect';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';
import emptyContacts from '../../../assets/images/graphics/data-not-available.svg';
import { ANALYTICS_TYPES } from '../../../constants/analytics/analytics.constant';

class Analytics extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      messageDeliveryStatus: [],
      messageReplyStatus: [],
      tagStatistics: [],
      page: { delivery: 1, reply: 1, tag: 1 },
      totalPages: { delivery: null, reply: null, tag: null },
      loading: false,
      selectedCampaigns: { delivery: [], reply: [], tag: [] }, // State for selected campaigns
      activeTab: tabs.DELIVERY_OVERVIEW,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount() {
    const { activeTab } = this.state;
    document.title = 'Analytics';
    this.getAllCampaignsAnalyticsByWorkspaceId(this.getAnalyticsType(activeTab));
  }

  toggleTab = (tab) => {
    const { activeTab, page } = this.sate;
    if (activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          page: { page, [tab]: tabs.DELIVERY_OVERVIEW },
        },
        () => {
          this.getAllCampaignsAnalyticsByWorkspaceId(this.getAnalyticsType(tab));
        },
      );
    }
  };

  getAnalyticsType = (tab) => {
    const { DELIVERY, REPLY, TAG } = ANALYTICS_TYPES;
    switch (tab) {
      case tabs.DELIVERY_OVERVIEW:
        return DELIVERY;
      case tabs.REPLY_ANALYSIS:
        return REPLY;
      case tabs.DISTRIBUTION_BY_TAGS:
        return TAG;
      default:
        return DELIVERY;
    }
  };

  getAllCampaignsAnalyticsByWorkspaceId = async (analyticsType) => {
    const { page, selectedCampaigns, totalPages } = this.state;
    const ids = selectedCampaigns[analyticsType].map((campaign) => campaign.value).join(',');
    const idParams = !_.isEmpty(ids) ? `ids=${ids}` : '';
    try {
      this.setState({ loading: true });
      const response = await axiosGet(
        `campaign/analytics?page=${page[analyticsType]}&${idParams}&analyticsType=${analyticsType}&limit=10`,
      );

      if (response?.status) {
        const campaigns = response?.data?.results;
        this.setState({
          totalPages: {
            ...totalPages,
            [analyticsType]: response?.data?.totalPages,
          },
          ...this.processAnalyticsData(campaigns, analyticsType),
        });
      }
    } catch (error) {
      console.error('Error fetching campaign report:', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  processAnalyticsData = (campaigns, analyticsType) => {
    let messageDeliveryStatus = [];
    let messageReplyStatus = [];
    let tagStatistics = [];
    const { DELIVERY, REPLY, TAG } = ANALYTICS_TYPES;

    if (analyticsType === DELIVERY) {
      messageDeliveryStatus = campaigns.map((campaign) => ({
        name: campaign.name,
        read: campaign?.analytics?.messageStatistics?.readStatus?.read || 0,
        delivered: campaign?.analytics?.messageStatistics?.deliveryStatus?.delivered || 0,
        failed: campaign?.analytics?.messageStatistics?.deliveryStatus?.failed || 0,
        sent: campaign?.analytics?.messageStatistics?.deliveryStatus?.totalSent || 0,
        createdAt: campaign?.createdAt || '',
      }));
    } else if (analyticsType === REPLY) {
      messageReplyStatus = campaigns.map((campaign) => ({
        name: campaign.name,
        replied: campaign?.analytics?.messageStatistics?.replyStatus?.replied || 0,
        notReplied: campaign?.analytics?.messageStatistics?.replyStatus?.notReplied || 0,
        createdAt: campaign?.createdAt || '',
      }));
    } else if (analyticsType === TAG) {
      tagStatistics = campaigns.map((campaign) => ({
        id: campaign?.id,
        name: campaign?.name,
        tags: campaign?.analytics?.tagStatistics?.tagsCount || [],
        createdAt: campaign?.createdAt || '',
      }));
    }

    return { messageDeliveryStatus, messageReplyStatus, tagStatistics };
  };

  prepareChartData = (title, data, type) => {
    const { loading } = this.state;
    const xAxisData = data.map((item) => item.name);
    let seriesNames;
    let seriesData;
    let yAxisName;
    const { DELIVERY, REPLY, TAG } = ANALYTICS_TYPES;

    switch (type) {
      case DELIVERY:
        seriesNames = ['Read', 'Delivered', 'Failed', 'Sent'];
        seriesData = seriesNames.map((name) => data.map((item) => item[name.toLowerCase()]));
        yAxisName = 'No. of messages';
        break;
      case REPLY:
        seriesNames = ['Replied', 'Not Replied'];
        seriesData = seriesNames.map((name) => data.map((item) => item[name === 'Not Replied' ? 'notReplied' : name.toLowerCase()]));
        yAxisName = 'No. of messages';
        break;
      case TAG:
        seriesNames = data[0]?.tags.map((tag) => tag.tagName) || [];
        seriesData = seriesNames.map((tagName) => data.map((item) => item.tags.find((tag) => tag.tagName === tagName)?.contactCount || 0));
        yAxisName = 'No. of contacts';
        break;
      default:
        break;
    }

    return {
      title,
      yAxisName,
      xAxisData,
      seriesNames,
      data: seriesData,
      loading,
    };
  };

  loadNextPage = () => {
    const { activeTab } = this.state;
    const analyticsType = this.getAnalyticsType(activeTab);
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          [analyticsType]: prevState.page[analyticsType] + 1,
        },
      }),
      () => {
        this.getAllCampaignsAnalyticsByWorkspaceId(analyticsType);
      },
    );
  };

  loadPreviousPage = () => {
    const { activeTab } = this.state;
    const analyticsType = this.getAnalyticsType(activeTab);
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          [analyticsType]: prevState.page[analyticsType] - 1,
        },
      }),
      () => {
        this.getAllCampaignsAnalyticsByWorkspaceId(analyticsType);
      },
    );
  };

  handleSelectChange = (selectedCampaigns, type) => {
    const { activeTab, selectedCampaigns: selectedCampaignsProp } = this.state;
    this.setState(
      {
        selectedCampaigns: {
          ...selectedCampaignsProp,
          [type]: selectedCampaigns,
        },
        messageDeliveryStatus: [],
        messageReplyStatus: [],
        tagStatistics: [],
        page: { delivery: 1, reply: 1, tag: 1 },
      },
      () => {
        this.getAllCampaignsAnalyticsByWorkspaceId(this.getAnalyticsType(activeTab));
      },
    );
  };

  toggleTab = (tab) => {
    const { activeTab, page } = this.state;
    if (activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          page: { ...page, [tab]: 1 },
        },
        () => {
          this.getAllCampaignsAnalyticsByWorkspaceId(this.getAnalyticsType(tab));
        },
      );
    }
  };

  renderChart = (title, data, type) => {
    const chartData = this.prepareChartData(title, data, type);
    const { page, totalPages, selectedCampaigns } = this.state;

    // Prepare table data based on type
    let columns = [];
    let tableData = [];
    const { DELIVERY, REPLY, TAG } = ANALYTICS_TYPES;

    switch (type) {
      case DELIVERY:
        columns = [
          {
            dataField: 'name',
            text: 'Campaign',
            sort: true,
          },
          {
            dataField: 'read',
            text: 'Read',
            sort: true,
          },
          {
            dataField: 'delivered',
            text: 'Delivered',
            sort: true,
          },
          {
            dataField: 'failed',
            text: 'Failed',
            sort: true,
          },
          {
            dataField: 'sent',
            text: 'Sent',
            sort: true,
          },
          {
            text: 'Created On',
            dataField: 'createdAt',
            sort: true,
            formatter: (cellContent) => {
              const formattedDate = moment(cellContent).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
              const formattedTime = moment(cellContent).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
              return (
                <div>
                  {formattedDate}
                  <br />
                  {formattedTime}
                </div>
              );
            },
          },
        ];
        tableData = data.map((item) => ({
          id: item.id,
          name: item.name,
          read: item.read,
          delivered: item.delivered,
          failed: item.failed,
          sent: item.sent,
        }));
        break;
      case REPLY:
        columns = [
          {
            dataField: 'name',
            text: 'Campaign',
            sort: true,
          },
          {
            dataField: 'replied',
            text: 'Replied',
            sort: true,
          },
          {
            dataField: 'notReplied',
            text: 'Not Replied',
            sort: true,
          },
          {
            text: 'Created On',
            dataField: 'createdAt',
            sort: true,
            formatter: (cellContent) => {
              const formattedDate = moment(cellContent).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
              const formattedTime = moment(cellContent).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
              return (
                <div>
                  {formattedDate}
                  <br />
                  {formattedTime}
                </div>
              );
            },
          },
        ];
        tableData = data.map((item) => ({
          id: item.id,
          name: item.name,
          replied: item.replied,
          notReplied: item.notReplied,
        }));
        break;
      case TAG:
        columns = [
          {
            dataField: 'name',
            text: 'Campaign',
            sort: true,
          },
          ...chartData.seriesNames.map((tagName) => ({
            dataField: tagName,
            text: tagName,
            sort: true,
          })),
          {
            text: 'Created On',
            dataField: 'createdAt',
            sort: true,
            formatter: (cellContent) => {
              const formattedDate = moment(cellContent).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
              const formattedTime = moment(cellContent).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
              return (
                <div>
                  {formattedDate}
                  <br />
                  {formattedTime}
                </div>
              );
            },
          },
        ];
        tableData = data.map((item) => {
          const row = { name: item.name, id: item.id };
          item.tags.forEach((tag) => {
            row[tag.tagName] = tag.contactCount;
          });
          return row;
        });
        break;
      default:
        break;
    }

    return (
      <div>
        <Row className="mb-2 mt-2">
          <div className="d-flex justify-content-end">
            <Col lg="5" md="9" xs="12">
              <MultiSelect
                fetchAllCampaigns
                className="z-2"
                selectedOptions={selectedCampaigns[type]}
                onChange={(selected) => this.handleSelectChange(selected, type)}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <Col lg="12">
            <Card className="shadow-sm border border-light-subtle">
              <CardBody className="e-chart">
                <div id={`bar-graph-${type}`}>
                  <BarChart
                    {...chartData}
                    page={page[type]}
                    totalPages={totalPages[type]}
                    onLoadPreviousPage={this.loadPreviousPage}
                    onLoadNextPage={this.loadNextPage}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className="shadow-sm border border-light-subtle">
              <CardBody>
                {!isEmpty(tableData) ? (
                  <BootstrapTable keyField="id" data={tableData} columns={columns} />
                ) : (
                  <DisplayImage src={emptyContacts} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const {
      messageDeliveryStatus, messageReplyStatus, tagStatistics, activeTab,
    } = this.state;
    const { DELIVERY, REPLY, TAG } = ANALYTICS_TYPES;

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={<i className="bx bx-home-circle" />} breadcrumbItem="Analytics" />
          <div className="crypto-buy-sell-nav">
            <Nav tabs className="nav-tabs-custom" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === tabs.DELIVERY_OVERVIEW,
                  })}
                  onClick={() => {
                    this.toggleTab(tabs.DELIVERY_OVERVIEW);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-rocket-launch me-2 fs-4" />
                    <span>Delivery Overview</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === tabs.REPLY_ANALYSIS,
                  })}
                  onClick={() => {
                    this.toggleTab(tabs.REPLY_ANALYSIS);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-reply me-2 fs-4" />
                    <span>Reply Analysis</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === tabs.DISTRIBUTION_BY_TAGS,
                  })}
                  onClick={() => {
                    this.toggleTab(tabs.DISTRIBUTION_BY_TAGS);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <i className="mdi mdi-tag-multiple me-2 fs-4" />
                    <span>Distribution by Tags</span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          {/* Render chart based on activeTab */}
          {activeTab === tabs.DELIVERY_OVERVIEW
            && this.renderChart('Campaign Delivery Overview', messageDeliveryStatus, DELIVERY)}
          {activeTab === tabs.REPLY_ANALYSIS
            && this.renderChart('Reply Analysis', messageReplyStatus, REPLY)}
          {activeTab === tabs.DISTRIBUTION_BY_TAGS
            && this.renderChart('Distribution by Tags', tagStatistics, TAG)}
        </Container>
      </div>
    );
  }
}

export default withRouter(Analytics);
