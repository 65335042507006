import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { setConversationFilter } from '../../../store/actions';
import { fetchAllTags } from '../../../services/api/tags.service';
import {
  ALL_STATUS_TYPE,
  CONVERSATION_TYPE,
} from '../../../constants/channels/conversation.constant';
import { capitalizeFirstLetter } from '../../../utils/general';
import CustomRadio from '../../../components/RadioButton';
import CustomOverlay from '../../../components/Overlay';
import Button from '../../../components/Button';
import UserSelect from '../../../components/Common/UserSelect';
import MultiSelect from '../../../components/MultiSelect';
import { fetchAssignedPhoneNumbers } from '../../../services/api/phoneNumber.service';
import { fetchAssignedWhatsappNumbers } from '../../../services/api/whatsappNumber.service';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import Avatar from '../../../components/Avatar';
import { getInitials } from '../../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';

function Filter() {
  const dispatch = useDispatch();
  const { userData } = useContext(UserContext); // Access the context here
  const [status, setStatus] = useState('');
  const [viewStatus, setViewStatus] = useState('');
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const componentDidMountAsync = async () => {
    const [phoneNumbersResult, whatsappNumbersResult] = await Promise.allSettled([
      fetchAssignedPhoneNumbers(),
      fetchAssignedWhatsappNumbers(),
    ]);

    const textNumbers = phoneNumbersResult.status === 'fulfilled' ? phoneNumbersResult.value : [];
    const whatsappNumbers = whatsappNumbersResult.status === 'fulfilled' ? whatsappNumbersResult.value : [];
    const mappedPhoneNumbers = textNumbers?.map((number, index) => ({
      label: number?.name || `Text Number ${index}`,
      id: number?.id,
      value: number?.phoneNumber?.replace(/\+/g, ''),
      phoneNumber: number?.phoneNumber,
      channel: CHANNEL_TYPES.TEXT,
      icon: 'mdi mdi-message-text-outline text-primary',
    }));
    const mappedWhatsappNumbers = whatsappNumbers?.map((number) => ({
      label: number?.name,
      id: number?.id,
      value: number?.phoneNumber?.replace(/\+/g, ''),
      phoneNumber: number?.phoneNumber,
      channel: CHANNEL_TYPES.WHATSAPP,
      icon: 'mdi mdi-whatsapp text-success',
    }));
    const fromNumbers = [...mappedPhoneNumbers, ...mappedWhatsappNumbers];
    setPhoneNumbers(fromNumbers);
    // Api call to get all tags by sending limit as '-1'
    const allTags = await fetchAllTags(userData?.activeWorkspace?.workspaceId, 1, -1);
    if (allTags?.results) {
      const mappedTags = allTags?.results?.map((tag) => ({ label: tag?.tagKey, value: tag?._id }));
      setTags(mappedTags);
    }
  };

  useEffect(() => {
    componentDidMountAsync();
  }, []);

  const handleStatusChange = (e) => {
    const newStatus = e.value;
    setStatus(newStatus);
  };

  const handleConversationChange = (e) => {
    const newConversation = e.value;
    setViewStatus(newConversation);
  };

  const handleChannelChange = (e) => {
    setSelectedChannels(e.value);
  };

  const handlePhoneNumberChange = (e) => {
    const selectedSenderNumbers = e.value;
    setSelectedPhoneNumbers(selectedSenderNumbers);
  };

  const handleTagChange = (e) => {
    const selectedtags = e.value;
    setSelectedTags(selectedtags);
  };

  const channelOption = Object.values(CONVERSATION_TYPE).map((channel) => ({
    label: capitalizeFirstLetter(channel),
    value: channel,
  }));

  // Template to render tag,channel and phone number items with avatar and labels
  const itemTemplate = (option) => (
    <div className="p-dropdown-item d-flex p-0">
      <div className="d-flex align-items-center me-2">
        <Avatar label={getInitials(option?.label)} shape="circle" className="font-size-14" />
      </div>
      <div className="d-flex flex-column">
        <span className="item-label font-size-14">{option?.label}</span>
        {option?.phoneNumber && (
          <span className="item-label font-size-14 ">
            {' '}
            <PhoneNumberWithFlag phoneNumber={option?.phoneNumber} />
          </span>
        )}
      </div>
    </div>
  );

  // Template to render assignee items with avatar and labels
  const assigneeItemTemplate = (option) => (
    <div className="p-dropdown-item d-flex p-0">
      <div className="d-flex flex-column">
        <span className="item-label font-size-14">{option?.label}</span>
        {option?.phoneNumber && (
          <span className="item-label font-size-14 ">
            {' '}
            <PhoneNumberWithFlag phoneNumber={option?.phoneNumber} />
          </span>
        )}
      </div>
    </div>
  );

  const handleOnApply = () => {
    const filterData = {};
    if (viewStatus) {
      if (viewStatus === 'read') {
        filterData.isRead = true;
      } else {
        filterData.isRead = false;
      }
    }
    if (status) {
      filterData.status = status;
    }
    if (!isEmpty(assignedUsers)) {
      filterData.assignedUserIds = assignedUsers.join(',');
    }
    if (!isEmpty(selectedChannels)) {
      filterData.channels = selectedChannels.join(',');
    }
    if (!isEmpty(selectedPhoneNumbers)) {
      filterData.phoneNumbers = selectedPhoneNumbers.join(',');
    }
    if (!isEmpty(selectedTags)) {
      filterData.tagIds = selectedTags.join(',');
    }
    dispatch(setConversationFilter(filterData));
    setIsOpen(false);
  };

  const handleOnCancel = () => {
    setStatus('');
    setViewStatus('');
    setSelectedChannels([]);
    setSelectedPhoneNumbers([]);
    setAssignedUsers([]);
    setSelectedTags([]);
    dispatch(setConversationFilter({}));
    setIsOpen(false);
  };

  return (
    <div className="">
      <CustomOverlay
        buttonContent=""
        buttonIcon="mdi mdi-filter-outline font-size-24"
        className="text-secondary filter-btn ms-1"
        onClick={() => setIsOpen(true)}
        isOpen={isOpen}
      >
        <div className="d-flex flex-column font-size-14">
          <div className="mb-3">
            <span className="fw-bold fs-4">Filter</span>
          </div>
          <div className="">
            <div>
              <span>Conversation Status</span>
            </div>
            <div>
              <div className="d-flex">
                <CustomRadio
                  inputId="status-open"
                  name="status"
                  value={ALL_STATUS_TYPE.OPEN}
                  label={capitalizeFirstLetter(ALL_STATUS_TYPE.OPEN)}
                  checked={status === ALL_STATUS_TYPE.OPEN}
                  onChange={handleStatusChange}
                  className="d-flex align-items-start me-2"
                />
                <CustomRadio
                  inputId="status-resolved"
                  name="status"
                  value={ALL_STATUS_TYPE.RESOLVED}
                  label={capitalizeFirstLetter(ALL_STATUS_TYPE.RESOLVED)}
                  checked={status === ALL_STATUS_TYPE.RESOLVED}
                  onChange={handleStatusChange}
                  className="d-flex align-items-start"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>Message Status</span>
            </div>
            <div>
              <div className="d-flex">
                <CustomRadio
                  inputId="conversation-read"
                  name="conversation"
                  value={ALL_STATUS_TYPE.READ}
                  label={capitalizeFirstLetter(ALL_STATUS_TYPE.READ)}
                  checked={viewStatus === ALL_STATUS_TYPE.READ}
                  onChange={handleConversationChange}
                  className="d-flex align-items-start me-2"
                />
                <CustomRadio
                  inputId="conversation-unread"
                  name="conversation"
                  value={ALL_STATUS_TYPE.UNREAD}
                  label={capitalizeFirstLetter(ALL_STATUS_TYPE.UNREAD)}
                  checked={viewStatus === ALL_STATUS_TYPE.UNREAD}
                  onChange={handleConversationChange}
                  className="d-flex align-items-start"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>Assigned To</span>
            </div>
            <div>
              <UserSelect
                className="width-21rem mt-2"
                selectedOptions={assignedUsers}
                onChange={(e) => {
                  setAssignedUsers(e);
                }}
                isMulti
                itemTemplate={assigneeItemTemplate}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="mb-2">
              <span>Channel</span>
            </div>
            <div>
              <MultiSelect
                className="width-21rem"
                value={selectedChannels}
                options={channelOption}
                onChange={handleChannelChange}
                placeholder="Search channels"
                display="chip"
                filter
                optionLabel="label"
                itemTemplate={itemTemplate}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="mb-2">
              <span>Phone Numbers</span>
            </div>
            <div>
              <MultiSelect
                className="width-21rem"
                value={selectedPhoneNumbers}
                options={phoneNumbers}
                onChange={handlePhoneNumberChange}
                placeholder="Search phone numbers"
                display="chip"
                filter
                optionLabel="label"
                itemTemplate={itemTemplate}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="mb-2">
              <span>Tags</span>
            </div>
            <div>
              <MultiSelect
                className="width-21rem"
                value={selectedTags}
                options={tags}
                onChange={handleTagChange}
                placeholder="Search tags"
                display="chip"
                filter
                optionLabel="label"
                itemTemplate={itemTemplate}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button label="Cancel" className="btn btn-danger me-2" onClick={handleOnCancel} />
            <Button label="Apply" className="bg-primary" onClick={handleOnApply} />
          </div>
        </div>
      </CustomOverlay>
    </div>
  );
}

export default Filter;
