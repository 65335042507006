import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber, some } from 'lodash';
import {
  setBMessageContactGroups,
  setLoader,
  setBMessageStepTwoData,
} from '../../../../../store/actions';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import Label from '../../../../../components/Label';
import IconText from '../../../../../components/IconText';
import Card from '../../../../../components/Card';
import DataGrid from '../../../../../components/DataTable';
import { WAITING_TIMING } from '../../../../../constants/constants';
import { renderUserAvatar } from '../../../../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../../../../constants/datetime.constant';
import { showToast } from '../../../../../services/toastService';
import { toastType } from '../../../../../constants/toast';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledSpan = styled.span`
  font-size: 1.1rem;
`;

const ViewCount = styled.div`
  cursor: pointer;
  color: ${({ countFounded }) => (countFounded ? '' : 'var(--blue-600)')};
`;

// NOTE: `BMessage`: Broadcast message
function Step2({ formCompletion }) {
  const dispatch = useDispatch();
  const { step2 } = useSelector((state) => state.bulkMessage);
  const {
    selectedContactGroup, contactGroups, totalSize, page, size, searchValue,
  } = step2;

  const handleViewCountClick = async (contactGroupId) => {
    try {
      dispatch(setLoader(true));
      const response = await contactGroupServices.getContactCount(contactGroupId);
      if (response?.status) {
        const contactGroupsArray = contactGroups.map((contactGroup) => (contactGroup.id === contactGroupId
          ? { ...contactGroup, contactCount: response?.data?.contactCount }
          : contactGroup));

        dispatch(setBMessageContactGroups({ contactGroups: contactGroupsArray }));
        if (selectedContactGroup?.id === contactGroupId) {
          dispatch(
            setBMessageStepTwoData({
              selectedContactGroup: {
                ...selectedContactGroup,
                contactCount: response?.data?.contactCount,
              },
            }),
          );
        }
      } else {
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({ content: error?.message || 'Oops! Something went wrong', type: toastType.error });
      console.error('error at handleGetAllContactGroups :', error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const contactListColumns = [
    {
      header: 'Segment Name',
      accessorKey: 'name',
      disableFilters: true,
    },
    {
      header: 'Created By',
      accessorKey: 'assignedUserName',
      // eslint-disable-next-line react/prop-types
      Cell: ({ renderedCellValue }) => renderUserAvatar(renderedCellValue, 'me-3'),
    },
    {
      header: 'Number of Audience',
      accessorKey: 'contactIds',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const currentRowData = row?.original;
        return (
          <ViewCount
            onClick={() => {
              if (!isNumber(currentRowData?.contactCount)) {
                handleViewCountClick(currentRowData?.id);
              }
            }}
            countFounded={isNumber(currentRowData?.contactCount)}
          >
            {currentRowData?.contactCount ?? 'View count'}
          </ViewCount>
        );
      },
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      // eslint-disable-next-line react/prop-types
      Cell: ({ renderedCellValue }) => {
        const formattedDate = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
        const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
        return (
          <div>
            <IconText icon="bx bx-calendar" text={formattedDate} />
            <IconText icon="bx bx-time" text={formattedTime} />
          </div>
        );
      },
    },
  ];

  let timer = null;

  const handleGetAllContactGroups = async () => {
    try {
      dispatch(setLoader(true));
      const response = await contactGroupServices.getAllContactGroupsInPages(
        page,
        size,
        searchValue,
      );
      if (response?.status) {
        const selectedContactGroupExists = some(response?.data?.results, {
          id: selectedContactGroup?.id,
        });

        if (!selectedContactGroupExists) {
          dispatch(setBMessageStepTwoData({}));
          formCompletion(false);
        }
        dispatch(
          setBMessageContactGroups({
            contactGroups: response?.data?.results || [],
            totalSize: response?.data?.totalPages || 0,
          }),
        );
      } else {
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! Something went wrong',
        type: toastType.error,
      });
      console.error('error at handleGetAllContactGroups :', error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const debounceSearch = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleGetAllContactGroups();
    }, WAITING_TIMING);
  };

  const handleSearch = (searchQuery) => {
    dispatch(setBMessageContactGroups({ searchValue: searchQuery, page: 1 }));
  };

  const handlePageChange = (newPage) => {
    dispatch(setBMessageContactGroups({ page: newPage }));
  };

  const handleSizeChange = (newSize) => {
    dispatch(setBMessageContactGroups({ page: 1, size: newSize }));
  };

  const onContactGroupSelection = (selectedRows) => {
    dispatch(setBMessageStepTwoData({ selectedContactGroup: selectedRows?.[0] }));
    formCompletion(true);
  };

  useEffect(() => {
    if (
      typeof selectedContactGroup !== 'object'
      || Object.keys(selectedContactGroup).length === 0
    ) {
      formCompletion(false);
    }

    if (!contactGroups?.length) {
      handleGetAllContactGroups();
    }
  }, []);

  useEffect(() => {
    debounceSearch();
    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, [page, size, searchValue]);

  return (
    <StyledCard>
      <div className="mb-3">
        <Label className="form-label mb-3">
          <StyledSpan>Select Contact Group</StyledSpan>
        </Label>
        <DataGrid
          data={contactGroups}
          columns={contactListColumns}
          enableColumnResizing={false}
          enableColumnFilterModes
          enableEditing={false}
          enableMultiRowSelection={false}
          selectedRows={selectedContactGroup ? [{ ...selectedContactGroup, id: 2 }] : []}
          onRowSelectionChange={onContactGroupSelection}
          totalSize={totalSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handleSizeChange}
          initialPage={page - 1}
          initialPageSize={10}
          onSearch={handleSearch}
        />
      </div>
    </StyledCard>
  );
}

Step2.propTypes = {
  formCompletion: PropTypes.func,
};

export default Step2;
