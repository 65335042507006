import { SHOW_UPLOAD_BLOCKED_NUMBERS_MODAL } from './actionTypes';

const INIT_STATE = {
  showUploadBlockedNumberModal: false,
};

const blockedNumbers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_UPLOAD_BLOCKED_NUMBERS_MODAL:
      return {
        ...state,
        showUploadBlockedNumberModal: !state.showUploadBlockedNumberModal,
      };

    default:
      return state;
  }
};

export default blockedNumbers;
