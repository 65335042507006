import React from 'react';
import { RadioButton as PrimeRadioButton } from 'primereact/radiobutton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from '../Label';

// Styled Components
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: ${(props) => props.$margin || '0.5rem 0'};
  opacity: ${(props) => (props.$disabled ? '0.6' : '1')};
`;

const StyledLabel = styled(Label)`
  font-size: 1rem;
  color: ${(props) => (props.$disabled ? '#666' : '#333')};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  
  &:hover {
    color: ${(props) => !props.$disabled && '#4338ca'};
  }
`;

const RequiredIndicator = styled.span`
  color: #dc2626;
  margin-left: 4px;
`;

const TooltipWrapper = styled.div`
  position: relative;
  
  &:hover::after {
    content: "${(props) => props.$tooltip}";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: #333;
    color: white;
    border-radius: 4px;
    font-size: 0.875rem;
    white-space: nowrap;
    z-index: 1000;
  }
`;

function RadioButton({
  inputId,
  name,
  value,
  onChange,
  checked,
  disabled = false,
  label,
  required = false,
  tooltip = '',
  autoFocus = false,
  margin,
  className = '',
  style = {},
  labelStyle = {},
  labelClassName = '',
}) {
  const RadioComponent = (
    <PrimeRadioButton
      inputId={inputId}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
      style={style}
    />
  );

  return (
    <RadioWrapper
      className={className}
      $disabled={disabled}
      $margin={margin}
    >
      {tooltip ? (
        <TooltipWrapper $tooltip={tooltip}>
          {RadioComponent}
        </TooltipWrapper>
      ) : (
        RadioComponent
      )}
      {label && (
        <StyledLabel
          htmlFor={inputId}
          $disabled={disabled}
          style={labelStyle}
          className={labelClassName}
        >
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </StyledLabel>
      )}
    </RadioWrapper>
  );
}

RadioButton.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  autoFocus: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  labelClassName: PropTypes.string,
};

export default RadioButton;
