import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber, some } from 'lodash';
import { setContactGroupsOfStep3, setLoader, setStepThreeData } from '../../../../../store/actions';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import Label from '../../../../../components/Label';
import IconText from '../../../../../components/IconText';
import Card from '../../../../../components/Card';
import DataGrid from '../../../../../components/DataTable';
import { WAITING_TIMING } from '../../../../../constants/constants';
import { renderUserAvatar } from '../../../../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../../../../constants/datetime.constant';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledSpan = styled.span`
  font-size: 1.1rem;
`;

const ViewCount = styled.div`
  cursor: pointer;
  color: ${({ countFounded }) => (countFounded ? '' : 'var(--blue-600)')};
`;

class Step3 extends Component {
  constructor(props) {
    super(props);
    const { page, size, searchValue } = props;

    this.state = {
      page,
      size,
      searchValue,
      contactListColumns: [
        {
          header: 'Segment Name',
          accessorKey: 'name',
        },
        {
          header: 'Created By',
          accessorKey: 'assignedUserName',
          // eslint-disable-next-line react/prop-types
          Cell: ({ renderedCellValue }) => renderUserAvatar(renderedCellValue, 'me-3'),
        },
        {
          header: 'Number of Audience',
          accessorKey: 'contactIds',
          // eslint-disable-next-line react/prop-types
          Cell: ({ row }) => {
            // eslint-disable-next-line react/prop-types
            const currentRowData = row?.original;
            return (
              <ViewCount
                onClick={() => {
                  if (!isNumber(currentRowData?.contactCount)) {
                    this.handleViewCountClick(currentRowData?.id);
                  }
                }}
                countFounded={isNumber(currentRowData?.contactCount)}
              >
                {currentRowData?.contactCount ?? 'View count'}
              </ViewCount>
            );
          },
        },
        {
          header: 'Created At',
          accessorKey: 'createdAt',
          // eslint-disable-next-line react/prop-types
          Cell: ({ renderedCellValue }) => {
            const formattedDate = moment(renderedCellValue).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                <IconText icon="bx bx-calendar" text={formattedDate} />
                <IconText icon="bx bx-time" text={formattedTime} />
              </div>
            );
          },
        },
      ],
    };
    this.timer = null;
    // this.toggleTab = this.toggleTab.bind(this);
    this.onContactGroupSelection = this.onContactGroupSelection.bind(this);
  }

  componentDidMount() {
    const { selectedContactGroup, formCompletion, contactGroups } = this.props;

    // Check contact group is selected or not
    if (
      typeof selectedContactGroup !== 'object'
      || Object.keys(selectedContactGroup).length === 0
    ) {
      formCompletion(false);
    }

    if (!contactGroups?.length) {
      this.handleGetAllContactGroups();
    }
  }

  handleViewCountClick = async (contactGroupId) => {
    const {
      onSetLoader,
      contactGroups,
      onSetContactGroups,
      selectedContactGroup,
      onSetStepThreeData,
    } = this.props;

    try {
      onSetLoader(true);
      const response = await contactGroupServices.getContactCount(contactGroupId);
      if (response?.status) {
        const contactGroupsArray = contactGroups.map((contactGroup) => {
          if (contactGroup.id === contactGroupId) {
            return { ...contactGroup, contactCount: response?.data?.contactCount };
          }
          return contactGroup;
        });

        onSetContactGroups({ contactGroups: contactGroupsArray });
        if (selectedContactGroup?.id === contactGroupId) {
          onSetStepThreeData({
            selectedContactGroup: {
              ...selectedContactGroup,
              contactCount: response?.data?.contactCount,
            },
          });
        }
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('error at handleGetAllContactGroups :', error);
      onSetLoader(false);
    }
  };

  handleGetAllContactGroups = async () => {
    const { searchValue, page, size } = this.state;
    const {
      onSetLoader,
      onSetContactGroups,
      selectedContactGroup,
      onSetStepThreeData,
      formCompletion,
    } = this.props;

    try {
      onSetLoader(true);
      const response = await contactGroupServices.getAllContactGroupsInPages(
        page,
        size,
        searchValue,
      );
      if (response?.status) {
        const selectedContactGroupExists = some(response?.data?.results, {
          id: selectedContactGroup?.id,
        });

        if (!selectedContactGroupExists) {
          // If selectedContactGroup is not exist from new contacts list then reset it.
          onSetStepThreeData({});
          formCompletion(false);
        }
        onSetContactGroups({
          contactGroups: response?.data?.results || [],
          totalSize: response?.data?.totalPages || 0,
        });
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('error at handleGetAllContactGroups :', error);
      onSetLoader(false);
    }
  };

  debounceSearch = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.handleGetAllContactGroups();
    }, WAITING_TIMING);
  };

  handleSearch = async (searchValue) => {
    const { onSetContactGroups } = this.props;
    this.setState({ searchValue, page: 1 }, () => {
      onSetContactGroups({ searchValue, page: 1 });
      this.debounceSearch();
    });
  };

  handlePageChange = (newPage) => {
    this.setState({ page: newPage }, () => {
      const { onSetContactGroups } = this.props;
      onSetContactGroups({ page: newPage });
      this.debounceSearch();
    });
  };

  handleSizeChange = (newSize) => {
    this.setState({ size: newSize, page: 1 }, () => {
      const { onSetContactGroups } = this.props;
      onSetContactGroups({ page: 1, size: newSize });
      this.debounceSearch();
    });
  };

  onContactGroupSelection = (selectedRows) => {
    const { onSetStepThreeData, formCompletion } = this.props;
    const selectedContactGroup = selectedRows?.[0];
    onSetStepThreeData({ selectedContactGroup });
    formCompletion(true);
  };

  render() {
    const { contactListColumns } = this.state;

    const { selectedContactGroup, contactGroups, totalSize } = this.props;

    return (
      <StyledCard>
        <div className="mb-3">
          <Label className="form-label mb-3">
            <StyledSpan>Select Contact Group</StyledSpan>
          </Label>
          <DataGrid
            data={contactGroups}
            columns={contactListColumns}
            enableColumnResizing={false}
            enableColumnFilterModes
            enableEditing={false}
            enableMultiRowSelection={false}
            selectedRows={selectedContactGroup ? [selectedContactGroup] : []}
            onRowSelectionChange={this.onContactGroupSelection}
            totalSize={totalSize}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handleSizeChange}
            initialPage={0}
            initialPageSize={10}
            onSearch={this.handleSearch}
          />
        </div>
      </StyledCard>
    );
  }
}

Step3.propTypes = {
  selectedContactGroup: PropTypes.object,
  contactGroups: PropTypes.object,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  size: PropTypes.number,
  searchValue: PropTypes.string,
  onSetLoader: PropTypes.func,
  onSetStepThreeData: PropTypes.func,
  formCompletion: PropTypes.func,
  onSetContactGroups: PropTypes.func,
};

const mapStateToProps = ({ bulkWhatsapp }) => ({
  selectedContactGroup: bulkWhatsapp.step3.selectedContactGroup,
  contactGroups: bulkWhatsapp.step3.contactGroups,
  totalSize: bulkWhatsapp.step3.totalSize,
  page: bulkWhatsapp.step3.page,
  size: bulkWhatsapp.step3.size,
  searchValue: bulkWhatsapp.step3.searchValue,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onSetStepThreeData: (formData) => dispatch(setStepThreeData(formData)),
  onSetContactGroups: (contactGroups) => dispatch(setContactGroupsOfStep3(contactGroups)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step3));
