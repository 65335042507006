/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Col, Label, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showToast } from '../../../../services/toastService';
import { toastType } from '../../../../constants/toast';
import { axiosGet, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import VariableSelect from '../VariableSelect';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../../constants/onboarding.constant';
import { handleOnboardingStepCompletion } from '../../../../utils/onboarding';
import { setOnboardingData as setOnboardingDataAction } from '../../../../store/actions';
import { transformWhatsappTemplate } from '../../../../helpers/commonHelpers';
import {
  TEMPLATE_COMPONENT_TYPE,
  WHATSAPP_TEMPLATES_BUTTON_TYPES,
  WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS,
  WHATSAPP_TEMPLATES_PARAMETER_TYPES,
} from '../../../../constants/channels/whatsApp.constant';
import { bytesToMegabytes, capitalizeFirstLetter } from '../../../../utils/general';
import {
  ACCEPTED_FILE_TYPES,
  FILE_SIZE_ALLOWED,
  FILE_TYPES,
} from '../../../../constants/file.constant';

class Whatsapp extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      showModalLoader: false,
      uploadedFile: {},
      whatsappNumbers: [],
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      buttonComponent: {},
      selectedVariable: {},
      initialValues: {
        templateName: '',
        whatsappNumber: '',
        whatsappTemplate: '',
      },
      validationSchemaObj: {
        templateName: Yup.string().required('Please enter template name'),
        whatsappNumber: Yup.string().required('Please select a whatsapp number'),
        whatsappTemplate: Yup.object().required('Please select a template'),
      },
    };
  }

  componentDidMount() {
    this.setState({
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      buttonComponent: {},
      selectedVariable: {},
    });

    this.handleGetWhatsappNumbers();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedTemplate, headerComponent, bodyComponent, buttonComponent,
    } = this.state;
    if (selectedTemplate !== prevState.selectedTemplate) {
      // Update all components when the selected template changes
      this.updateComponents();
    }
    // Check if either headerComponent or bodyComponent has changed
    if (headerComponent !== prevState.headerComponent) {
      this.updateHeaderComponent();
    }
    if (bodyComponent !== prevState.bodyComponent) {
      this.updateBodyComponent();
    }
    if (buttonComponent !== prevState.buttonComponent) {
      this.updateButtonComponent();
    }
  }

  // to get list of all whatsapp numbers
  handleGetWhatsappNumbers = async () => {
    try {
      const numbers = await axiosGet('whatsapp-numbers');
      if (numbers?.status) {
        this.setState({ whatsappNumbers: numbers?.data?.results });
      }
    } catch (error) {
      console.error('error at handleGetWhatsappNumbers :', error);
    }
  };

  // API integration functions end here

  handleSetFieldValue = (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    this.setState((prevState) => ({
      selectedVariable: {
        ...prevState.selectedVariable,
        [fieldName]: value,
      },
    }));
  };

  // to get templates from meta for the particular selected whatsapp number
  // wabaId : WhatsApp Business Account ID

  handleGetTemplatesFromMeta = async (wabaId) => {
    const { userData } = this.context;
    const workspaceId = userData?.activeWorkspace?.workspaceId;
    try {
      const whatsappTemplates = await axiosGet(
        `whatsapp-template/available-templates/${workspaceId}?whatsappBusinessAccountId=${wabaId}`,
      );
      if (whatsappTemplates?.status) {
        this.setState({ templates: whatsappTemplates?.data });
      }
    } catch (error) {
      console.error('error at handleGetTemplatesFromMeta :', error);
    }
  };

  handleSetSelectedVariable = (variable, fieldName, setFieldValue) => {
    if (variable) {
      this.setState(
        (prevState) => ({
          selectedVariable: {
            ...prevState.selectedVariable,
            [fieldName]: `${
              prevState.selectedVariable[fieldName] ? prevState.selectedVariable[fieldName] : ''
            }{{${variable}}}`,
          },
        }),
        () => {
          const { selectedVariable } = this.state;
          if (fieldName) {
            setFieldValue(fieldName, selectedVariable[fieldName]);
          }
        },
      );
    }
  };

  handleAddTemplate = async (templateData) => {
    try {
      const {
        toggle, isOnboarding, history, setOnboardingData,
      } = this.props;
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      this.setState({ showModalLoader: true });
      const { getAllTemplates } = this.props;
      const response = await axiosPost('whatsapp-template', templateData);
      if (response?.status) {
        showToast({
          content: response?.message || 'Template created successfully!',
          type: toastType.success,
        });
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.CREATE_TEMPLATE,
            organizationId,
            history,
            setOnboardingData,
          });
        }
        getAllTemplates();
        toggle();
      } else {
        showToast({
          content: 'Oops! something went wrong' || response?.message,
          type: toastType.error,
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error at handleAddTemplate :', error);
    }
  };

  replacePlaceholders = (text, replacements) => {
    // Regular expression to match placeholders like {{1}}, {{2}}, etc.
    const placeholderRegex = /\{\{(\d+)\}\}/g;
    // Use replace method with a callback function to replace each placeholder
    const replacedText = text.replace(placeholderRegex, (match, index) => {
      // Extract the index from the placeholder
      const placeholderIndex = parseInt(index, 10);

      // Check if the replacement value exists for the placeholder index
      if (replacements.hasOwnProperty(`BODY-text-${placeholderIndex}`)) {
        // Return the replacement value

        return replacements[`BODY-text-${placeholderIndex}`];
      }
      // Return the original placeholder if replacement value doesn't exist
      return match;
    });

    return replacedText;
  };

  handleOnSubmit = async (values) => {
    const { bodyComponent } = this.state;
    const updatedBody = this.replacePlaceholders(bodyComponent?.text, values);

    const whatsappTemplate = JSON.parse(values?.whatsappTemplate);
    const templateViewObj = transformWhatsappTemplate(whatsappTemplate);
    const componentData = await this.formatTemplate(values, templateViewObj);

    const phoneNumberId = values?.whatsappNumber?.split('-')?.[1] || '';
    const templateData = {};
    templateData.templateName = values?.templateName;
    templateData.templateContent = updatedBody;
    templateData.phoneNumberId = phoneNumberId;
    templateData.template = {};
    templateData.template.name = whatsappTemplate?.name;
    templateData.template.language = {};
    templateData.template.language.code = whatsappTemplate?.language;
    templateData.template.components = componentData?.components;
    templateData.templateViewObj = Object.keys(templateViewObj).reduce((acc, key) => {
      if (Object.values(templateViewObj[key]).length) {
        return { ...acc, [key]: templateViewObj[key] };
      }
      return acc;
    }, {});

    if (templateData) {
      this.handleAddTemplate(templateData);
    }
  };

  formatTemplate = async (templateData, templateViewObj) => {
    const components = [];

    // Iterate over the keys of the template data object
    // eslint-disable-next-line no-restricted-syntax
    for (const key in templateData) {
      // eslint-disable-next-line no-prototype-builtins
      if (templateData.hasOwnProperty(key)) {
        const [type, sub_type, index] = key.split('-'); // Split the key into type and index

        if (
          (type === TEMPLATE_COMPONENT_TYPE.HEADER
            && sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.LOCATION)
          || type === TEMPLATE_COMPONENT_TYPE.BODY
        ) {
          // Determine the type of component
          let uploadedFilelink = '';
          if (sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase()) {
            // eslint-disable-next-line no-await-in-loop
            const response = await this.handleFileAddition();
            uploadedFilelink = response?.data?.destination; // Assuming handleFileAddition sets the uploaded file link
          }

          const componentType = type.toLowerCase();

          let existingComponentIndex = -1;
          // Check if a component of the same type already exists
          for (let i = 0; i < components.length; i += 1) {
            if (components[i] && components[i].type === componentType) {
              existingComponentIndex = i;
              break;
            }
          }

          const parameter = {};
          if (!templateViewObj[type.toLowerCase()]) {
            templateViewObj[type.toLowerCase()] = {
              type: WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase(),
              text: '',
              url: '',
            };
          }

          if (sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase()) {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = { link: uploadedFilelink };

            templateViewObj[type.toLowerCase()].type = sub_type.toLowerCase();
            templateViewObj[type.toLowerCase()].url = uploadedFilelink;
          } else {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = templateData[key];

            const text = templateViewObj[type.toLowerCase()].text || '';
            templateViewObj[type.toLowerCase()].text = text.replace(
              `{{${index}}}`,
              templateData[key],
            );
          }

          if (existingComponentIndex !== -1) {
            // If a component of the same type already exists, push the parameters to it
            components[existingComponentIndex].parameters.push(parameter);
          } else {
            // If no component of the same type exists, create a new one
            components.push({
              type: componentType,
              parameters: [parameter],
            });
          }
        } else if (type === TEMPLATE_COMPONENT_TYPE.BUTTON) {
          const { COPY_CODE, URL } = WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS;
          // Handle button type
          const button = {
            type: type.toLowerCase(),
            sub_type: sub_type.toLowerCase(),
            index,
            parameters: [],
          };
          const buttons = templateViewObj?.buttons || [];

          if (sub_type === COPY_CODE) {
            button.parameters.push({
              type: 'coupon_code',
              coupon_code: templateData[key],
            });
          } else {
            button.parameters.push({
              type: sub_type === 'URL' ? 'text' : sub_type.toLowerCase(),
              text: templateData[key],
            });
          }
          templateViewObj.buttons = buttons?.map((buttonObj) => {
            switch (buttonObj?.type) {
              case COPY_CODE.toLowerCase(): {
                return { ...buttonObj, code: templateData[key] };
              }
              case URL.toLowerCase(): {
                return {
                  ...buttonObj,
                  url: buttonObj?.url?.replace(`{{${parseInt(index, 10) + 1}}}`, templateData[key]),
                };
              }
              default: {
                return buttonObj;
              }
            }
          });
          components.push(button);
        }
      }
    }

    return { components };
  };

  handleNumberSelection = async (value, setFieldValue) => {
    const [wabaId] = value.split('-');

    this.setState({
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      // footerComponent: {},
      buttonComponent: {},
    });
    if (value) {
      this.setState({ showModalLoader: true });
      setFieldValue('whatsappNumber', value);
      // this.setState({ selectedNumber: value });
      // this.setState({ templates: whatsappTemplates });
      await this.handleGetTemplatesFromMeta(wabaId);
      this.setState({ showModalLoader: false });
    } else {
      this.setState({ templates: [] });
    }
  };

  handleTemplateSelection = (value, values, setFieldValue) => {
    setFieldValue('whatsappTemplate', value); // Value is already a string
    this.setState({
      selectedTemplate: {},
      initialValues: {
        templateName: '',
        whatsappNumber: values?.whatsappNumber || '',
        whatsappTemplate: value || '',
      },
      validationSchemaObj: {
        templateName: Yup.string().required('Please enter template name'),
        whatsappNumber: Yup.string().required('Please select a whatsapp number'),
        whatsappTemplate: Yup.object().required('Please select a template'),
      },
    });
    const templateObject = JSON.parse(value); // Parse the value to access template properties
    this.setState({
      selectedTemplate: templateObject,
    });
  };

  updateComponents = () => {
    const { selectedTemplate } = this.state;
    const componentTypes = {
      [TEMPLATE_COMPONENT_TYPE.HEADER]: 'headerComponent',
      [TEMPLATE_COMPONENT_TYPE.BODY]: 'bodyComponent',
      [TEMPLATE_COMPONENT_TYPE.FOOTER]: 'footerComponent',
      [TEMPLATE_COMPONENT_TYPE.BUTTONS]: 'buttonComponent',
    };

    selectedTemplate?.components?.forEach((component) => {
      const componentType = component.type;
      const stateVariable = componentTypes[componentType];
      if (stateVariable) {
        this.setState({
          [stateVariable]: component,
        });
      }
    });
  };

  updateHeaderComponent = () => {
    const { headerComponent } = this.state;

    if (headerComponent.format === 'TEXT' && Array.isArray(headerComponent.example?.header_text)) {
      this.setState((prevState) => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };
        const sub_type = headerComponent?.format?.toLowerCase();
        headerComponent.example.header_text.forEach((text, index) => {
          const fieldName = `${headerComponent.type}-${sub_type}-${index + 1}`;
          initialValues[fieldName] = '';
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for header`,
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    } else if (
      headerComponent?.format === 'IMAGE'
      || headerComponent?.format === 'DOCUMENT'
      || headerComponent?.format === 'VIDEO'
    ) {
      const sub_type = headerComponent?.format?.toLowerCase();
      this.setState((prevState) => ({
        initialValues: {
          ...prevState.initialValues,
          [`${headerComponent.type}-${sub_type}-uploadedFile`]: '',
        },
        validationSchemaObj: {
          ...prevState.validationSchemaObj,
          [`${headerComponent.type}-${sub_type}-uploadedFile`]:
            Yup.mixed().required('File is required'),
        },
      }));
    }
  };

  updateBodyComponent = () => {
    const { bodyComponent } = this.state;

    if (bodyComponent?.example?.body_text) {
      const bodyTexts = bodyComponent.example.body_text.flat();

      this.setState((prevState) => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };

        bodyTexts.forEach((text, index) => {
          const fieldName = `${bodyComponent.type}-text-${index + 1}`;
          initialValues[fieldName] = '';
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for body`,
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    }
  };

  updateButtonComponent = () => {
    const { buttonComponent } = this.state;

    if (Array.isArray(buttonComponent.buttons)) {
      const buttonsWithDynamicValue = buttonComponent.buttons.filter((button, index) => {
        if (button.example) {
          // eslint-disable-next-line no-param-reassign
          button.index = index;
          return true;
        }
        return false;
      });
      if (buttonsWithDynamicValue.length) {
        this.setState((prevState) => {
          const initialValues = { ...prevState.initialValues };
          const validationSchemaObj = { ...prevState.validationSchemaObj };

          // eslint-disable-next-line array-callback-return
          buttonsWithDynamicValue.map((button) => {
            const fieldName = `BUTTON-${button.type}-${button.index}`;
            initialValues[fieldName] = '';
            validationSchemaObj[fieldName] = Yup.string().required(
              `Please enter the value for ${WHATSAPP_TEMPLATES_BUTTON_TYPES[button.type]}`,
            );
          });

          return {
            initialValues,
            validationSchemaObj,
          };
        });
      }
    }
  };

  // handleFileUpload = async (event, setFieldValue, fieldName) => {
  //   const file = event.target.files?.[0];
  //   setFieldValue(fieldName, event.target.value);
  //   this.setState({ uploadedFile: file });
  // };

  handleFileUpload = async (event, setFieldValue, fieldName, fileTypeParam) => {
    const file = event.target.files?.[0];
    const fileSize = event.currentTarget.files?.[0]?.size;
    const sizeInMb = bytesToMegabytes(fileSize);
    const sizeAllowed = FILE_SIZE_ALLOWED?.[fileTypeParam.toUpperCase()];

    if (sizeInMb < sizeAllowed) {
      setFieldValue(fieldName, event.target.value);
      this.setState({
        uploadedFile: file,
      });
    } else {
      setFieldValue(fieldName, '');
      showToast({
        content: `${capitalizeFirstLetter(
          fileTypeParam,
        )} uploaded must be under ${sizeAllowed} MB in size`,
        type: toastType.error,
      });
    }
  };

  handleFileAddition = async () => {
    try {
      const { uploadedFile } = this.state;
      // Create formData object and append imageAttachment
      const formData = new FormData();
      formData.append('file', uploadedFile);

      // Upload image
      this.setState({ showModalLoader: true });
      const uploadResponse = await axiosPost('upload', formData);

      this.setState({ showModalLoader: false });
      return uploadResponse;
    } catch (error) {
      console.error('error at handleFileAddition:'.error);
      return null;
    }
  };

  renderHeader = (component, values, errors, touched, setFieldValue) => {
    const formattedType = component.type.charAt(0).toUpperCase() + component.type.slice(1).toLowerCase();
    if (component.format === 'TEXT' && Array.isArray(component.example?.header_text)) {
      const sub_type = component?.format?.toLowerCase();
      const getAcceptedFileTypes = (subType) => ACCEPTED_FILE_TYPES[subType];
      return (
        <div className="mt-3">
          <span className="fw-bold">{formattedType}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            {component?.text ? <span className="fw-bold">Text :</span> : ''}
            <div className="border border-darkgrey rounded py-1 px-2 my-2">
              <div className="template-text my-2">
                <span>{component?.format === 'TEXT' ? component?.text : ''}</span>
              </div>
            </div>
            {component.example?.header_text?.length ? (
              <span className="fw-bold">Parameters :</span>
            ) : (
              ''
            )}
            <div className="mb-2 border border-darkgrey rounded p-3">
              {component.example?.header_text?.map((text, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="mb-3" key={index}>
                  <div className="d-flex justify-content-between">
                    <Label className="form-label mt-2">{`{{${index + 1}}}`}</Label>
                    <VariableSelect
                      setSelectedVariable={(value) => this.handleSetSelectedVariable(
                        value,
                        `${component?.type}-${sub_type}-${index + 1}`,
                        setFieldValue,
                      )}
                    />
                  </div>

                  <Field
                    name={`${component?.type}-${sub_type}-${index + 1}`}
                    innerRef={this.inputRef}
                    type="text"
                    placeholder={text}
                    value={values?.[`${component?.type}-${sub_type}-${index + 1}`]}
                    onChange={(event) => this.handleSetFieldValue(
                      `${component?.type}-${sub_type}-${index + 1}`,
                      event.target.value,
                      setFieldValue,
                    )}
                    className={`form-control${
                      errors[`${component?.type}-${sub_type}-${index + 1}`]
                      && touched[`${component?.type}-${sub_type}-${index + 1}`]
                        ? ' is-invalid'
                        : ''
                    }`}
                    accept={getAcceptedFileTypes(sub_type)}
                  />
                  <ErrorMessage
                    name={`${component?.type}-${sub_type}-${index + 1}`}
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (
      component?.format === 'IMAGE'
      || component?.format === 'DOCUMENT'
      || component?.format === 'VIDEO'
    ) {
      const sub_type = component?.format?.toLowerCase();
      const sampleFile = component?.example?.header_handle[0];
      return (
        <div className="mt-3">
          <span className="fw-bold">{`${formattedType}`}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            <span className="fw-bold">{`Upload ${sub_type} :`}</span>
            <a href={sampleFile} target="_blank" rel="noopener noreferrer" className="ms-1">
              View Sample
            </a>
            <div className="mb-3 mt-2">
              <Field
                type="file"
                id="file"
                accept={
                  sub_type === FILE_TYPES.DOCUMENT
                    ? 'application/pdf'
                    : sub_type === FILE_TYPES.IMAGE
                      ? ['.jpeg', '.png', '.jpg']
                      : sub_type === FILE_TYPES.VIDEO
                        ? ['.mp4']
                        : null // Add a default case or handle appropriately
                }
                name={`${component?.type}-${sub_type}-uploadedFile`}
                onChange={(event) => this.handleFileUpload(
                  event,
                  setFieldValue,
                  `${component?.type}-${sub_type}-uploadedFile`,
                  sub_type,
                )}
                value={values[`${component?.type}-${sub_type}-uploadedFile`]}
                className={`form-control${
                  errors[`${component?.type}-${sub_type}-uploadedFile`]
                  && touched[`${component?.type}-${sub_type}-uploadedFile`]
                    ? ' is-invalid'
                    : ''
                }`}
              />
              <ErrorMessage
                name={`${component?.type}-${sub_type}-uploadedFile`}
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderBody = (component, values, errors, touched, setFieldValue) => {
    if (Array.isArray(component.example?.body_text)) {
      const formattedType = component.type.charAt(0).toUpperCase() + component.type.slice(1).toLowerCase();
      return (
        <div className="mt-3">
          <span className="fw-bold">{formattedType}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            <span className="fw-bold">Text :</span>
            <div className="border border-darkgrey rounded py-1 px-2 my-2">
              <div className="template-text my-2">
                {/* <label className="me-1">Text</label> */}
                <span>{component?.text}</span>
              </div>
            </div>
            {component.example?.body_text?.length ? (
              <span className="fw-bold">Parameters :</span>
            ) : (
              ''
            )}
            {' '}
            <div className="border border-darkgrey rounded pt-3 px-2 mt-2">
              {component.example.body_text.map((texts) => texts.map((text, index2) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="mb-3" key={index2}>
                  <div className="d-flex justify-content-between">
                    <Label className="form-label text-center mt-2">{`{{${index2 + 1}}}`}</Label>
                    <VariableSelect
                      setSelectedVariable={(value) => this.handleSetSelectedVariable(
                        value,
                        `${component?.type}-text-${index2 + 1}`,
                        setFieldValue,
                      )}
                    />
                  </div>
                  <Field
                    name={`${component?.type}-text-${index2 + 1}`}
                    type="text"
                    placeholder={text}
                    innerRef={this.inputRef}
                    value={values?.[`${component?.type}-text-${index2 + 1}`]}
                    onChange={(event) => this.handleSetFieldValue(
                      `${component?.type}-text-${index2 + 1}`,
                      event.target.value,
                      setFieldValue,
                    )}
                    className={`form-control${
                      errors[`${component?.type}-text-${index2 + 1}`]
                        && touched[`${component?.type}-text-${index2 + 1}`]
                        ? ' is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage
                    name={`${component?.type}-text-${index2 + 1}`}
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              )))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderButtons = (component, errors, touched) => {
    if (Array.isArray(component.buttons)) {
      const buttonsWithDynamicValue = component.buttons.filter((button, index) => {
        if (button.example) {
          // eslint-disable-next-line no-param-reassign
          button.index = index;
          return true;
        }
        return false;
      });

      if (buttonsWithDynamicValue.length) {
        const formattedType = component.type.charAt(0).toUpperCase() + component.type.slice(1).toLowerCase();
        return (
          <div className="mt-3">
            <span className="fw-bold">{formattedType}</span>
            <div className="mt-2 border border-darkgrey rounded py-2 px-3">
              {buttonsWithDynamicValue.length
                ? buttonsWithDynamicValue?.map((button, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="mb-3" key={`${button.type}-${index}`}>
                    <Label className="form-label mt-2">
                      {WHATSAPP_TEMPLATES_BUTTON_TYPES[button.type]}
                    </Label>
                    <Field
                      name={`BUTTON-${button.type}-${button.index}`}
                      type="text"
                      placeholder={button.example}
                      className={`form-control${
                        errors[`BUTTON-${button.type}-${button.index}`]
                          && touched[`BUTTON-${button.type}-${button.index}`]
                          ? ' is-invalid'
                          : ''
                      }`}
                    />
                    <ErrorMessage
                      name={`BUTTON-${button.type}-${button.index}`}
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                ))
                : ''}
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  };

  render() {
    const { showModal, toggle } = this.props;
    const {
      showModalLoader,
      selectedTemplate,
      initialValues,
      validationSchemaObj,
      whatsappNumbers,
      templates,
    } = this.state;

    const validationSchema = Yup.object().shape(validationSchemaObj);
    return (
      <Modal isOpen={showModal} className="whatsapp-template">
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={toggle} tag="h4">
          Add Whatsapp Template
        </ModalHeader>
        <ModalBody
          className={`pb-0
            ${selectedTemplate?.components?.length ? 'whatsapp-template-body' : ''}
          `}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors, touched, values, setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Whatspp Number</Label>
                      <Field
                        as="select"
                        name="whatsappNumber"
                        className={`form-control-select${
                          errors.whatsappNumber && touched.whatsappNumber ? ' is-invalid' : ''
                        }`}
                        onChange={(event) => {
                          this.handleNumberSelection(event.target.value, setFieldValue); // Your custom function
                        }}
                        value={values.whatsappNumber}
                      >
                        <option value="">Select a number</option>
                        {whatsappNumbers?.map((number, index) => (
                          <option
                            value={`${number?.whatsappBusinessAccountId}-${number?.numberId}`}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${number?.phoneNumber}-${index}`}
                          >
                            {number?.phoneNumber}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="whatsappNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Whatsapp Template</Label>
                      <Field
                        as="select"
                        name="whatsappTemplate"
                        className={`form-control-select${
                          errors.whatsappTemplate && touched.whatsappTemplate ? ' is-invalid' : ''
                        }`}
                        onChange={(event) => this.handleTemplateSelection(event.target.value, values, setFieldValue)}
                        value={values.whatsappTemplate}
                      >
                        <option value="">Select a template</option>
                        {templates.length
                          ? templates?.map((template, index) => (
                            <option
                                // eslint-disable-next-line react/no-array-index-key
                              key={`${template?.name}-${index}`}
                              value={JSON.stringify(template)}
                            >
                              {`${template?.name} - ${template?.language}`}
                            </option>
                          ))
                          : null}
                        {' '}
                      </Field>
                      <ErrorMessage
                        name="whatsappTemplate"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {selectedTemplate?.components ? (
                      <div>
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                          <i className="mdi mdi-alert font-size-24 me-3" />
                          <div>
                            Please keep in mind that your template should closely align with the
                            example or sample text/document provided. Deviating significantly may
                            result in rejection by the meta.
                          </div>
                        </div>
                        <b className="lh-lg">{selectedTemplate?.name.toUpperCase()}</b>
                        <div className="mb-3 mt-2">
                          <Label className="form-label">Template Name</Label>
                          <Field
                            name="templateName"
                            type="text"
                            placeholder="Enter template name here..."
                            className={`form-control${
                              errors.templateName && touched.templateName ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            name="templateName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        {selectedTemplate?.components?.map((component, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index}>
                            {component.type === 'HEADER'
                              && this.renderHeader(component, values, errors, touched, setFieldValue)}
                            {component.type === 'BODY'
                              && this.renderBody(component, values, errors, touched, setFieldValue)}
                            {component.type === 'BUTTONS'
                              && this.renderButtons(component, errors, touched)}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row className="fixed-footer p-2">
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

Whatsapp.propTypes = {
  toggle: PropTypes.func,
  getAllTemplates: PropTypes.func,
  showModal: PropTypes.bool,
  history: PropTypes.object,
  isOnboarding: PropTypes.bool,
  setOnboardingData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isOnboarding: state.onboarding.isOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Whatsapp));
