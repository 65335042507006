import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CHANNEL_TYPES } from '../../constants/channels/channels.constant';
import { NOTIFICATION_TYPES } from '../../constants/inbox/inbox.constant';
import LastMessageDisplay from '../../pages/Inbox/InboxCommonComponents/LastMessageDisplay';

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChannelInfo = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding-right: 0rem;
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SenderName = styled.div`
  font-weight: bold;
`;

const MessagePreview = styled.div`
  font-size: 12px;
`;

function CustomToastContent({ data }) {
  const {
    channel, from, message, conversationObj,
  } = data;
  const isTextChannel = channel === CHANNEL_TYPES.TEXT;

  return (
    <ToastContainer>
      <ChannelInfo className="notification-toast">
        <IconWrapper>
          <i className={`bx ${isTextChannel ? 'bx-message-square-detail' : 'bxl-whatsapp'} font-size-24 ${isTextChannel ? 'message-icon' : 'whatsapp-icon'} me-1`} />
        </IconWrapper>
        <div className="mb-1">
          <span>{isTextChannel ? NOTIFICATION_TYPES.SMS : NOTIFICATION_TYPES.WHATSAPP}</span>
        </div>
      </ChannelInfo>
      <MessageContent>
        <div className="from d-flex">
          <div className="d-flex flex-column">
            <SenderName>{conversationObj?.name || from}</SenderName>
            <MessagePreview>
              <LastMessageDisplay
                lastMessage={message}
                lastMessageType={conversationObj?.lastMessageType}
              />
            </MessagePreview>
          </div>
        </div>
      </MessageContent>
    </ToastContainer>
  );
}

CustomToastContent.propTypes = {
  data: PropTypes.shape({
    channel: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    conversationObj: PropTypes.shape({
      name: PropTypes.string,
      lastMessageType: PropTypes.string,
    }),
  }).isRequired,
};

export default CustomToastContent;
