import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as PrimeButton } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { styled } from 'styled-components';

const StyledButton = styled(PrimeButton)`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  height: 3rem;
  width: 3rem;

  &.small {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }

  &.large {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }

  &.p-button-badge {
    position: relative;
    .p-badge {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }
  }

  &:hover {
    filter: brightness(90%);
  }

  /* Applying styles based on props */
  ${({
    raised, outlined, rounded, text, severity,
  }) => `
    ${raised ? 'p-button-raised' : ''}
    ${outlined ? 'p-button-outlined' : ''}
    ${rounded ? 'p-button-rounded' : ''}
    ${text ? 'p-button-text' : ''}
    ${severity ? `p-button-${severity}` : ''}
  `}
`;

class CustomButton extends Component {
  render() {
    const {
      label,
      icon,
      iconPos,
      className,
      disabled,
      severity,
      raised,
      outlined,
      rounded,
      text,
      onClick,
      loading,
      badge,
      size,
      ...rest
    } = this.props;

    const buttonClass = `
      ${raised ? 'p-button-raised' : ''}
      ${outlined ? 'p-button-outlined' : ''}
      ${rounded ? 'p-button-rounded' : ''}
      ${text ? 'p-button-text' : ''}
      ${severity ? `p-button-${severity}` : ''}
      ${size}
      ${className}
    `;

    return (
      <StyledButton
        label={label}
        icon={icon}
        iconPos={iconPos}
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
        loading={loading}
        {...rest}
      >
        {badge && <Badge value={badge} severity={severity} />}
      </StyledButton>
    );
  }
}

CustomButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  iconPos: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  severity: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'help',
    'danger',
  ]),
  raised: PropTypes.bool,
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
  text: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

CustomButton.defaultProps = {
  iconPos: 'left',
  className: '',
  disabled: false,
  raised: false,
  outlined: false,
  rounded: false,
  text: false,
  severity: 'primary',
  loading: false,
  badge: null,
  size: 'normal',
};

export default CustomButton;
