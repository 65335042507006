import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row as ReactStrapRow } from 'reactstrap';
import styled from 'styled-components';

const StyledRow = styled(ReactStrapRow)`

`;

class Row extends Component {
  render() {
    const { children, ...props } = this.props;

    return <StyledRow {...props}>{children}</StyledRow>;
  }
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Row.defaultProps = {
  className: '',
};

export default Row;
