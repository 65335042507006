import React, { Component } from 'react';
import { Container } from 'reactstrap';

// Import Breadcrumb
import BulkMessage from './BulkMessage';

class Message extends Component {
  render() {
    // meta title
    document.title = 'Message Campaign';

    return (
      <div className="page-content">
        <Container fluid>
          <BulkMessage />
        </Container>
      </div>
    );
  }
}

export default Message;
