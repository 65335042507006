/* eslint-disable import/prefer-default-export */
export const REPORTS = [
  {
    title: 'Sent SMS',
    iconClass: 'mdi mdi-email',
    key: 'totalSentMessages',
    description: '0',
  },
  {
    title: 'Delivered SMS',
    iconClass: 'mdi mdi-email-check',
    key: 'totalDeliveredMessages',
    description: '0',
  },
  {
    title: 'Failed SMS',
    iconClass: 'mdi mdi-email-remove',
    key: 'totalFailedMessages',
    description: '0',
  },
  {
    title: 'Queued SMS',
    iconClass: 'mdi mdi-email-plus',
    key: 'totalQueuedMessages',
    description: '0',
  },
  {
    title: 'Not Respond',
    iconClass: 'mdi mdi-email-mark-as-unread',
    key: 'totalNotRespondMessages',
    description: '0',
  },
  {
    title: 'Success Respond',
    iconClass: 'mdi mdi-email',
    key: 'totalSuccessRespondMessages',
    description: '0',
  },
];

export const ANALYTICS_TYPES = {
  DELIVERY: 'delivery',
  REPLY: 'reply',
  TAG: 'tag',
};
