import React from 'react';
import PropTypes from 'prop-types';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import styled from 'styled-components';

const StyledDataTable = styled(PrimeDataTable)`
  /* Add your custom styles here */
`;

const StyledSearchInput = styled(InputText)`
  width: 25%;
  border-radius: 0.4rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
`;

const StyledDataTableWrapper = styled.div``;

const StyledSearchContainerWrapper = styled.div``;

function DataTable({
  value,
  selection,
  onSelectionChange,
  className,
  rows,
  onRowClick,
  children,
  showGridlines,
  totalRecords,
  onPageChange,
  stripedRows,
  resizableColumns,
  first,
  rowsPerPageOptions,
  tableStyle,
  filters,
  filterDisplay,
  selectionMode,
  onSearch,
  searchValue,
  isSearchable,
  searchIcon,
  showPagination,
  ...rest
}) {
  const handleSearch = (event) => {
    onSearch({ event, searchValue: event.target.value });
  };

  return (
    <StyledDataTableWrapper>
      {isSearchable && (
        <StyledSearchContainerWrapper>
          <StyledSearchInput
            type="search"
            icon={searchIcon}
            onChange={handleSearch}
            placeholder="Search..."
            value={searchValue}
            className="w-25"
          />
        </StyledSearchContainerWrapper>
      )}
      <StyledDataTable
        value={value}
        selectionMode={selectionMode}
        selection={selection}
        onSelectionChange={onSelectionChange}
        onRowClick={onRowClick}
        className={className}
        stripedRows={stripedRows}
        showGridlines={showGridlines}
        resizableColumns={resizableColumns}
        filters={filters}
        filterDisplay={filterDisplay}
        tableStyle={tableStyle}
        {...rest}
      >
        {children}
      </StyledDataTable>
      {
        showPagination
        && (
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
        />
        )
      }
    </StyledDataTableWrapper>
  );
}

DataTable.propTypes = {
  value: PropTypes.array.isRequired,
  selection: PropTypes.any,
  onSelectionChange: PropTypes.func,
  className: PropTypes.string,
  rows: PropTypes.number,
  onRowClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  showGridlines: PropTypes.bool,
  totalRecords: PropTypes.number,
  showPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  first: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  resizableColumns: PropTypes.bool,
  stripedRows: PropTypes.bool,
  tableStyle: PropTypes.object,
  filters: PropTypes.object,
  filterDisplay: PropTypes.string,
  selectionMode: PropTypes.string,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  isSearchable: PropTypes.bool,
  searchIcon: PropTypes.string,
};

DataTable.defaultProps = {
  className: '',
  selection: null,
  onSelectionChange: () => {},
  onRowClick: () => {},
  totalRecords: 0,
  first: 0,
  searchValue: '',
  searchIcon: '',
  showPagination: true,
  rowsPerPageOptions: [10, 20, 30],
};

export default DataTable;
