/* eslint-disable no-shadow */
import {
  ErrorMessage,
  Field, Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { renderUserAvatar } from '../../../helpers/commonHelpers';
import UserSelect from '../../../components/Common/UserSelect';
import ModalLoader from '../../../components/Common/ModalLoader';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import { axiosPatch, axiosPost } from '../../../services/http.service';
import { setShowSenderGroupModal } from '../../../store/sender-group/actions';
import { ROLES } from '../../../constants/users/role.constant';

class SenderForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowSenderGroupModal } = this.props;
    onSetShowSenderGroupModal();
  };

  handleAddSenderGroup = async (name) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllSenderGroups } = this.props;
      const response = await axiosPost('sender-groups', { name });
      if (response?.status) {
        toast.success(
          response?.message || 'Sender Group created successfully!',
          {
            position: 'top-right',
          },
        );
        getAllSenderGroups();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while adding sender group :', error);
    }
  };

  handleEditSenderGroup = async (data) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllSenderGroups } = this.props;
      const { group } = this.props;
      const response = await axiosPatch(`sender-groups/${group?.id}`, {
        ...data,
      });
      if (response?.status) {
        toast.success(
          response?.message || 'Sender Group created successfully!',
          {
            position: 'top-right',
          },
        );
        getAllSenderGroups();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while updating sender group :', error);
    }
  };

  onSubmit = (values) => {
    const {
      isEdit, group,
    } = this.props;
    if (isEdit) {
      const isGroupNameChanged = group?.name !== values?.groupName;
      const isUserNameChanged = group?.assignedUserId !== values.assignedUserId.value;

      if (!isGroupNameChanged && !isUserNameChanged) {
        // No changes, you might want to display a message or handle it accordingly
        this.toggle();
        return;
      }

      const data = {};

      if (isGroupNameChanged) {
        data.name = values?.groupName;
      }

      if (isUserNameChanged) {
        const { value, name } = values?.assignedUserId;
        data.assignedUserId = value;
        data.assignedUserName = name;
      }

      if (isUserNameChanged && isGroupNameChanged) {
        const { value, name } = values?.assignedUserId;
        data.assignedUserId = value;
        data.assignedUserName = name;
        data.name = values?.groupName;
      }

      this.handleEditSenderGroup(data);
    } else {
      this.handleAddSenderGroup(values.groupName);
    }
  };

  render() {
    const {
      isEdit, showSenderFormModal, group, className,
    } = this.props;
    const { showModalLoader } = this.state;
    const { userData } = this.context;

    const mapToSelectOption = (groupData) => ({
      value: groupData.assignedUserId,
      label: (renderUserAvatar(groupData.assignedUserName)
      ),
    });

    const initialValues = {
      groupName: group?.name || '',
      ...(isEdit && userData?.role === ROLES.ADMIN
        ? {
          assignedUserId: group.assignedUserId
            ? mapToSelectOption(group)
            : '',
        }
        : {}),
    };

    const validationSchema = Yup.object().shape({
      groupName: Yup.string().required('Please enter group name'),
      ...(isEdit
        && userData?.role === ROLES.ADMIN && {
        assignedUserId: Yup.object().required('Please enter the user name'),
      }),
    });

    return (
      <Modal isOpen={showSenderFormModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          {isEdit ? 'Edit Group' : 'Add Group'}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >
            {({
              errors, touched, setFieldValue, values,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        name="groupName"
                        type="text"
                        className={
                          `form-control${
                            errors.groupName && touched.groupName
                              ? ' is-invalid'
                              : ''}`
                        }
                      />
                      <ErrorMessage
                        name="groupName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {isEdit && userData?.role === ROLES.ADMIN && (
                    <div className="mb-3">
                      <Label className="form-label">User Name</Label>
                      <UserSelect
                        className={`${
                          errors.assignedUserId && touched.assignedUserId ? ' is-invalid' : ''
                        }`}
                        selectedOptions={
                            values.assignedUserId
                          }
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'assignedUserId',
                            selectedOption,
                          );
                        }}
                      />
                      <ErrorMessage
                        name="assignedUserId"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

SenderForm.propTypes = {
  className: PropTypes.any,
  onSetShowSenderGroupModal: PropTypes.func,
  isEdit: PropTypes.bool,
  showSenderFormModal: PropTypes.bool,
  group: PropTypes.object,
  getAllSenderGroups: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowSenderGroupModal: () => dispatch(setShowSenderGroupModal()),
});

const mapStateToProps = ({ senderGroup }) => ({
  showSenderFormModal: senderGroup.showSenderFormModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SenderForm));
