import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  optType, PAGE, SIZE_PER_PAGE, TOTAL_SIZE,
} from '../../../constants/constants';
import { axiosDel, axiosGet } from '../../../services/http.service';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setShowOptModal } from '../../../store/user/actions';
import OptForm from './Modal/OptForm';
import { setLoader } from '../../../store/actions';
import DataGrid from '../../../components/DataTable';

class Opt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opts: [],
      opt: '',
      isEdit: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      optColumns: [
        {
          header: 'User',
          accessorKey: 'adminName',
        },
        {
          header: 'Opt Out',
          accessorKey: 'optOut',
          Cell: ({ renderedCellValue }) => (
            <span>
              {' '}
              {optType[renderedCellValue]}
            </span>
          ),
        },
        {
          header: 'Text',
          accessorKey: 'content',
        },
        {
          accessorKey: 'menu',
          enableSorting: false,
          header: 'Action',
          Cell: ({ row }) => {
            const opt = row?.original;
            return (
              <div className="d-flex gap-3">
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      id="edit"
                      onClick={() => this.handleUserClick(opt)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="danger-subtle" className="text-danger">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete"
                      onClick={() => this.onClickDelete(opt)}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetAllOpts(page);
  }

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllOpts(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getAllUsers(1, newPageSize);
      this.handleGetAllOpts(1, newPageSize);
    });
  };

  toggle = () => {
    const { onSetShowOptModal } = this.props;
    onSetShowOptModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (opt) => {
    this.setState({ opt, deleteModal: true });
  };

  handleUserClicks = () => {
    this.setState({ opt: '', isEdit: false });
    this.toggle();
  };

  handleUserClick = (arg) => {
    const optInfo = arg;

    this.setState({
      opt: {
        id: optInfo.id,
        text: optInfo.content,
        optOut: optInfo.optOut,
      },
      isEdit: true,
    });

    this.toggle();
  };

  /* Insert,Update Delete data */

  handleGetAllOpts = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `opt?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        this.setState({
          opts: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('error at handleGetAllOpts :', error);
      onSetLoader(false);
    }
  };

  handleOptDeletion = async () => {
    try {
      const { opt, page } = this.state;
      const response = await axiosDel(`opt/${opt?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Opt Deleted successfully!');
        this.handleGetAllOpts(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error('error at handleOptDeletion :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllOpts(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'opt';

    const {
      deleteModal, isEdit, opt, opts, page, optColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleOptDeletion}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <OptForm
          isEdit={isEdit}
          optInfo={opt}
          getAllOpts={() => {
            this.handleGetAllOpts(page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Opt" breadcrumbItem="Opt List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Opt
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={opts}
                        columns={optColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Opt.propTypes = {
  onSetShowOptModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowOptModal: () => dispatch(setShowOptModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ user }) => ({
  showOptFormModal: user.showOptFormModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Opt));
