/* eslint-disable import/prefer-default-export */
import { handleError } from '../errorHandler';
import { axiosGet } from '../http.service';

export const fetchAllQuickMessages = async () => {
  try {
    const response = await axiosGet('quick-message');
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to fetch quick messages');
  } catch (error) {
    handleError('Error fetching unread messages:', error);
    throw error;
  }
};
