export const FIELD_TYPES = {
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  BOOLEAN: 'boolean',
};

export const FIELDS_OBJ = {
  FIRST_NAME: {
    KEY: 'firstName',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-user',
    NAME: 'First Name',
    DEFAULT_VALUE: '',
  },
  LAST_NAME: {
    KEY: 'lastName',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-user',
    NAME: 'Last Name',
    DEFAULT_VALUE: '',
  },
  PHONE_NUMBER: {
    KEY: 'phoneNumber',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-phone',
    NAME: 'Phone Number',
    DEFAULT_VALUE: '',
  },
  COUNTRY_CODE: {
    KEY: 'countryCode',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-globe',
    NAME: 'Country Code',
    DEFAULT_VALUE: '',
  },
  STATE: {
    KEY: 'state',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-map-alt',
    NAME: 'State',
    DEFAULT_VALUE: '',
  },
  CITY: {
    KEY: 'city',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-buildings',
    NAME: 'City',
    DEFAULT_VALUE: '',
  },
  ZIP_CODE: {
    KEY: 'zip',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-map-pin',
    NAME: 'Zip Code',
    DEFAULT_VALUE: '',
  },
  EMAIL: {
    KEY: 'email',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'bx bx-envelope',
    NAME: 'Email',
    DEFAULT_VALUE: '',
  },
  CREATED_AT: {
    KEY: 'createdAt',
    TYPE: FIELD_TYPES.DATE,
    ICON: 'bx bx-calendar-plus',
    NAME: 'Created At',
    DEFAULT_VALUE: '',
  },
  UPDATED_AT: {
    KEY: 'updatedAt',
    TYPE: FIELD_TYPES.DATE,
    ICON: 'bx bx-calendar-plus',
    NAME: 'Updated At',
    DEFAULT_VALUE: '',
  },
  LEAD_STAGE: {
    KEY: 'leadStage',
    TYPE: FIELD_TYPES.SELECT,
    ICON: 'bx bx-trophy',
    NAME: 'Lead Stage',
    DEFAULT_VALUE: '',
  },
  MARKETING_OPTIN: {
    KEY: 'marketingOptIn',
    TYPE: FIELD_TYPES.BOOLEAN,
    ICON: 'bx bx-check-circle',
    NAME: 'Marketing OptIn',
    DEFAULT_VALUE: false,
  },
  ADDRESS: {
    KEY: 'address',
    TYPE: FIELD_TYPES.TEXT,
    ICON: 'mdi mdi-home',
    NAME: 'Address',
    DEFAULT_VALUE: '',
  },
};

export const FILTERED_FIELDS = [
  {
    name: FIELDS_OBJ.FIRST_NAME.NAME,
    key: FIELDS_OBJ.FIRST_NAME.KEY,
    type: FIELDS_OBJ.FIRST_NAME.TYPE,
    icon: FIELDS_OBJ.FIRST_NAME.ICON,
    defaultValue: FIELDS_OBJ.FIRST_NAME.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.LAST_NAME.NAME,
    key: FIELDS_OBJ.LAST_NAME.KEY,
    type: FIELDS_OBJ.LAST_NAME.TYPE,
    icon: FIELDS_OBJ.LAST_NAME.ICON,
    defaultValue: FIELDS_OBJ.LAST_NAME.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.PHONE_NUMBER.NAME,
    key: FIELDS_OBJ.PHONE_NUMBER.KEY,
    type: FIELDS_OBJ.PHONE_NUMBER.TYPE,
    icon: FIELDS_OBJ.PHONE_NUMBER.ICON,
    defaultValue: FIELDS_OBJ.PHONE_NUMBER.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.COUNTRY_CODE.NAME,
    key: FIELDS_OBJ.COUNTRY_CODE.KEY,
    type: FIELDS_OBJ.COUNTRY_CODE.TYPE,
    icon: FIELDS_OBJ.COUNTRY_CODE.ICON,
    defaultValue: FIELDS_OBJ.COUNTRY_CODE.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.STATE.NAME,
    key: FIELDS_OBJ.STATE.KEY,
    type: FIELDS_OBJ.STATE.TYPE,
    icon: FIELDS_OBJ.STATE.ICON,
    defaultValue: FIELDS_OBJ.STATE.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.CITY.NAME,
    key: FIELDS_OBJ.CITY.KEY,
    type: FIELDS_OBJ.CITY.TYPE,
    icon: FIELDS_OBJ.CITY.ICON,
    defaultValue: FIELDS_OBJ.CITY.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.ZIP_CODE.NAME,
    key: FIELDS_OBJ.ZIP_CODE.KEY,
    type: FIELDS_OBJ.ZIP_CODE.TYPE,
    icon: FIELDS_OBJ.ZIP_CODE.ICON,
    defaultValue: FIELDS_OBJ.ZIP_CODE.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.EMAIL.NAME,
    key: FIELDS_OBJ.EMAIL.KEY,
    type: FIELDS_OBJ.EMAIL.TYPE,
    icon: FIELDS_OBJ.EMAIL.ICON,
    defaultValue: FIELDS_OBJ.EMAIL.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.CREATED_AT.NAME,
    key: FIELDS_OBJ.CREATED_AT.KEY,
    type: FIELDS_OBJ.CREATED_AT.TYPE,
    icon: FIELDS_OBJ.CREATED_AT.ICON,
    defaultValue: FIELDS_OBJ.CREATED_AT.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.UPDATED_AT.NAME,
    key: FIELDS_OBJ.UPDATED_AT.KEY,
    type: FIELDS_OBJ.UPDATED_AT.TYPE,
    icon: FIELDS_OBJ.UPDATED_AT.ICON,
    defaultValue: FIELDS_OBJ.UPDATED_AT.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.LEAD_STAGE.NAME,
    key: FIELDS_OBJ.LEAD_STAGE.KEY,
    type: FIELDS_OBJ.LEAD_STAGE.TYPE,
    icon: FIELDS_OBJ.LEAD_STAGE.ICON,
    defaultValue: FIELDS_OBJ.LEAD_STAGE.DEFAULT_VALUE,
  },
  {
    name: FIELDS_OBJ.MARKETING_OPTIN.NAME,
    key: FIELDS_OBJ.MARKETING_OPTIN.KEY,
    type: FIELDS_OBJ.MARKETING_OPTIN.TYPE,
    icon: FIELDS_OBJ.MARKETING_OPTIN.ICON,
    defaultValue: FIELDS_OBJ.MARKETING_OPTIN.DEFAULT_VALUE,
  },
];

export const FIELD_NAMES = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Phone Number', value: 'phoneNumber' },
  { label: 'Country Code', value: 'countryCode' },
  { label: 'State', value: 'state' },
  { label: 'City', value: 'city' },
  { label: 'Zip Code', value: 'zipCode' },
  { label: 'Email', value: 'email' },
];

export const CONDITION_OPTIONS = [
  { label: 'Equals', value: 'equals' },
  { label: 'Not Equal', value: 'not_equal' },
  { label: 'Starts With', value: 'starts_with' },
  { label: 'Ends With', value: 'ends_with' },
  { label: 'Contains', value: 'contains' },
  { label: "Doesn't Contain", value: 'does_not_contain' },
  { label: 'Is Unknown', value: 'is_unknown' },
  { label: 'Has Any Value', value: 'has_any_value' },
  { label: 'In', value: 'in' },
  { label: 'Not In', value: 'not_in' },
];
