/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OverlayPanel } from 'primereact/overlaypanel';
import Button from '../Button';
import Dropdown from '../Dropdown';
import InputText from '../Input';
import Checkbox from '../Checkbox';
import Calendar from '../Calendar';
import Chip from '../Chip';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';

import { FIELD_TYPES, FILTERED_FIELDS, FIELDS_OBJ } from '../../constants/field.constant';

const FilterContainer = styled.div`
  font-family: 'Roboto', Arial, sans-serif;
`;

const StyledOverlayPanel = styled(OverlayPanel)`
  box-shadow: 2px 1px 6px var(--bluegray-400);

  .p-overlaypanel-content {
    padding: 0.5rem;
  }
`;

const FilterButton = styled(Button)`
  &.p-button {
    background-color: var(--bs-primary);
    border: none;
    color: white;
    font-weight: 600;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
      background-color: #2563eb;
      box-shadow: 0 4px 6px rgba(59, 130, 246, 0.25);
    }
    .p-button-icon {
      font-size: 1rem;
    }
  }
`;

const FilterPanel = styled.div`
  padding: 1.5rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const ConditionRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
`;

const StyledDropdown = styled(Dropdown)`
  .p-dropdown {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .p-dropdown-label {
    padding: 0.5rem 0.75rem;
  }
  .p-dropdown-trigger {
    padding: 0 0.75rem;
  }
  ul {
    padding-left: 0 !important;
  }
  .p-dropdown-item {
    padding-left: 0 !important;
  }
`;

const StyledInputText = styled(InputText)`
  border-radius: 6px !important;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: 2.7rem;
  margin-top: 0.25rem;
`;

const StyledCalendar = styled(Calendar)`
  .p-inputtext {
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    height: 2.7rem;
    margin-top: 1.1rem;
  }
  .p-button {
    height: 2.6rem;
    margin-top: 1.2rem;
    border-radius: 4px;
  }
`;

const RemoveButton = styled(Button)`
  &.p-button {
    background-color: transparent;
    border: none;
    color: var(--bs-danger);
    padding: 0.25rem;
    &:hover {
      // background-color: rgba(239, 68, 68, 0.1);
    }
    .p-button-icon {
      font-size: 1rem;
    }
  }
`;

const AddConditionButton = styled(Button)`
  &.p-button {
    margin-bottom: 0.5rem;
    background-color: white;
    border: none;
    color: var(--bs-primary);
    font-weight: 600;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 6px;
    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
      border: none;
      background-color: rgba(59, 130, 246, 0.1);
      box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
    }
  }
`;

const RelationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  gap: 1rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  color: #4b5563;
  input {
    margin-right: 0.5rem;
  }
`;

const ApplyButton = styled(Button)`
  &.p-button {
    background-color: var(--bs-primary);
    border: none;
    color: white;
    font-weight: 500;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 6px;
    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
      background-color: #2563eb;
      box-shadow: 0 4px 6px rgba(59, 130, 246, 0.25);
    }
    
    i {
      font-size: 1rem;
    }
  }
`;

const AppliedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const StyledChip = styled(Chip)`
  background-color: #e1f5fe;
  color: #0288d1;
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
  display: flex;
  justify-content: space-between;

  .p-chip-remove-icon {
    color: #0288d1;
    font-size: 0.75rem;
  }
`;

const FieldIcon = styled.i`
  margin-right: 0.5rem;
  font-size: 1.25rem;
  color: var(--bs-primary);
`;

const RelationChip = styled(Chip)`
  background-color: #f3f4f6;
  color: #4b5563;
  font-weight: 600;
  margin: 0 0.5rem;
`;

const FilterOperator = styled.span`
  font-weight: bold;
`;
const MoreFiltersChip = styled(Chip)`
  cursor: pointer;
`;

const ClearButton = styled(Button)`
  &.p-button {
    background-color: white;
    border: 2px solid var(--bs-danger);
    color: var(--bs-danger);
    font-weight: 500;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 6px;
    margin-right: 1rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
      background-color: rgba(239, 68, 68, 0.1);
      box-shadow: 0 2px 4px rgba(239, 68, 68, 0.2);
    }

    i {
      font-size: 1rem;
    }
  }
`;

const TagChip = styled(Chip)`
  background-color: #e5e7eb;
  color: #374151;
  border-radius: 16px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const FooterButtons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const PopupContent = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const conditionMap = {
  contains: 'Contains',
  not_contains: 'Does not contain',
  equals: 'Equals',
  not_equals: 'Does not equal',
  is: 'Is',
  is_before: 'Is before',
  is_after: 'Is after',
  is_not: 'Is Not',
  starts_with: 'Starts with',
  ends_with: 'Ends with',
};

function AdvancedFilter({ onFilterApplied }) {
  const [conditions, setConditions] = useState([
    {
      field: FIELDS_OBJ.FIRST_NAME.KEY,
      condition: '',
      value: '',
      type: FIELDS_OBJ.FIRST_NAME.TYPE,
    },
  ]);
  const [andRelation, setAndRelation] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const op = useRef(null);
  const moreConditionOp = useRef(null);

  const getOperators = useCallback((type) => {
    const {
      TEXT, DATE, SELECT, BOOLEAN,
    } = FIELD_TYPES;
    switch (type) {
      case TEXT:
        return [
          { label: 'Contains', value: 'contains' },
          { label: 'Does not contain', value: 'not_contains' },
          { label: 'Equals', value: 'equals' },
          { label: 'Does not equal', value: 'not_equals' },
        ];
      case DATE:
        return [
          { label: 'Is', value: 'is' },
          { label: 'Is before', value: 'is_before' },
          { label: 'Is after', value: 'is_after' },
        ];
      case SELECT:
        return [
          { label: 'Is', value: 'is' },
          { label: 'Is not', value: 'is_not' },
        ];
      case BOOLEAN:
        return [
          { label: 'Is', value: 'is' },
        ];
      default:
        return [];
    }
  }, []);

  const selectedFieldTemplate = useCallback((option) => {
    if (option) {
      const field = FILTERED_FIELDS.find((f) => f.key === option.value);
      return (
        <div className="d-flex align-items-center">
          <FieldIcon className={`${field.icon}`} />
          <span>{option.label}</span>
        </div>
      );
    }
    return <span>Select Field</span>;
  }, []);

  const FieldsOptionTemplate = useCallback((option) => {
    const field = FILTERED_FIELDS.find((f) => f.key === option.value);
    return (
      <div className="d-flex align-items-center justify-content-center">
        <FieldIcon className={`${field.icon}`} />
        <span>{option.label}</span>
      </div>
    );
  }, []);

  const handleValueChange = useCallback((index, value) => {
    setConditions((prevConditions) => prevConditions.map((condition, i) => (i === index ? { ...condition, value } : condition)));
  }, []);

  const renderValueInput = useCallback((condition, index) => {
    const {
      TEXT, DATE, SELECT, BOOLEAN,
    } = FIELD_TYPES;
    const field = FILTERED_FIELDS.find((f) => f.key === condition.field);

    switch (field.type) {
      case TEXT:
        if (field.key === 'tags') {
          return (
            <div>
              {condition.value.split(',').map((tag, i) => (
                <TagChip key={i} label={tag.trim()} />
              ))}
              <StyledInputText
                value={condition.value}
                onChange={(e) => handleValueChange(index, e.target.value)}
                placeholder="Enter tags (comma-separated)"
              />
            </div>
          );
        }
        return (
          <StyledInputText
            value={condition.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder="Enter value"
          />
        );
      case DATE:
        return (
          <StyledCalendar
            value={condition.value}
            onChange={(e) => handleValueChange(index, e.value)}
            showIcon
            showButtonBar
          />
        );
      case SELECT:
        return (
          <StyledDropdown
            value={condition.value}
            options={[{ label: 'Option 1', value: 'option1' }, { label: 'Option 2', value: 'option2' }]}
            onChange={(e) => handleValueChange(index, e.value)}
            placeholder="Select value"
          />
        );
      case BOOLEAN:
        return (
          <Checkbox
            id={condition.field}
            checked={condition.value}
            onChange={(checked) => {
              handleValueChange(index, checked);
            }}
          />
        );
      default:
        return (
          <StyledInputText
            value={condition.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder="Enter value"
          />
        );
    }
  }, [handleValueChange]);

  const addCondition = useCallback(() => {
    setConditions((prevConditions) => [...prevConditions, {
      field: '', condition: '', value: '', type: '',
    }]);
  }, []);

  const removeCondition = useCallback((index) => {
    setConditions((prevConditions) => prevConditions.filter((_, i) => i !== index));
  }, []);

  const handleFieldChange = useCallback((index, value) => {
    const { fieldType, defaultVal } = FILTERED_FIELDS.reduce((acc, field) => {
      const { key, type, defaultValue } = field;
      return key === value ? { fieldType: type, defaultVal: defaultValue } : acc;
    }, { fieldType: '', defaultVal: '' });

    setConditions((prevConditions) => prevConditions.map((condition, i) => (i === index ? {
      ...condition, field: value, condition: '', value: defaultVal, type: fieldType,
    } : condition)));
  }, []);

  const handleOperatorChange = useCallback((index, value) => {
    setConditions((prevConditions) => prevConditions.map((condition, i) => (i === index ? { ...condition, condition: value } : condition)));
  }, []);

  const formatDate = useCallback((value) => {
    if (value instanceof Date) {
      return moment(value).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
    }
    const formattedDate = moment(new Date(value)).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
    if (formattedDate.includes('Invalid')) {
      return value;
    }
    return formattedDate;
  }, []);

  const convertValueToString = useCallback((filters = []) => filters.map((filter) => ({ ...filter, value: `${filter?.value}` })), []);

  const applyFilters = useCallback(() => {
    const convertedConditions = convertValueToString(conditions);

    const formattedConditions = convertedConditions.map((condition) => ({
      ...condition,
      value: condition.value instanceof Date ? formatDate(condition.value) : condition.value,
    })).filter((c) => c.field && c.condition && c.value);

    setAppliedFilters(formattedConditions);

    if (onFilterApplied) {
      onFilterApplied({
        andBtnActive: andRelation,
        conditions: formattedConditions,
      });
    }

    op.current.hide();
  }, [conditions, andRelation, convertValueToString, formatDate, onFilterApplied]);

  const clearFilters = useCallback(() => {
    setConditions([{ field: FIELDS_OBJ.FIRST_NAME.KEY, condition: '', value: FIELDS_OBJ.FIRST_NAME.DEFAULT_VALUE }]);
    setAndRelation(true);
    setAppliedFilters([]);

    if (onFilterApplied) {
      onFilterApplied({
        andBtnActive: true,
        conditions: [],
      });
    }

    op.current.hide();
  }, [onFilterApplied]);

  const removeAppliedFilter = useCallback((index) => {
    setAppliedFilters((prevFilters) => {
      const newAppliedFilters = prevFilters.filter((_, i) => i !== index);
      setConditions(newAppliedFilters);

      if (onFilterApplied) {
        onFilterApplied({
          andBtnActive: andRelation,
          conditions: convertValueToString(newAppliedFilters),
        });
      }

      moreConditionOp.current.hide();

      if (!newAppliedFilters.length) {
        setConditions([{
          field: FIELDS_OBJ.FIRST_NAME.KEY,
          condition: '',
          value: '',
          type: FIELDS_OBJ.FIRST_NAME.TYPE,
        }]);
      }

      return newAppliedFilters;
    });
  }, [andRelation, convertValueToString, onFilterApplied]);

  const formatFilterLabel = useCallback((filter) => (
    <>
      {filter.field}
      {' '}
      <FilterOperator>{conditionMap[filter.condition]}</FilterOperator>
      {' '}
      {formatDate(filter.value)}
    </>
  ), [formatDate]);

  const disableAppliedFilterButton = conditions.length && conditions.every((condition) => {
    const valid = Object.keys(condition).every((key) => condition[key] !== null && condition[key] !== undefined && condition[key] !== '');
    return valid;
  });

  const handleFilterButtonClick = useCallback((e) => op.current.toggle(e), []);
  const handleMoreFiltersToggle = useCallback((e) => moreConditionOp.current.toggle(e), []);

  return (
    <FilterContainer>
      <FilterButton label="Filter" icon="bx bx-filter" onClick={handleFilterButtonClick} />
      <StyledOverlayPanel ref={op} visible>
        <FilterPanel>
          {conditions.map((condition, index) => (
            <ConditionRow key={`condition-${index}`}>
              <StyledDropdown
                valueTemplate={selectedFieldTemplate}
                itemTemplate={FieldsOptionTemplate}
                value={condition.field}
                options={FILTERED_FIELDS.reduce((acc, field) => {
                  const { name, key } = field;
                  const obj = {
                    label: name,
                    value: key,
                  };

                  if (condition.field === key) {
                    acc.push(obj);
                    return acc;
                  }

                  const keyAlreadySelected = conditions.some((cond) => cond.field === key);
                  if (!keyAlreadySelected) {
                    acc.push(obj);
                  }
                  return acc;
                }, [])}
                onChange={(e) => handleFieldChange(index, e.value)}
                placeholder="Select Field"
              />
              {condition.field && (
                <StyledDropdown
                  value={condition.condition}
                  options={getOperators(FILTERED_FIELDS.find((f) => f.key === condition.field)?.type)}
                  onChange={(e) => handleOperatorChange(index, e.value)}
                  placeholder="Select Operator"
                />
              )}
              {condition.condition && renderValueInput(condition, index)}
              <RemoveButton icon="bx bx-trash" onClick={() => removeCondition(index)} />
              {index < conditions.length - 1 && (
                <RelationChip label={andRelation ? 'AND' : 'OR'} />
              )}
            </ConditionRow>
          ))}
          <AddConditionButton label="Add Condition" icon="bx bx-plus" onClick={addCondition} />
          <RelationContainer>
            <RadioLabel>
              <input
                type="radio"
                value="AND"
                checked={andRelation}
                onChange={() => setAndRelation(true)}
              />
              AND
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                value="OR"
                checked={!andRelation}
                onChange={() => setAndRelation(false)}
              />
              OR
            </RadioLabel>
          </RelationContainer>
          <FooterButtons>
            <ClearButton
              label="Clear Filters"
              icon="mdi mdi-filter-variant-remove"
              onClick={clearFilters}
              disabled={!conditions?.length}
            />
            <ApplyButton
              label="Apply Filters"
              icon="bx bx-check"
              onClick={applyFilters}
              disabled={!disableAppliedFilterButton}
            />
          </FooterButtons>
        </FilterPanel>
      </StyledOverlayPanel>
      <AppliedFilters>
        {appliedFilters.map((filter, index) => (index < 2 ? (
          <StyledChip
            key={`applied-filter-${filter?.field}`}
            label={formatFilterLabel(filter)}
            removable
            onRemove={() => removeAppliedFilter(index)}
          />
        ) : null))}
        {appliedFilters.length > 2 && (
          <MoreFiltersChip
            className="more-filters"
            label={`+${appliedFilters.length - 2} more`}
            onMouseEnter={handleMoreFiltersToggle}
            onClick={handleMoreFiltersToggle}
          />
        )}
      </AppliedFilters>
      <StyledOverlayPanel ref={moreConditionOp}>
        <PopupContent>
          {appliedFilters.map((filter, index) => (index >= 2 ? (
            <StyledChip
              key={`additional-filter-${filter.field}`}
              label={formatFilterLabel(filter)}
              removable
              onRemove={() => removeAppliedFilter(index)}
            />
          ) : null))}
        </PopupContent>
      </StyledOverlayPanel>
    </FilterContainer>
  );
}

AdvancedFilter.propTypes = {
  onFilterApplied: PropTypes.func,
};

export default AdvancedFilter;
