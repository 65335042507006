import React, { Component } from 'react';
import {
  Container, Row, Col, Nav, NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { tabs } from '../../constants/tabs';

import PhoneNumber from './PhoneNumber';
import WhatsappNumber from './WhatsappNumber';

class Numbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabs.TEXT,
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleMenu() {
    this.setState((prevState) => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    // meta title
    document.title = 'inbox';

    const { activeTab } = this.state;

    return (
      <div className="">
        <Container fluid>
          <h4 className="mb-2 font-size-17">Phone Numbers</h4>

          <Row>
            <Col lg="12">
              <div className="crypto-buy-sell-nav">
                <Nav tabs className="nav-tabs-custom" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === tabs.TEXT,
                      })}
                      onClick={() => {
                        this.toggleTab(tabs.TEXT);
                      }}
                    >
                      <i className="bx bx-message-alt-dots font-size-18 me-1" />
                      Text
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === tabs.WHATSAPP,
                      })}
                      onClick={() => {
                        this.toggleTab(tabs.WHATSAPP);
                      }}
                    >
                      <i className="bx bxl-whatsapp font-size-18 me-1" />
                      Whatsapp
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="">
                {activeTab === tabs.TEXT ? <PhoneNumber activeTab={activeTab} /> : ''}
                {activeTab === tabs.WHATSAPP ? <WhatsappNumber activeTab={activeTab} /> : ''}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Numbers;
