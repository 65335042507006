import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSenderGroups } from '../../../services/api/bulkSms.service';
import { PAGE, SIZE_PER_PAGE } from '../../../constants/constants';
import Select from '../../Select';
import { showToast } from '../../../services/toastService';
import { SOMETHING_WENT_WRONG } from '../../../constants/error.constant';

function SenderGroupSelect({ onSelect, selectedValue, ...rest }) {
  const [{
    options,
    selectedOption,
    isLoading,
    page,
    limit,
    totalPages,
    searchQuery,
  }, setStates] = useState({
    options: [],
    selectedOption: {
      label: selectedValue?.name,
      value: selectedValue?.id,
      group: selectedValue,
    },
    isLoading: false,
    page: PAGE,
    limit: SIZE_PER_PAGE,
    totalPages: 0,
    searchQuery: '',
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 'unset',
    }),
  };

  const handleState = (key, value) => {
    setStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (newValue) => {
    handleState('selectedOption', newValue);
    onSelect(newValue?.group);
  };

  const handleGetSenderGroups = async () => {
    try {
      handleState('isLoading', true);
      const response = await getSenderGroups({
        page,
        limit,
        searchQuery,
      });
      if (response?.status) {
        const resultOptions = response?.data?.results?.map((group) => ({
          label: group?.name,
          value: group?.id,
          group,
        }));
        setStates((prevState) => {
          const newOptions = resultOptions.filter(
            (option) => !prevState.options?.some((group) => group?.value === option.value),
          );
          return {
            ...prevState,
            options: [...prevState.options, ...newOptions],
            isLoading: false,
            page: prevState.page + 1,
            totalPages: response.data.totalPages,
          };
        });
      } else {
        showToast({
          type: 'error',
          content: response?.message || SOMETHING_WENT_WRONG,
        });
      }
    } catch (error) {
      showToast({
        type: 'error',
        content: error?.message || SOMETHING_WENT_WRONG,
      });
    } finally {
      handleState('isLoading', false);
    }
  };

  const customNoOptionsMessage = () => <span className="">No Sender Id Available</span>;

  const handleMenuScrollToBottom = () => {
    if (!isLoading && page <= totalPages) {
      handleGetSenderGroups();
    }
  };

  const handleSearch = (searchValue) => {
    setStates((prevState) => ({
      ...prevState,
      page: 1,
      searchQuery: searchValue,
    }));
  };

  useEffect(() => {
    handleGetSenderGroups();
  }, [searchQuery]);

  return (
    <Select
      label="Sender ID"
      options={options}
      value={selectedOption}
      onChange={handleChange}
      styles={customStyles}
      placeholder="Select Sender Id..."
      noOptionsMessage={customNoOptionsMessage}
      isLoading={isLoading}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onInputChange={handleSearch}
      className="mb-3"
      containerStyle={{
        width: '100%',
      }}
      {...rest}
    />
  );
}

SenderGroupSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedValue: PropTypes.object,
};

export default SenderGroupSelect;
