// eslint-disable-next-line import/no-unresolved
import React, { Component } from 'react';
import {
  Card, CardBody, Col, Container, Row, Badge,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BADGE_COLORS, TEXT_COLORS } from '../../constants/status.constant';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { callType } from '../../constants/voice/call.constant';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import { axiosPost } from '../../services/http.service';
import LineChart from '../../components/Charts/LineChart'; // Import the LineChart component
import Pie from '../../components/Charts/PieChart'; // Import your reusable Pie component
import FilterComponent from './FilterComponent'; // Import the FilterComponent
import { setLoader } from '../../store/actions';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import DataGrid from '../../components/DataTable';

class CallBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        selectedUser: null,
        startDate: moment().subtract(30, DATE_CONSTANTS.UNITS.DAYS).toDate(),
        endDate: moment().toDate(),
      },
      page: PAGE,
      size: SIZE_PER_PAGE,
      filterData: [],
      totalSize: TOTAL_SIZE,
      totalInboundCallCost: 0,
      totalOutboundCallCost: 0,
      selectedRows: [],
      columns: [
        {
          accessorKey: 'createdAt',
          header: 'Date',
          Cell: ({ renderedCellValue }) => {
            const formattedDate = moment(renderedCellValue).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
        {
          accessorKey: 'from',
          header: 'From',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          accessorKey: 'to',
          header: 'To',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Type',
          accessorKey: 'type',
          Cell: ({ row }) => {
            const originalRow = row?.original;
            if (originalRow.type === callType.INBOUND) {
              return (
                <Badge color="success-subtle" pill className="font-size-12 text-success">
                  <i className="mdi mdi-phone-incoming" />
                  {' '}
                  Inbound
                </Badge>
              );
            }
            return (
              <Badge color="primary-subtle" pill className="font-size-12 text-primary">
                <i className="mdi mdi-phone-outgoing" />
                {' '}
                Outbound
              </Badge>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          Cell: ({ row }) => {
            const log = row?.original;
            const statusKey = log?.status?.toLowerCase().replace(/ /g, '_');
            return (
              <Badge
                color={BADGE_COLORS[statusKey] || 'default-subtle'} // Fallback to default
                pill
                className={`font-size-12 ${TEXT_COLORS[statusKey] || 'text-default'}`}
              >
                {log?.status}
              </Badge>
            );
          },
        },
        { accessorKey: 'duration', header: 'Duration' },
        { accessorKey: 'callSid', header: 'Call ID' },
        {
          accessorKey: 'cost',
          header: 'Cost',
          Cell: ({ renderedCellValue }) => <span>{`$${renderedCellValue}`}</span>,
        },
        {
          header: 'Voicemail',
          accessorKey: 'voiceRecordingUrl',
          Cell: ({ renderedCellValue }) => (
            <audio controls>
              <source src={renderedCellValue} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.getCallHistoryStats();
  }

  handleFilterChange = (filters) => {
    this.setState({ filters }, () => {
      this.getCallHistoryStats();
    });
  };

  getCallHistoryStats = async () => {
    const { onSetLoader } = this.props;
    const { page, size, filters } = this.state;
    const filter = {
      dateRange: {
        startDate: filters?.startDate,
        endDate: filters?.endDate,
      },
      userId: filters?.selectedUser?.value,
    };
    onSetLoader(true);
    try {
      const response = await axiosPost(`/call-histories/billing?page=${page}&limit=${size}`, {
        filter,
      });
      if (response?.status) {
        this.setState({
          filterData: response.data.results,
          totalSize: response.data.totalPages,
          totalInboundCallCost: response.data.totalInboundCallCost,
          totalOutboundCallCost: response.data.totalOutboundCallCost,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('Error at getCallHistoryStats:', error);
      onSetLoader(false);
    }
  };

  getPieChartData = () => {
    const { totalInboundCallCost, totalOutboundCallCost } = this.state;
    return [
      { name: 'Inbound Cost', value: totalInboundCallCost },
      { name: 'Outbound Cost', value: totalOutboundCallCost },
    ];
  };

  getLineChartData = (filteredData) => {
    // Create a map to store aggregated costs by date and type
    const dateMap = {};

    filteredData.forEach((data) => {
      // Extract the date part from the createdAt timestamp
      const date = data.createdAt.split('T')[0]; // Format: YYYY-MM-DD

      if (!dateMap[date]) {
        dateMap[date] = { inbound: 0, outbound: 0 };
      }

      // Aggregate costs based on the call type
      if (data.type === callType.INBOUND) {
        dateMap[date].inbound += data.cost;
      } else if (data.type === callType.OUTBOUND) {
        dateMap[date].outbound += data.cost;
      }
    });

    // Sort dates and prepare data for the line chart
    const dates = Object.keys(dateMap).sort();
    const inboundCostData = dates.map((date) => dateMap[date].inbound);
    const outboundCostData = dates.map((date) => dateMap[date].outbound);

    return {
      xAxisData: dates,
      seriesNames: ['Inbound', 'Outbound'],
      data: [inboundCostData, outboundCostData],
    };
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.getCallHistoryStats();
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getCallHistoryStats();
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    const {
      filterData, filters, columns, totalSize, selectedRows,
    } = this.state;
    const pieChartData = this.getPieChartData(filterData);
    const lineChartData = this.getLineChartData(filterData);

    return (
      <Container fluid>
        <div className="page-content">
          <Breadcrumbs title="Billing" breadcrumbItem="Calls" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Pie data={pieChartData} title="Call Cost Breakdown" name="Cost Breakdown" />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="shadow-sm border border-light-subtle">
                <CardBody className="">
                  <FilterComponent
                    showCampaigns={false}
                    initialFilters={filters}
                    onApplyFilter={this.handleFilterChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <LineChart
                    title="Call Expense Analysis"
                    yAxisName="Cost ($)"
                    xAxisName="Date"
                    data={lineChartData.data}
                    xAxisData={lineChartData.xAxisData}
                    seriesNames={lineChartData.seriesNames}
                    loading={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="mt-3">
                    <DataGrid
                      data={filterData}
                      columns={columns}
                      enableColumnResizing={false}
                      enableColumnFilterModes
                      enableEditing={false}
                      enableGlobalFilter={false}
                      selectedRows={selectedRows}
                      onRowSelectionChange={this.handleRowSelection}
                      totalSize={totalSize}
                      onPageChange={this.handlePageClick}
                      onPageSizeChange={this.handleLimitChange}
                      initialPage={0}
                      initialPageSize={10}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
CallBilling.propTypes = {
  onSetLoader: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (loading) => dispatch(setLoader(loading)),
});
export default connect(null, mapDispatchToProps)(CallBilling);
