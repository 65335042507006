import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button,
} from 'reactstrap';
import { FormSelect } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { axiosGet, axiosPatch } from '../../services/http.service';
import { setLoader, updateAlert } from '../../store/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  ALERT_FILTER_OPTIONS,
  ALERT_TYPES,
  BASE_MESSAGES,
  MESSAGE_TYPE_ICONS,
} from '../../constants/inbox/message.constant';
import { randomString } from '../../utils/general';
import DataGrid from '../../components/DataTable';

class AlertCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: [],
      searchQuery: '',
      filter: 'all',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      selectedRows: [],
      columns: [
        {
          header: 'Alert For',
          accessorKey: 'alertType',
          Cell: ({ renderedCellValue }) => {
            const alertType = ALERT_TYPES[renderedCellValue];
            return <span className="d-flex align-items-center">{alertType}</span>;
          },
          sort: true,
        },
        {
          header: 'Alert Type',
          accessorKey: 'messageType',
          Cell: ({ renderedCellValue }) => {
            const iconClass = MESSAGE_TYPE_ICONS[renderedCellValue] || '';
            const message = BASE_MESSAGES[renderedCellValue] || renderedCellValue;
            return (
              <span className="d-flex align-items-center">
                <i className={`font-size-18 me-1 ${iconClass}`} />
                {message}
              </span>
            );
          },
          sort: true,
        },
        {
          header: 'Message',
          accessorKey: 'message',
        },
        {
          header: 'Timestamp',
          accessorKey: 'createdAt',
          Cell: ({ renderedCellValue }) => (
            <span>{this.formatLastMessageTime(renderedCellValue)}</span>
          ),
        },
        {
          accessorKey: 'actions',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const alert = row?.original;
            return (
              <div className="d-flex align-items-center gap-3">
                <div
                  className="d-flex align-items-center justify-self-start alert-tab-actions"
                  // style={{ width: "70px" }}
                >
                  {!alert.isRead ? (
                    <Button
                      size="sm"
                      outline
                      onClick={() => this.toggleRead(alert)}
                      className="d-flex align-items-center justify-content-start"
                      color="primary"
                    >
                      <i className="bx bx-check me-1" />
                      Read
                    </Button>
                  ) : (
                    <Button
                      className="d-flex align-items-center justify-content-start"
                      color="success"
                      outline
                      disabled
                      size="sm"
                    >
                      <i className="bx bx-check-double me-1" />
                      Seen
                    </Button>
                  )}
                </div>

                <div className="mx-2 action-partition" />

                <div className="d-flex align-items-center me-4 alert-tab-actions">
                  {!alert.isArchived ? (
                    <Button
                      size="sm"
                      outline
                      onClick={() => this.toggleArchive(alert)}
                      className="d-flex align-items-center justify-content-start "
                      color="primary"
                    >
                      <i className="mdi mdi-archive me-1" />
                      Archive
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      outline
                      disabled
                      className="d-flex align-items-center justify-content-start "
                      color="dark"
                    >
                      <i className="mdi mdi-archive me-1" />
                      Archived
                    </Button>
                  )}
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetAllAlerts(page);
  }

  formatLastMessageTime = (timestamp) => moment(timestamp).fromNow();

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllAlerts(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetAllAlerts(1, newPageSize);
    });
  };

  handleFilterChange = (event) => {
    const { page } = this.state;
    this.setState({ filter: event.target.value, page: 1 }, () => {
      this.handleGetAllAlerts(page);
    });
  };

  toggleRead = async (alert) => {
    const { page } = this.state;
    const { onUpdateAlert } = this.props;
    try {
      const response = await axiosPatch(`/alert/${alert.id}`, {
        isRead: !alert.isRead,
      });
      if (response?.status) {
        onUpdateAlert({ ...alert, isRead: !alert.isRead });
        this.handleGetAllAlerts(page);
      }
    } catch (error) {
      console.error('Error toggling read status:', error);
    }
  };

  toggleArchive = async (archiveAlert) => {
    const { page } = this.state;
    try {
      const response = await axiosPatch(`/alert/${archiveAlert.id}`, {
        isArchived: !archiveAlert.isArchived,
      });
      if (response?.status) {
        if (archiveAlert.isArchived) {
          this.setState((prevState) => ({
            alerts: prevState.alerts.filter((alert) => alert.id !== archiveAlert.id),
          }));
          this.handleGetAllAlerts(page);
        } else {
          this.setState((prevState) => ({
            alerts: prevState.alerts.map((alert) => (alert.id === archiveAlert.id ? { ...alert, isArchived: true } : alert)),
          }));
        }
      }
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllAlerts(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  handleGetAllAlerts = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, filter, size } = this.state;
    const indexKey = randomString();
    try {
      onSetLoader(true);

      let filterParams = '';
      switch (filter) {
        case 'read':
          filterParams = '&isRead=true';
          break;
        case 'unread':
          filterParams = '&isRead=false';
          break;
        case 'archived':
          filterParams = '&isArchived=true';
          break;
        default:
          filterParams = '';
      }

      const response = await axiosGet(
        `/alert?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }${filterParams}&sortBy=createdAt:desc`,
      );
      if (response.status) {
        this.setState({
          alerts: response.data.results.map((alert) => ({
            ...alert,
            tableKey: `alert-${alert.id || indexKey}`,
          })),
          totalSize: response.data.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('Error at handleGetAllAlerts:', error);
      onSetLoader(false);
    }
  };

  render() {
    document.title = 'Alert Center';

    const {
      alerts, columns, totalSize, filter, selectedRows,
    } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Alerts" breadcrumbItem="Alerts" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Col sm="12" className="d-flex justify-content-end align-items-center">
                    <div className="filter-select w-25 mb-3">
                      <FormSelect
                        value={filter}
                        onChange={this.handleFilterChange}
                        aria-label="Filter alerts"
                      >
                        {ALERT_FILTER_OPTIONS.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </FormSelect>
                    </div>
                  </Col>
                  <div className="mt-3">
                    <DataGrid
                      data={alerts}
                      columns={columns}
                      enableColumnResizing={false}
                      enableColumnFilterModes
                      enableGlobalFilter
                      enableEditing={false}
                      selectedRows={selectedRows}
                      onRowSelectionChange={this.handleRowSelection}
                      totalSize={totalSize}
                      onPageChange={this.handlePageClick}
                      onPageSizeChange={this.handleLimitChange}
                      initialPage={0}
                      initialPageSize={10}
                      onSearch={this.debounceSearch}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AlertCenter.propTypes = {
  onSetLoader: PropTypes.func,
  onUpdateAlert: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onUpdateAlert: (alert) => dispatch(updateAlert(alert)),
});

const mapStateToProps = (state) => ({
  alerts: state.notification.alerts,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlertCenter));
