import React, { Component } from 'react';
import styled from 'styled-components';
import { Image } from 'primereact/image';
import PropTypes from 'prop-types';

// Styled container for the image
const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

class DisplayImage extends Component {
  render() {
    const { src, alt, className } = this.props; // Get the image source from props

    return (
      <DivContainer>
        <Image src={src} alt={alt} className={className} />
      </DivContainer>
    );
  }
}

DisplayImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

DisplayImage.defaultProps = {
  className: 'd-flex justify-content-center',
  alt: 'no Data Available',
};
export default DisplayImage;
