import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';

const StyledMultiSelect = styled(PrimeMultiSelect)`
  width: 100%;
  min-width: 200px;
  .p-multiselect {
    padding: 0.3rem;
  }
  .p-multiselect-label {
    color: #495057;
  }
`;

const DEFAULT_PLACEHOLDER = 'Select';
const DEFAULT_OPTION_LABEL = 'label';

function MultiSelect({
  options,
  value,
  onChange,
  placeholder = DEFAULT_PLACEHOLDER,
  disabled = false,
  filter = true,
  loading = false,
  display,
  optionLabel = DEFAULT_OPTION_LABEL,
  optionGroupLabel,
  optionGroupChildren,
  className,
  group = false,
  itemTemplate,
}) {
  const handleChange = (event) => {
    try {
      onChange(event);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledMultiSelect
      value={value}
      options={options}
      onChange={handleChange}
      placeholder={placeholder}
      display={display}
      disabled={disabled}
      filter={filter}
      loading={loading}
      optionLabel={optionLabel}
      optionGroupLabel={group ? optionGroupLabel : undefined}
      optionGroupChildren={group ? optionGroupChildren : undefined}
      className={className}
      itemTemplate={itemTemplate}
    />
  );
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  itemTemplate: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  filter: PropTypes.bool,
  loading: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionGroupLabel: PropTypes.string,
  optionGroupChildren: PropTypes.string,
  group: PropTypes.bool,
  className: PropTypes.string,
  display: PropTypes.string,
};

export default MultiSelect;
