import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { axiosGet } from '../../services/http.service';
import {
  getDataFromLocalStorage,
  parseJwt,
} from '../../helpers/commonHelpers';

export const UserContext = React.createContext({});

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      userData: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getUserinfo();
  }

  getUser = async (id) => {
    try {
      const users = await axiosGet(`users/${id}`);
      if (users?.status) {
        this.setState({ userData: users?.data });
      }
      this.setState({ isLoading: false });
      return users?.data;
    } catch (error) {
      console.error('error while getting user :', error);
      return null;
    }
  };

  getUserinfo = async () => {
    const { history } = this.props;
    try {
      const token = getDataFromLocalStorage('accessToken');

      if (token) {
        const userinfo = parseJwt(token);
        this.setState({ user: userinfo });
        const data = await this.getUser(userinfo?.sub);
        return data;
      }
      this.setState({ isLoading: false });
      return null;
    } catch (err) {
      if (err === 'Please authenticate') {
        localStorage.clear();
        history.push('/login');
      }
      return null;
    }
  };

  getContextValue = () => {
    const { user, userData } = this.state;
    return {
      user,
      getUserinfo: this.getUserinfo,
      setUser: (newUser) => this.setState({ user: newUser }),
      userData,
      setUserdata: (newUserData) => this.setState({ userData: newUserData }),
    };
  };

  render() {
    const { isLoading } = this.state;
    const { children } = this.props;
    return (
      <UserContext.Provider value={this.getContextValue()}>
        {isLoading ? null : children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.any,
};

export default withRouter(UserProvider);
