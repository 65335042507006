import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { renderUserAvatar } from '../../../../helpers/commonHelpers';
import { axiosPatch, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import { setShowGroupFormModal } from '../../../../store/contacts/actions';
import UserSelect from '../../../../components/Common/UserSelect';
import { ROLES } from '../../../../constants/users/role.constant';

class GroupForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowGroupFormModal } = this.props;
    onSetShowGroupFormModal();
  };

  createNewGroup = async (groupName) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllContactGroups } = this.props;
      const response = await axiosPost('contact-groups', { name: groupName });
      if (response?.status) {
        toast.success(response?.message || 'Contact Group Created Successfully!', {
          position: 'top-right',
        });
        getAllContactGroups();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while creating new contact group :', error);
    }
  };

  editGroup = async (editedData) => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllContactGroups, group } = this.props;
      const response = await axiosPatch(`contact-groups/${group?.id}`, editedData);
      if (response?.status) {
        toast.success(response?.message || 'Group edited successfully!', {
          position: 'top-right',
        });
        getAllContactGroups();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while updating contact group :', error);
    }
  };

 onSubmit = (values) => {
   const {
     isEdit, group,
   } = this.props;
   const { userData } = this.context;
   if (isEdit) {
     const isGroupNameChanged = group?.name !== values?.groupName;
     const isUserNameChanged = userData?.role === ROLES.ADMIN
        && group?.id !== values?.assignedUserId.value;

     if (!isGroupNameChanged && !isUserNameChanged) {
       // No changes, you might want to display a message or handle it accordingly
       this.toggle();
       return;
     }

     const data = {};

     if (isGroupNameChanged) {
       data.name = values?.groupName;
     }

     if (userData?.role === ROLES.ADMIN && isUserNameChanged) {
       const { value, name } = values?.assignedUserId;
       data.assignedUserId = value;
       data.assignedUserName = name;
     }

     this.editGroup(data);
   } else {
     this.createNewGroup(values?.groupName);
   }
 };

 render() {
   const {
     isEdit, showGroupFormModal, group, className,
   } = this.props;
   const { showModalLoader } = this.state;
   const { userData } = this.context;

   const mapToSelectOption = (groupData) => ({
     value: groupData.assignedUserId,
     label: (renderUserAvatar(groupData.assignedUserName)
     ),
   });

   const initialValues = {
     groupName: group?.name || '',
     ...(isEdit && userData?.role === ROLES.ADMIN
       ? {
         assignedUserId: group.assignedUserId
           ? mapToSelectOption(group)
           : '',
       }
       : {}),
   };

   const validationSchema = Yup.object().shape({
     groupName: Yup.string().required('Please enter group name'),
     ...(isEdit
        && userData?.role === ROLES.ADMIN && {
       assignedUserId: Yup.object().required('Please enter the user name'),
     }),
   });

   return (
     <Modal isOpen={showGroupFormModal} className={className}>
       {showModalLoader ? <ModalLoader /> : ''}
       <ModalHeader toggle={this.toggle} tag="h4">
         {isEdit ? 'Edit Group' : 'Add Group'}
       </ModalHeader>
       <ModalBody>
         <Formik
           enableReinitialize
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={this.onSubmit}
         >
           {({
             errors, touched, values, setFieldValue,
           }) => (
             <Form>
               <Row>
                 <Col className="col-12">
                   <div className="mb-3">
                     <Label className="form-label">Group Name</Label>
                     <Field
                       name="groupName"
                       type="text"
                       className={`form-control${
                         errors.groupName && touched.groupName ? ' is-invalid' : ''
                       }`}
                     />
                     <ErrorMessage name="groupName" component="div" className="invalid-feedback" />
                   </div>
                   {isEdit && userData?.role === ROLES.ADMIN && (
                   <div className="mb-3">
                     <Label className="form-label">User Name</Label>
                     <UserSelect
                       className={`${
                         errors.assignedUserId && touched.assignedUserId ? ' is-invalid' : ''
                       }`}
                       selectedOptions={
                            values.assignedUserId
                          }
                       onChange={(selectedOption) => {
                         setFieldValue(
                           'assignedUserId',
                           selectedOption,
                         );
                       }}
                     />
                     <ErrorMessage
                       name="assignedUserId"
                       component="div"
                       className="invalid-feedback"
                     />
                   </div>
                   )}
                 </Col>
               </Row>
               <Row>
                 <Col>
                   <div className="text-end">
                     <button type="submit" className="btn btn-success save-user">
                       Save
                     </button>
                   </div>
                 </Col>
               </Row>
             </Form>
           )}
         </Formik>
       </ModalBody>
     </Modal>
   );
 }
}

GroupForm.propTypes = {
  className: PropTypes.any,
  onSetShowGroupFormModal: PropTypes.func,
  getAllContactGroups: PropTypes.func,
  group: PropTypes.object,
  isEdit: PropTypes.bool,
  showGroupFormModal: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
});

const mapStateToProps = ({ contacts }) => ({
  showGroupFormModal: contacts.showGroupFormModal,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupForm));
