/* eslint-disable import/prefer-default-export */
// NOTE: `BMESSAGE`: Broadcast message
import {
  SET_BMESSAGE_STEP_ONE_DATA,
  SET_BMESSAGE_STEP_TWO_DATA,
  SET_BMESSAGE_STEP_THREE_DATA,
  SET_STEP_COMPLETION_BMESSAGE,
  SET_ACTIVE_INDEX_OF_BMESSAGE_FORM,
  RESET_BMESSAGE_FORM,
  SET_BMESSAGE_CONTACT_GROUPS,
} from './actionTypes';

export const setStepCompletionBMessage = (stepCompletionArray) => ({
  type: SET_STEP_COMPLETION_BMESSAGE,
  payload: { stepCompletionArray },
});

export const setActiveIndexOfBMessageForm = (activeIndex) => ({
  type: SET_ACTIVE_INDEX_OF_BMESSAGE_FORM,
  payload: { activeIndex },
});

export const setBMessageStepOneData = (formData) => ({
  type: SET_BMESSAGE_STEP_ONE_DATA,
  payload: formData,
});

export const setBMessageStepTwoData = (formData) => ({
  type: SET_BMESSAGE_STEP_TWO_DATA,
  payload: formData,
});

export const setBMessageContactGroups = (contactGroupData) => ({
  type: SET_BMESSAGE_CONTACT_GROUPS,
  payload: contactGroupData,
});

export const setBMessageStepThreeData = (formData) => ({
  type: SET_BMESSAGE_STEP_THREE_DATA,
  payload: formData,
});

export const resetBMessageForm = (template) => ({
  type: RESET_BMESSAGE_FORM,
  payload: template,
});
