/* eslint-disable import/prefer-default-export */
export const INBOX_FILTER = {
  isAnsweredByCustomer: 'Read',
  isOpenedByUser: 'Unread',
};

export const NOTIFICATION_TYPES = {
  SMS: 'SMS',
  WHATSAPP: 'Whatsapp',
};

export const MEDIA_ICONS = {
  IMAGE: 'mdi mdi-image',
  VIDEO: 'mdi mdi-video',
  APPLICATION: 'mdi mdi-file-document',
  AUDIO: 'mdi mdi-microphone',
};
