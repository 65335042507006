import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { axiosDel, axiosGet } from '../../services/http.service';
import DeleteModal from '../../components/Common/DeleteModal';
import MessageForm from './Modal/messageForm';
import { setLoader } from '../../store/actions';
import setShowQuickSmsModal from '../../store/quick-message/actions';
import { sliceStringWithEllipsis } from '../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import DataGrid from '../../components/DataTable';

class QuickMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickMessages: [],
      quickMessage: '',
      isEdit: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      quickMessageListColumns: [
        {
          header: 'Name',
          accessorKey: 'name',
        },
        {
          header: 'Template Text',
          accessorKey: 'content',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            const slicedContent = sliceStringWithEllipsis(30, renderedCellValue);
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {renderedCellValue}
                </UncontrolledTooltip>
                <span id={tooltipId}>{slicedContent}</span>
              </>
            );
          },
        },
        {
          header: 'Created On',
          accessorKey: 'createdAt',
          Cell: ({ row }) => {
            const quickMessage = row?.original;
            const formattedDate = moment(quickMessage?.createdAt).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(quickMessage?.createdAt).format(
              DATE_CONSTANTS.FORMATS.HH_MM_SS,
            );
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
        {
          accessorKey: 'menu',
          header: 'Action',
          enableSorting: false,
          Cell: ({ row }) => {
            const quickMessage = row?.original;
            return (
              <div className="d-flex gap-3">
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18 text-success"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      id="edit"
                      onClick={() => this.handleUserClick(quickMessage)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="danger-subtle" className="text-success">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete"
                      onClick={() => this.onClickDelete(quickMessage)}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetAllQuickMessages(page);
  }

  toggle = () => {
    const { onSetShowQuickSmsModal } = this.props;
    onSetShowQuickSmsModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handleUserClicks = () => {
    this.setState({ quickMessage: '', isEdit: false });
    this.toggle();
  };

  onClickDelete = (quickmessage) => {
    this.setState({ quickMessage: quickmessage, deleteModal: true });
  };

  handleUserClick = (arg) => {
    const quickMessage = arg;
    this.setState({
      quickMessage,
      isEdit: true,
    });
    this.toggle();
  };

  /* Insert,Update Delete data */

  handleGetAllQuickMessages = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const response = await axiosGet(
        `quick-message?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        onSetLoader(false);
        this.setState({
          quickMessages: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleGetAllQuickMessages :', error);
    }
  };

  handleDeleteQuickMessages = async () => {
    try {
      const { quickMessage, page } = this.state;
      const response = await axiosDel(`quick-message/${quickMessage?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Quick message Deleted successfully!');
        this.handleGetAllQuickMessages(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error('error at handleGetAllQuickMessages :', error);
    }
  };

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllQuickMessages(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetAllQuickMessages(1, newPageSize);
    });
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllQuickMessages(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'quick-sms';

    const {
      deleteModal,
      isEdit,
      quickMessage,
      quickMessages,
      page,
      quickMessageListColumns,
      totalSize,
      selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteQuickMessages}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <MessageForm
          isEdit={isEdit}
          quickMessage={quickMessage}
          getAllQuickMessages={() => {
            this.handleGetAllQuickMessages(page);
          }}
        />
        <div className="">
          <Container fluid>
            <h4 className="mb-2 font-size-17">Canned Messages</h4>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end me-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Quick Sms
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={quickMessages}
                        columns={quickMessageListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

QuickMessage.propTypes = {
  onSetShowQuickSmsModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = ({ quickMessage }) => ({
  showQuickSmsModal: quickMessage.showQuickSmsModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowQuickSmsModal: () => dispatch(setShowQuickSmsModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickMessage));
