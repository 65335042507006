import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Container, Row, Button, Badge,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
import { axiosDel, axiosGet } from '../../../services/http.service';
import socketService from '../../../utils/socket';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setShowGroupFormModal } from '../../../store/contacts/actions';

import GroupForm from './Modal/GroupForm';
import { setLoader } from '../../../store/actions';
import { FIELD_NAMES } from '../../../constants/field.constant';
import DataGrid from '../../../components/DataTable';

class ContactGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactGroups: [],
      group: '',
      isEdit: false,
      groupIdToEdit: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      contactListColumns: [
        {
          header: 'User Name',
          accessorKey: 'assignedUserName',
        },
        {
          header: 'Group Name',
          accessorKey: 'name',
        },
        {
          header: 'Total Contacts',
          accessorKey: 'contactIds',
          Cell: ({ renderedCellValue, row }) => {
            const originalRow = row?.original;
            return (
              <div>
                <Badge color="primary-subtle" pill className="text-primary pt-1 fs-6">
                  {originalRow?.status === 'pending' ? 'Updating...' : renderedCellValue?.length}
                </Badge>
              </div>
            );
          },
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const contactGroup = row?.original;
            return (
              <div className="d-flex gap-3">
                <Badge color="success-subtle" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    id="edit"
                    onClick={() => this.handleGroupEdit(contactGroup)}
                  />
                </Badge>
                <Link
                  // eslint-disable-next-line react/destructuring-assignment
                  to={`/view-contacts/${contactGroup?.id}?page=${this.state.page}`}
                >
                  <Badge color="primary-subtle" className="text-primary">
                    <i
                      className="mdi mdi-eye-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View Contacts"
                      id="viewContacts"
                    />
                  </Badge>
                </Link>
                <Badge color="warning-subtle" className="text-warning">
                  <i
                    className={`mdi mdi-download-circle-outline font-size-18  ${
                      // eslint-disable-next-line react/destructuring-assignment
                      this.state.group?.id === contactGroup?.id ? 'cursor-not-allowed' : ''
                    }`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Download Contacts"
                    id="downloadContacts"
                    onClick={() => {
                      // eslint-disable-next-line react/destructuring-assignment
                      if (this.state.group?.id !== contactGroup?.id) {
                        this.downloadCSV(contactGroup);
                      }
                    }}
                  />
                </Badge>
                <Badge color="danger-subtle" className="text-danger">
                  <i
                    className="mdi mdi-trash-can-outline font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    onClick={() => this.onClickDelete(contactGroup)}
                  />
                </Badge>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    // Create a new URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(window.location.search);
    const { page } = this.state;

    // Accessing the value of the 'id' parameter
    const contactGroupPage = queryParams.get('page');
    if (contactGroupPage) {
      this.setState({ page: contactGroupPage }, () => {
        this.handleAllContactGroups(contactGroupPage);
      });
    } else {
      this.handleAllContactGroups(page);
    }

    socketService.on('contact-group', (data) => {
      toast.success(data?.message);
      this.handleAllContactGroups(page);
    });
  }

  deleteGroupById = async () => {
    const { page, group } = this.state;
    try {
      const response = await axiosDel(`contact-groups/${group?.id}`);
      if (response?.status) {
        toast.success(response?.message, {
          position: 'top-right',
        });
        this.handleAllContactGroups(page);
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message, {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('error while adding user :', error);
    }
  };

  onClickDelete = (contactGroup) => {
    this.handleUserClick(contactGroup);
    this.setState({ deleteModal: true });
  };

  handleUserClick = (arg) => {
    const group = arg;

    this.setState({
      group: {
        id: group.id,
        name: group.name,
        assignedUserName: group.assignedUserName,
        assignedUserId: group.assignedUserId,
      },
    });
  };

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleAllContactGroups(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleAllContactGroups(1, newPageSize);
    });
  };

  handleGroupEdit = (group) => {
    const { onSetShowGroupFormModal } = this.props;
    this.setState({ isEdit: true, groupIdToEdit: group?.id });
    this.handleUserClick(group);
    onSetShowGroupFormModal();
  };

  handleAddGroup = () => {
    const { onSetShowGroupFormModal } = this.props;
    this.setState({ isEdit: false });
    onSetShowGroupFormModal();
  };

  /* Insert,Update Delete data */

  handleAllContactGroups = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;

    try {
      onSetLoader(true);
      const groups = await axiosGet(
        `contact-groups?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (groups?.status) {
        onSetLoader(false);
        this.setState({
          contactGroups: groups?.data?.results,
          totalSize: groups?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting all contact groups :', error);
    }
  };

  deleteGroupById = async () => {
    const { page, group } = this.state;
    try {
      const response = await axiosDel(`contact-groups/${group?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Group Deleted!', {
          position: 'top-right',
        });
        this.handleAllContactGroups(page);
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('error while deleting a contact group :', error);
    }
  };

  getAllContactsInContactGroup = async (id) => {
    try {
      const groups = await axiosGet(`contact-groups/contacts/${id}`);
      if (groups?.status) {
        return groups?.data?.contactIds;
      }
      return null;
    } catch (error) {
      console.error('error while getting group contacts :', error);
      return null;
    }
  };

  downloadCSV = async (group) => {
    this.setState({ group });
    const id = toast.loading(`Downloading ${group?.name}...`, {
      closeOnClick: true,
      autoClose: 3000,
    });
    try {
      const contacts = await this.getAllContactsInContactGroup(group?.id);
      if (contacts?.length > 0) {
        const headersString = FIELD_NAMES.reduce((str, header, index) => {
          // eslint-disable-next-line no-param-reassign
          str += index === FIELD_NAMES?.length - 1 ? header : `${header},`;
          return str;
        }, '');

        const valuesString = contacts
          .map((callObj) => {
            const val = FIELD_NAMES.map(
              (header) => callObj?.[header]?.trim()?.replace(/["|.|,]/g, '') || '-',
            );

            const str = val.join(',').replace(/\n/g, '');
            return str;
          })
          .join('\n');

        const csvData = `${headersString}\n${valuesString}`;

        // Create a Blob and initiate download
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `${group.name}_contacts.csv`);
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        toast.update(id, {
          render: `Downloaded ${group?.name}!`,
          type: 'success',
          isLoading: false,
        });
        this.setState({ group: '' });
      } else {
        toast.update(id, {
          render: 'No Data available!',
          type: 'error',
          isLoading: false,
        });
        this.setState({ group: '' });
        // toast.error("No Data available!");
      }
    } catch (error) {
      console.error('error at downloadCSV: ', error);
      this.setState({ group: '' });
      toast.update(id, {
        render: 'Oops something went wrong!',
        type: 'error',
        isLoading: false,
      });
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleAllContactGroups(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'Contact Groups';

    const {
      group,
      contactGroups,
      isEdit,
      groupIdToEdit,
      deleteModal,
      page,
      contactListColumns,
      totalSize,
      selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.deleteGroupById()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <GroupForm
          isEdit={isEdit}
          id={isEdit ? groupIdToEdit : ''}
          group={isEdit ? group : ''}
          getAllContactGroups={() => {
            this.handleAllContactGroups(page);
          }}
        />
        <div className="">
          <Container fluid>
            <h4 className="mb-2 font-size-17">Contact Groups</h4>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleAddGroup}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New Group
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={contactGroups}
                        columns={contactListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ContactGroups.propTypes = {
  onSetShowGroupFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ contacts }) => ({
  showGroupFormModal: contacts.showGroupFormModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactGroups));
