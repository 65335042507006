/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { handleOnboardingStepCompletion } from '../../../utils/onboarding';
import { axiosGet, axiosPost } from '../../../services/http.service';
import ModalLoader from '../../../components/Common/ModalLoader';
import { setShowWhatsappNumberModal } from '../../../store/phone-number/actions';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import { STEPS_NAME } from '../../../constants/onboarding.constant';
import { setOnboardingData as setOnboardingDataAction } from '../../../store/actions';
import UserSelect from '../../../components/Common/UserSelect';
import PhoneNumberSelector from '../PhoneNumberList/NumberList';

const initialValues = {
  wabaId: '',
};

const validationSchema = Yup.object().shape({
  wabaId: Yup.string().required('Please enter your WhatsApp Business Account ID'),
});

const initialValues2 = {
  selectedNumber: {},
  user: '',
};

const validationSchema2 = Yup.object().shape({
  selectedNumber: Yup.object().required('Please select the phone number'),
  user: Yup.object().required('Please select a user'),
});
class ManageWhatsappNumber extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      wabaId: '',
      availableNumbers: [],
      showSpinner: false,
    };
  }

  toggle = () => {
    const { onSetShowWhatsappNumberModal } = this.props;
    onSetShowWhatsappNumberModal();
    this.setState({ wabaId: '', availableNumbers: [] });
  };

  // To get available numbers from twilio using area code and number type
  handleGetAvailableNumbers = async (wabaId) => {
    try {
      this.setState({ wabaId });
      this.setState({ showSpinner: true });
      const numbers = await axiosGet(
        `whatsapp-numbers/available-numbers?whatsappBusinessAccountId=${wabaId}`,
      );
      if (numbers?.status) {
        if (numbers?.data.length > 0) {
          this.setState({
            availableNumbers: numbers?.data,
          });
          this.setState({ showSpinner: false });
        } else {
          toast.error(numbers?.message || 'No Numbers Available', {
            position: 'top-right',
          });
          this.setState({ showSpinner: false });
        }
      } else {
        toast.error(numbers?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
        this.setState({ showSpinner: false });
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      console.error('error while getting available twilio numbers :', error);
    }
  };

  // To buy a twilio number
  handleWhatsappNumberAddition = async (values) => {
    try {
      const { selectedNumber } = values;
      const {
        alias, number, id, verifiedName,
      } = selectedNumber;
      const {
        getAllNumbers, isOnboarding, history, setOnboardingData,
      } = this.props;
      const { wabaId } = this.state;
      const { userData } = this.context;
      const organizationId = userData?.organizationId;
      const data = {
        assignedUserId: values?.user.value,
        whatsappBusinessAccountId: wabaId,
        phoneNumber: number.replace(/[\(\)\-\s]/g, ''),
        numberId: id,
        name: verifiedName,
        phoneAlias: alias,
      };
      const response = await axiosPost('whatsapp-numbers', data);
      if (response?.status) {
        toast.success(response?.message || 'Whatsapp Number added successfully!', {
          position: 'top-right',
        });
        if (isOnboarding) {
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.ADD_PHONE_NUMBER,
            organizationId,
            history,
            setOnboardingData,
          });
        }
        getAllNumbers();
        this.setState({ availableNumbers: [], wabaId: '' });
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! Something went wrong!', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('error at handleWhatsappNumberAddition :', error);
    }
  };

  // Formik onSubmit handler
  handleOnSubmit = (values) => {
    this.handleGetAvailableNumbers(values.wabaId);
  };

  render() {
    const { showWhatsappNumberModal, className, activeTab } = this.props;
    const { availableNumbers, showSpinner } = this.state;
    return (
      <Modal isOpen={showWhatsappNumberModal} className={className}>
        {showSpinner ? <ModalLoader /> : ''}

        <ModalHeader toggle={this.toggle} tag="h4">
          Add Whatsapp Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">WhatsApp Business Account ID</Label>
                      <Field
                        name="wabaId"
                        type="text"
                        className={`form-control${
                          errors.wabaId && touched.wabaId ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="wabaId" component="div" className="invalid-feedback" />
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user d-flex justify-content-center"
                      >
                        Get Numbers
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {availableNumbers.length > 0 ? (
            <Formik
              enableReinitialize
              initialValues={initialValues2}
              validationSchema={validationSchema2}
              onSubmit={this.handleWhatsappNumberAddition}
            >
              {({
                errors, touched, setFieldValue, values,
              }) => (
                <Form>
                  {' '}
                  <Row>
                    <Col>
                      <Label className="form-label px-0">Associated Phone Number</Label>
                    </Col>
                  </Row>
                  <Row className="">
                    {/* {availableNumbers.map((phoneNumber) => (
                      <Col
                        sm={6}
                        key={`${phoneNumber?.display_phone_number}`}
                        className=""
                      >
                        <Label className="d-flex align-items-center">
                          <Field
                            type="radio"
                            name="selectedNumber"
                            value={`${phoneNumber?.display_phone_number} | ${phoneNumber?.id} | ${phoneNumber?.verified_name}`}
                            className={
                                `me-2${
                                  errors.user && touched.user
                                    ? ' is-invalid'
                                    : ''}`
                              }
                          />
                          {phoneNumber.display_phone_number}
                        </Label>
                      </Col>
                    ))} */}
                    <PhoneNumberSelector
                      availableNumbers={availableNumbers}
                      onChange={(selectedNumbers) => {
                        setFieldValue('selectedNumber', selectedNumbers);
                      }}
                      activeTab={activeTab}
                    />
                  </Row>
                  <ErrorMessage name="selectedNumber" component="div" className="error" />
                  <Row>
                    <div className="mb-3">
                      <Label className="form-label">User</Label>
                      <UserSelect
                        className={`${errors.user && touched.user ? ' is-invalid' : ''}`}
                        selectedOptions={values.user}
                        onChange={(option) => {
                          setFieldValue('user', option);
                        }}
                      />
                      <ErrorMessage name="user" component="div" className="invalid-feedback" />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success save-user">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            ''
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ManageWhatsappNumber.propTypes = {
  onSetShowWhatsappNumberModal: PropTypes.func,
  getAllNumbers: PropTypes.func,
  showWhatsappNumberModal: PropTypes.bool,
  className: PropTypes.any,
  isOnboarding: PropTypes.bool,
  history: PropTypes.object,
  setOnboardingData: PropTypes.func,
  activeTab: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowWhatsappNumberModal: () => dispatch(setShowWhatsappNumberModal()),
  setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
});

const mapStateToProps = ({ phoneNumber, onboarding }) => ({
  showWhatsappNumberModal: phoneNumber.showWhatsappNumberModal,
  isOnboarding: onboarding.isOnboarding,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageWhatsappNumber));
