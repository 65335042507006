import React, { Component } from 'react';

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

// actions

import { axiosPatch } from '../../services/http.service';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { showToast as showToastAction } from '../../store/actions';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { SOMETHING_WENT_WRONG } from '../../constants/error.constant';

class ChangePassword extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showOldPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      error: '',

    };
  }

  handleChangePassword = async (values) => {
    try {
      const { history, showToast } = this.context;
      const { userData } = this.context;
      const response = await axiosPatch(
        `/users/change-password/${userData?.id}`,
        {
          oldPassword: values?.oldPassword,
          password: values?.password,
        },
      );
      if (response?.status) {
        showToast({
          type: 'success',
          message: response?.message || 'Change password successfully',
          title: 'Change Password',
          duration: 3000,
        });
        history.push('/');
      } else {
        console.error('ChangePassword API error: ', response?.message);
        this.setState({ error: response?.message || SOMETHING_WENT_WRONG });
      }
    } catch (error) {
      console.error('ChangePassword error: ', error);
      this.setState({ error: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  handleStates = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      showConfirmPassword, showPassword, showOldPassword, error, oldPassword, password, confirmPassword,
    } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" breadcrumbItem="Change Password" />
          <Row className="justify-content-center mt-4">
            <Col>
              <Card className="overflow-hidden">
                <CardBody className="pt-0 mt-4">
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}
                    <Formik
                      enableReinitialize
                      initialValues={{
                        oldPassword: this.state && oldPassword,
                        password: this.state && password,
                        confirmPassword:
                          this.state && confirmPassword,
                      }}
                      validationSchema={Yup.object().shape({
                        oldPassword: Yup.string().required(
                          'Old password is required',
                        ),
                        password: Yup.string()
                          .notOneOf(
                            [Yup.ref('oldPassword'), null],
                            'New Password cannot be the same as Old Password',
                          )
                          .required('New Password is required'),
                        confirmPassword: Yup.string()
                          .oneOf(
                            [Yup.ref('password'), null],
                            'Passwords must match',
                          )
                          .required('Confirm Password is required'),
                      })}
                      onSubmit={this.handleChangePassword}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3 d-flex">
                            <Label for="oldPassword" className="form-label col-1">
                              Old Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup width-50rem">
                              <Field
                                name="oldPassword"
                                type={showOldPassword ? 'text' : 'password'}
                                autoComplete="true"
                                className={
                                  `form-control${errors.oldPassword && touched.oldPassword
                                    ? ' is-invalid'
                                    : ''}`
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() => this.handleStates(
                                  'showOldPassword',
                                  !showOldPassword,
                                )}
                              >
                                {showOldPassword ? (
                                  <i className="mdi mdi-eye-outline" />
                                ) : (
                                  <i className="mdi mdi-eye-off-outline" />
                                )}
                              </button>
                              <ErrorMessage
                                name="oldPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="mb-3 mt-4 d-flex">
                            <Label for="password" className="form-label col-1">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup width-50rem">
                              <Field
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="true"
                                className={
                                  `form-control${errors.password && touched.password
                                    ? ' is-invalid'
                                    : ''}`
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() => this.handleStates(
                                  'showPassword',
                                  !showPassword,
                                )}
                              >
                                {showPassword ? (
                                  <i className="mdi mdi-eye-outline" />
                                ) : (
                                  <i className="mdi mdi-eye-off-outline" />
                                )}
                              </button>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="mb-3 d-flex">
                            <Label
                              for="confirmPassword"
                              className="form-label col-1"
                            >
                              Confirm Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup width-50rem">
                              <Field
                                name="confirmPassword"
                                type={
                                  showConfirmPassword ? 'text' : 'password'
                                }
                                autoComplete="true"
                                className={
                                  `form-control${errors.confirmPassword
                                    && touched.confirmPassword
                                    ? ' is-invalid'
                                    : ''}`
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() => this.handleStates(
                                  'showConfirmPassword',
                                  !showConfirmPassword,
                                )}
                              >
                                {showConfirmPassword ? (
                                  <i className="mdi mdi-eye-outline" />
                                ) : (
                                  <i className="mdi mdi-eye-off-outline" />
                                )}
                              </button>
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="mt-3 row justify-content-end">
                            <Col sm={11}>
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Change Password
                              </button>
                            </Col>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ChangePassword.propTypes = {};

const mapStateToProps = () => { };

export default withRouter(
  connect(mapStateToProps, { showToast: showToastAction })(ChangePassword),
);
