export const MESSAGE_TYPES = {
  SEND: 'send',
  RECEIVED: 'received',
  QUEUED: 'queued',
};

export const MESSAGE_TYPE_ICONS = {
  SCHEDULED: 'mdi mdi-clock text-warning',
  CREATED: 'mdi mdi-check-circle text-success',
  ERROR: 'mdi mdi-alert-octagon text-danger',
  INITIALIZING: 'mdi mdi-information text-info',
};

export const BASE_MESSAGES = {
  SCHEDULED: 'Scheduled',
  CREATED: 'Created',
  ERROR: 'Error',
  INITIALIZING: 'Initializing',
};

export const ALERT_TYPES = {
  CAMPAIGN: 'Campaign',
  'CONTACT-GROUP': 'Contact-group',
};

export const ALERT_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Read', value: 'read' },
  { label: 'Unread', value: 'unread' },
  { label: 'Archived', value: 'archived' },
];

export const SMS_CHARACTER_COUNT = 150;
export const WHATSAPP_CHARACTER_COUNT = 1024;

export const SCHEDULE_TYPES = {
  SCHEDULED_LATER: 'scheduled_later',
  SEND_IMMEDIATELY: 'send_immediately',
};

export const SCHEDULED_LATER_OBJECT = {
  label: 'Schedule Later',
  value: SCHEDULE_TYPES.SCHEDULED_LATER,
  icon: 'bx bx-time fs-6',
  text: 'Send Later',
};

export const SCHEDULED_IMMEDIATELY_OBJECT = {
  label: 'Send Immediately',
  value: SCHEDULE_TYPES.SEND_IMMEDIATELY,
  icon: 'bx bxs-rocket fs-6',
  text: 'Send Now',
};

export const SCHEDULE_TYPES_ARRAY = [SCHEDULED_LATER_OBJECT, SCHEDULED_IMMEDIATELY_OBJECT];
