export const callType = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};

export const CALL_TYPES = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const DEFAULT_NAME = 'Unknown';
export const KEYPAD_BUTTONS = [
  { number: '1', letters: '' },
  { number: '2', letters: 'ABC' },
  { number: '3', letters: 'DEF' },
  { number: '4', letters: 'GHI' },
  { number: '5', letters: 'JKL' },
  { number: '6', letters: 'MNO' },
  { number: '7', letters: 'PQRS' },
  { number: '8', letters: 'TUV' },
  { number: '9', letters: 'WXYZ' },
  { number: '*', letters: '' },
  { number: '0', letters: '+' },
  { number: '#', letters: '' },
];

export const DEFAULT_RINGING_TEXT = 'Ringing...';
