import React from 'react';
import { Link } from 'react-router-dom';
import SidebarContent from './SidebarContent';

import Logo from '../../assets/images/logo.png';
import LogoIcon from '../../assets/images/logo-icon.png';

function Sidebar() {
  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo">
          <span className="logo-sm">
            <img src={LogoIcon} alt="" height="35" className="me-5" />
          </span>
          <span className="logo-lg d-flex justify-content-center align-items-center mt-4">
            <img src={Logo} alt="" height="48" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SidebarContent />
      </div>
      <div className="sidebar-background" />
    </div>
  );
}

export default Sidebar;
