import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Col, Label, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import * as Yup from 'yup';
import ModalLoader from '../../../components/Common/ModalLoader';
import { LIMIT } from '../../../constants/constants';
import { axiosGet, axiosPatch } from '../../../services/http.service';
import { setShowAssignNumberModal } from '../../../store/sender-group/actions';

class AssignNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twilioNumbers: [],
      selectedTwilioNumbers: [],
      showModalLoader: false,
    };
  }

  componentDidMount() {
    this.getSenderGroupById();
  }

  toggle = () => {
    const { onSetShowAssignNumberModal } = this.props;
    onSetShowAssignNumberModal();
  };

  handleMulti = (selectedTwilioNumbers) => {
    this.setState({ selectedTwilioNumbers });
  };

  getAllTwilioNumbers = async () => {
    try {
      const response = await axiosGet(`numbers?page=1&limit=${LIMIT}`);
      if (response?.status) {
        const formattedOptions = response?.data?.results?.map((option) => ({
          value: option.id,
          label: option.phoneNumber,
        }));
        this.setState({ twilioNumbers: formattedOptions });
      }
    } catch (error) {
      console.error('Error while getting twilio numbers:', error);
    }
  };

  getSenderGroupById = async () => {
    try {
      const { group } = this.props;
      const response = await axiosGet(`sender-groups/${group?.id}?page=1&limit=${LIMIT}`);
      if (response?.status) {
        const formattedOptions = response?.data?.numberIds?.map((option) => ({
          value: option.id,
          label: option.phoneNumber,
        }));
        this.setState({ selectedTwilioNumbers: formattedOptions }, () => this.getAllTwilioNumbers());
      }
    } catch (error) {
      console.error('Error while getting sender group by id:', error);
    }
  };

  handleAssignNumbers = async () => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllSenderGroups, group } = this.props;
      const { selectedTwilioNumbers } = this.state;
      const numberIds = selectedTwilioNumbers?.map((option) => option?.value);
      const response = await axiosPatch(`sender-groups/${group?.id}`, {
        numberIds,
      });
      if (response?.status) {
        toast.success(response?.message || 'Number assigned successfully!', {
          position: 'top-right',
        });
        getAllSenderGroups();
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! Something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('Error while assigning number:', error);
    }
  };

  render() {
    const { showAssignNumberModal, group, className } = this.props;
    const { twilioNumbers, selectedTwilioNumbers, showModalLoader } = this.state;

    // Define initial values
    const initialValues = {
      groupName: group?.name || '',
    };

    // Define validation schema
    const validationSchema = Yup.object().shape({
      groupName: Yup.string().required('Please enter group name'),
    });

    // Define submit handler
    const onSubmit = () => {
      if (selectedTwilioNumbers.length > 0) {
        this.handleAssignNumbers();
      } else {
        toast.error('Select a number first!', {
          position: 'top-right',
        });
      }
    };

    return (
      <Modal isOpen={showAssignNumberModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          Assign Number
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Group Name</Label>
                      <Field
                        name="groupName"
                        type="text"
                        disabled
                        className={`form-control${
                          errors.groupName && touched.groupName ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="groupName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Primary Number</Label>
                      <Select
                        value={selectedTwilioNumbers}
                        isMulti
                        onChange={this.handleMulti}
                        options={twilioNumbers}
                        classNamePrefix="select2-selection"
                        noOptionsMessage={() => 'No numbers available'}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

AssignNumber.propTypes = {
  className: PropTypes.any,
  onSetShowAssignNumberModal: PropTypes.func,
  showAssignNumberModal: PropTypes.bool,
  group: PropTypes.object,
  getAllSenderGroups: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowAssignNumberModal: () => dispatch(setShowAssignNumberModal()),
});

const mapStateToProps = ({ senderGroup }) => ({
  showAssignNumberModal: senderGroup.showAssignNumberModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignNumber));
